import React, { FC, useEffect, useMemo } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { TaxonomyType } from "data/types";
import CardCategory3 from "components/CardCategory3/CardCategory3";
import CardCategory4 from "components/CardCategory4/CardCategory4";
import NextPrev from "shared/NextPrev/NextPrev";
import CardCategory5 from "components/CardCategory5/CardCategory5";
import useNcId from "hooks/useNcId";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";




export interface SectionSliderNewCategories4Props {
    className?: string;
    itemClassName?: string;
    heading?: string;
    subHeading?: string;
    categories?: TaxonomyType[];
    categoryCardType?: "card3" | "card4" | "card5";
    itemPerRow?: 4 | 5;
    sliderStyle?: "style1" | "style2";
    uniqueClassName: string;
}

const DEMO_CATS: TaxonomyType[] = [
    {
        id: "1",
        href: "/",
        name: "Breakfast",
        taxonomy: "category",
        /* count: 17288,*/
        text: "",
        thumbnail:
            "http://localhost:3000/static/media/chinese.db4b8b8ad202e2cd1e1b.png",
    },
    {
        id: "2",
        href: "/",
        name: "Lunch",
        taxonomy: "category",
        /* count: 2118,*/
        text: "",
        thumbnail:
            "http://localhost:3000/static/media/koreanfood.767c42db3dc1d8e96882.png",
    },
    {
        id: "3",
        href: "/",
        name: "Dinner",
        taxonomy: "category",
        /*count: 36612,*/
        text: "",
        thumbnail:
            "http://localhost:3000/static/media/carribeanfood.161c00e0c1198d149e50.png",
    },
    {
        id: "4",
        href: "/",
        name: "Indian Food",
        taxonomy: "category",
        /*count: 188288,*/
        text: "",
        thumbnail:
            "http://localhost:3000/static/media/indianfood.f44c5a2ef450a886266c.png",
    },
    {
        id: "5",
        href: "/",
        name: "Japanese",
        taxonomy: "category",
        /*count: 188288,*/
        text: "",
        thumbnail:
            "http://localhost:3000/static/media/japanesefood.bb5e80554e8f7c84a1fb.png",
    },
    {
        id: "6",
        href: "/",
        name: "Japanese",
        taxonomy: "category",
        /*count: 188288,*/
        text: "",
        thumbnail:
            "http://localhost:3000/static/media/japanesefood.bb5e80554e8f7c84a1fb.png",
    },
];

const SectionSliderNewCategories4: FC<SectionSliderNewCategories4Props> = ({
    heading = "",
    subHeading = "",
    className = "",
    itemClassName = "",
    categories = DEMO_CATS,
    itemPerRow = 6,
    categoryCardType = "card3",
    sliderStyle = "style1",
    uniqueClassName,


}) => {
    const UNIQUE_CLASS =
        "SectionSliderNewCategories4__" + uniqueClassName + useNcId();

    let MY_GLIDEJS = useMemo(() => {
        return new Glide(`.${UNIQUE_CLASS}`, {
            perView: itemPerRow,
            gap: 12,
            bound: true,
            breakpoints: {
                1280: {
                    perView: itemPerRow - 1,
                },
                1024: {
                    gap: 20,
                    perView: itemPerRow - 1,
                },
                768: {
                    gap: 20,
                    perView: itemPerRow - 2,
                },
                640: {
                    gap: 20,
                    perView: itemPerRow - 3,
                },
                500: {
                    gap: 20,
                    perView: 1.3,
                },
            },
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        setTimeout(() => {
            MY_GLIDEJS.mount();
        }, 100);
    }, [MY_GLIDEJS, UNIQUE_CLASS]);

    const renderCard = (item: TaxonomyType, index: number) => {
        switch (categoryCardType) {

            case "card4":
                return <CardCategory4 taxonomy={item} />;
            case "card5":
                return <CardCategory5 taxonomy={item} />;
            default:
                return <CardCategory4 taxonomy={item} />;
        }
    };

    return (
        <div className={`nc-SectionSliderNewCategories4 ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
                <div className="flex justify-between items-center ">
                    <div className="font text-black font-bold text-2xl pb-4">
                        Popular Activities
                    </div>
                    <div>
                        <Heading
                            hasNextPrev={sliderStyle === "style1"}
                            isCenter={sliderStyle === "style2"}  >
                        </Heading>
                        {sliderStyle === "style2" && (
                            <NextPrev className="justify-center mt-16" />
                        )}
                    </div>
                </div>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides ">
                        {categories.map((item, index) => (
                            <li key={index} className={`glide__slide  ${itemClassName}`}>

                                {renderCard(item, index)}
                            </li>
                        ))}
                    </ul>
                </div>

            </div>

        </div>
    );
};

export default SectionSliderNewCategories4;
