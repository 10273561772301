import CardCategoryBoxx from "components/CardCategoryBox1/CardCategoryBoxx";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React from "react";
import Badge from "shared/Badge/Badge";

export interface SectionGridCategoryHotelProps {
    categories?: TaxonomyType[];
    headingCenter?: boolean;
    categoryCardType?: "card1";
    className?: string;
    gridClassName?: string;
}

const DEMO_CATS: TaxonomyType[] = [
    {
        id: "2",
        href: "dubai-tour-packages",
        name: "Dubai",
        taxonomy: "category",
        count: 22323,
        text: "United Arab Emirates",
        thumbnail:
            "https://i.ibb.co/L8Rdfzc/11.webp",
    },
    {
        id: "1",
        href: "/enquiry",
        name: "Singapore",
        taxonomy: "category",
        count: 12882,
        text: "Singapore",
        thumbnail:
            "https://i.ibb.co/GM9kdnF/12.jpg",
    },
    {
        id: "2",
        href: "/enquiry",
        name: "Abu Dhabi",
        taxonomy: "category",
        count: 28288,
        text: "United Arab Emirates",
        thumbnail:
            "https://i.ibb.co/cX3xt68/13.jpg",
    },

    {
        id: "2",
        href: "/enquiry",
        name: "Kuala Lumpur",
        taxonomy: "category",
        count: 21112,
        text: "Malaysia",
        thumbnail:
            "https://i.ibb.co/tmRGGCQ/17.jpg",
    },
    {
        id: "2",
        href: "/enquiry",
        name: "Bangkok",
        taxonomy: "category",
        count: 19288,
        text: "Thailand",
        thumbnail:
            "https://i.ibb.co/SBHTG2v/14.webp",
    },

    {
        id: "2",
        href: "/enquiry",
        name: "Sydney",
        taxonomy: "category",
        count: 29223,
        text: "Australia",
        thumbnail:
            "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
    },
    {
        id: "11",
        href: "/enquiry",
        name: "Istanbul",
        taxonomy: "category",
        count: 17775,
        text: "Turkey",
        thumbnail:
            "https://i.ibb.co/sKQTXLc/15.webp",
    },
    {
        id: "222",
        href: "/enquiry",
        name: "Hanoi",
        taxonomy: "category",
        count: 19288,
        text: "Vietnam",
        thumbnail:
            "https://i.ibb.co/qBmh6TZ/16.jpg",
    },
];

const SectionGridCategoryHotel: React.FC<SectionGridCategoryHotelProps> = ({
    categories = DEMO_CATS,
    categoryCardType = "card1",
    headingCenter = true,
    className = "",
    gridClassName = "grid-cols-2 lg:gap-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 md:grid-cols-3  xl:grid-cols-4",
}) => {
    let CardComponentName = CardCategoryBoxx;
    switch (categoryCardType) {
        case "card1":
            CardComponentName = CardCategoryBoxx;
            break;

        default:
            CardComponentName = CardCategoryBoxx;
    }

    return (
        <div className={`font nc-SectionGridCategoryBox relative ${className}`}>
            <div className="lg:font-semibold font-medium font lg:text-2xl text-xl">
                Trip Ideas from TrawelMart
            </div>
            <div className="font font-regular lg:text-base text-sm text-neutral-60 pt-1">
                Travel and Tours Organisation in Dubai, Vietnam and Thailand
            </div>
            <div className={`grid ${gridClassName}gap-5 xl:gap-3 lg:gap-2 sm:gap-2 md:gap-2 lg:mt-8 mt-4`}>
                {categories.map((item, i) => (
                    <CardComponentName key={i} taxonomy={item} />

                ))}
            </div>
        </div>
    );
};

export default SectionGridCategoryHotel;
