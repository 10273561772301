import React, { useState, Fragment, useEffect, useRef } from "react";
import { Transition, Dialog, Popover } from "@headlessui/react";
import NavMobile from "shared/Navigation/NavMobile";
import { useLocation, useParams } from "react-router-dom";
import { CalendarIcon, ChevronDownIcon, ClockIcon, MapPinIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import ButtonClose from "shared/ButtonClose/ButtonClose";

export interface MenuBarProps {
  className?: string;
  iconClassName?: string;
}
const MenuBar: React.FC<MenuBarProps> = ({
  className = "p-1 rounded-lg text-neutral-700 dark:text-neutral-300",
  iconClassName = "h-5 w-5",
}) => {
  const [isVisable, setIsVisable] = useState(false);
  const [isVisablee, setIsVisablee] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setIsVisable(false);
    setIsVisablee(false);
  }, [location]);


  const { place, month } = useParams<{ place?: string, month?: string }>();
  const placeValue = place ?? '';
  const monthValue = month ?? '';

  const handleOpenMenu = () => setIsVisable(true);
  const handleCloseMenu = () => setIsVisable(false);
  const handleOpenMenu1 = () => setIsVisablee(true);
  const handleCloseMenu1 = () => setIsVisablee(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [placeText, setPlaceText] = useState("");
  const [showPopover, setShowPopover] = useState(false);
  const [locationText, setLocationText] = useState(placeValue);
  const handleSelectLocation = (item: string) => {
    setLocationText(item);
    setPlaceText(item);
    setShowPopover(false);
  };

  const [startDate, setStartDate] = useState<Date | null>(new Date("2024/02/06"));
  const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const [selectedMonth, setSelectedMonth] = useState<string | null>(monthValue);

  const renderInput = () => {
    return (
      <>
     
        <div className="text-neutral-300 dark:text-neutral-400 p-4 mt-2 mb-1">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left font">
          <span className="block text-base font-semibold">
            {selectedMonth ? `${selectedMonth} ${startDate?.getFullYear() || ""}` : "Travel Date"}
          </span>
          <span className="block mt-2 mb-2 text-sm text-neutral-400 font-light  leading-none">
            {"Select Month"}
          </span>
        </div>
      </>
    );
  };

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
  };

  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August", "September", "October", "November", "December"
  ];

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  useEffect(() => {
    if (showPopover) {
      setTimeout(() => {
        setShowPopover(false);
      }, 4000);
    }
  }, [showPopover])

  const renderContent = () => {
    return (
      <Transition appear show={isVisable} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 overflow-hidden"
          onClose={handleCloseMenu}
        >
          <Transition.Child
            as={Fragment}
            enter=" duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave=" duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex justify-end min-h-full ">
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 translate-x-56"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-56"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden transition-all ">
                  <NavMobile onClickClose={handleCloseMenu} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };


  const renderSearchh = () => {
    return (

      <Transition appear show={isVisablee} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 overflow-y-scroll"
          onClose={handleCloseMenu1}
        >
          <Transition.Child
            as={Fragment}
            enter=" duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave=" duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-neutral-900 bg-opacity-50" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex justify-end min-h-full ">
              <Transition.Child
                as={Fragment}
                enter="transition duration-100 transform"
                enterFrom="opacity-0 translate-x-56"
                enterTo="opacity-100 translate-x-0"
                leave="transition duration-150 transform"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-56"
              >
                <Dialog.Panel className="container bg-white w-full max-w-md transform overflow-hidden transition-all ">
                  {/* <NavMobile onClickClose={handleCloseMenu1} /> */}
                  <span className="absolute right-2 top-2 p-1 bg-neutral-100 rounded-full">
                    <ButtonClose onClick={handleCloseMenu1} />
                  </span>
                  <h2 className="font-semibold font1  text-xs mt-20 text-center bg-[#fff0ff] py-2 uppercase">
                    Your desired tours are only a search away
                  </h2>
                  <div
                    className={`bg-font nc-HeroSearchForm w-full max-w-7xl py-5 lg:py-0 ${className}`}
                  >
                    <form className="w-full relative lg:flex md:flex mt-0 ">
                      <div className="xl:w-7/12 md:w-7/12 border border-neutral-300 rounded-xl mr-2">
                        <div className={`relative flex lg:-mt-2 mx-2`} ref={containerRef}>
                          <div
                            onClick={() => setShowPopover(true)}
                            className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "" : ""
                              }`}/*nc-hero-field-focused =====${showPopover ? "" inside the "" */
                          >
                            <div className="text-neutral-300 dark:text-neutral-400">
                              <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                            </div>
                            <div className="flex-grow font">
                              <input
                                className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                                placeholder='Destinations'
                                value={placeText}
                                autoFocus={showPopover}
                                onChange={(e) => {
                                  setPlaceText(e.currentTarget.value);
                                }}
                                ref={inputRef}
                              />
                              <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                                <span className="line-clamp-1">{!!locationText ? 'Destinations' : 'Where do you want to travel?'}</span>
                              </span>
                              {locationText && showPopover && (
                                <ClearDataButton
                                  onClick={() => {
                                    setPlaceText("");
                                    (window as any).location = '/';
                                  }}
                                />
                              )}
                            </div>
                          </div>

                          {showPopover && (
                            <div
                              className={``}
                            ></div>
                          )}

                          {showPopover && (
                            <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                              {locationText ?
                                <>
                                  {[
                                    "Dubai",
                                    "Bali",
                                    "Singapore",
                                    "Vietnam",
                                  ].map((item) => (
                                    <span
                                      onClick={() => handleSelectLocation(item)}
                                      key={item}
                                      className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                    >
                                      <span className="block text-neutral-400">
                                        <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
                                      </span>
                                      <span className="block font-medium text-neutral-700 dark:text-neutral-200">
                                        {item}
                                      </span>
                                    </span>
                                  ))}
                                </> :
                                <>
                                  <h3 className="font block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
                                    Recent searches
                                  </h3>
                                  <div className="mt-2">
                                    {[
                                      "Dubai",
                                      "Bali",
                                      "Singapore",
                                      "Vietnam",
                                    ].map((item) => (
                                      <span
                                        onClick={() => handleSelectLocation(item)}
                                        key={item}
                                        className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
                                      >
                                        <span className="block text-neutral-400">
                                          <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
                                        </span>
                                        <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                                          {item}
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                </>
                              }
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="self-center  h-8"></div>

                      <div className="xl:w-5/12 md:w-5/12 border border-neutral-300 rounded-xl mr-2 xl:-mt-0 lg:-mt-0 md:-mt-0 -mt-6">

                        <Popover className={`StayDatesRangeInput z-10 relative flex`}>
                          {({ open }) => (
                            <>
                              <Popover.Button
                                className={` p-1 z-10 flex-1 flex justify-between relative items-center focus:outline-none  ${open ? "" : ""}`}
                                onClickCapture={() => document.querySelector("html")?.click()}
                              >
                                {renderInput()}
                                {startDate && open && (
                                  <ClearDataButton onClick={() => onChangeDate([null, null])} />
                                )}
                              </Popover.Button>

                              {/* BUTTON SUBMIT OF FORM */}


                              {open && (
                                <div className=" absolute self-center"></div>
                              )}

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                              >
                                <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                                  <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                                    <div className="flex-grow text-left">
                                      {/* <span className="block xl:text-lg font-semibold">
                                      {selectedMonth || "Travel Date"}
                                    </span> */}
                                      <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                        {"Select Month"}
                                      </span>
                                    </div>
                                    <div className="grid grid-cols-4 gap-0 border-t">
                                      {months.map((month, index) => (
                                        <div key={index} className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base" onClick={() => handleMonthClick(month)}>
                                          <h3 className="font-medium font">{month.substr(0, 3)}</h3>
                                          <p className="text-neutral-500 font1 text-base font-semibold">2024</p>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </Popover.Panel>
                              </Transition>
                            </>
                          )}
                        </Popover>
                        <div className=""></div>
                      </div>


                      <div className="flex justify-between mt-2">
                        <div className=" pl-4 py-4">
                          <a className="text-xs underline underline-offset-4 font uppercase" href="/">
                            Skip
                          </a>
                        </div>
                        <div className="pr-2 xl:pl-28 py-4">
                          <button className="gradient2 px-8 py-2 text-white text-xs uppercase font1 rounded-md">
                            <a href={(placeText === '') ? '#!' : "/filter-details/" + placeText + '/' + ((selectedMonth === null || selectedMonth === '') ? '' : selectedMonth)}>
                              Search
                            </a>
                          </button>



                        </div>
                      </div>



                    </form>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

  return (
    <>
      <div className="flex items-center xl:hidden md:hidden lg:hidden block">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          aria-hidden="true"
          className="flex-shrink-0 w-8 h-8 gradient2 rounded-lg text-white p-2"
          onClick={handleOpenMenu1}>
          <path stroke-linecap="round"
            stroke-linejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"></path>
        </svg>
      </div>
      <button
        onClick={handleOpenMenu}
        className={`focus:outline-none flex items-center items-start border rounded-md border-neutral-400 ${className}`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={iconClassName}
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            clipRule="evenodd"
          />
        </svg>
      </button>

      {renderContent()}
      {renderSearchh()}
    </>
  );
};

export default MenuBar;
