import React, { FC } from "react";
import facebookSvg from "images/Facebook.svg";
import twitterSvg from "images/Twitter.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import email from "images/email.png"
import lock from "images/padlock.png"
import google from "images/search.png"
import facebook from "images/facebook.png"



export interface PageLoginProps {
  className?: string;
}

const loginSocials = [
  {
    name: "Continue with Facebook",
    href: "#",
    icon: facebookSvg,
  },
  {
    name: "Continue with Twitter",
    href: "#",
    icon: twitterSvg,
  },
  {
    name: "Continue with Google",
    href: "#",
    icon: googleSvg,
  },
];

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {
  return (
    <div className={`font nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Sign Up || Trawel Mart</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 border border-gray-200 max-w-xl rounded-lg py-10 m-auto px-10 shadow-xl mt-10">
        <h2 className="font mb-3 text-sky-600  text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold justify-center">
          Create an Account
        </h2>
        <p className="font">
          Whatsup! Please fill in this form to Sign up
        </p>
        <div className="max-w-xl mx-auto space-y-2">
          {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className="nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
          {/* OR */}




          {/* FORM */}
          <form className="grid grid-cols-1 gap-4" action="#" method="post">

            <div className="flex ">
              <label className="block">
                <div className=" bg-white border border-gray-300  px-2 mt-5  py-1 rounded-lg mr-2">
                  <Input type="text" placeholder="Firstname" className="border-0" />
                </div>
              </label>
              <label className="block">
                <div className=" bg-white border border-gray-300  px-2 mt-5  py-1 rounded-lg ml-2">
                  <Input type="text" placeholder="Lastname" className="border-0" />
                </div>
              </label>
            </div>

            <label className="block">
              <div className=" bg-white border border-gray-300  px-2   py-1 rounded-lg">
                <Input type="email" placeholder="Email" className="border-0" />
              </div>
            </label>


            <label className="block">
              <div className="flex items-center bg-white border border-gray-300 px-2  py-1 rounded-lg">
                <Input type="password" placeholder="Create Password" className="border-0" />
              </div>
            </label>

            <label className="block">
              <div className="flex items-center bg-white border border-gray-300 px-2  py-1 rounded-lg">
                <Input type="password" placeholder="Confirm Password" className="border-0" />
              </div>
            </label>
            <div className="flex items-center justify-between">
              <div className="text-sm">
                <input type="checkbox" className="mr-2" /><span>I read and agree to terms and conditions</span>
              </div>
            </div>
            <label className="block mt-4">
              <button type="submit" className="w-full py-2 bg-sky-600 rounded-lg text-white ">
                Sign Up
              </button>
            </label>
            <div className="relative text-center">
              <span className="relative z-10 inline-block px-4 font-normal text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900 text-gray-400">
                Or Sign up through
              </span>
              <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800">
              </div>
            </div>
            <div className=" flex items-center justify-between mt-2">
              <div className="font flex items-center justify-center bg-white border border-gray-200 px-4 py-2  w-full mx-4 rounded-md">
                <img src={google} className="w-6 h-6 mr-3 " />
                <h2 className="text-base font-normal">Google</h2>
              </div>
              <div className="flex items-center justify-center bg-white border border-gray-200 px-4 py-2 w-full mx-4 rounded-md">
                <img src={facebook} className="w-6 h-6 mr-3 " />
                <h2 className="text-base font-normal">Facebook</h2>
              </div>
            </div>
          </form>

          {/* ==== */}
          <span className="text-sm pt-2 block text-center text-neutral-700 dark:text-neutral-300">
            Already have an account? {` `}
            <Link to="/login"><a>Log in</a></Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
