// Popup.js
import React, { FC, useState, Fragment, useEffect, FormEvent } from "react";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import axios from "axios";
import toastr from "toastr";
import Input from "shared/Input/Input";
import popupbanners from "images/sample/popupbanners.jpg";
import { useLocation } from "react-router-dom";
import coin from "images/ICONS/coin.png";
import { Dialog } from "@headlessui/react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import authentication from "firebaseConfig";
import { Popover, Transition } from "@headlessui/react";
import india from "images/india.png";

const PagePopup = () => {
  // signup
  let [inOpen, setInOpen] = useState(false);
  function incloseModal() {
    setInOpen(false);
  }

  function inopenModal() {
    setInOpen(true);
  }

  const [mobileNumberEntry, setMobileNumberEntry] = useState(true);
  const [OTPEntry, setOTPEntry] = useState(false);
  const [signUpEntry, setSignUpEntry] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [OTP, setOTP] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isMobileError, setIsMobileError] = useState(false);
  // const [finalVerificationId, setFinalVerificationId] = useState<string | null>(null);

  // const auth = getAuth()
  // const recaptchaVerifierRef = useRef<firebase.auth.RecaptchaVerifier | null>(null);

  const generateRecaptcha = () => {
    (window as any).recaptchaVerifier = new RecaptchaVerifier(
      authentication,
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response: any) => {
          console.log(response);
        },
        "expired-callback": () => {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
          console.log("Expired");
        },
      }
    );
  };

  const sendOTP = async () => {
    setIsMobileError(false);
    if (mobileNumber === "" || mobileNumber.length < 10) 
    {
      setIsMobileError(true);
      return;
    }
    if(!(/^\d+$/.test(mobileNumber)))
    {
      setIsMobileError(true);
      return;
    }
    const otpNumber = "+91" + mobileNumber;
    const sendOtpButton = document.getElementById(
      "sendOTPBtn"
    ) as HTMLButtonElement | null;

    try {
      if (sendOtpButton) {
        sendOtpButton.disabled = true;
        sendOtpButton.innerHTML = "Checking Recaptcha...";
        generateRecaptcha();
        sendOtpButton.innerHTML = "Sending OTP...";
      }

      const appVerifier: any = (window as any).recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        authentication,
        otpNumber,
        appVerifier
      );

      (window as any).confirmationResult = confirmationResult;
      setMobileNumberEntry(false);
      setOTPEntry(true);
    } catch (error) {
      console.log(error);
      setMobileNumberEntry(true);
      setOTPEntry(false);
      if (sendOtpButton) {
        sendOtpButton.disabled = false;
        sendOtpButton.innerHTML = "Request OTP";
      }
    }
  };

  const resendOTP = async () => {
    setIsMobileError(false);
    if (mobileNumber === "" || mobileNumber.length < 10) 
    {
      setIsMobileError(true);
      return;
    }
    if(!(/^\d+$/.test(mobileNumber)))
    {
      setIsMobileError(true);
      return;
    }
    const otpNumber = "+91" + mobileNumber;

    try {
      const appVerifier: any = (window as any).recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        authentication,
        otpNumber,
        appVerifier
      );

      (window as any).confirmationResult = confirmationResult;
      setMobileNumberEntry(false);
      setOTPEntry(true);
    } catch (error) {
      console.log(error);
      setMobileNumberEntry(true);
      setOTPEntry(false);
    }
  };

  function validateOTP() {
    if (OTP.length != 6) return;

    let confirmationResult = (window as any).confirmationResult;
    confirmationResult.confirm(OTP).then((result: any) => {
      console.log(result);
      getUserDetails();
    });
  }

  function getUserDetails() {
    const requestData = { mobile: mobileNumber };

    axios
      .post(
        "https://admin.trawelmart.com/admin/public/index.php/api/userLogin",
        requestData
      )
      .then((response) => {
        if (response.data.status) {
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          console.log(response.data.message);
          (window as any).location = "/";
        } else {
          setOTPEntry(false);
          setSignUpEntry(true);
          console.log(response.data.message);
        }
      });
  }

  function registerUser() {
    const requestData = {
      mobile: mobileNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      user_location: location,
    };
    axios
      .post(
        "https://admin.trawelmart.com/admin/public/index.php/api/registerUser",
        requestData
      )
      .then((response: any) => {
        console.log(response.data);
        if (response.data.status) {
          localStorage.setItem(
            "loggedInUser",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem("isLoggedIn", JSON.stringify(true));
          console.log(response.data.message);
          incloseModal();
          (window as any).location = "/";
        } else {
          console.log(response.data.message);
        }
      });
  }

  useEffect(() => {
    const isLoggedInString = localStorage.getItem("isLoggedIn");
    const isLoggedIn = isLoggedInString ? JSON.parse(isLoggedInString) : false;
    setIsLoggedIn(isLoggedIn);
  }, []);

  // useEffect(() => {
  //   const pTag = document.getElementById("signInPTag");
  //   if(pTag)
  //   {
  //     const handleClick = () => setInOpen(true);
  //     pTag.addEventListener('click', handleClick);

  //     // Cleanup function
  //     return () => {
  //       pTag.removeEventListener('click', handleClick);
  //     }
  //   }
  //   else
  //   {
  //     console.log("No P Tag");
  //   }
  // },[]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Show the popup after a delay
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    setIsVisible(false);
  };

  return (
    <div className={`popup ${isVisible ? "visible" : ""}`}>
      <div className="popup-content xl:w-6/12 lg:w-8/12 md:w-11/12 w-11/12 sm:w-11/12">
        <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 xl:gap-8 sm:grid-cols-2 grid-cols-1">
          <div className="hidden sm:block">
            <div className=" my-6 rounded-md p-6 bgpopupp ">
              <h1 className="font font-medium uppercase xl:text-base text-white">
                Unlock this Benefits
              </h1>

              <div className="">
                <div className="flex items-center xl:pt-6 mt-2">
                  <div className="lg:w-2/12 md:w-2/12 -mt-2 lg:ml-0 xl:ml-0 md:ml-0">
                    <img
                      className="block dark:hidden w-8"
                      src={coin}
                      alt="logo1"
                    />
                  </div>
                  <div className="lg:w-10/12 md:w-10/12 border-b lg:ml-4 xl:ml-0 md:ml-0 ml-0 sm:ml-4">
                    <span className="xl:text-lg lg:text-lg md:text-xl text-base font-semibold font">
                      Big Savings
                    </span>
                    <p className="text-white font font-normal text-xs pb-4 pt-1">
                      On Your Every trips
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="flex items-center xl:pt-4 pt-4">
                  <div className="lg:w-2/12 md:w-2/12 -mt-2">
                    <img
                      className="block dark:hidden w-8"
                      src={presents}
                      alt="logo1"
                    />
                  </div>
                  <div className="lg:w-10/12 md:w-10/12 border-b lg:ml-4 xl:ml-0 md:ml-0 ml-0 sm:ml-4">
                    <span className="xl:text-lg lg:text-lg md:text-xl text-base font-semibold font">
                      Special Offers
                    </span>
                    <p className="text-white font font-normal text-xs pb-4 pt-1">
                      Explore great deals & offers
                    </p>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="flex items-center xl:pt-4 pt-4">
                  <div className="lg:w-2/12 md:w-2/12 -mt-2">
                    <img
                      className="block dark:hidden w-8"
                      src={coin}
                      alt="logo1"
                    />
                  </div>
                  <div className="lg:w-10/12 md:w-10/12 border-b lg:ml-4 xl:ml-0 md:ml-0 ml-0 sm:ml-4">
                    <span className="xl:text-lg lg:text-lg md:text-xl text-base font-medium font ">
                      My Trips
                    </span>
                    <p className="text-white font font-normal text-xs pb-4 pt-1">
                      Manage your bookings with Trawel Mart
                    </p>
                  </div>
                </div>
              </div>

              <p className="xl:pt-6 pt-6 font-medium xl:text-xs text-xs text-white font">
                By proceeding, you agree to Trawelmart Privacy Policy, User
                Agreement and Terms of Service
              </p>
            </div>
          </div>

          <div className="font xl:py-6 lg:pt-5 pt-0 xl:-ml-10 rounded-xl shad bg-white lg:my-0 xl:my-0 md:my-3 sm:my-4 ">
            <div className="font p-4 bg-[#fbf7fb] lg:-mt-8 text-center rounded-t-xl lg:pt-6 text-xl font-semibold leading-6 text-sky-600">
              Welcome to Trawel Mart
              <p className="pt-1 font-normal text-center text-xs text-sky-500">
                Plan an unforgettable trip with Trawel Mart
              </p>
            </div>
            <p className="text-xs text-gray-500 xl:pt-10">
              {mobileNumberEntry && (
                <p className=" font-normal text-center mt-4 text-sm xl:text-base lg:text-base md:text-base px-0 xl:px-4">
                  Please enter your mobile number to receive a verification code
                </p>
              )}
              {OTPEntry && (
                <p className=" font-normal text-center xl:pt-0 lg:pt-6 md:pt-6 pt-5 text-sm xl:text-base lg:text-base md:text-base">
                  Please enter your verification code received on {mobileNumber}
                </p>
              )}
              {signUpEntry && (
                <p className=" font-normal text-center ">
                  Please enter your details for registration
                </p>
              )}
            </p>
            <div className="">
              <div className="mx-auto">
                <label className="block xl:px-6 md:px-12 px-6">
                  <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                    {mobileNumberEntry && (
                      <>
                        <img src={india} className="w-7 h-7 mr-2" />
                        <Input
                          type="number"
                          placeholder="Phone number"
                          className="border-none border-l outline-none "
                          value={mobileNumber}
                          onChange={(e) => {
                            setMobileNumber(e.target.value);
                          }}
                        />
                      </>
                    )}
                    {OTPEntry && (
                      <Input
                        type="text"
                        placeholder="Verification Code"
                        className="border-none border-l outline-none "
                        value={OTP}
                        onChange={(e) => {
                          setOTP(e.target.value);
                        }}
                      />
                    )}
                    {signUpEntry && (
                      <>
                        <input
                          type="text"
                          placeholder="First Name"
                          className="border-none border-l outline-none "
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </>
                    )}
                  </div>
                </label>
                {signUpEntry && (
                  <>
                    <label className="block xl:px-6">
                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="border-none border-l outline-none "
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </label>
                    <label className="block xl:px-6">
                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                        <input
                          type="text"
                          placeholder="Email address"
                          className="border-none border-l outline-none "
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </label>
                    <label className="block xl:px-6">
                      <div className="flex items-center bg-white border border-gray-300  px-3  py-1 rounded-lg mt-6">
                        <input
                          type="text"
                          placeholder="Location"
                          className="border-none border-l outline-none "
                          value={location}
                          onChange={(e) => {
                            setLocation(e.target.value);
                          }}
                        />
                      </div>
                    </label>
                  </>
                )}
              </div>
              <div className="m-auto w-44 mb-4">
                {mobileNumberEntry && (
                  <>
                    <div id="recaptcha-container"></div>
                    {isMobileError && <span style={{ fontSize:'12px', color:'red', fontWeight:'bold' }}>Enter valid mobile number</span>}
                    <button
                      id="sendOTPBtn"
                      type="button"
                      onClick={sendOTP}
                      className="rounded-md bg-sky-500 mt-4 px-8 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3 xl:ml-4 ml-4"
                    >
                      Request OTP
                    </button>
                  </>
                )}
                {OTPEntry && (
                  <>
                    <button
                      type="button"
                      onClick={validateOTP}
                      className="rounded-md bg-sky-500 ml-4 mt-6 px-8 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                    >
                      Validate OTP
                    </button>
                    <br />
                    <div className="pl-12 pb-3">
                      <a href="#!" onClick={resendOTP}>
                        <h6>Resend OTP</h6>
                      </a>
                    </div>
                  </>
                )}
                {signUpEntry && (
                  <button
                    type="button"
                    onClick={registerUser}
                    className="rounded-md bg-sky-500 mt-6 px-12 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 mb-3"
                  >
                    Register
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <span
          className="close md:pt-2 xl:pt-0 lg:pt-0 sm:pt-3"
          onClick={closePopup}
        >
          &times;
        </span>
      </div>
    </div>
  );
};

export default PagePopup;
