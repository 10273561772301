import React, { FC, useState } from "react";
import pin from "images/ICONS/pin.png";
import Badge from "shared/Badge/Badge";
import SectionSliderNewCategories2 from "components/SectionSliderNewCategories/SectionSliderNewCategories2";

export interface FlightCard3Props {
    className?: string;
    defaultOpen?: boolean;
    data: {
        id: string;
        airlines: {
            logo: string;
            name: string;
        };
        day: string;
        days: string;
        price: string;
        text: string;
        icons: string;
    };
}

const FlightCard3: FC<FlightCard3Props> = ({
    className = "",
    data,
    defaultOpen = false,
}) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    const renderDetailTop = () => {
        return (
            <div>
                <div className="flex flex-col md:flex-row ">
                    <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
                        <img src={data.airlines.logo} className="w-10" alt="" />
                    </div>
                    <div className="flex my-5 md:my-0">
                        <div className="flex-shrink-0 flex flex-col items-center py-2">
                            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
                            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
                        </div>
                        <div className="ml-4 space-y-10 text-sm">
                            <div className="flex flex-col space-y-1">
                                <span className=" text-neutral-500 dark:text-neutral-400">
                                    Monday, August 12 · 10:00
                                </span>
                                <span className=" font-semibold">
                                    Tokyo International Airport (HND)
                                </span>
                            </div>
                            <div className="flex flex-col space-y-1">
                                <span className=" text-neutral-500 dark:text-neutral-400">
                                    Monday, August 16 · 10:00
                                </span>
                                <span className=" font-semibold">
                                    Singapore International Airport (SIN)
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
                    <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
                        <li>Trip time: 7 hours 45 minutes</li>
                        <li>ANA · Business class · Boeing 787 · NH 847</li>
                    </ul>
                </div>
            </div>
        );
    };

    const renderDetail = () => {
        if (!isOpen) return null;
        return (
            <div className="p-4 md:pt-8 md:pl-8 md:pr-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
                <SectionSliderNewCategories2
                    uniqueClassName="PageHome_s1"
                />
            </div>
        );
    };

    return (
        <div className=" bg-white  ">
            <div className={`nc-FlightCardgroup p-4 sm:p-1 relative dark:bg-neutral-900 
        dark:border-neutral-800 overflow-hidden  transition-shadow  ${className}`}
                data-nc-id="FlightCard">
                <div className=" items-center  ">
                    <div
                        className={` sm:pr-12 relative  ${className}`}
                        data-nc-id="FlightCard"
                    >
                        <div className="grid lg:grid-cols-2 gap-2  ">
                            <div className="lg:pt-6">
                               
                                <div className="text-justify-center">
                                    </div>
                                <div className="grid lg:grid-cols-2 gap-2 ">
                                    <span className="block w-8 h-8 rounded-full border border-green-200 lg:-ml-4 bg-green-200">
                                        <i className="text-xl las la-hotel p-1 text-green-800"></i>
                                        <span className="block flex-grow border-l border-neutral-400 border-dashed lg:ml-4 mt-1 lg:py-80">
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute lg:mt-2 right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <i className="text-xl las la-angle-down"></i>
                        </span>

                        <div className="flex rounded bg-red-50 p-2 lg:-mt-10 ml-8 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            {/* LOGO IMG */}
                            <div className="w-24 lg:w-32 flex-shrink-0 ">
                                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-orange-800 bg-orange-100  relative">
                                    {data.day}
                                </span>
                            </div>
                            {/* FOR MOBILE RESPONSIVE */}
                            <div className="block lg:hidden space-y-1">
                                <div className="flex font-semibold">
                                    <div>
                                        <span>11:00</span>
                                        <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                                            HND
                                        </span>
                                    </div>
                                    <span className="w-12 flex justify-center">
                                        <i className=" text-2xl las la-long-arrow-alt-right"></i>
                                    </span>
                                    <div>
                                        <span>20:00</span>
                                        <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                                            SIN
                                        </span>
                                    </div>
                                </div>
                                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                                    <span className="VG3hNb">Nonstop</span>
                                    <span className="mx-2">·</span>
                                    <span>7h 45m</span>
                                    <span className="mx-2">·</span>
                                    <span>HAN</span>
                                </div>
                            </div>
                            <div className="hidden lg:block text-center whitespace-nowrap">
                                <div className="font-semibold text-base "> {data.text}</div>
                            </div>
                            {/* PRICE */}
                            <div className="flex-[4] whitespace-nowrap sm:text-right">
                                <div className="">
                                    <span className="text-base font-semibold text-secondary-6000 ">
                                        {data.price}
                                    </span>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* DETAIL */}
                    <div className="pt-4 pb-4 ml-8">

                        {renderDetail()}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default FlightCard3;




{/*<div className={` relative ${className}`} data-nc-id="FlightCard" >
<div className=" items-center pr-12 ">
    <span className="block w-9 h-9 rounded-full border border-neutral-400 bg-blue-600 -ml-4">
        <img src={pin} alt="" className="p-2 font-bold" />
    </span>
    <span className="block flex-grow border-l border-neutral-400 border-dashed ">
        <div
            className={` sm:pr-20 relative  ${className}`}
            data-nc-id="FlightCard"
        >
            {/*  eslint-disable-next-line jsx-a11y/anchor-has-content 
            <a href="##" className="absolute inset-0" />
            <span
                className={`absolute -mt-3 right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
                    }`}
                onClick={() => setIsOpen(!isOpen)}
            >
                <i className="text-xl las la-angle-down"></i>
            </span>
            <div className="flex  ml-8 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                {/* LOGO IMG 
                <div className="w-24 lg:w-32 flex-shrink-0 -mt-6">
                    <Badge name={data.day} />
                </div>
                {/* FOR MOBILE RESPONSIVE 
                <div className="block lg:hidden space-y-1">
                    <div className="flex font-semibold">
                        <div>
                            <span>11:00</span>
                            <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                                HND
                            </span>
                        </div>
                        <span className="w-12 flex justify-center">
                            <i className=" text-2xl las la-long-arrow-alt-right"></i>
                        </span>
                        <div>
                            <span>20:00</span>
                            <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                                SIN
                            </span>
                        </div>
                    </div>
                    <div className="text-sm text-neutral-500 font-normal mt-0.5">
                        <span className="VG3hNb">Nonstop</span>
                        <span className="mx-2">·</span>
                        <span>7h 45m</span>
                        <span className="mx-2">·</span>
                        <span>HAN</span>
                    </div>
                </div>
                <div className="hidden lg:block text-center whitespace-nowrap">
                    <div className="font-semibold text-base -mt-5"> {data.text}</div>
                </div>
                {/* PRICE
                <div className="flex-[4] whitespace-nowrap sm:text-right">
                    <div className="-mt-5">
                        <span className="text-base font-semibold text-secondary-6000 ">
                            {data.price}
                        </span>
                    </div>

                </div>
            </div>
        </div>

        {/* DETAIL 
        <div className="pb-12 pt-4 pl-4">
            {renderDetail()}
        </div>
    </span>
</div>
</div>*/}