import React, { FC, useState, Fragment, useEffect, FormEvent, useRef, MutableRefObject } from "react";
import Slider from "react-slick";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StayCard from "components/StayCard/StayCard";
import { StayDataType, TaxonomyType } from "data/types";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import SectionSliderNewCategories4 from "components/SectionSliderNewCategories/SectionSliderNewCategories4";
import { Dialog } from '@headlessui/react'
import Input from "shared/Input/Input";
import india from "images/india.png"
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import axios from "axios";
import toastr from 'toastr';
import DubaiTrourPackage from 'images/sample/Dubai-Trour-Package.jpg';
import besttravelpackagesfordubaiaindubai from 'images/sample/best-travel-packages-for-dubai-ain-dubai.jpg';
import dubaitravelpackageinbangaloredubaifuture from 'images/sample/dubai-travel-package-in-bangalore-dubai-future.jpg';
import dubaibesttravelpackagesburjalarab from 'images/sample/dubai-best-travel-packages-burj-al-arab.jpg';
import dubaitourpackage from 'images/sample/dubai-tour-package.jpg';
import indiabesttourpackagescompany from "images/sample/india-best-tour-packages-company.jpg";
import PagePopup from "containers/PageHome/PagePopup";
import GallerySlider from "components/GallerySlider/GallerySlider";
import NcImage from "shared/NcImage/NcImage";
// // import ReCAPTCHA from "react-google-recaptcha";



const DEMO_CATS: TaxonomyType[] = [
    {
        id: "1",
        href: "/",
        name: "Resort",
        taxonomy: "category",
        count: 188288,
        text: "₹5,000",
        thumbnail:
            "https://i.ibb.co/pwfS2ys/1-1.jpg",
    },
    {
        id: "2",
        href: "/",
        name: "Hotels",
        taxonomy: "category",
        count: 188288,
        text: "₹1,500",
        thumbnail:
            " https://i.ibb.co/PMwTMvZ/waterparkdubai.png"
    },
    {
        id: "3",
        href: "/",
        name: "Guest House",
        taxonomy: "category",
        count: 188288,
        text: "₹6,000",
        thumbnail:
            "https://i.ibb.co/jbkXyv5/safaridubai.png ",
    },
    {
        id: "4",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/J24fZdd/aquariumdubai.png",
    },
    {
        id: "5",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/1vtBMG4/dubaiframe.png",
    },
    {
        id: "6",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/tY9Mv8X/bollywooddubai.png",
    },

    {
        id: "7",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/XWC5xgH/themepark.png",
    },

    {
        id: "8",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            " https://i.ibb.co/5KqWF8Q/globaldubai.png",
    },
    {
        id: "9",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/kQVpHHq/motiongatedubai.png",
    },
];

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 1);

function GroupTour() {
    // const recaptcha: MutableRefObject<ReCAPTCHA | null> = useRef(null);
    let [isOpen, setIsOpen] = useState(false)
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [departureError, setDepartureError] = useState(false);
    const [travelError, setTravelError] = useState(false);
    const [destinationError, setDestinationError] = useState(false);
    const [passangerError, setPassangerError] = useState(false);
    const location = useLocation();
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [mobileNumber, setMobileNumber] = useState('');
    let [departureCity, setDepartureCity] = useState('');
    let [travelDate, setTravelDate] = useState('');
    let [destination, setDestination] = useState('');
    let [noofPassanger, setNoofPassanger] = useState('');
    const [inputDateType, setInputDateType] = useState('text');
    let [description, setDescription] = useState('Enquiring for Group Tour Package');
    const [groupPackages, setGroupPackages] = useState([]);

    const [showMoreStates, setShowMoreStates] = useState<{ [key: number]: boolean }>({});

  const toggleReadMoreLess = (index:number) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const formatPrice = (price:string) => {
    // Convert the input string to a number
    const amount = parseFloat(price.replace(/,/g, ''));
  
    // Ensure amount is a valid number
    if (isNaN(amount)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with Indian numbering system
    const formattedAmount = amount.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  
    return formattedAmount;
  };

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
    //testimonials slider //
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: false,
        speed: 500,
        centerPadding: '10px',
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: false,
        className: "center",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            }
        ]
    };
    const renderCard = (stay: StayDataType) => {
        return <StayCard key={stay.id} data={stay} />;
    };

    useEffect(() => {
        const userDataJSON = localStorage.getItem('loggedInUser');
        if (userDataJSON) {
            const userData = JSON.parse(userDataJSON);
            setName(userData.name);
            setMobileNumber(userData.mobile);
            setEmail(userData.email);
            setDepartureCity(userData.departure);
            setTravelDate(userData.travel);
            setDestination(userData.destination);
            setNoofPassanger(userData.Passanger);
        }
    }, []);

    useEffect(() => {
        axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite")
          .then((response) => {
            if (response.data.status) {
              setGroupPackages(response.data.packages.filter((package1:any) => package1.tour_type === "Group"));
            }
          });
      }, []);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (name.length == 0) {
            setNameError(true);
        }
        else if (/\d/.test(name)) {
            setNameError(true);
        }
        else if (mobileNumber.length != 10) {
            setNameError(false);
            setMobileError(true);
        }
        else if (email.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(true);
        }
        else if (departureCity.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(true);
        }
        else if (travelDate.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(false);
            setTravelError(true);
        }
        else if (noofPassanger.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(false);
            setTravelError(false);
            setDestinationError(false);
            setPassangerError(true);
        }
        else {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(false);
            setTravelError(false);
            setDestinationError(false);
            setPassangerError(false);
            const isScriptExist = document.getElementById("recaptcha-key");
            if (!isScriptExist) {
                var script = document.createElement("script");
                script.type = "text/javascript";
                script.src = "https://www.google.com/recaptcha/api.js?render=6LcBeLYoAAAAALBMn-BMX0hGCbbC55l7kJn34n4m";
                script.id = "recaptcha-key";
                document.body.appendChild(script);
                script.onload = () => {
                    // Initialize reCAPTCHA after the script is loaded
                    (window as any).grecaptcha
                        .execute('6LcBeLYoAAAAALBMn-BMX0hGCbbC55l7kJn34n4m', { action: 'submit' })
                        .then((recaptchaToken: any) => {
                            console.log(recaptchaToken);
                        });
                };
            }
            const submitButton = document.getElementById("submitButton");
            if (submitButton) {
                (submitButton as HTMLButtonElement).disabled = true;
                submitButton.innerHTML = "Submitting...";
            }
            const formData = { name: name, mobile: mobileNumber, email_address: email, description: description, url: location.pathname, city: departureCity, travel_date: travelDate, passenger_count: noofPassanger };
            axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addContact", formData)
                .then((response: any) => {
                    console.log(response);
                    if (response.status == 200) {
                        if (response.data.status) {
                            document.getElementById("recaptcha-key")?.remove();
                            (window as any).location = '/thanks';
                        }
                        else {
                            toastr.error(response.data.message);
                        }
                    }
                    else {
                        console.log(response.responseText);
                    }
                });
        }
    }

    return (
        <div className=" nc-PageHome relative overflow-hidden">
            {/* GLASSMOPHIN */}
            <div className="font xl:block lg:block md:hidden sm:hidden hidden" style={{
                backgroundImage: `url("group/group.jpg")`,
                width: "100%",
                height: "475px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero grid xl:grid-cols-2 xl:gap-6 lg:grid-cols-2 relative items-center`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left xl:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:mt-56 lg:mt-64 mt-14 lg:pb-52 lg:mr-10 xl:mr-0">
                                <p className="text-4xl bannerfont pt-2 text-white">
                                    Discover the World Together 
                                </p>
                                <h2 className="font font-bold text-4xl md:text-2xl xl:text-4xl !leading-[114%] lg:pb-0 mt-0 text-white">
                                    A Journey in Unity Creates Memories 
                                </h2>
                                <p className="text-base md:text-lg lg:text-base font-normal text-white dark:text-neutral-400 lg:block hidden ">
                                TrawelMart group tours... your group adventure awaits!!
                                </p>
                               

                                {/*<div className="flex pt-4">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                        Enquire Now
                                    </button>
                                    <Transition appear show={isOpen} as={Fragment}>
                                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                                            </Transition.Child>

                                            <div className="font fixed inset-0 overflow-y-auto">
                                                <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 scale-95"
                                                        enterTo="opacity-100 scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 scale-100"
                                                        leaveTo="opacity-0 scale-95"
                                                    >
                                                        <Dialog.Panel className="w-full xl:mt-12 max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                                            <div className="text-right mr-2">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                    onClick={closeModal}
                                                                >
                                                                    X
                                                                </button>
                                                            </div>

                                                            <Dialog.Title
                                                                as="h3"
                                                                className="font p-4 bg-[#fbf7fb] lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                            >
                                                                Quick Enquiry 
                                                                <p className="pt-2 font-normal text-xs text-sky-500">
                                                                    Plan an unforgettable trip with Trawel Mart
                                                                </p>
                                                            </Dialog.Title>

                                                            <div className="mt-2">
                                                                <form onSubmit={handleSubmit}>
                                                                    <div className="mx-auto">
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-6" />
                                                                            {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}

                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type={inputDateType} value={travelDate} onChange={(e) => setTravelDate(e.target.value)} placeholder="Travel Date" onFocus={() => setInputDateType('date')} className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {travelError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid travel date</p>)}
                                                                        </label>
                                                                        {/* <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                                        </label> 
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passanger" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {passangerError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid number of passengers</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-16 rounded-lg mt-4" />
                                                                        </label>
                                                                        {/*  <p className="font mt-8 text-xs font-normal text-center">
                                                                        Would you like to share more info? It will help us <br></br>curate the best tours for you.
                                                                          </p>
                                                                        <label className="block xl:px-16 px-4 py-2">
                                                                            <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" ref={recaptcha}/>
                                                                        </label>
                                                                    </div>
                                                                    <div className="xl:px-16 px-4 mt-4 mb-8">
                                                                        <button type="submit"
                                                                            id="submitButton"
                                                                            className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75">
                                                                            Submit Enquiry
                                                                        </button>
                                                                    </div>
                                                                     {/*<p className="mt-4 text-xs text-center">
                                                                        Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                                    </p>
                                                                    <div className="bg-[#fbf7fb] mt-6">
                                                                        <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                                <div className="lg:w-2/12">
                                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                                </div>
                                                                                <div className="lg:w-9/12 pl-2">
                                                                                    <span className="text-sm font-medium ">
                                                                                        Super Offers
                                                                                    </span>
                                                                                    <p className="text-sky-700 italic font-normal text-xs ">
                                                                                        Explore Great Deals
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                                <div className="lg:w-2/12">
                                                                                    <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                                </div>
                                                                                <div className="lg:w-10/12 pl-2">
                                                                                    <span className="text-sm font-medium ">
                                                                                        My Trips
                                                                                    </span>
                                                                                    <p className="text-sky-700 font-normal italic text-xs ">
                                                                                        Manage Your Bookings
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                </form>
                                                            </div>
                                                        </Dialog.Panel>
                                                    </Transition.Child>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </Transition>
                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>*/}
                            </div>
                        </div>



                        <div className="text-white pl-12 my-6 xl:mt-10 lg:mt-0 xl:-mt-0 lg:-mt-20 xl:px-12">

                            <div className="bg-white pb-3 rounded-lg">
                                <div className="mt-2 ">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mx-auto">
                                            <div
                                                className="text-center rounded-t-lg font p-3 bg-[#fbf7fb] xl:-mt-8 lg:-mt-24    x md:-mt-8 -mt-8 lg:pt-4 text-xl font-semibold leading-6 text-black"
                                            >
                                                Quick Enquiry
                                                <p className="pt-2 font-normal text-xs text-sky-500">
                                                    Plan an unforgettable trip with Trawel Mart
                                                </p>
                                            </div>
                                            <div className="px-3">
                                                <div className="grid grid-cols-2">
                                                    <label className="block xl:px-2 px-4">
                                                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6" />
                                                        {nameError && (<p style={{ textAlign: 'start', color: 'red', fontSize: '13px' }}>Please enter valid name</p>)}
                                                    </label>
                                                    <label className="block xl:px-2 px-4">
                                                        <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6" />
                                                        {mobileError && (<p style={{ textAlign: 'start', color: 'red', fontSize: '13px' }}>Please enter valid mobile number</p>)}
                                                    </label>
                                                </div>
                                                <div className="grid grid-cols-2">
                                                    <label className="block xl:px-2 px-4">
                                                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {emailError && (<p style={{ textAlign: 'start', color: 'red', fontSize: '13px'  }}>Please enter valid email</p>)}
                                                    </label>
                                                    <label className="block xl:px-2 px-4">
                                                        <Input type="text" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {departureError && (<p style={{ textAlign: 'start', color: 'red', fontSize: '13px'  }}>Please enter valid departure city</p>)}
                                                    </label>
                                                </div>

                                                <div className="grid grid-cols-2 ">
                                                    <label className="block xl:px-2 px-4">
                                                        <Input value={travelDate} onChange={(e) => setTravelDate(e.target.value)} type={inputDateType} placeholder="Travel Date" onFocus={() => setInputDateType('date')} className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {travelError && (<p style={{ textAlign: 'start', color: 'red', fontSize: '13px'  }}>Please enter valid travel date</p>)}
                                                    </label>
                                                    {/* <label className="block xl:px-16 px-4">
                                                            <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                        </label> */}
                                                    <label className="block xl:px-2 px-4">
                                                        <Input type="number" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passanger" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {passangerError && (<p style={{ textAlign: 'start', color: 'red', fontSize: '13px'  }}>Please enter valid number of passanger</p>)}
                                                    </label>
                                                </div>


                                                <label className="block xl:px-2 px-4">
                                                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4" />
                                                </label>
                                                {/* <div id="recaptcha-container"></div> */}
                                                {/* <label className="block xl:px-2 px-4 py-2 w-full">
                                                    <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" className="h-14 w-full" ref={recaptcha} />
                                                </label> */}

                                            </div>

                                            <div className="xl:px-6 lg:px-4 md:px-4 px-4 mt-4 mb-2">
                                                <button type="submit"
                                                    id="submitButton"
                                                    className="w-full rounded-md bg-sky-600 mt-2 px-4 py-3 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75">
                                                    Submit Enquiry
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            {/* ======== BANNER LG SCREEN VIEW ============ */}


            <div className="font xl:hidden lg:hidden md:hidden sm:hidden hidden" style={{
                backgroundImage: `url("https://i.ibb.co/hYj6j47/dubaibannernew.jpg")`,
                width: "100%",
                height: "350px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left lg:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:mt-6 lg:mt-6 mt-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                                <p className="text-4xl bannerfont  text-white">
                                    Discover the World Together 
                                </p>
                                <h2 className="font font-bold text-4xl md:text-4xl xl:text-4xl !leading-[114%] lg:pb-2 mt-0 text-white">
                                A Journey in Unity Creates Memories 
                                </h2>


                                <div className="flex pt-4">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                        Enquire Now
                                    </button>
                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* ========== BANNER MD SCREEN VIEW ======== */}

            <div className="font xl:hidden lg:hidden hidden md:block sm:hidden" style={{
                backgroundImage: `url("https://i.ibb.co/NWcHgwy/backgrouund.jpg")`,
                width: "100%",
                height: "475px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero grid xl:grid-cols-2 xl:gap-6 lg:grid-cols-2 lg:gap-6 md:grid-cols-2 relative items-center`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left xl:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:mt-56 lg:mt-64 mt-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                                <p className="xl:text-xl md:text-xl  font1 xl:pt-1 lg:pt-6 text-white">
                                Discover the World Together 
                                </p>
                                <h2 className="font font-bold text-4xl md:text-3xl xl:text-4xl  lg:text-2xl !leading-[114%] lg:pb-3 mt-0 text-white">
                                A journey in Unity Creates Memories 
                                </h2>
                                <span className="text-base md:text-lg lg:text-lg font-normal text-white dark:text-neutral-400">
                                A futuristic city with the finest and grandest range of attractions and entertainment possible!
                                </span>
                                {/*<div className="flex pt-4">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                        Enquire Now
                                    </button>
                                    <Transition appear show={isOpen} as={Fragment}>
                                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                                            </Transition.Child>

                                            <div className="font fixed inset-0 overflow-y-auto">
                                                <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 scale-95"
                                                        enterTo="opacity-100 scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 scale-100"
                                                        leaveTo="opacity-0 scale-95"
                                                    >
                                                        <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                                            <div className="text-right mt-2 mr-2">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                    onClick={closeModal}
                                                                >
                                                                    X
                                                                </button>
                                                            </div>
                                                            <Dialog.Title
                                                                as="h3"
                                                                className="font p-4 bg-[#fbf7fb] lg:-mt-8 md:-mt-8 -mt-8 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                            >
                                                                Quick Enquiry
                                                                <p className="pt-2 font-normal text-xs text-sky-500">
                                                                    Plan an unforgettable trip with Trawel Mart
                                                                </p>
                                                            </Dialog.Title>

                                                            <div className="mt-2">
                                                                <form onSubmit={handleSubmit} >
                                                                    <div className="mx-auto">
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-6" />
                                                                            {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}

                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type={inputDateType} value={travelDate} onChange={(e) => setTravelDate(e.target.value)} placeholder="Travel Date" onFocus={() => setInputDateType('date')} className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid travel date</p>)}
                                                                        </label>
                                                                        {/* <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                                        </label> 
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passanger" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                                            {passangerError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid number of passengers</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-16 rounded-lg mt-4" />
                                                                        </label>
                                                                        {/* <label className="block xl:px-16 px-4 py-2">
                                                                            <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" ref={recaptcha}/>
                                                                        </label> */}
                                {/*  <p className="font mt-8 text-xs font-normal text-center">
                                                                        Would you like to share more info? It will help us <br></br>curate the best tours for you.
                                                                          </p>
                                                                    </div>
                                                                    <div className="xl:px-16 px-4 mt-4 mb-8">
                                                                        <button type="submit"
                                                                            id="submitButton"
                                                                            className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75">
                                                                            Submit Enquiry
                                                                        </button>
                                                                    </div>
                                                                     {/*<p className="mt-4 text-xs text-center">
                                                                        Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                                    </p>
                                                                    <div className="bg-[#fbf7fb] mt-6">
                                                                        <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                                <div className="lg:w-2/12">
                                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                                </div>
                                                                                <div className="lg:w-9/12 pl-2">
                                                                                    <span className="text-sm font-medium ">
                                                                                        Super Offers
                                                                                    </span>
                                                                                    <p className="text-sky-700 italic font-normal text-xs ">
                                                                                        Explore Great Deals
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                                <div className="lg:w-2/12">
                                                                                    <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                                </div>
                                                                                <div className="lg:w-10/12 pl-2">
                                                                                    <span className="text-sm font-medium ">
                                                                                        My Trips
                                                                                    </span>
                                                                                    <p className="text-sky-700 font-normal italic text-xs ">
                                                                                        Manage Your Bookings
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> 
                                                                </form>
                                                            </div>
                                                        </Dialog.Panel>
                                                    </Transition.Child>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </Transition>
                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>*/}
                            </div>
                        </div>

                        <div className="text-white pl-12 my-6 xl:mt-10 xl:-mt-0 lg:-mt-20 lg:mt-0 md:mt-12 xl:px-12 md:px-0">
                            <div className="bg-white pb-3 rounded-lg">
                                <div className="mt-2 ">
                                    <form onSubmit={handleSubmit}>
                                        <div className="mx-auto">
                                            <div
                                                className="text-center rounded-t-lg font p-3 bg-[#fbf7fb] xl:-mt-8 lg:-mt-24  md:-mt-8 -mt-8 lg:pt-4 text-xl font-semibold leading-6 text-black"
                                            >
                                                Quick Enquiry
                                                <p className="pt-2 font-normal text-xs text-sky-500">
                                                    Plan an unforgettable trip with Trawel Mart
                                                </p>
                                            </div>
                                            <div className="px-3">
                                                <div className="grid grid-cols-2">
                                                    <label className="block xl:px-2 px-2">
                                                        <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6" />
                                                        {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                                                    </label>
                                                    <label className="block xl:px-2 px-2">
                                                        <Input type="" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6" />
                                                        {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                    </label>
                                                </div>
                                                <div className="grid grid-cols-2">
                                                    <label className="block xl:px-2 px-2">
                                                        <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                    </label>
                                                    <label className="block xl:px-2 px-2">
                                                        <Input type="" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                                                    </label>
                                                </div>

                                                <div className="grid grid-cols-2 ">
                                                    <label className="block xl:px-2 px-2">
                                                        <Input value={travelDate} onChange={(e) => setTravelDate(e.target.value)} type={inputDateType} placeholder="Travel Date" onFocus={() => setInputDateType('date')} className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {travelError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid travel date</p>)}
                                                    </label>
                                                    {/* <label className="block xl:px-16 px-4">
                                                            <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                        </label> */}
                                                    <label className="block xl:px-2 px-2">
                                                        <Input type="" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passanger" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                                                        {passangerError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid number of passanger</p>)}
                                                    </label>
                                                </div>


                                                <label className="block xl:px-2 px-2">
                                                    <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4" />
                                                </label>
                                                {/* <div id="recaptcha-container"></div> */}
                                                {/* <label className="block xl:px-2 px-4 py-2 w-full">
                                                    <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" className="h-14 w-full" ref={recaptcha} />
                                                </label> */}

                                            </div>

                                            <div className="xl:px-6 lg:px-4 md:px-4 px-4 mt-4 mb-2">
                                                <button type="submit"
                                                    id="submitButton"
                                                    className="w-full rounded-md bg-sky-600 mt-2 px-4 py-3 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75">
                                                    Submit Enquiry
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* ========== BANNER mbl SCREEN VIEW ======== */}

            <div className="font xl:hidden lg:hidden md:hidden block" style={{
                backgroundImage: `url(${DubaiTrourPackage})`,
                width: "100%",
                height: "230px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left xl:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-1 xl:pb-14 lg:pb-14 xl:mt-6 lg:mt-2 mt-10 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                                <p className="text-base font1 text-center text-white pt-6">
                                    Discover the World Together 
                                </p>
                                <h2 className="font font-bold text-xl md:text-3xl text-center xl:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white">
                                A Journey in Unity Creates Memories 
                                </h2>
                                <p className="text-white text-center text-sm pt-1">
                                    A futuristic city with the finest and grandest range of
                                    attractions and entertainment possible!
                                </p>


                                {/*<div className="flex pt-2">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-4 py-2 text-white font-normal text-xs">
                                        Enquire Now
                                    </button>

                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-4 py-2 text-white font-normal text-xs ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="text-white xl:hidden  lg:hidden md:hidden block">

                <div className=" pb-3 px-4 rounded-lg  -mt-12">
                    <div className=" ">
                        <form onSubmit={handleSubmit}>
                            <div className="mx-auto border rounded-lg">
                                <div
                                    className="text-center rounded-t-lg font p-3 bg-[#fbf7fb] lg:-mt-8 md:-mt-8  lg:pt-4 text-xl font-semibold leading-6 text-black"
                                >
                                    Quick Enquiry
                                    <p className="pt-2 font-normal text-xs text-sky-500">
                                        Plan an unforgettable trip with Trawel Mart
                                    </p>
                                </div>
                                <div className="xl:px-8 px-3 ">
                                    <div className="grid grid-cols-1 xl:gap-2 gap-1">
                                        <label className="block xl:px-2 px-1">
                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-6 font" />
                                            {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                                        </label>
                                        <label className="block xl:px-2 px-1">
                                            <Input type="" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font" />
                                            {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                        </label>
                                    </div>
                                    <div className="grid grid-cols-1 xl:gap-2 gap-1">
                                        <label className="block xl:px-2 px-1">
                                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font" />
                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                        </label>
                                        <label className="block xl:px-2 px-1">
                                            <Input type="" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font" />
                                            {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                                        </label>
                                    </div>

                                    <div className="grid grid-cols-1 xl:gap-2 gap-1">
                                        <label className="block xl:px-2 px-1">
                                            <Input value={travelDate} onChange={(e) => setTravelDate(e.target.value)} type={inputDateType} placeholder="Travel Date" onFocus={() => setInputDateType('date')} className="bg-white text-black border font border-slate-300 px-3 py-1 h-10 rounded-md mt-3" />
                                            {travelError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid travel date</p>)}
                                        </label>
                                        {/* <label className="block xl:px-16 px-4">
                                                            <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                        </label> */}
                                        <label className="block xl:px-2 px-1">
                                            <Input type="" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passanger" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-3 font" />
                                            {passangerError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid number of passanger</p>)}
                                        </label>
                                    </div>


                                    <label className="block xl:px-2 px-1">
                                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-3 font" />
                                    </label>
                                    {/* <div id="recaptcha-container"></div> */}
                                    {/* <label className="block xl:px-2 px-4 py-2 w-full">
                                                    <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" className="h-14 w-full" ref={recaptcha} />
                                                </label> */}

                                </div>

                                <div className="xl:px-10 lg:px-4 md:px-4 px-4 mt-4 mb-4">
                                    <button type="submit"
                                        id="submitButton"
                                        className="w-full rounded-md bg-sky-600 mt-2 px-4 py-3 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75">
                                        Submit Enquiry
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

           

           

            {/* =========== Dubai Tour Packages ================ */}

            <div className="container relative space-y-2 mb-12 lg:space-y-2 lg:mb-8 lg:mt-24">
                <div className="font text-black font-bold text-2xl pb-4">
                   Group Tour Packages
                </div>
                <div
                    className={`grid gap-6 md:gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
                    {groupPackages.map((item:any,index:any) => (
                      <div
                          className={`hover-zoom cursor-pointer nc-StayCard group relative bg-white dark:bg-neutral-900 border mx-1 border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                          data-nc-id="StayCard"
                      >
                         <Link to={'/' + (item as any).slug}>
                        <div className=" relative w-full">
                          <NcImage
                            src={item.thumbnail_image ? `https://admin.trawelmart.com/admin/public/${item.thumbnail_image}` : `https://admin.trawelmart.com/admin/public/${item.photos_details.split(", ")[0]}`}
                          />
                        </div></Link>
                        <div className="">
                            <div className="px-4 pt-5 space-y-2 " >
                                <div className="space-y-2 ">
                                    <div className="flex items-center space-x-2">
                                        <h2
                                            className={` lg:font-medium font-regular capitalize text-base
                                                }`}
                                        >
                                            <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                            
                                        </h2>
                                    </div>
                                    <div className="flex items-center text-neutral-600 dark:text-neutral-400 text-xs space-x-2 ">
                                      <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="h-4 w-4"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke="currentColor"
                                      >
                                          <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={1.5}
                                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                          />
                                          <path
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              strokeWidth={1.5}
                                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                          />
                                      </svg>
                                      <span className="font ">{(item as any).tour_city}</span>
                                      <span className="font nc-Badge bg-sky-100 text-sky-700 px-4 py-1 rounded-full">{(item as any).tour_type} </span>
                                    </div>
                                </div>
                                {(item as any).is_flight === "Yes" && (
                                  <span className="font  lg:text-xs font-medium text-sky-600 dark:text-sky-400 text-xs space-x-2">
                                    <i className="las la-plane  text-base"></i> With Flight
                                  </span>
                                )}
                                {(item as any).is_cruise === "Yes"  && (
                                    <span className="font  lg:text-xs  text-sky-600 dark:text-sky-400 text-xs space-x-2 py-4 pl-3">
                                        <i className="las la-ship  text-base"></i> With Cruise
                                    </span>
                                )}
                                {(item as any).night_description && (
                                  <div className="card">
                                    <p className="font text-xs font-normal xl:h-14">
                                      {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                                      <span>
                                        {showMoreStates[(item as any).id] && (
                                          <p className="font text-xs font-normal">
                                            {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                              <span key={i}>{night}{(item as any).night_description.split(", ").length === (i+3) ? ' ' : ', '}</span>
                                            ))}
                                          </p>
                                        )}
                                      </span>
                                      {(item as any).night_description.split(", ").length > 2 && (
                                        <span>
                                            <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                            {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                            </button>
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                                <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                                    <div className="">
                                        <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                                    </div>
                                    <div className="">
                                        <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-concierge-bell"></i></a>
                                    </div>
                                    <div className="">
                                        <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-car-side"></i></a>
                                    </div>
                                    <div className="">
                                        <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-binoculars"></i></a>
                                    </div>
                                </nav>
                            </div>
                            <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                            <div className=" bg-[#fff0ff]  mt-2">
                                <div className="p-4 space-y-4" >
                                    <div className="flex justify-between items-center">
                                      <Link to={'/' + (item as any).slug}>
                                        <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                            View Details
                                        </div>
                                      </Link>
                                        <span className="text-xl font-extrabold">
                                            <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                                Starts From
                                            </div>
                                            <div className="font">
                                              {(item as any).price ? `${formatPrice((item as any).price)}` : '₹'}
                                              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                            </div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                      </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default GroupTour;
