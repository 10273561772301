import { getAuth } from "firebase/auth";
import { initializeApp } from 'firebase/app';

var firebaseConfig = {
  apiKey: "AIzaSyB063AfZ6ct1MqAfFJvpZtbZPIlzSJrG98",
  authDomain: "trawelmart.protechnologys.in/",
  projectId: "trawelmart-321d7",
  storageBucket: "trawelmart-321d7.appspot.com",
  messagingSenderId: "862311373586",
  appId: "1:862311373586:web:79d45b1fcebc53fb9eb1cb",
  measurementId: "G-DCJS6CD8HF"
};

const app = initializeApp(firebaseConfig);
const authentication = getAuth(app);

export default authentication;