import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import aboutban from "images/about/aboutban.png";

export interface SectionHero2Props {
  className?: string;
}

const SectionHero2: FC<SectionHero2Props> = ({ className = "" }) => {
  return (
    <div
      className="font lg:block "
      /*style={{
      backgroundImage: `url("https://i.ibb.co/dpnpPwC/bg.png")`,
      width: "100%",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat"
    */
    >
      <div
        className={`container nc-SectionHero relative ${className}`}
        data-nc-id="SectionHero"
      >
        <div className="space-y-2 lg:space-y-0 lg:space-x-10 items-center relative lg:text-left">
          <div className="w-screen max-w-full xl:max-w-full space-y-2 lg:space-y-2 text-center">
            <h2 className="text-4xl bannerfont !leading-tight lg:mb-4 xl:mb-0 lg:mt-10 font-medium text-white md:text-4xl xl:text-6xl dark:text-neutral-100 xl:pt-8">
              About Trawel Mart
              <h3 className="lneheght xl:px-44 xl:text-lg md:text-base lg:text-base text-sm font font-regular text-white md:text-2xl text-sky-600 dark:text-neutral-100 lg:pt-1 xl:py-0 lg:px-8">
                Founded in 2008 by Mr. K. Mohan Sundar and Mr. Meda S Kiran
                Setty, TRAWEL MART aimed to innovate travel, making it
                affordable and discovering new destinations. With a strong
                presence in 5 Indian locations, they've earned the title of
                India's Number 1 Travel Company, upholding excellence,
                value-added service, and ethical standards.
              </h3>
            </h2>
            {/*<span className="block text-sm xl:text-center xl:text-lg text-neutral-800 dark:text-neutral-400 xl:px-28 xl:pb-24 md:pb-4 text-justify">
              To make such dreams come true, <strong>TRAWEL MART</strong> was conceptualised in 2008 as a joint collaboration between <strong>Mr. K. Mohan Sundar</strong>,
              Founder and Managing Director, and <strong>Mr. Meda S Kiran Setty </strong> Co-founder, with the desire to create
              an innovative travel company that makes travel affordable across the world and explore new and
              undiscovered destinations in the travel industry.

              Thus, helping a longing traveller travel in the
              direction of their dream. With a formidable presence in 5 strategic locations in India, the company
              strives everyday on the core principle of excellence and value-added service while upholding utmost
              ethical standards. This feat has been key in Trawel Mart emerging as <strong>India&#39;s Number 1 Travel
                Company</strong>.
     </span>*/}
          </div>
        </div>
      </div>
      {/*<div className="xl:pt-0 md:pt-12 pt-12">
        <img className="w-full" src={aboutban} alt="" />
  </div>*/}
    </div>
  );
};

export default SectionHero2;
