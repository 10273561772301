import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "New Yourk",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Singapore",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Paris",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "London",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Tokyo",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Maldives",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
// ];

// const DEMO_CATS_2: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "Enjoy the great cold",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "222",
//     href: "/listing-stay",
//     name: "Sleep in a floating way",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "3",
//     href: "/listing-stay",
//     name: "In the billionaire's house",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "4",
//     href: "/listing-stay",
//     name: "Cool in the deep forest",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "5",
//     href: "/listing-stay",
//     name: "In the billionaire's house",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
// ];

function TermsAndConditions() {
  return (
    <>
      <section className="font static-pages">
        <div className="static-header">
          <h1 className="bg-sky-600 text-center text-white xl:h-52 lg:h-52 md:h-52 h-32 font-semibold xl:text-4xl lg:text-3xl md:text-2xl text-3xl font flex items-center justify-center">
            Terms and Conditions</h1>
        </div>
      </section>

      <section className="font container">
        <div className="xl:m-10 lg:my-12 md:my-6 my-12 border border-blueGray-600 xl:px-16 lg:px-12 md:px-6 px-4 text-justify py-10 rounded-xl">
          <h1 className="text-xl font-medium font mt-6">
            Applicability of The Agreement
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            This agreement ("user agreement") incorporates the terms and conditions for TrawelMart world
            Tours Pvt Ltd and its brand TrawelMart to provide services to the person (s) ("the User") intending
            to purchase or inquiring for any products and/ or services of TRAWELMART by using TRAWELMART's websites
            or using any other customer interface channels of TRAWELMART which includes its sales persons, offices,
            call centres, advertisements, information campaigns etc. Both User and TRAWELMART are individually
            referred as 'party' to the agreement and collective referred to as 'parties'.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            User's Responsibility of Cognizance
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The Users availing services from TRAWELMART shall be deemed to have read, understood and expressly accepted
            the terms and conditions of this agreement, which shall govern the desired transaction or provision of such
            services by TRAWELMART for all purposes, and shall be binding on the User. All rights and liabilities of the
            User and/or TRAWELMART with respect to any services to be provided by TRAWELMART shall be restricted to the
            scope of this agreement.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART reserves the right, in its sole discretion, to terminate the access to any or
            all TRAWELMART websites or its other sales channels and the related services or any portion thereof at any
            time, without notice, for general maintenance or any reason what so ever.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            In addition to this Agreement, there are certain terms of service (TOS) specific to the services
            rendered/ products provided by TRAWELMART like the air tickets, holiday packages etc. Such TOS will
            be provided/ updated by TRAWELMART which shall be deemed to be a part of this Agreement and in the
            event of a conflict between such TOS and this Agreement, the terms of this Agreement shall prevail.
            The User shall be required to read and accept the relevant TOS for the service/ product availed by the User.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Additionally, the Service Provider itself may provide terms and guidelines that govern particular features,
            offers or the operating rules and policies applicable to each Service (for example, flights, hotel reservations,
            packages, etc.). The User shall be responsible for ensuring compliance with the terms and guidelines or operating
            rules and policies of the Service Provider with whom the User elects to deal, including terms and conditions set
            forth in a Service Providers' fare rules, contract of carriage or other rules.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART's Services are offered to the User conditioned on acceptance without modification of all
            the terms, conditions and notices contained in this Agreement and the TOS, as may be applicable from
            time to time. For the removal of doubts, it is clarified that availing of the Services by the User constitutes
            an acknowledgement and acceptance by the User of this Agreement and the TOS. If the User does not agree with
            any part of such terms, conditions and notices, the User must not avail TRAWELMART's Services.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART's Services are offered to the User conditioned on acceptance without modification of all
            the terms, conditions and notices contained in this Agreement and the TOS, as may be applicable from
            time to time. For the removal of doubts, it is clarified that availing of the Services by the User constitutes
            an acknowledgement and acceptance by the User of this Agreement and the TOS. If the User does not agree with
            any part of such terms, conditions and notices, the User must not avail TRAWELMART's Services.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            In the event that any of the terms, conditions, and notices contained herein conflict with
            the Additional Terms or other terms and guidelines contained within any other TRAWELMART document,
            then these terms shall control.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Third Party Account Information
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            By using the Account Access service in TRAWELMART's websites, the User authorizes TRAWELMART
            and its agents to access third party sites, including that of Banks and other payment gateways,
            designated by them or on their behalf for retrieving requested information. While registering, the User
            will choose a password and is responsible for maintaining the confidentiality of the password and the account.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User is fully responsible for all activities that occur while using their password or account. It is
            the duty of the User to notify TRAWELMART immediately in writing of any unauthorized use of their password
            or account or any other breach of security. TRAWELMART will not be liable for any loss that may be incurred
            by the User as a result of unauthorized use of his password or account, either with or without his knowledge.
            The User shall not use anyone else's password at any time.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Confidentiality
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Any information which is specifically mentioned by TRAWELMART as Confidential shall be maintained
            confidentially by the user and shall not be disclosed unless as required by law or to serve the purpose
            of this agreement and the obligations of both the parties therein.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Website And Mobile App Usage
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Your use of any information or materials on the website and mobile app is entirely at your
            own risk, for which TRAWELMART shall not undertake any liability. It shall be your own accountability
            to ensure that any products, services or information available through our website or app, meet your explicit specifications.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            You are restrained from altering, duplicating, distributing, transmitting, reproducing,
            publishing, licensing or selling any information, software, products or services acquired from this
            website or app. Duplication of content from the website and app is forbidden which is in accordance
            with the copyright notice and forms a part of the Terms of Use. Additionally, TRAWELMART reserves
            private rights to modify, revise, and delete any of the contents without giving prior notice to any person.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Usage Of the Mobile Number of The User By TRAWELMART
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART may send booking confirmation, itinerary information, cancellation, payment confirmation,
            refund status, schedule change or any such other information relevant for the transaction, via SMS or
            by voice call on the contact number given by the User at the time of booking; TRAWELMART may also
            contact the User by voice call, SMS or email in case the User couldn't or hasn't concluded the booking,
            for any reason what so ever, to know the preference of the User for concluding the booking and also to
            help the User for the same. The User hereby unconditionally consents that such communications via SMS
            and/ or voice call by TRAWELMART is (a) upon the request and authorization of the User, (b) "transactional"
            and not an "unsolicited commercial communication" as per the guidelines of Telecom Regulation Authority
            of India (TRAI) and (c) in compliance with the relevant guidelines of TRAI or such other authority in
            India and abroad. The User will indemnify TRAWELMART against all types of losses and damages incurred
            by TRAWELMART due to any action taken by TRAI, Access Providers (as per TRAI regulations) or any other
            authority due to any erroneous compliant raised by the User on TRAWELMART with respect to the intimations
            mentioned above or due to a wrong number or email id being provided by the User for any reason whatsoever.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Responsibility Of the User
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART is responsible only for the transactions that are done by the User through
            TRAWELMART. TRAWELMART will not be responsible for screening, censoring or otherwise controlling
            transactions, including whether the transaction is legal and valid as per the laws of the land of the User.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART is responsible only for the transactions that are done by the User through TRAWELMART.
            TRAWELMART will not be responsible for screening, censoring or otherwise controlling transactions,
            including whether the transaction is legal and valid as per the laws of the land of the User.
          </p>

          <h1 className="text-xl font-medium font mt-6">Insurance</h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Unless explicitly provided by TRAWELMART in any specific service or deliverable, obtaining
            sufficient insurance coverage is the obligation/option of the user and TRAWELMART doesn't accept any
            claims arising out of such scenarios.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Insurance, if any provided as a part of the service/ product by TRAWELMART shall be as per the
            terms and conditions of the insuring company. The User shall contact the insurance company directly
            for any claims or disputes and TRAWELMART shall not provide any express or implied undertakings for a
            cceptance of the claims by the insurance company.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Safety Of Data Downloaded
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User understands and agrees that any material and/or data downloaded or otherwise obtained through
            the use of the Service is done entirely at their own discretion and risk and they will be solely responsible
            for any damage to their computer systems or loss of data that results from the download of such material and/or data.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Nevertheless, TRAWELMART will always make its best endeavours to ensure that the content on its
            websites or other information channels are free of any virus or such other malwares.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Feedback From Customer And Solicitation
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User is aware that TRAWELMART provides various services like hotel bookings, car rentals,
            holidays and would like to learn about them, to enhance his / her travel experience. The User
            hereby specifically authorizes TRAWELMART to contact the User with offers on various services
            offered by it through direct mailers, e-mailers, telephone calls, short messaging services (SMS)
            or any other medium, from time to time. In case that the customer chooses not to be contacted,
            he /she shall write to TRAWELMART for specific exclusion at support@trawelmart.com or provide his
            / her preferences to the respective service provider. The customers are advised to read and understand
            the privacy policy of TRAWELMART on its website in accordance of which TRAWELMART contacts, solicits
            the user or shares the user's information. </p>

          <h1 className="text-xl font-medium font mt-6">
            Proprietary Rights
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART may provide the User with contents such as sound, photographs, graphics, video or other
            material contained in sponsor advertisements or information. This material may be protected by copyrights,
            trademarks, or other intellectual property rights and laws. The User may use this material only as expressly
            authorized by TRAWELMART and shall not copy, transmit or create derivative works of such material without
            express authorization.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User acknowledges and agrees that he/she shall not upload post, reproduce, or distribute any content on or
            through the Services that is protected by copyright or other proprietary right of a third party, without
            obtaining the written permission of the owner of such right.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Any copyrighted or other proprietary content distributed with the consent of the owner must contain
            the appropriate copyright or other proprietary rights notice. The unauthorized submission or distribution
            of copyrighted or other proprietary content is illegal and could subject the User to personal liability
            or criminal prosecution.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Visa Obligations of the User
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The travel bookings done by TRAWELMART are subject to the applicable requirements of Visa
            which are to be obtained by the individual traveller. TRAWELMART is not responsible for any issues,
            including inability to travel, arising out of such Visa requirements and is also not liable to refund
            for the untraveled bookings due to any such reason.
          </p>
          <h1 className="text-xl font-medium font mt-6">Indemnification</h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User agrees to indemnify, defend and hold harmless TRAWELMART and/or its affiliates, their websites and their
            respective lawful successors and assigns from and against any and all losses, liabilities, claims, damages, costs
            and expenses (including reasonable legal fees and disbursements in connection therewith and interest chargeable thereon)
            asserted against or incurred by TRAWELMART and/or its affiliates, partner websites and their respective lawful successors
            and assigns that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any
            representation, warranty, covenant or agreement made or obligation to be performed by the User pursuant to this
            agreement. The user shall be solely and exclusively liable for any breach of any country specific rules and
            regulations or general code of conduct and TRAWELMART cannot be held responsible for the same.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Right To Refuse
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART at its sole discretion reserves the right to not to accept any customer
            order without assigning any reason thereof. Any contract to provide any service by TRAWELMART
            is not complete until full money towards the service is received from the customer and accepted by TRAWELMART.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Without prejudice to the other remedies available to TRAWELMART under this agreement, the
            TOS or under applicable law, TRAWELMART may limit the user's activity, or end the user's listing,
            warn other users of the user's actions, immediately temporarily/indefinitely suspend or terminate
            the user's registration, and/or refuse to provide the user with access to the website if:</p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The user is in breach of this agreement, the TOS and/or the documents it incorporates by reference
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART is unable to verify or authenticate any information provided by the user; or
            TRAWELMART believes that the user's actions may infringe on any third-party rights or breach
            any applicable law or otherwise result in any liability for the user, other users of the website
            and/or TRAWELMART.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART may at any time in its sole discretion reinstate suspended users. Once the user has been
            indefinitely suspended the user shall not register or attempt to register with TRAWELMART or use the
            website in any manner whatsoever until such time that the user is reinstated by TRAWELMART.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Notwithstanding the foregoing, if the user breaches this agreement, the TOS or the documents it
            incorporates by reference, TRAWELMART reserves the right to recover any amounts due and owing by the
            user to TRAWELMART and/or the service provider and to take strict legal action as TRAWELMART deems necessary.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Right To Cancellation In Case Of Invalid User Information
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User expressly undertakes to provide to TRAWELMART only correct and valid information while
            requesting for any services under this agreement, and not to make any misrepresentation of facts at all.
            Any default on part of the User would vitiate this agreement and shall disentitle the User from availing
            the services from TRAWELMART.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            In case TRAWELMART discovers or has reasons to believe at any time during or after receiving a
            request for services from the User that the request for services is either unauthorized or the information
            provided by the User or any of them is not correct or that any fact has been misrepresented by him,
            TRAWELMART in its sole discretion shall have the unrestricted right to take any steps against the User(s),
            including cancellation of the bookings, etc. without any prior intimation to the User. In such an event,
            TRAWELMART shall not be responsible or liable for any loss or damage that may be caused to the User or
            any of them as a consequence of such cancellation of booking or services.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User unequivocally indemnifies TRAWELMART of any such claim or liability and shall not hold
            TRAWELMART responsible for any loss or damage arising out of measures taken by TRAWELMART for safeguarding
            its own interest and that of its genuine customers. This would also include TRAWELMART denying/cancelling
            any bookings on account of suspected fraud transactions.
          </p>
          <h1 className="text-xl font-medium font mt-6">Severability</h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            If any provision of this agreement is determined to be invalid or unenforceable in whole or in
            part, such invalidity or unenforceability shall attach only to such provision or part of such provision
            and the remaining part of such provision and all other provisions of this Agreement shall continue to
            be in full force and effect.
          </p >
          <h1 className="text-xl font-medium font mt-6">Headings</h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The headings and subheadings herein are included for convenience and identification only and are not
            intended to describe, interpret, define or limit the scope, extent or intent of this agreement, terms and
            conditions, notices, or the right to use this website by the User contained herein or any other section or
            pages of TRAWELMART Websites or its partner websites or any provision hereof in any manner whatsoever.
          </p>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            In the event that any of the terms, conditions, and notices contained herein conflict with the Additional
            Terms or other terms and guidelines contained within any particular TRAWELMART website, then these terms
            shall control.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Relationship
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            None of the provisions of any agreement, terms and conditions, notices, or the right to use this
            website by the User contained herein or any other section or pages of TRAWELMART Websites or its partner
            websites, shall be deemed to constitute a partnership between the User and TRAWELMART and no party shall
            have any authority to bind or shall be deemed to be the agent of the other in any way.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Updation Of The Information By TTGPL
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            User acknowledges that TRAWELMART provides services with reasonable diligence and care. It endeavours
            its best to ensure that User does not face any inconvenience. However, at some times, the information,
            software, products, and services included in or available through the TRAWELMART websites or other
            sales channels and ad materials may include inaccuracies or typographical errors which will be immediately
            corrected as soon as TRAWELMART notices them. Changes are/may be periodically made/added to the information
            provided such. TRAWELMART may make improvements and/or changes in the TRAWELMART websites at any time without
            any notice to the User. Any advice received except through an authorized representative of TRAWELMART via
            the TRAWELMART websites should not be relied upon for any decisions.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Modification Of These Terms Of Use
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART reserves the right to change the terms, conditions, and notices under which the TRAWELMART
            websites are offered, including but not limited to the charges. The User is responsible for regularly
            reviewing these terms and conditions.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Jurisdiction
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            TRAWELMART hereby expressly disclaims any implied warranties imputed by the laws of any jurisdiction
            or country other than those where it is operating its offices. TRAWELMART considers itself and intends
            to be subject to the jurisdiction only of the courts of Chennai, India.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Responsibilities Of User Vis-a-Vis The Agreement
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            The User expressly agrees that use of the services is at their sole risk. To the extent TRAWELMART acts
            only as a booking agent on behalf of third-party service providers, it shall not have any liability whatsoever
            for any aspect of the standards of services provided by the service providers. In no circumstances shall TRAWELMART
            be liable for the services provided by the service provider. The services are provided on an "as is" and
            "as available" basis. TRAWELMART may change the features or functionality of the services at any time,
            in their sole discretion, without notice. TRAWELMART expressly disclaims all warranties of any kind,
            whether express or implied, including, but not limited to the implied warranties of merchantability,
            fitness for a particular purpose and non-infringement. No advice or information, whether oral or written,
            which the User obtains from TRAWELMART or through the services shall create any warranty not expressly
            made herein or in the terms and conditions of the services. If the User does not agree with any of the terms
            above, they are advised not to read the material on any of the TRAWELMART pages or otherwise use any of the
            contents, pages, information or any other material provided by TRAWELMART. The sole and exclusive remedy
            of the User in case of disagreement, in whole or in part, of the user agreement, is to discontinue using the
            services after notifying TRAWELMART in writing.
          </p>
          <h1 className="text-xl font-medium font mt-6">
            Usage Of Customer's Holiday Pictures
          </h1>
          <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
            Any picture or video shared by the customers during or post the trip in the support groups or emails,
            will be used in the social media platform to engage the audience. However, if you are not comfortable
            with the same, kindly drop a mail to help@trawelmart.com or happiness@trawelmart.com and your request
            will immediately be looked into.
          </p>
        </div>
      </section>


    </>

  );
}

export default TermsAndConditions;
