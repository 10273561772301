import React, { FC , useState } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import FlightCard6, { FlightCard6Props} from "components/FlightCard/FlightCard6";
import FlightCard7, { FlightCard7Props} from "components/FlightCard/FlightCard7";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import bulletin from "images/logos/bulletin.png"
import FlightCard5 from "components/FlightCard/FlightCard5";
// import FlightCard from "components/FlightCard/FlightCard";

export interface SectionGridFilterCardProps {
  className?: string;
}

const DEMO_DATA: FlightCardProps["data"][] = [
  {
    id: "1",
    price: "₹4,100",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Korean Air",
    },
  },
  
  // {
  //   id: "2",
  //   price: "₹3,380",
  //   airlines: {
  //     logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
  //     name: "Singapore Airlines",
  //   },
  // },
  // {
  //   id: "3",
  //   price: "₹2,380",
  //   airlines: {
  //     logo: "https://www.gstatic.com/flights/airline_logos/70px/multi.png",
  //     name: "Philippine Airlines",
  //   },
  // },
  // {
  //   id: "1",
  //   price: "₹4,100",
  //   airlines: {
  //     logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
  //     name: "Korean Air",
  //   },
  // },
  // {
  //   id: "2",
  //   price: "₹3,380",
  //   airlines: {
  //     logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
  //     name: "Singapore Airlines",
  //   },
  // },
  // {
  //   id: "1",
  //   price: "₹4,100",
  //   airlines: {
  //     logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
  //     name: "Korean Air",
  //   },
  // },
  // {
  //   id: "2",
  //   price: "₹3,380",
  //   airlines: {
  //     logo: "https://www.gstatic.com/flights/airline_logos/70px/SQ.png",
  //     name: "Singapore Airlines",
  //   },
  // },
];

const DEMO_DATA1: FlightCard6Props["data"][] = [
  {
    id: "1",
    price: "₹4,779",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Korean Air",
    },
  },
]

const DEMO_DATA2: FlightCard7Props["data"][] = [
  {
    id: "1",
    price: "₹5,612",
    airlines: {
      logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
      name: "Korean Air",
    },
  },
]


const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  }) => {
  const [activeTab, setActiveTab] = useState(1);
  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      {/* <Heading2
        heading="Bengaluru - Dubai"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            22 flights
            <span className="mx-2">·</span>
            round trip
            <span className="mx-2">·</span>2 Guests
          </span>
        }
      /> */}


    {/* <div>
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 md:grid-cols-3  xl:grid-cols-3  lg:mt-8 mt-4">
        <a className="nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-none " data-nc-id="CardCategoryBox1" href="/enquiry">
          <div className="relative  flex-shrink-0 lg:w-12 lg:h-12 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover border border-black w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/h8PWDtg/rupee-1.png" className="object-cover w-full h-full" alt="nc-imgs" />
              </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
              <span className="font line-clamp-1">Cheapest</span>
              <div className="font-regular text-xs">Duration: 14 h 05 m</div>
            </h2>
            <span className="block mt-1 lg:text-lg text-xs text-black dark:text-neutral-400 font-semibold">
              ₹ 11,162
            </span>
          </div>
        </a>
        <a className="nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-none " data-nc-id="CardCategoryBox1" href="/enquiry">
          <div className="relative flex-shrink-0 lg:w-12 lg:h-12 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/Rcy70k6/flash.png" className="object-cover w-full h-full" alt="nc-imgs" />
              </div>
              </div>   
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
                  <span className="font line-clamp-1">Non Stop First</span>
                  <div className="font-regular text-xs">Duration: 03 h 45 m</div>
                </h2>
                  <span className="block mt-1 lg:text-lg text-xs text-black dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span>
              </div>
         
        </a>
        <a className="nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-none  " data-nc-id="CardCategoryBox1" href="/enquiry">
          <div className="relative flex-shrink-0 lg:w-12 lg:h-12 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500 ">
                <img src="https://i.ibb.co/V99PT53/star-1.png" className="object-cover w-full h-full border-4" alt="nc-imgs" />
              </div>
              </div>
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
                  <span className="font line-clamp-1">You May Prefer</span>
                  <div className="font-regular text-xs">Duration: 03 h 45 m</div>
                </h2>
                  <span className="block mt-1 lg:text-lg text-xs text-black dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span>
              </div>
        </a>
      </div>
    </div> */}    


   {/* section desktop view  */}
    <div className="md:pb-8 sm:pb-8 xl:pb-16 space-y-6 lg:space-y-2 pb-1 xl:pb-2 lg:mb-2">
      <div className="xl:block lg:hidden hidden md:hidden sm:hidden">
        <div className="tab-buttons text-center lg:pb-2">
          <div className="grid xl:grid-cols-3 xl:mx-0 lg:mx-10">
            <button
              className={activeTab === 1 ? 'activee xl:text-xl lg:h-20' : 'lg:rounded-l-lg xl:text-xl xl:py-0 py-2 lg:h-20'}
              onClick={() => handleTabClick(1)}
            >


            {/* <button
              className={activeTab === 1 ? 'activee xl:text-xl lg:h-20' : ' font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none text-blue-800 border-blue-500 border-b-2'}
              onClick={() => handleTabClick(1)}
            > */}
              
              
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none" data-nc-id="CardCategoryBox1" >
          <div className="relative lg:ml-12 flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/h8PWDtg/rupee-1.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="lg:text-lg text-xs font font-medium">
              <span className="font line-clamp-1 lg:text-left">Cheapest</span>
              <div className="font text-xs  lg:text-left"> ₹ 11,162 - Duration: 14 h 05 m</div>
            </h2>
            {/* <span className="block mt-1 lg:text-lg text-xs dark:text-neutral-400 font-semibold">
              ₹ 11,162
            </span> */}
          </div>
        </div>
            </button>

            <button
              className={activeTab === 2 ? 'activeee xl:text-xl lg:h-20' : 'border-inherit border rounded-none xl:text-xl xl:py-0 py-2 lg:h-20'}
              onClick={() => handleTabClick(2)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none " data-nc-id="CardCategoryBox1" >
          <div className="relative lg:ml-12 flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/Rcy70k6/flash.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
              </div>   
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 lg:text-left">Non Stop First</span>
                  <div className="font text-xs lg:text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
         
        </div>
            </button>
            <button
              className={activeTab === 3 ? 'activeeee xl:text-xl lg:h-20' : 'border-inherit border rounded-r-lg xl:text-xl xl:py-0 py-2 lg:h-20'}
              onClick={() => handleTabClick(3)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none  " data-nc-id="CardCategoryBox1">
          <div className="relative lg:ml-12  flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500 ">
                <img src="https://i.ibb.co/V99PT53/star-1.png" className="object-cover w-full h-full border-4 lg:px-1 lg:py-1" alt="nc-imgs" />
              </div>
            </div>
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 lg:text-left">Fastest</span>
                  <div className="font text-xs lg:text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
            </div>
            </button>
          </div>
        </div>
        </div>

        {/* section mobile view */}

        <div className="lg:hidden md:hidden sm:hidden xl:hidden block sm:block">
        <div className="tab-buttons text-center">
          <div className="grid md:grid-cols-3  lg:mx-10">
            <button
              className={activeTab === 1 ? 'activetab xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(1)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3" data-nc-id="CardCategoryBox1" >
          <div className="relative lg:ml-12 flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12  overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/h8PWDtg/rupee-1.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="lg:text-lg text-xs font font-medium">
              <span className="font line-clamp-1 text-left">Cheapest</span>
              <div className="font text-xs text-left"> ₹ 11,162 - Duration: 14 h 05 m</div>
            </h2>
            {/* <span className="block mt-1 lg:text-lg text-xs dark:text-neutral-400 font-semibold">
              ₹ 11,162
            </span> */}
          </div>
        </div>
            </button>

            <button
              className={activeTab === 2 ? 'activetabb xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(2)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3" data-nc-id="CardCategoryBox1" >
          <div className="relative lg:ml-12 flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12  overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/Rcy70k6/flash.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
              </div>   
              <div className=" ml-4 flex-grow overflow-hidden">      
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 text-left">Non Stop First</span>
                  <div className="font text-xs text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
         
        </div>
            </button>
            <button
              className={activeTab === 3 ? 'activetabb xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(3)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none  " data-nc-id="CardCategoryBox1">
          <div className="relative lg:ml-12  flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500 ">
                <img src="https://i.ibb.co/V99PT53/star-1.png" className="object-cover w-full h-full border-4 lg:px-1 lg:py-1" alt="nc-imgs" />
              </div>
            </div>
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 text-left">Fastest</span>
                  <div className="font text-xs text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
            </div>
            </button>
          </div>
        </div>
        </div>

   {/* section mobile landscape view  */}

      <div className="font xl:hidden lg:hidden hidden md:block sm:hidden">
        <div className="tab-buttons text-center">
          <div className="grid md:grid-cols-3 lg:mx-10">
            <button
              className={activeTab === 1 ? 'activetabneww xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-l-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(1)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3" data-nc-id="CardCategoryBox1" >
          <div className="relative lg:ml-12 flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12  overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/h8PWDtg/rupee-1.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="lg:text-lg text-xs font font-medium">
              <span className="font line-clamp-1 text-left">Cheapest</span>
              <div className="font text-xs  text-left"> ₹ 11,162 - Duration: 14 h 05 m</div>
            </h2>
            {/* <span className="block mt-1 lg:text-lg text-xs dark:text-neutral-400 font-semibold">
              ₹ 11,162
            </span> */}
          </div>
        </div>
            </button>

            <button
              className={activeTab === 2 ? 'activetabnew xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-none xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(2)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none " data-nc-id="CardCategoryBox1" >
          <div className="relative lg:ml-12 flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/Rcy70k6/flash.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
              </div>   
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 text-left">Non Stop First</span>
                  <div className="font text-xs text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
         
        </div>
            </button>
            <button
              className={activeTab === 3 ? 'activetabnewww xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-r-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(3)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none  " data-nc-id="CardCategoryBox1">
          <div className="relative lg:ml-12  flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500 ">
                <img src="https://i.ibb.co/V99PT53/star-1.png" className="object-cover w-full h-full border-4 lg:px-1 lg:py-1" alt="nc-imgs" />
              </div>
            </div>
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 text-left">Fastest</span>
                  <div className="font text-xs text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
            </div>
            </button>
          </div>
        </div>
      </div>

     {/* section tab landscape view */}

      <div className="font xl:hidden lg:block md:hidden sm:hidden hidden">
        <div className="tab-buttons text-center">
          <div className="grid md:grid-cols-3 lg:mx-10">
            <button
              className={activeTab === 1 ? 'activetabneww xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-l-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(1)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3" data-nc-id="CardCategoryBox1" >
          <div className="relative flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12  overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/h8PWDtg/rupee-1.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
          </div>
          <div className="ml-4 flex-grow overflow-hidden">
            <h2 className="lg:text-lg text-xs font font-medium">
              <span className="font line-clamp-1 lg:text-left">Cheapest</span>
              <div className="font text-xs  lg:text-left"> ₹ 11,162 - Duration: 14 h 05 m</div>
            </h2>
            {/* <span className="block mt-1 lg:text-lg text-xs dark:text-neutral-400 font-semibold">
              ₹ 11,162
            </span> */}
          </div>
        </div>
            </button>

            <button
              className={activeTab === 2 ? 'activetabnew xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-none xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(2)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none " data-nc-id="CardCategoryBox1" >
          <div className="relative flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500">
                <img src="https://i.ibb.co/Rcy70k6/flash.png" className="object-cover w-full h-full lg:px-2 lg:py-2" alt="nc-imgs" />
              </div>
              </div>   
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 lg:text-left">Non Stop First</span>
                  <div className="font text-xs lg:text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
         
        </div>
            </button>
            <button
              className={activeTab === 3 ? 'activetabnewww xl:text-xl lg:h-20' : 'mb-4 border-inherit border rounded-r-lg xl:text-xl xl:py-0 lg:h-20'}
              onClick={() => handleTabClick(3)}
            >
        <div className="nc-CardCategoryBox1 border-sky-100 relative flex items-center lg:p-2 sm:p-2 p-3 rounded-none  " data-nc-id="CardCategoryBox1">
          <div className="relative flex-shrink-0 lg:w-10 lg:h-10 w-12 h-12 rounded-lg overflow-hidden">
              <div className="object-cover w-full h-full flex items-center justify-center bg-neutral-200 dark:bg-neutral-6000 text-neutral-100 dark:text-neutral-500 ">
                <img src="https://i.ibb.co/V99PT53/star-1.png" className="object-cover w-full h-full border-4 lg:px-1 lg:py-1" alt="nc-imgs" />
              </div>
            </div>
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs font font-medium">
                  <span className="font line-clamp-1 lg:text-left">Fastest</span>
                  <div className="font text-xs lg:text-left"> ₹ 16,819 - Duration: 03 h 45 m</div>
                </h2>
                  {/* <span className="block mt-1 lg:text-lg text-xs  dark:text-neutral-400 font-semibold">
                  ₹ 16,819
                  </span> */}
              </div>
            </div>
            </button>
          </div>
        </div>
      </div>


        <div className="tab-content text-center">
          {activeTab === 1 &&
           <div className="lg:p-3 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6">
           {DEMO_DATA.map((item, index) => (
             <FlightCard defaultOpen={!index} key={index} data={item} />
           ))}
            {DEMO_DATA1.map((item, index) => (
             <FlightCard6 defaultOpen={false} key={index} data={item} />
           ))}
           {DEMO_DATA2.map((item, index) => (
             <FlightCard7 defaultOpen={false} key={index} data={item} />
           ))}
   
           {/* <div className="flex mt-12 justify-center items-center">
             <ButtonPrimary loading>Show more</ButtonPrimary>
           </div> */}
         </div>
            }
          {activeTab === 2 &&
            <div className="lg:p-3 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-2">
            {DEMO_DATA1.map((item, index) => (
             <FlightCard6 defaultOpen={!index} key={index} data={item} />
           ))}        
            </div>}
          {activeTab === 3 &&
           <div className="lg:p-3 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
           {DEMO_DATA.map((item, index) => (
             <FlightCard defaultOpen={false} key={index} data={item} />
           ))}
   
           {/* <div className="flex mt-12 justify-center items-center">
             <ButtonPrimary loading>Show more</ButtonPrimary>
           </div> */}
         </div>
            }
            <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>Show more</ButtonPrimary>
        </div>
        </div>
      </div>

            
      <div className="mb-1 lg:mb-1">
        {/*<TabFilters />*/}
      </div>
      {/* <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {DEMO_DATA.map((item, index) => (
          <FlightCard defaultOpen={!index} key={index} data={item} />
        ))}
        

        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>Show more</ButtonPrimary>
        </div>
      </div> */}
    </div>
  );
};

export default SectionGridFilterCard;
