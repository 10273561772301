import rightImg from "images/about-hero-right.png";
import React, { FC, useState } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import $ from "jquery";
import quotation from "images/quotation.png";
import vision from "images/about/vision.png";
import mission from "images/about/mission.png";
import icon5 from "images/sample/icon5.png";
import icon4 from "images/sample/icon4.png";
import PagePopup from "containers/PageHome/PagePopup";
import bgg from "images/about/bgg.png";
import TestimonialSlider from "./TestimonialSlider";
import PageHistory from "./PageHistory";
import SectionHero2 from "./SectionHero2";
import ourstory from "images/about/ourstory.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import logoss from "images/about/logoss.webp";
import logosss from "images/about/logosss.webp";

export interface PageAbouttProps {
  className?: string;
}

const PageAboutt: FC<PageAbouttProps> = ({ className = "" }) => {
  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: "Our Vision",
      content: (
        <div>
          <p>
            To establish and maintain TRAWEL MART as the most highlighted travel
            agency. A reliable and enthusiastic tour operator with superior
            customer service to provide extra value added service to the
            customers.
          </p>
        </div>
      ),
    },
    {
      title: "Our Mission",
      content: (
        <div>
          <p>
            To continuously expand our customer base by being a proactive travel
            service company and provide a comprehensive range of travel services
            products. To be one of the foremost and innovative travel companies
            in the world.
          </p>
        </div>
      ),
    },
    {
      title: "Founders Motto",
      content: (
        <div>
          <p>
            Going above and beyond to provide excellent, world-class affordable
            travel with customer service and satisfaction as the key. To bring
            in creative and innovative tour packages to cater to customers from
            all walks of life.
          </p>
        </div>
      ),
    },
  ];

  // testimonials slider
  const [inPaused, setInPaused] = useState(false);

  const handleMouseEnter = () => {
    setInPaused(true);
  };

  const settings2 = {
    autoplay: true,
    autoplaySpeed: 2500, // Adjust as needed
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  return (
    <div
      className={`font nc-PageAbout overflow-hidden relative font ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Trawel Mart</title>
      </Helmet>
      {/*<BgGlassmorphism />*/}

      {/* ======== BG GLASS ======== */}

      <div
        className="font "
        style={{
          backgroundImage: `url("https://i.ibb.co/jTqgZdN/about-us-2.jpg")`,
          backgroundSize: "cover",
          height: "350px",
        }}
      >
        <div className="font pt-16 pb-2 lg:pt-12 space-y-16 lg:space-y-2 text-white">
          <SectionHero2 className="" />
        </div>
      </div>
      <div className="bg-sky-100">
        <div className="xl:mb-6 xl:py-4 lg:mb-0 lg:py-0 md:mb-0 md:py-0 mb-0 py-0 ">
          <p className="text-center font-semibold text-bold font1 xl:text-2xl lg:text-2xl md:text-2xl text-xl xl:pb-0 xl:pt-6 md:pt-7 mb-2 pt-4">
            Awards
          </p>
          <TestimonialSlider />
        </div>
      </div>
      {/*<div className="font container py-2 xl:py-16 space-y-16 lg:space-y-2">
        <div className="grid xl:grid-cols-2 xl:gap-2 items-center">
          <div className="font-extrabold font xl:text-2xl xl:pl-12">
            We have been in the tourism industry<br></br> for more than 20 years
          </div>
          <div className="">
            <span className="text-neutral-600 text-sm">
              To make such dreams come true, <strong>TRAWEL MART</strong> was conceptualised in 2008 as a joint collaboration between <strong>Mr. K. Mohan Sundar</strong>,
              Founder and Managing Director, and <strong>Mr. Meda S Kiran Setty </strong> Co-founder, with the desire to create
              an innovative travel company that makes travel affordable across the world and explore new and
              undiscovered destinations in the travel industry.

              Thus, helping a longing traveller travel in the
              direction of their dream. With a formidable presence in 5 strategic locations in India, the company
              strives everyday on the core principle of excellence and value-added service while upholding utmost
              ethical standards. This feat has been key in Trawel Mart emerging as <strong>India&#39;s Number 1 Travel
                Company</strong>.
            </span>
          </div>
        </div>
      </div>*/}

      <div className="container py-16 lg:py-0 space-y-6 lg:space-y-2 pb-16 xl:mb-24 xl:pt-10">
        <div className="grid xl:grid-cols-3 md:grid-cols-3 xl:gap-2 md:gap-1 gap-0 xl:px-12">
          <div className="xl:p-3 lg:p-3 md:p-1 p-3 center">
            <div className="rounded bg-sky-50 xl:p-6 lg:p-6 md:p-2 p-6">
              <div className="center justify-center xl:w-12 xl:h-12 md:h-10 md:w-10 w-10 h-10 pt-4">
                <img src={vision} alt="" />
              </div>
              <p className="font xl:text-lg md:text-sm font-regular lneheght lg:pb-4 xl:pb-4 md:pb-10 pb-10 pt-6">
                To establish and maintain TRAWEL MART as the most highlighted
                travel agency. A reliable and enthusiastic tour operator with
                superior customer service to provide extra value added service
                to the customers.
              </p>
              <div className="border-t pt-4 border-neutral-400"></div>
              <p className="font-semibold xl:text-base uppercase">Our Vision </p>
            </div>
          </div>
          <div className="xl:p-3 lg:p-3 md:p-1 p-3 center">
            <div className="rounded bg-sky-50 xl:p-6 lg:p-6 md:p-2 p-6">
              <div className="center justify-center xl:w-12 xl:h-12 md:h-10 md:w-10 w-10 h-10  pt-4">
                <img src={mission} alt="" />
              </div>
              <p className="font xl:text-lg md:text-sm font-regular lneheght pb-4 pt-6">
                To continuously expand our customer base by being a proactive
                travel service company and provide a comprehensive range of
                travel services products. To be one of the foremost and
                innovative travel companies in the world.
              </p>
              <div className="border-t pt-4 border-neutral-400"></div>
              <p className="font font-semibold xl:text-base uppercase">
                Our Mission
              </p>
            </div>
          </div>
          <div className="xl:p-3 lg:p-3 md:p-1 p-3 center">
            <div className="rounded bg-sky-50 xl:p-6 lg:p-6 md:p-2 p-6">
              <div className="center justify-center xl:w-12 xl:h-12 md:h-10 md:w-10 w-10 h-10  pt-4">
                <img src={vision} alt="" />
              </div>
              <p className="font xl:text-lg md:text-sm font-regular lneheght lg:pb-4 xl:pb-4 md:pb-10 pb-4 pt-6">
                Going above and beyond to provide excellent, world-class
                affordable travel with customer service and satisfaction as the
                key. To bring in creative and innovative tour packages to cater
                to customers from all walks of life.
              </p>
              <div className="border-t pt-4 border-neutral-400"></div>
              <p className="font font-semibold xl:text-base uppercase">
                Founder Motto
              </p>
            </div>
          </div>
        </div>
      </div>

      {/*<div className="container py-16 lg:py-0 space-y-6 lg:space-y-2 pb-16 xl:mb-24 xl:pt-16">
        <div className="grid xl:grid-cols-3 xl:gap-12 gap-2 ">
          <div className=" p-6 center ">
            <img src="https://www.veenaworld.com/assets/images/bsp/bsp-sp-preferred.svg"></img>
            <div className=" font text-center xl:text-xl font-bold pt-2">
              Our Vision
            </div>
            <p className=" text-center font1 text-sm pt-2 font-normal lneheght">
              To establish and maintain TRAWEL MART as the most highlighted travel agency.
              A reliable and enthusiastic tour operator with superior customer service to provide extra value
              added service to the customers.
            </p>
          </div>


          <div className=" p-6 center ">
            <img src="https://www.veenaworld.com/assets/images/bsp/bsp-sp-preferred.svg"></img>
            <div className=" font text-center xl:text-xl font-bold pt-2">
              Our Mission
            </div>
            <p className=" text-center font1 text-sm pt-2 font-normal lneheght">
              To continuously expand our customer base by being a proactive travel service company
              and provide a comprehensive range of travel services products. To be one of the foremost and innovative
              travel companies in the world.
            </p>
          </div>



          <div className="p-6 center ">
            <img src="https://www.veenaworld.com/assets/images/bsp/bsp-sp-preferred.svg"></img>
            <div className=" font text-center xl:text-xl font-bold pt-2">
              Founder Motto
            </div>
            <p className=" text-center font1 text-sm pt-2 font-normal lneheght">
              Going above and beyond to provide excellent, world-class affordable travel with customer service and satisfaction as the key.
              To bring in creative and innovative tour packages to cater to customers <br></br>from all walks of life.
            </p>
          </div>
        </div>
    </div>*/}

      <div className="container xl:mt-0 md:mt-8 lg:py-8 md:py-4">
        <div className="grid xl:grid-cols-2 md:grid-cols-2  xl:gap-2 items-center">
          <div className="xl:mb-0 lg:mb-0 md:mb-0 mb-24">
            <img src={ourstory} alt="" />
          </div>
          <div className="-mt-24 xl:pb-0 lg:pb-0 md:pb-0 pb-6">
            <p className="text-sky-600 xl:text-4xl md:text-3xl text-3xl font-medium bannerfont">
              Our Story
            </p>
            <p className="font-medium text-black xl:text-2xl pt-1 font ">
              Trawel Mart is a brain child of Mr. K. Mohan Sundar
            </p>

            <div className="xl:pt-2 xl:text-lg md:text-sm  text-justify lneheght font ">
              A visionary of speciality travel concepts and a renowned name in
              the travel industry with an enriching travel experience of over 20
              years.
            </div>
            <div className="xl:pt-2 xl:text-lg md:text-sm  text-justify lneheght font ">
              Our endeavour has been to be outstanding in designing tailor made
              national and international tour packages. Regarded as experts we
              enhance the travel experience with world class standards in
              service and best possible prices for any destination worldwide.
              Insight, expertise, personalizing travel experience and dedicated
              personal service has been the USP of this company.
            </div>
          </div>
        </div>
      </div>

      <div
        className="font xl:block lg:hidden hidden"
        style={{
          backgroundImage: `url("https://i.ibb.co/HK6k98Q/mapbg.png")`,
          width: "100%",
          backgroundSize: "cover",
        }}
      >
        <div className="xl:pt-12">
          <PageHistory />
        </div>
      </div>

      <div className=" bg-sky-100  py-16 lg:py-6 space-y-4 lg:space-y-2 pb-16 xl:mb-0 xl:pb-12">
        <div className="container lg:pb-0 md:pb-0 pb-6 mb-0">
          <SectionFounder />
        </div>
        <div className="container relative lg:pt-0 md:pt-0 lg:pb-0 md:pb-0  pt-0 pb-6 ">
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>
      </div>

      <div
        className="font "
        style={{
          backgroundImage: `url("https://i.ibb.co/sW485bY/gridbanner.png")`,
          backgroundSize: "cover",

          backgroundColor: "rgb(221 214 214 / 46%)",
        }}
      >
        <div className="container xl:py-24">
          <div onMouseEnter={handleMouseEnter}>
            <div className="flex">
              <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-1/12"></div>
              <div className="xl:w-8/12 lg:w-8/12 md:w-8/12 w-10/12 rounded-lg lg:pb-12 xl:pt-2 md:pt-8 xl:pb-0 md:pb-8 pb-8">
                <div>
                  <div className="text-center">
                    <p className="font xl:text-3xl lg:text-xl md:text-xl text-lg font-semibold xl:pt-5 lg:pt-12 md:pt-3 pt-3">
                      Testimonials
                    </p>
                    <p className="font font-regular lg:text-base text-xs text-neutral-600">
                      Lorem ipsum is simply dummy text.
                    </p>
                  </div>

                  <div className="xl:px-8 xl:py-0">
                    <Slider {...settings2}>
                      <div className="xl:py-16 xl:px-6 lg:py-10 lg:px-3 md:py-10 md:px-3 py-10">
                        <div className="xl:flex lg:flex md:flex sm:flex flex">
                          <div className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-3/12">
                            <img
                              src="https://www.hcltech.com/sites/default/files/images/special-pages/supercharging-progress/Wendy-Redshaw.webp"
                              className="xl:w-28 xl:h-28 xl:mt-24 lg:w-28 lg:h-28 lg:mt-20 lg:ml-3  md:w-24 md:h-24 md:mt-20 md:ml-3 w-16 h-16 mt-20 ml-1 rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 w-9/12">
                            <img
                              src="https://www.hcltech.com/sites/default/files/images/special-pages/supercharging-progress/quote.svg "
                              className="w-8"
                              alt=""
                            />
                            <p className="font-regular xl:text-lg lg:text-base md:text-base text-xs font1 pt-2 ">
                              " Many thanks to Trawelmart for making our first
                              international trip as memorable Everything was
                              planned Neatly. Right from the communication
                              before trip to drop back to the Airport was
                              planned so amazingly. Special thanks to
                              Thanuja.Kajal and Dilip who helped us to fly
                              Thailand with good plan. Would recommend the same
                              to our friends. Kudos to whole team who worked in
                              backend. "
                            </p>
                            <p className="font-medium text-sky-600 pl-2 xl:text-2xl xl:pt-4">
                              Ms Sushma ,Ms Smitha , Ms Shruthi
                            </p>
                            <p className="font font-regular lg:text-base text-xs text-neutral-600 xl:pl-2 lg:pl-2 md:pl-2 pl-2">
                              Lorem and ipsum
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="xl:py-16 xl:px-6 lg:py-10 lg:px-3 md:py-10 md:px-3 py-10">
                        <div className="xl:flex lg:flex md:flex sm:flex flex">
                          <div className="xl:w-3/12  lg:w-3/12 md:w-3/12 w-3/12">
                            <img
                              src="https://www.hcltech.com/sites/default/files/images/special-pages/supercharging-progress/Wendy-Redshaw.webp"
                              className="xl:w-28 xl:h-28 xl:mt-24 lg:w-28 lg:h-28 lg:mt-20 lg:ml-3   md:w-24 md:h-24 md:mt-24 md:ml-3  w-16 h-16 mt-24 ml-1 rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 w-9/12">
                            <img
                              src="https://www.hcltech.com/sites/default/files/images/special-pages/supercharging-progress/quote.svg "
                              className="w-8"
                              alt=""
                            />
                            <p className="font-regular xl:text-lg lg:text-base md:text-base  text-xs font1 pt-2 ">
                              " Thank you so much Trawelmart, Special thanks to
                              Mamatha & Kajal Team from Banglore. We went on our
                              international tours Singapore, Malaysia &
                              Thailand, for 12 days.All the arrangements were
                              fantanstic.All hotels and transporation
                              arrangements were excellent ... from trawelmart we
                              always got a speedy response. We had no issues
                              throughout the journey.All process was easy and
                              carefree. Our travel experience was
                              unforgettable.I have recommended my friends and
                              family about Trawelmart to others."
                            </p>
                            <p className="font-medium text-sky-600 pl-2 xl:text-2xl xl:pt-4">
                              Mr Veerendra Mohan & Mrs Supriya Veerendra
                            </p>
                            <p className="font font-regular lg:text-base text-xs text-neutral-600 xl:pl-2 lg:pl-2 md:pl-2 pl-2">
                              Lorem and ipsum
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="xl:py-16 xl:px-6 lg:py-10 lg:px-3 md:py-10 md:px-3 py-10">
                        <div className="xl:flex lg:flex md:flex sm:flex flex">
                          <div className="xl:w-3/12  lg:w-3/12 md:w-3/12 w-3/12">
                            <img
                              src="https://www.hcltech.com/sites/default/files/images/special-pages/supercharging-progress/Wendy-Redshaw.webp"
                              className="xl:w-28 xl:h-28 xl:mt-24 lg:w-28 lg:h-28 lg:mt-20 lg:ml-3 md:w-24 md:h-24 md:mt-24 md:ml-3  w-16 h-16 mt-24 ml-1  rounded-full"
                              alt=""
                            />
                          </div>
                          <div className="xl:w-9/12 lg:w-9/12 md:w-9/12 w-9/12">
                            <img
                              src="https://www.hcltech.com/sites/default/files/images/special-pages/supercharging-progress/quote.svg "
                              className="w-8"
                              alt=""
                            />
                            <p className="font-regular xl:text-lg lg:text-base md:text-base text-xs  font1 pt-2 ">
                              " Visited with family and friends to Vietnam
                              organised by Trawel Mart. Very good arrangements
                              and prompt service everywhere. Felt very
                              comfortable throughout the trip. They were in
                              touch with us during the full trip of 9 days and
                              enquired about the operations of the local agency
                              service provider and we are able to communicate
                              with them with ease.I strongly recommend all of
                              you to utilise their services for you trips which
                              they customise according to your budget and
                              requirements.Thank you Trawel Mart. "
                            </p>
                            <p className="font-medium text-sky-600 pl-2 xl:text-2xl xl:pt-4">
                              Ms Anupama & Mr Navindran
                            </p>
                            <p className="font font-regular lg:text-base text-xs text-neutral-600 xl:pl-2 lg:pl-2 md:pl-2 pl-2">
                              Lorem and ipsum
                            </p>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
              <div className="xl:w-3/12 lg:w-3/12 md:w-3/12  w-1/12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageAboutt;
