import React from "react";
import MyRouter from "routers/index";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <MyRouter />
    </div>
    <ToastContainer/>
    </> 
  );
}

export default App;
