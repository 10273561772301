import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import { Link, useLocation } from "react-router-dom";
import SectionLatestPosts from "./SectionLatestPosts";
import WidgetPosts from "./WidgetPosts";
import { Helmet } from "react-helmet";

function Blog() {

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-6 aspect-h-3 rounded-xl overflow-hidden group"
            >
                {/* <Link to={post.href} /> */}
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-1 p-4">
                    <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100  relative">
                        {post.date}
                    </h5>
                    <h2 className="font block xl:text-lg lg:text-base md:text-lg text-base lg:font-bold font-medium text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <>
        <Helmet>
        <title>
          Trawel Mart India’s leading and award winning travel agency{" "}
        </title>
        <meta
          name="description"
          content="We offer services including world tour packages, custom itineraries, expert guides, and hassle-free bookings. Explore the world with confidence and ease."
        />
        <meta
          name="keywords"
          content="Tour packages, Best tour packages, Best tour packages company, Travel Company in Bangalore, Visa Packages"
        />
      </Helmet>
        
        <div className=" nc-PageHome relative overflow-hidden">
            
            <div className="container mt-6">
            <ul className="breadcrumb  lg:w-4/12">
                <li className="lg:text-base text-xs"><a href="/">Home</a></li>
                
                <li className="lg:text-base text-xs">Blog</li>
            </ul>
            </div>
            <div className="container lg:mt-12 mt-12 pb-6">

                <div className="lg:font-semibold font-medium font lg:text-2xl text-lg ">
                    Travel Blog  
                </div>
                <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-0 lg:block hidden">
                    Suggestions tailored to your interest
                </div>
                <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 block  lg:hidden">
                    Suggestions tailored to your interest
                </div>
                {/* <div className="lg:mt-2 lg:mb-24 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-2 xl:gap-3 lg:gap-1 md:gap-3 ">
                    
                    {DEMO_POSTS.filter((_, i) => i < 12).map(renderPostRelated)}
                </div> */}
            </div>

            <div className="container mb-24">
                <div className="flex flex-col lg:flex-row">
                    <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-2 ">
                        <div className={`grid gap-2  grid-cols-1`}>
                            {DEMO_POSTS.filter((_, i) => i < 1).map(renderPostRelated)}
                        </div>
                        <SectionLatestPosts/>
                    </div>
                    <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 m-4 ">
                        <WidgetPosts />
                    </div>
                </div>
            </div>

        </div>
        </>
    );
}

export default Blog;
