import React, { Fragment, useState, FC } from "react";
import { Popover, Transition } from "@headlessui/react";
import { CalendarIcon } from "@heroicons/react/24/outline";
import ClearDataButton from "../ClearDataButton";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";

export interface StayDatesRangeInputProps {
  className?: string;
  fieldClassName?: string;
  buttonSubmitHref?: string;
  hasButtonSubmit?: boolean;
}

const StayDatesRangeInput: FC<StayDatesRangeInputProps> = ({
  className = "[ lg:nc-flex-2 ]",
  fieldClassName = "[]",
  buttonSubmitHref = "/",
  hasButtonSubmit = true,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date("2024/02/06"));
  const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const renderInput = () => {
    return (
      <>
      <div className="text-neutral-300 dark:text-neutral-400">
        <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
      </div>
      <div className="flex-grow text-left">
        <span className="block text-lg font-semibold">
          {selectedMonth ? `${selectedMonth} ${startDate?.getFullYear() || ""}` : "Starting On"}
        </span>
        <span className="block mt-1 text-sm text-neutral-400 font-light  leading-none">
          {"Any Time"}
        </span>
      </div>
      </>
    );
  };

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
  };

  const months = [
    "January", "February", "March", "April",
    "May", "June", "July", "August",
  ];

  return (
    <Popover className={`StayDatesRangeInput z-10 relative flex ${className}`}>
      {({ open }) => (
        <>
          <Popover.Button
            className={` z-10 flex-1 flex relative ${fieldClassName} xl:pl-4 items-center space-x-3 focus:outline-none ${open ? "" : ""}`}
            onClickCapture={() => document.querySelector("html")?.click()}
          >
            {renderInput()}
            {startDate && open && (
              <ClearDataButton onClick={() => onChangeDate([null, null])} />
            )}
          </Popover.Button>

          {hasButtonSubmit && (
            <div className="pr-2 xl:pr-4 xl:py-2">
              <ButtonSubmit href={buttonSubmitHref} />
            </div>
          )}

          {open && (
            <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5  dark:bg-neutral-800"></div>
          )}

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
              <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                <div className="flex-grow text-left">
                  {/* <span className="block xl:text-lg font-semibold">
                    {selectedMonth || "Starting On"}
                  </span> */}
                  <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                    {"Any Time"}
                  </span>
                </div>
                <div className="grid grid-cols-4 gap-0 border-t">
                  {months.map((month, index) => (
                    <div key={index} className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base" onClick={() => handleMonthClick(month)}>
                      <h3 className="font-medium font">{month.substr(0, 3)}</h3>
                      <p className="text-neutral-500 font1 text-base font-semibold">2024</p>
                    </div>
                  ))}
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default StayDatesRangeInput;
