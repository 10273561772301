import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "New Yourk",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Singapore",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Paris",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "London",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Tokyo",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Maldives",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
// ];

// const DEMO_CATS_2: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "Enjoy the great cold",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "222",
//     href: "/listing-stay",
//     name: "Sleep in a floating way",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "3",
//     href: "/listing-stay",
//     name: "In the billionaire's house",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "4",
//     href: "/listing-stay",
//     name: "Cool in the deep forest",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "5",
//     href: "/listing-stay",
//     name: "In the billionaire's house",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
// ];

function PrivacyPolicy() {
    return (
        <>
            <section className="font static-pages">
                <div className="static-header">
                    <h1 className="font bg-sky-600 text-center text-white xl:h-52 lg:h-52 md:h-52 h-32 font-semibold xl:text-4xl lg:text-3xl md:text-2xl text-3xl flex items-center justify-center">
                        Privacy Policy
                    </h1>
                </div>
            </section>

            <section className="font container">
                <div className="xl:m-10 lg:m-4 md:my-6 my-6 border border-blueGray-600 xl:px-20 lg:px-8 md:px-6 px-4 text-justify py-10 rounded-xl">
                    <div className="font xl:text-base lg:text-base md:text-base text-sm">
                        We are in the business of personalized vacations, so privacy is of atmost importance
                        to us. We are not here for your data. We assure you that we will also not spam you
                        with unsolicited emails, messages or calls.
                    </div>
                    <div className="mt-6 font xl:text-base lg:text-base md:text-base text-sm">
                        The Privacy Policy sets forth our policies regarding the collection, use and protection
                        of the personal information of those using the Trawelmart.com website. Personal information
                        means information that can be linked to a specific individual, such as name, address, telephone
                        number, e-mail address, and credit card number. We encourage you to review our Privacy Policy,
                        and become familiar with it, but you should know that we do not sell or rent our customers'
                        personal information to third parties.
                    </div>
                    <div className="mt-6 font xl:text-base lg:text-base md:text-base text-sm">
                        Please note that we review our Privacy Policy from time to time, and we may make
                        periodic changes to the policy in connection with that review. Therefore, you may wish
                        to bookmark this page and/or periodically review this page to make sure you have the latest
                        version. Regardless of later updates, we will abide by the privacy practices described to you
                        in this Privacy Policy at the time you provided us with your personal information.
                    </div>
                    <h1 className="text-xl font-medium mt-6 font">
                        What personal information we collect from you and how we use it?
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        When browsing our site, you are not required to provide any personal information unless
                        and until you choose to make a purchase or sign up for one of our e-mail newsletters or
                        other services as described below.
                    </p>
                    <h1 className="text-xl font-medium mt-6 font">
                        Making a Purchase
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        In order to purchase travel and/or related services through our site, you must provide us
                        with certain personal information such as your name, payment instrument details such as
                        your credit card number and expiration date, credit card billing address, telephone number,
                        e-mail address and the name or names of the person(s) travelling (if not you). We may also
                        ask you for other personal information, such as your frequent flyer numbers.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        We require this information to process, fulfil and confirm your reservations and
                        transactions and keep you informed of each transaction's status. If you are making a
                        reservation for one or more travellers other than yourself, you must confirm and represent
                        that each of these other travellers have agreed, in advance, that you may disclose their
                        personal information to us.
                    </p>
                    <h1 className="text-xl font-medium mt-6 font">
                        Member Registration
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        If you choose to become a registered member of TrawelMart.com site, you must provide
                        your name, address, telephone number, e-mail address, a unique login name, password, and
                        password validation, and a password hint to help you remember your password. This information
                        is collected on the registration form for several reasons including but not limited to:
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Personal identification to complete holiday, air, hotel, car, and other reservations to
                        allow us to contact you for customer service purposes, if necessary to customize the
                        content of our site to strive to meet your specific needs; and/or to make product or
                        other improvements to our site. In addition, we need your e-mail address to confirm your
                        new member registration and each reservation you transact on our site.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        TrawelMart Mobile App
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Our app will never publicly disclose any personal or sensitive data of yours related to
                        financial or payment activities or any government identification numbers.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Access to information
                    </h1>
                    <p className="mt-2 font text-sky-600 font-medium">
                        Things we use -
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Your permissions for Camera and Microphone are required in *iOS only*
                        to let you take photos, videos and send it to customer support in chat if their
                        intervention is required to handle a situation.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Your GPS location permission is required in both Android and iOS to suggest the places near your location.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        As a TrawelMart.com site member you will also occasionally receive updates from us
                        about fare sales in your area, special offers, travel inspirations and other noteworthy
                        items. However, you may choose at any time to no longer receive these types of e-mail
                        messages. Please see our Opt-out Policy described below for details.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Member Profile
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        As a TrawelMart.com site member, you can choose to complete your online profile by providing us
                        with travel preferences, frequent - traveller or affinity numbers, credit card billing information,
                        paper vouchers / ticket delivery address, and other personal information. This information is
                        primarily used to assist you in making reservations quickly without having to type in the same
                        information repeatedly.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Online Surveys
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        TrawelMart World Tours Private Limited values opinions and comments from members,
                        so we frequently conduct online surveys. Participation in these surveys is entirely voluntary
                        and optional. Typically, the information is aggregated, and used to make improvements to the
                        TrawelMart.com site and its services and to develop appealing content, features and promotions
                        for TrawelMart.com site members. Survey participants are anonymous unless otherwise stated in the survey.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Promotions & Sweepstakes
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        TrawelMart World Tours Private Limited frequently sponsors promotions and sweepstakes
                        to give TrawelMart.com site members the opportunity to win great travel and travel -
                        related prizes. Information collected by us for such activities may include contact
                        information and survey questions. We use contact information to notify contest winners
                        and survey information to develop promotions and product improvements to the TrawelMart.com site.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Automatic Logging of Session Data
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        We automatically log generic information about your computer's connection to the Internet,
                        which we call "session data", that is anonymous and not linked to any personal information. Session
                        data consists of things such as IP address, operating system and type of browser software being
                        used and the activities conducted by the user while on TrawelMart.com site. We collect session
                        data because it helps us analyse such things as what items visitors are likely to click on most,
                        the way visitors are clicking through the site, how many visitors are surfing to various pages
                        on the site, how long visitors to the site are staying and how often they are visiting. It also
                        helps us diagnose problems with our servers and lets us better administer our systems. Although
                        such information does not identify any visitor personally, it is possible to determine from an
                        IP address a visitor's Internet Service Provider (ISP), and the approximate geographic location
                        of his or her point of connectivity.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Cookies
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        "Cookies" are small pieces of information that are stored by your browser on your computer's hard drive.
                        There are many myths circulating about cookies, but you should know that cookies are only read by the
                        server that placed them, and are unable to do such things as run programs on your computer, plant
                        viruses or harvest your personal information. The use of cookies is very common on the Internet and
                        TrawelMart World Tours Private Limited's use of cookies is similar to that of such sites as NDTV, Orbitz,
                        as well as Yahoo!, CNN.com and other reputable online companies. First and foremost, you can rest
                        assured that no personally identifiable information ("PII") about you (e.g., name, address, etc.)
                        is gathered or stored in the cookies placed by the TrawelMart.com site and, as a result, none can
                        be passed on to any third parties. Cookies allow us to serve you better and more efficiently, and
                        to personalize your experience at our site. TrawelMart World Tours Private Limited uses cookies
                        to personalize your experience on the TrawelMart.com site, and with respect to advertisements.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        As to the former, these types of cookies allow you to log in without having to type your log - in name
                        each time (only your password is needed. We may also use such cookies to display an advertisement
                        to you while you are on TrawelMart.com site or to send you a "Best Day to Buy" email (or similar emails
                        - assuming you have not opted out of receiving such emails) focusing on destinations in which we
                        think you might be interested. None of this information is passed to any third party, and is used
                        solely by us to provide you with a better user experience on TrawelMart.com site. A cookie may
                        also be placed by our advertising server. Such cookies are used only for purposes of tracking
                        the effectiveness of advertising served by us on our site and no PII is gathered from you by the
                        use of these cookies, nor is this information shared with any third parties. Similarly, a cookie
                        may be placed by our third - party advertising companies. These companies may use aggregated statistics
                        about your visits to this and other web sites in order to provide advertisements about travel - related
                        goods and services that you may be interested in. The information they collect does not include your PII.
                        The third-party advertising companies may also employ technology that is used to measure the effectiveness
                        of ads. Any such information is anonymous. They may use this anonymous information about your visits
                        to this and other sites in order to provide advertisements about goods and services of potential
                        interest to you. No PII is collected during this process. The information is anonymous, and does
                        not link online actions to an identifiable person. Most Web browsers automatically accept cookies.
                        Of course, by changing the options on your web browser or using certain software programs, you can
                        control how and whether cookies will be accepted by your browser.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        TrawelMart World Tours Private Limited supports your right to block any unwanted
                        Internet activity, especially that of unscrupulous websites. However, blocking TrawelMart
                        World Tours Private Limited cookies may disable certain features on the TrawelMart.com site,
                        and may make it impossible to purchase or use certain services available on the TrawelMart.com
                        site. Please note that it is possible to block cookie activity from certain websites while permitting
                        cookies from sites you trust, like TrawelMart World Tours Private Limited.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Other
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        From time to time, we may add or enhance services available on TrawelMart.com site. To the extent
                        these services are provided, and used by you, we will use the information you provide to facilitate
                        the service requested. For example, if you email us with a question, we will use your email address,
                        name, nature of the question, etc. to respond to your question. We may also store such information
                        to assist us in making the site the better and easier to use.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        With whom (if anyone) is your personal information is shared?
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        When you reserve or purchase travel services through TrawelMart.com, we must
                        provide certain of your personal information to the airline, hotel, car - rental agency,
                        travel agency or other involved third party to enable the successful fulfilment of your
                        travel arrangements. However, we do not sell or rent individual customer names or other
                        personal information to third parties. We use non-personally identifiable information in
                        aggregate form to build higher quality, more useful online services by performing statistical
                        analysis of the collective characteristics and behaviour of our customers and visitors,
                        and by measuring demographics and interests regarding specific areas of the TrawelMart.com Site.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        We may provide anonymous statistical information based on this data to suppliers,
                        advertisers, affiliates and other current and potential business partners. We may also
                        use such aggregate data to inform these third parties as to the number of people who have
                        seen and clicked on links to their web sites. Occasionally, TrawelMart World Tours Private
                        Limited will hire a third party to act on our behalf for projects such as market research
                        surveys and contest - entry processing and will provide information to these third parties
                        specifically for use in connection with these projects. The information we provide to such
                        third parties is protected by a confidentiality agreement and is to be used solely for completing
                        the specific project.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        How you can opt-out of receiving our promotional communications?
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        A member or promotion/sweepstakes entrant, on their consent, will occasionally receive
                        e-mail and SMS updates from us about fare sales in their area, special deals and offers,
                        newsletters, new TrawelMart World Tours Private Limited services, and other important offerings.
                        We hope you will find these updates interesting and informative. Of course, if you do not wish
                        to receive any such communication, please click on the "unsubscribe" link or follow the instructions
                        in each e-mail message or send us an email. You can also log in to 'Your Account' on TrawelMart.com
                        and make the desired changes under the Preferences section. TrawelMart World Tours Private Limited
                        reserves the right to limit membership to those who will accept e-mails. Members will be notified
                        via e-mail prior to any actions taken.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        What safeguards we have in place to protect your personal information?
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        All payments on the TrawelMart.com site is SSL secured. This means all personal information
                        you provide to TrawelMart World Tours Private Limited is transmitted using SSL
                        (Secure Socket Layer) encryption. SSL is a proven coding system that lets your browser automatically
                        encrypt, or scramble, data before you send it to us. The same process happens when you make travel
                        purchases on the TrawelMart.com site.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        What other information should I know about my privacy?
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        TrawelMart.com site contains links to other web sites. Please note that when you click on one of
                        these links, you are entering another web site for which TrawelMart World Tours Private Limited has
                        no responsibility. We encourage you to read the privacy statements of all such sites as their policies
                        may be materially different from this Privacy Policy. Of course, you are solely responsible for maintaining
                        the secrecy of your passwords, and your TrawelMart.com site membership account information. Please be very
                        careful, responsible, and alert with this information, especially whenever you're online.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        In addition to the circumstances described above, TrawelMart World Tours Private Limited may
                        disclose the TrawelMart.com site member information if required to do so by law, court order,
                        as requested by other government or law enforcement authority, or in the good faith belief that
                        disclosure is otherwise necessary or advisable including, without limitation, to protect the
                        rights or properties of TrawelMart World Tours Private Limited or any or all of its affiliates,
                        associates, employees, directors or officers or when we have reason to believe that disclosing
                        the information is necessary to identify, contact or bring legal action against someone who may
                        be causing interference with our rights or properties, whether intentionally or otherwise, or
                        when anyone else could be harmed by such activities. In addition, if TrawelMart World Tours Private
                        Limited or substantially all of its assets are acquired, \ our customer information will most likely
                        also be transferred in connection with such acquisition.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        This policy is effective as of Jan 1st, 2014. Any material changes in the way TrawelMart World
                        Tours Private Limited uses personal information will be described in future versions of this Privacy
                        Statement. Of course, you may always submit concerns regarding our Privacy Statement or our privacy practices
                        via email to support@TrawelMart.com. Please reference the privacy policy in your subject line. TrawelMart
                        World Tours Private Limited will attempt to respond to all reasonable concerns or inquiries within seven
                        working days of receipt. We look forward to having the opportunity to serve you!!
                    </p>
                </div>
            </section>
        </>

    );
}

export default PrivacyPolicy;
