import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogEurope = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Europe" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Unveiling the Tapestry of Europe: A Journey Through Historic Landscapes and Cultural Marvels
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Europe, a continent woven with the threads of history, beckons travellers to embark on a
                        captivating journey through its diverse landscapes and cultural treasures. From the grandeur of
                        ancient monuments to the charm of cobblestone streets, this tapestry of nations invites exploration
                        and discovery.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    A Prelude in Paris: City of Lights and Romance
                </p>

                <p className="">
                    Begin your European odyssey in the City of Lights – Paris. The Eiffel Tower stands tall, a symbol
                    of romance and architectural splendour. Stroll along the Seine, where the Louvre's iconic glass pyramid
                    reflects centuries of artistic brilliance. Paris, with its blend of history and modernity, sets the stage
                    for the enchanting chapters that lie ahead.
                </p>

                <p className="font-semibold text-xl">
                    Rome: Where Antiquity Comes Alive
                </p>
                <p className="">
                    Traveling south, Rome emerges as a living museum. The Colosseum echoes with the cheers of ancient
                    spectators, and the Forum invites you to walk in the footsteps of emperors. Amidst the timeless beauty
                    of the Vatican City, Michelangelo's Sistine Chapel mesmerizes, telling tales of artistry and faith.
                </p>

                <p className="font-semibold text-xl">
                    Venice: Navigating Canals and Venetian Mystique
                </p>
                <p className="">
                    Venture northeast to Venice, a city suspended on water. Glide along the canals on a gondola, passing beneath
                    ornate bridges and historic palaces. St. Mark's Basilica and the Doge's Palace stand as testaments to
                    Venetian opulence, inviting you to unravel the mysteries of this aquatic wonder.
                </p>


                <p className="font-semibold text-xl">
                Cultural Kaleidoscope in Barcelona
                </p>
                <p className="">
                Crossing the Pyrenees, Barcelona unfolds as a cultural kaleidoscope. Gaudi's Sagrada Familia and Park 
                Güell showcase the city's artistic flair. The Gothic Quarter whispers tales of medieval times, while the
                 vibrant La Rambla pulsates with contemporary energy. Barcelona is a testament to the harmonious coexistence 
                 of the old and the new.
                </p>

                <p className="font-semibold text-xl">
                Swiss Alps: Majestic Peaks and Alpine Serenity
                </p>
                <p className="">
                Journey into the heart of the Swiss Alps, where majestic peaks pierce the sky. The scenic train rides
                 through snow-covered landscapes and pristine lakes offer a tranquil interlude. Whether skiing in Zermatt
                  or savouring Swiss chocolate in Lucerne, the Alps provide a breath-taking backdrop for unforgettable moments.
                </p>

                <p className="font-semibold text-xl">
                Prague: Bohemian Elegance and Old-World Charms
                </p>
                <p className="">
                Heading east, Prague captivates with its fairy-tale-like ambiance. Wander through the cobbled streets
                 of Old Town, where medieval architecture enchants at every turn. The Prague Castle perched on the 
                 hilltop and the iconic Charles Bridge create a panorama of timeless elegance.
                </p>

                <p className="font-semibold text-xl">
                Epilogue: Europe's Ever-Unfolding Tapestry
                </p>
                <p className="">
                As your journey through Europe concludes, you carry with you a mosaic of experiences – the echoes of 
                ancient civilizations, the whispers of artistic genius, and the warmth of diverse cultures. 
                Europe's tapestry, intricately woven with history and modernity, invites each traveler to become a part of 
                its ever-unfolding narrative. Whether amidst the romance of Paris, the grandeur of Rome, or the serenity 
                of the Swiss Alps, Europe beckons adventurers to uncover its boundless treasures and be embraced by the 
                richness of its cultural tapestry.
                </p>

                

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/Qf82SYr/blogeurope.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogEurope;
