import React, { FC } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategoryBoxxProps {
    className?: string;
    taxonomy: TaxonomyType;
}

const CardCategoryBoxx: FC<CardCategoryBoxxProps> = ({
    className = "",
    taxonomy,
}) => {
    const { count, name, thumbnail, text, href = "/" } = taxonomy;
    return (
        <Link
            to={href}
            className={`nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-lg [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
            data-nc-id="CardCategoryBox1"
        >


            <div className="relative flex-shrink-0 lg:w-24 lg:h-24 w-12 h-12 rounded-full overflow-hidden">
                <NcImage src={thumbnail} containerClassName="absolute inset-0" />
            </div>
            <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
                    <span className="font line-clamp-1">{name}</span>
                </h2>
                {/*<span
                    className={`block mt-1 lg:text-xs text-xs text-neutral-600 dark:text-neutral-400`}
                >
                    {text}
                </span>*/}
                <div className="font-regular text-xs">
                    Round Trip From 
                </div>
                <span
                    className={`block mt-1 lg:text-lg text-xs text-black dark:text-neutral-400 font-semibold`}
                >
                    ₹ {convertNumbThousand(count)}
                </span>

            </div>
        </Link>
    );
};

export default CardCategoryBoxx;
