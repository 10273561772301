import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogMalaysia = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Malaysia" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        An Island Paradise Waiting to Be Explored
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Malaysia, a nation of unparalleled beauty and diversity, boasts an array of pristine islands that
                        beckon travellers from around the globe. Nestled in the heart of Southeast Asia, this tropical
                        haven is often overlooked by those seeking an island escape. However, Malaysia&#39;s islands
                        offer an idyllic blend of natural wonders, cultural richness, and adventure, making it a true
                        island paradise.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    A Melting Pot of Island Experiences
                </p>
                <p className="">
                    Malaysia&#39;s archipelago comprises over 800 islands, each with its own unique charm. Among
                    these, Langkawi, Penang, Perhentian Islands, and Tioman Island stand out as some of the
                    most captivating.
                </p>
                <p className="">
                    <strong>Langkawi:</strong>  Known as the &quot;Jewel of Kedah,&quot; Langkawi is a paradise for beach lovers and
                    nature enthusiasts. With its pristine shores, lush rainforests, and vibrant underwater world,
                    Langkawi offers a multitude of activities, from sunbathing on Pantai Cenang to exploring the
                    Langkawi Wildlife Park.
                </p>
                <li>
                    <strong> Penang:</strong> Often referred to as the &quot;Pearl of the Orient,&quot; Penang is famous for its cultural
                    heritage and delectable street food. George Town, a UNESCO World Heritage Site, features
                    captivating street art and architecture, while Penang&#39;s beaches offer relaxation and water
                    sports.
                </li>
                <li>
                    <strong> Perhentian Islands:</strong> These unspoiled gems in the South China Sea are a diver&#39;s paradise.
                    Crystal-clear waters teem with marine life, including turtles and reef sharks. Travelers
                    seeking tranquillity and underwater adventure will find it here.
                </li>
                <li>
                    <strong> Tioman Island:</strong> Tucked away in the South China Sea, Tioman Island is a favorite among
                    divers and trekkers. Its lush jungles, pristine beaches, and vibrant coral reefs make it a nature
                    lover&#39;s paradise. Don&#39;t miss a trek through the dense rainforest to discover cascading
                    waterfalls.
                </li>


                <p className="font-semibold text-lg">
                    Diving into an Underwater Wonderland
                </p>

                <p className="">
                    One of Malaysia&#39;s greatest draws is its underwater world. The country&#39;s islands offer some of
                    the best diving and snorkelling experiences in the world. The warm waters are home to an
                    astonishing diversity of marine life, colourful coral reefs, and underwater caves.
                </p>

                <p className="">
                    The Perhentian Islands, for example, are known for their thriving coral gardens and
                    encounters with turtles, reef sharks, and schools of tropical fish. Snorkelers can explore
                    shallow reefs, while experienced divers can venture into the deep to discover hidden
                    treasures.
                </p>
                <p className="">
                    Langkawi also boasts impressive snorkelling and diving spots. Pulau Payar Marine Park, a
                    short boat ride from Langkawi, is a marine sanctuary teeming with marine life and coral
                    formations. It&#39;s an ideal place for both beginners and experienced divers.
                </p>

                <p className="">
                    Tioman Island&#39;s underwater world is equally captivating, with dive sites like Chebeh, known
                    for its stunning coral gardens and diverse marine species. Whether you&#39;re a novice or an
                    expert, Tioman offers a diving experience like no other.
                </p>


                <p className="font-semibold text-lg">
                    Exploring Rich Cultural Heritage
                </p>

                <p className="">
                    Malaysia&#39;s islands are not just about sun, sand, and sea; they are also windows into the
                    nation&#39;s rich cultural heritage. George Town in Penang, a UNESCO World Heritage Site, is a
                    living museum of Chinese, Malay, and Indian culture. Stroll through its historic streets
                    adorned with colourful street art and intricate temples, and savor a variety of mouthwatering
                    street food.
                </p>

                <p className="">
                    In Langkawi, discover the island&#39;s folklore and legends at the Mahsuri Tomb and Atma Alam
                    Batik Art Village. Immerse yourself in the local culture by attending traditional dance
                    performances or sampling Malay cuisine.
                </p>
                <p className="">
                    Tioman Island offers a unique cultural experience with its friendly local communities.
                    Engage with the islanders, learn about their way of life, and witness traditional ceremonies.
                    It&#39;s a chance to connect with the heart of Malaysia.
                </p>



                <p className="font-semibold text-lg">
                    Adventure Beyond the Beaches
                </p>

                <p className="">
                    While the beaches are undoubtedly a highlight, Malaysia&#39;s islands offer adventures beyond
                    the shoreline. Trekking enthusiasts will find themselves in paradise on Tioman Island, where
                    dense jungles conceal waterfalls and exotic wildlife. Explore the network of trails that
                    crisscross the island, taking you deep into its lush heart.
                </p>

                <p className="">
                    For thrill-seekers, Langkawi offers an adrenaline rush with activities like zip-lining through
                    the treetops at Umgawa Zipline Eco Adventures or taking a thrilling ride on the Langkawi
                    Cable Car to the summit of Gunung
                </p>


                <p className="font-semibold text-lg">
                    A Culinary Journey
                </p>

                <p className="">
                    No trip to Malaysia would be complete without indulging in its world-famous cuisine. The
                    islands offer a delectable array of flavours, from street food stalls to high-end restaurants. Try
                    nasi lemak (coconut rice with spicy sambal), char kway teow (stir-fried flat noodles), and
                    laksa (spicy noodle soup) in Penang&#39;s vibrant food scene.
                </p>
                <p className="">
                    In Langkawi, sample fresh seafood at local eateries and savor unique dishes like ikan bakar
                    (grilled fish) while dining on the beach. Tioman Island also boasts fresh seafood, and you
                    can&#39;t leave without trying rendang (spicy beef stew) and sambal (chili paste) served with
                    traditional Malay dishes.
                </p>
                <p className="font-semibold text-lg">
                    Conclusion: Malaysia&#39;s Island Paradise Awaits
                </p>
                <p>
                    Malaysia&#39;s islands are a well-kept secret, waiting to be explored by travellers seeking an
                    island paradise that offers more than just pristine beaches. With diverse cultural experiences,
                    underwater wonders, adventure opportunities, and mouthwatering cuisine, these islands
                    present a multifaceted destination that caters to all kinds of travelers. So, pack your bags and
                    set out to discover the hidden gems of Malaysia&#39;s island paradise, where adventure and
                    relaxation converge in perfect harmony.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {post.author.displayName}
                        </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/myCm9dY/malaysia.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/* 
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogMalaysia;
