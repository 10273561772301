import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogNepal = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Nepal" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Exploring Nepal's Rich Cultural Heritage: A Journey Through Temples and Traditions
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Nepal, nestled in the heart of the Himalayas, is not just a haven for trekkers and adventure
                        seekers; it is also a treasure trove of cultural richness that spans centuries. The cultural
                        tapestry of Nepal is woven with threads of tradition, spirituality, and architectural marvels.
                        Join us on a virtual journey through the vibrant heritage of this enchanting land.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    A Glimpse Into the Spiritual Landscape
                </p>

                <p className="">
                    Nepal is home to an array of temples, each with its own unique story and significance.
                    From the iconic Pashupatinath Temple, a UNESCO World Heritage site and one of the holiest Hindu
                    shrines, to the serene Swayambhunath Stupa, also known as the Monkey Temple, the spiritual landscape
                    is diverse and awe-inspiring. Explore the intricate architecture that reflects the country's deep-rooted
                    religious traditions.
                </p>

                <p className="font-semibold text-xl">
                    Festivals: A Kaleidoscope of Colors and Celebrations
                </p>
                <p className="">
                    Nepal is synonymous with vibrant festivals that bring communities together in joyous
                    celebrations. Dashain, Tihar, and Holi are just a few examples of festivals that showcase the
                    country's cultural diversity. Dive into the heart of these festivities, where rituals, music,
                    dance, and elaborate ceremonies create an atmosphere of exuberance.
                </p>

                <p className="font-semibold text-xl">
                    Traditions That Withstand Time
                </p>
                <p className="">
                    As modernity sweeps through the world, Nepal holds steadfast to its traditional customs.
                    The intricate art of Thangka painting, the melodious tunes of traditional music, and the
                    elaborate rituals that accompany life events contribute to the unique identity of Nepal.
                    Discover the stories behind these age-old traditions and their relevance in contemporary Nepali society.
                </p>


                <p className="font-semibold text-xl">
                    Architectural Marvels: Preserving the Past for the Future
                </p>
                <p className="">
                    The architectural heritage of Nepal is a testament to the craftsmanship of generations
                    past. Durbar Squares in Kathmandu, Bhaktapur, and Patan are living museums, showcasing palaces,
                    courtyards, and temples adorned with woodcarvings and intricate metalwork. Learn about the
                    efforts to preserve and protect these architectural gems, which stand as witnesses to
                    Nepal's historical grandeur.
                </p>

                <p className="font-semibold text-xl">
                    The Living Culture: Warmth and Hospitality
                </p>
                <p className="">
                    Beyond the tangible heritage, it's the warmth and hospitality of the Nepali people that
                    leave an indelible mark on visitors. Engage with local communities, share a cup of tea in
                    a traditional teahouse, and witness the simplicity and kindness that define the Nepali way of life.
                </p>

                <p className="font-semibold text-xl">
                    Preserving Cultural Heritage: Challenges and Triumphs
                </p>
                <p className="">
                    While Nepal's cultural heritage is undoubtedly rich, it faces challenges in the
                    modern era. Rapid urbanization, environmental factors, and the aftermath of the
                    devastating earthquakes in 2015 have posed threats to the preservation of these treasures.
                    However, amidst these challenges, there are inspiring stories of restoration efforts and
                    community-driven initiatives aimed at safeguarding Nepal's cultural identity.
                </p>

                <p className="">
                    Organizations and local communities have come together to restore and rebuild damaged
                    structures, employing traditional techniques to maintain the authenticity of these heritage
                    sites. The dedication to preserving cultural heritage is not only a nod to the past but also
                    a promise to future generations.
                </p>


                <p className="font-semibold text-xl">
                    Cultural Tourism: A Bridge Between Past and Present
                </p>
                <p className="">
                    Cultural tourism plays a pivotal role in sustaining Nepal's heritage. Visitors not only marvel
                    at the architectural wonders but also actively contribute to the conservation efforts. The revenue
                    generated from tourism aids in the maintenance of historical sites and supports local communities,
                    creating a symbiotic relationship between preserving the past and embracing the present.
                </p>

                <p className="font-semibold text-xl">
                    Intangible Cultural Heritage: The Soul of Nepal
                </p>
                <p className="">
                    Beyond the tangible monuments, Nepal's intangible cultural heritage is equally captivating.
                    Traditional dances, rituals, and oral traditions passed down through generations add depth to
                    the cultural tapestry. Explore the intricacies of dances like the masked Lakhe dance or the lively
                    Maruni dance, each telling a story that transcends time.
                </p>

                <p className="font-semibold text-xl">
                    The Future of Nepal's Cultural Heritage
                </p>
                <p className="">
                    As Nepal strides into the future, there's a delicate balance to be maintained between
                    progress and preservation. Sustainable tourism practices, community engagement, and education
                    about the significance of cultural heritage are integral in ensuring that the legacy endures.
                </p>

                <p className="">
                    Nepal's cultural heritage is a kaleidoscope of spirituality, festivals, traditions, and
                    architectural marvels. It's a journey that transcends time, inviting travelers to not just
                    witness, but immerse themselves in the vibrant tapestry of a nation that takes pride in its
                    rich cultural legacy. As you explore the temples and traditions of Nepal, you'll find that
                    each step is a pilgrimage, and each encounter is a cultural exchange that leaves an
                    everlasting impression.
                </p>

                <p className="font-semibold text-lg">
                    Conclusion
                </p>

                <p>
                    In conclusion, Nepal's cultural heritage is not a static relic of the past; it's a living,
                    breathing entity that evolves with time. It beckons travelers to delve into its mysteries,
                    appreciate its beauty, and become custodians of its legacy. The journey through Nepal's temples
                    and traditions is not just a stroll through history but an immersive experience that resonates with
                    the soul of a nation, inviting everyone to be a part of its enduring story. As you embark on this
                    cultural odyssey, you'll find that Nepal's heritage is not just a destination; it's a celebration of the
                    human spirit and the timeless allure of tradition.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/hfYT0np/nepal.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogNepal;
