import React, { FC, Fragment, useState, useEffect } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS9 } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import DetailPagetLayout from "../Layout";
import BaliCard1, {
  BaliCard1Props,
} from "components/FlightCard/Bali/BaliCard1";
import BaliCard2, {
  BaliCard2Props,
} from "components/FlightCard/Bali/BaliCard2";
import BaliCard3, {
  BaliCard3Props,
} from "components/FlightCard/Bali/BaliCard3";
import BaliCard4, {
  BaliCard4Props,
} from "components/FlightCard/Bali/BaliCard4";
import BaliCard5, {
  BaliCard5Props,
} from "components/FlightCard/Bali/BaliCard5";
import { Tab } from "@headlessui/react";
import $ from "jquery";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import coin from "images/ICONS/coin.png";
import india from "images/india.png";
import offersss from "images/offersss.gif";
import emirates from "images/ICONS/emirates.png";
import airasia from "images/ICONS/airasia.png";
import indigo from "images/ICONS/indigo.png";
import { HashLink } from "react-router-hash-link";
import Accordion from "./Accordion";
import axios from "axios";
import PagePopup from "containers/PageHome/PagePopup";
import passport from "images/flags/passport.png";

const StayDetailsPageContainer: FC<{}> = () => {
  let [inOpen, setInOpen] = useState(false);
  function incloseModal() {
    setInOpen(false);
  }

  function inopenModal() {
    setInOpen(true);
  }
  //
  {
    /* wizard script starts */
  }

  const pdfUrl = "/firstpdf.pdf";

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);

  const handleNextButtonClick = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevButtonClick = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleEnquiryButtonClick = () => {
    setShowEnquiryPopup(true);
  };

  const handleEditIconClick = () => {
    setCurrentStep(1);
    setShowEnquiryPopup(false); // Show the popup again after going back to the first step
  };

  {
    /* wizard script Ends */
  }

  const accordionData = [
    {
      title: "Do I need a Visa for Singapore ?",
      content: [
        <ul className="pl-8 list-disc">
          <li className="py-2">
            All Indian nationals require visa to enter Singapore. The Government
            of Singapore has made Visa Online. The Application should be
            submitted online with required documens.
          </li>
        </ul>,
      ],
    },
  ];

  const accordionData2 = [
    {
      title: "How much does the Visa Cost ?",
      content: [
        <ul className="pl-8 list-disc">
          <li className="py-2">
            The Visa Cost for both Tourist and Business is same. It cost Rs.
            3500/- per person inclusive of Visa Fee and Service Charges.
          </li>
        </ul>,
      ],
    },
  ];

  const accordionData3 = [
    {
      title: "How do I get my Visa processed ?",
      content: [
        <ul className="pl-6 list-disc">
          <li className="py-1">
            All application has to be submitted online through an Singapore
            Government authorized Agent to process visa
          </li>
        </ul>,
      ],
    },
  ];

  const accordionData4 = [
    {
      title: "Tourist Visa",
      content: [
        <div className="xl:w-10/12">
          {/* <div className="p-4 rounded-t-lg">
            <h3 className="font font-medium xl:text-base">
              48 Hours Transit Visa + Insurance (Covid)
            </h3>
          </div> */}
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 grid-cols-2 gap-2 px-4 pt-3 xl:pt-6 md:pt-4">
            <div className="font-normal font text-base">
              Processing Time or Day :
            </div>
            <div className="font-normal font text-base text-end">
              3-5 Working Days
            </div>
          </div>
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3  xl:pt-3 md:pt-3">
            <div className="font-normal font text-base">Insurance :</div>
            <div className="font-normal font text-base text-end">
              Not Mandatory
            </div>
          </div>
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 grid-cols-2 gap-2  md:gap-2 pt-3 px-4 xl:pt-3 md:pt-3">
            <div className="font-normal font text-base">
              Visa Price includes :
            </div>
            <div className="font-normal font text-base text-end">
              Visa Fee and Service Charges
            </div>
          </div>
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 grid-cols-2 gap-2 pt-3 px-4 xl:pt-3 md:pt-3 pb-6">
            <div className="font-normal font text-base">Visa Pricing :</div>
            <div className="font-semibold text-sky-600 font text-lg text-end">
              INR 3,500/-
            </div>
          </div>
        </div>,
      ],
    },
  ];

  const accordionData7 = [
    {
      title: "What would be validity of Visa ?",
      content: [
        <ul className="pl-8 list-disc">
          <li className="py-2">
            Once the visa is granted the validity of the visa would be given for
            a minimum validity of 3 months to 1 year with a maximum stay Of 1
            month.
          </li>
        </ul>,
      ],
    },
  ];

  const accordionData5 = [
    {
      title: "Business Visa",
      content: [
        <div className="  xl:w-10/12">
          {/* <div className=" p-4 rounded-t-lg">
            <h3 className="font font-medium xl:text-base">
              30 Days Tourist Visa + Insurance (Covid)
            </h3>
          </div> */}
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-6 md:pt-4">
            <div className="font-normal font text-base">
              Processing Time or Day :
            </div>
            <div className="font-normal font text-base text-end">
              3-5 Working Days
            </div>
          </div>
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
            <div className="font-normal font text-base">Insurance :</div>
            <div className="font-normal font text-base text-end">
              Not Mandatory
            </div>
          </div>
          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
            <div className="font-normal font text-base">
              Visa Price Includes :
            </div>
            <div className="font-normal font text-base text-end">
              Visa Fee and Service Charges
            </div>
          </div>

          <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3 pb-6">
            <div className="font-normal font text-base">Visa Pricing :</div>
            <div className="font-semibold text-sky-600 font text-lg text-end">
              INR 3,500/-
            </div>
          </div>
        </div>,
      ],
    },
  ];

  const [activeMenuItem, setActiveMenuItem] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight / 3 &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveMenuItem(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  let [categories] = useState([
    "Need to know",
    "Tour Preparation",
    "Cancellation Policy",
  ]);
  let [categories2] = useState(["Transport Details", "Accommodation Details"]);
  let [categories3] = useState(["Flight Upgrade", "Hotel Upgrade"]);
  let [categories1] = useState(["Included", "Excluded"]);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [adultValue, setAdultValue] = useState(1);
  const [childrenValue, setChildrenValue] = useState(0);
  const [infantValue, setInfantValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(1999);
  const [maximumPeople, setMaximumPeople] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [departureError, setDepartureError] = useState(false);
  const [travelError, setTravelError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [passangerError, setPassangerError] = useState(false);
  const location = useLocation();
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [mobileNumber, setMobileNumber] = useState("");
  let [departureCity, setDepartureCity] = useState("");
  let [travelDate, setTravelDate] = useState("");
  let [destination, setDestination] = useState("");
  let [noofPassanger, setNoofPassanger] = useState("");
  const [inputDateType, setInputDateType] = useState("text");
  let [description, setDescription] = useState(
    "Enquiring for Singapore Visa Online"
  );

  useEffect(() => {
    setMaximumPeople(adultValue + childrenValue + infantValue);
  }, [adultValue, childrenValue, infantValue]);
  const handleAdultChange = (value: any) => {
    setAdultValue(value);
    setTotalAmount(value * 50000 + childrenValue * 30000 + infantValue * 25000);
    console.log(value);
  };

  const handleChildrenChange = (value: any) => {
    setChildrenValue(value);
    setTotalAmount(adultValue * 50000 + value * 30000 + infantValue * 25000);
    console.log(value);
  };

  const handleInfantChange = (value: any) => {
    setInfantValue(value);
    setTotalAmount(adultValue * 50000 + childrenValue * 30000 + value * 25000);
    console.log(value);
  };

  const updateRoomPrice = (value: any, roomText: any) => {
    setTotalAmount(
      adultValue * 50000 + childrenValue * 30000 + infantValue * 25000 + value
    );
    setSelectedRoom(roomText);
  };

  useEffect(() => {
    const userDataJSON = localStorage.getItem("loggedInUser");
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setName(userData.name);
      setMobileNumber(userData.mobile);
      setEmail(userData.email);
      setDepartureCity(userData.departure);
      setTravelDate(userData.travel);
      setDestination(userData.destination);
      setNoofPassanger(userData.Passanger);
    }
  }, []);

  const handleSubmit = () => {
    setNameError(name.length === 0 || /\d/.test(name));
    setMobileError(mobileNumber.length !== 10);
    setEmailError(email.length === 0);
    setDepartureError(departureCity.length === 0);
    setTravelError(travelDate.length === 0);
    setDestinationError(destination.length === 0);
    setPassangerError(noofPassanger.length === 0);

    if (!nameError && !mobileError && !emailError) {
      const submitButton = document.getElementById("submitButton");
      if (submitButton) {
        (submitButton as HTMLButtonElement).disabled = true;
        submitButton.innerHTML = "Submitting...";
      }
      const travelers = `${adultValue} Adults, ${childrenValue} Child, ${infantValue} Infant`;
      const formData = {
        name,
        mobile: mobileNumber,
        email_address: email,
        description,
        guests: travelers,
        room: selectedRoom,
        url: location.pathname,
      };
      axios
        .post(
          "https://admin.trawelmart.com/admin/public/index.php/api/addContact",
          formData
        )
        .then((response: any) => {
          console.log(response);
          if (response.status === 200) {
            if (response.data.status) {
              (window as any).location = "/thanks";
            } else {
              toastr.error(response.data.message);
            }
          } else {
            console.log(response.responseText);
          }
        });
    }
  };

  const thisPathname = useLocation().pathname;
  const router = useNavigate();
  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderSidebar = () => {
    return (
      <div>
        <div className="bg-white xl:mb-2 border px-4 py-4 rounded-lg shadow-md  lg:block hidden">
          <div className="flex">
            <div className="xl:w-8/12">
              <div className="text-lg text-black font-semibold">
                Visa Includes
              </div>

              <div>
                <nav
                  className="pt-4 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-4"
                  data-nc-id="SocialsList"
                >
                  <div className="">
                    <a
                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="las la-hotel"></i>
                    </a>
                    <span className="text-xs font-normal text-black text-center">
                      Charges
                    </span>
                  </div>
                  <div className="">
                    <a
                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="las la-utensils"></i>
                    </a>
                    <span className="text-xs font-normal text-black xl:pl-2 text-center">
                      Tax
                    </span>
                  </div>
                  <div className="">
                    <a
                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="las la-exchange-alt"></i>
                    </a>
                    <span className="text-xs font-normal text-black text-center">
                      Transfer
                    </span>
                  </div>
                  <div className="">
                    <a
                      className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                      href=""
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className="las la-magic"></i>
                    </a>
                    <span className="text-xs font-normal text-black text-center">
                      Visa Fees
                    </span>
                  </div>
                </nav>
              </div>
            </div>
            <div className="xl:w-5/12 xl:mt-3">
              <div className=" lg:pt-3 justify-center text-right xl:block lg:block md:hidden hidden  xl:pr-3 xl:pt-5 ">
                <div className="font xl:text-xs lg:text-sm font-regular text-green-600">
                  Trawelmart Offer Price
                </div>
                <div className="font uppercase xl:text-xs lg:text-xs font-regular text-black">
                  Starts from
                </div>
                <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
                  ₹{totalAmount}
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
        <div
          onClick={inopenModal}
          className="cursor-pointer rounded py-6 px-4 xl:h-16 gradient2 xl:my-3 lg:flex items-center justify-between hidden lg:block "
        >
          <div className="font text-white font1 font-bold text-2xl">
            Sample Visa
          </div>
          <img src={passport} alt="" className="w-10" />
        </div>
        <Transition appear show={inOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={incloseModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-25" />
            </Transition.Child>
            <div className="font fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all xl:mt-24">
                    {/* <div className="text-right xl:my-2 mr-2">
                      <button
                        type="button"
                        className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                        onClick={incloseModal}
                      >
                        X
                      </button>
                    </div> */}

                    <img
                      src="https://i.ibb.co/wWPf2ZK/singapore-sample.jpg"
                      alt="Singapore"
                      className=""
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
        <div className="bg-white rounded-xl shadow-xl height">
          <div className=" p-4 bg-[#fbf7fb] rounded-lg">
            <div className="flex justify-between">
              <span className="text-xl font-semibold">
                Visa Details
                <div className="text-xs font-normal text-blue-800 italic">
                  9,232 Guests already travelled
                </div>
              </span>
              <div className="text-right ">
                <div className="border-orange-500 rounded text-orange-600 px-6 text-center border text-xs font-semibold py-1">
                  Visa
                </div>
              </div>
            </div>
          </div>

          <div className="w-full border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>
          <div className="">
            <form>
              <div className="mx-auto">
                <label className="block xl:px-8 px-4">
                  <Input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Full Name"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6"
                  />
                  {nameError && (
                    <p style={{ textAlign: "start", color: "red" }}>
                      Please enter valid name
                    </p>
                  )}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type="number"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                    placeholder="Phone Number"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                  />
                  {mobileError && (
                    <p style={{ textAlign: "start", color: "red" }}>
                      Please enter valid mobile number
                    </p>
                  )}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email ID"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                  />
                  {emailError && (
                    <p style={{ textAlign: "start", color: "red" }}>
                      Please enter valid email
                    </p>
                  )}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input
                    type="text"
                    value={departureCity}
                    onChange={(e) => setDepartureCity(e.target.value)}
                    placeholder="Visa Type"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                  />
                  {departureError && (
                    <p style={{ textAlign: "start", color: "red" }}>
                      Please enter valid departure city
                    </p>
                  )}
                </label>

                {/* <label className="block xl:px-8 px-4">
                <Input
                  type={inputDateType}
                  value={travelDate}
                  onChange={(e) => setTravelDate(e.target.value)}
                  placeholder="Travel Date"
                  onFocus={() => setInputDateType("date")}
                  className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                />
                {travelError && (
                  <p style={{ textAlign: "start", color: "red" }}>
                    Please enter valid travel date
                  </p>
                )}
              </label> */}
                {/* <label className="block xl:px-8 px-4">
                                <Input type="text" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                            </label> */}
                <label className="block xl:px-8 px-4">
                  <Input
                    type="text"
                    value={noofPassanger}
                    onChange={(e) => setNoofPassanger(e.target.value)}
                    placeholder="Country"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4"
                  />
                  {passangerError && (
                    <p style={{ textAlign: "start", color: "red" }}>
                      Please enter valid no of passenger
                    </p>
                  )}
                </label>
                <label className="block xl:px-8 px-4">
                  <textarea
                    //   value={description}
                    //   onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                    className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4"
                  />
                </label>
              </div>

              <div className="xl:px-8 px-4 mt-4">
                <button
                  type="button"
                  id="submitButton"
                  className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75"
                  onClick={handleSubmit}
                >
                  Submit Enquiry
                </button>
              </div>
              <p className="mt-4 text-xs text-center">
                Get the most recent travel offers, new tour <br></br>
                announcements, trip ideas and much more.
              </p>
              <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                  <div className="lg:w-6/12 text-left flex items-center">
                    <div className="lg:w-2/12">
                      <img
                        className="block dark:hidden w-8"
                        src={presents}
                        alt="logo1"
                      />
                    </div>
                    <div className="lg:w-9/12 pl-2">
                      <span className="text-sm font-medium ">Super Offers</span>
                      <p className="text-sky-700 italic font-normal text-xs ">
                        Explore Great Deals
                      </p>
                    </div>
                  </div>
                  <div className="lg:w-6/12 text-left flex items-center">
                    <div className="lg:w-2/12">
                      <img
                        className="block dark:hidden w-8"
                        src={luggage}
                        alt="logo1"
                      />
                    </div>
                    <div className="lg:w-10/12 pl-2">
                      <span className="text-sm font-medium ">My Trips</span>
                      <p className="text-sky-700 font-normal italic text-xs ">
                        Manage Your Bookings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage font">
      {/*  HEADER*/}{" "}
      <ul className="breadcrumb  lg:w-4/12">
        <li className="lg:text-base text-xs">
          <a href="/">Home</a>
        </li>
        <li className="lg:text-base text-xs">
          <a href="/visa">Visa</a>
        </li>
        <li className="lg:text-base text-xs">Singapore Visa</li>
      </ul>
      <header className="rounded-md sm:rounded-xl lg:pt-5">
        {/* =================Title section Desktop view ============= */}
        <div className="flex flex-col lg:flex-row items-center ">
          <div className="lg:w-8/12 xl:block lg:block md:hidden hidden">
            <h2 className="font text-2xl sm:text-3xl lg:text-2xl lg:pb-1 lg:font-semibold ">
              Singapore Visa Online
            </h2>
            <div className="font text-xs text-neutral-500 dark:text-neutral-400 font-normal">
              Processing Time :{" "}
              <span className="font font-medium text-base">Up to 48 hours</span>
            </div>
          </div>
          <div className="lg:w-2/12 lg:pt-3 justify-center text-left flex flex-row  items-center "></div>

          {/* <div className="xl:w-2/12 lg:w-3/12 lg:pt-3 justify-center text-right xl:block lg:block md:hidden hidden">
            <div className="font xl:text-xs lg:text-sm font-regular text-green-600">
              Trawelmart Offer Price
            </div>
            <div className="font uppercase xl:text-xs lg:text-xs font-regular text-black">
              Starts from
            </div>
            <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
              ₹{totalAmount}
            </div>
          </div> */}
        </div>

        {/* =================Title section Mobile view ============= */}
        <div className="flex flex-col lg:flex-row items-center lg:pb-4 ">
          <div className="lg:w-8/12 xl:hidden lg:hidden md:block block">
            <h2 className="font text-2xl sm:text-3xl lg:text-2xl lg:pb-1 lg:font-semibold ">
              Singapore Visa Online
            </h2>
            <div className="font text-xs text-neutral-500 dark:text-neutral-400 font-normal">
              Processing Time :{" "}
              <span className="font-medium text-base">Up to 48 hours</span>
            </div>
          </div>
          <div className="lg:w-2/12 lg:pt-3 justify-center text-left flex flex-row  items-center "></div>

          <div className="xl:w-2/12 lg:w-3/12 lg:pt-3 justify-center text-right xl:hidden lg:hidden md:block block">
            <div className="font xl:text-xs lg:text-sm font-regular text-green-600">
              Trawelmart Offer Price
            </div>
            <div className="fontuppercase xl:text-xs lg:text-xs font-regular text-black">
              Starts from
            </div>
            <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
              ₹{totalAmount}
            </div>
          </div>
        </div>

        {/* ======== image Tour includes desktop view ======== */}
        {/* <div className="w-full ">
          <div className="xl:w-12/12 ">
            <img
              src="https://i.ibb.co/W3bffJ4/Dubai-visa.jpg"
              alt=""
              className="w-12/12 "
            ></img>
          </div>
        </div> */}
      </header>
      {/* MAIN */}
      <main className=" relative z-10 mt-0 lg:mt-11 xl:mt-6 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-2 lg:space-y-2 lg:pr-10 lg:pb-12">
          <div className="w-full  rounded-lg ">
            <div className="xl:w-12/12">
              <img
                src="https://i.ibb.co/W3bffJ4/Dubai-visa.jpg"
                alt=""
                className="w-12/12 xl:h-56 rounded-lg"
              ></img>
            </div>
          </div>
          <section id="includ" className="hidden lg:block  xl:block pt-1">
            {accordionData4.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
          </section>
          <section id="includ" className="hidden lg:block  xl:block   xl:-mt-3">
            {accordionData5.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
          </section>

          {/* <div className="font font-medium xl:text-xl text-xl xl:pt-8 md:pt-12 pt-12 font xl:pb-0 pb-2">
                        Types of Singapore Visas for Indians
                    </div> */}
          {/* <div className="grid xl:grid-cols-2 xl:gap-4 md:grid-cols-2 md:gap-4 lg:grid-cols-1 lg:gap-2 gap-4 xl:pt-4">
                        <div className=" shadow-xl rounded-lg">
                            <div className="bg-[#fff0ff] p-4 rounded-t-lg">
                                <h3 className="font font-medium xl:text-base">
                                    48 Hours Transit Visa + Insurance (Covid)
                                </h3>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 grid-cols-2 gap-2 px-4 pt-3 xl:pt-6 md:pt-4">
                                <div className="font-normal font text-base">
                                    Processing time:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Upto 5 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3  xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Stay period:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    2 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 grid-cols-2 gap-2  md:gap-2 pt-3 px-4 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Validity:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    30 days
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 grid-cols-2 gap-2 md:gap-2 pt-3 px-4 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Entry:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Single
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 grid-cols-2 gap-2 pt-3 px-4 xl:pt-3 md:pt-3 pb-6">
                                <div className="font-normal font text-base">
                                    Fees:
                                </div>
                                <div className="font-semibold text-sky-600 font text-lg text-end">
                                    INR 1,999/-
                                </div>
                            </div>
                        </div>




                        <div className=" shadow-xl rounded-lg">
                            <div className="bg-[#fff0ff] p-4 rounded-t-lg">
                                <h3 className="font font-medium xl:text-base">
                                    30 Days Tourist Visa + Insurance (Covid)
                                </h3>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-6 md:pt-4">
                                <div className="font-normal font text-base">
                                    Processing time:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Upto 5 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Stay period:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    30 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Validity:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    58 days
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Entry:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Single
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3 pb-6">
                                <div className="font-normal font text-base">
                                    Fees:
                                </div>
                                <div className="font-semibold text-sky-600 font text-lg text-end">
                                    INR 7,799/-
                                </div>
                            </div>
                        </div>
                    </div> */}

          <div className="font-medium font  xl:text-xl text-xl xl:pt-8 md:pt-12 pt-12">
            Documents for Singapore Tourist/Business Visa :
          </div>
          <ul className="font pl-6">
            <li className="py-1">
              {" "}
              1. Original Passport with at least 6 months validity and minimum 2
              blank pages If travelled earlier to Singapore in old passports
              give passport copies of old passports along with year of travel
              details.
            </li>
            <li className="font flex py-1">
              2. Singapore Visa Application form.
              <div className="font text-sky-500 text-sm ml-2 mt-1">
                <a
                  href={pdfUrl}
                  download="Singapore Visa.pdf"
                  className="underline underline-sky-600"
                >
                  Download PDF
                </a>
              </div>
            </li>

            <li className="font py-1">
              3. 2 recent colour photographs (Photo Specification)
            </li>
            <li className="font py-1">
              4. Personal Covering letter - Employed to give on Plain Paper
            </li>
            <li className="font py-1">
              5. Personal Covering letter - Self-Employed – Company Letterhead
            </li>
            <li className="font py-1">
              6. For employed - Last 3 months salary slips & Employment ID Card.
            </li>
            <li className="font py-1">
              7. Self-employed – GST Copy /Business Registration License / MOA /
              Partnership deed/Rental Agreement.
            </li>
            <li className="font py-1">
              8. For retired –Retirement proof like Retirement letter, pension
              book, statement etc.
            </li>
            <li className="font py-1">
              9. Students - school / College / Institute ID Card.
            </li>
            <li className="font py-1">
              10. For minor - No Objection Certificate from the parents & ID
              proof of parent like passport or PAN card.
            </li>
            <li className="font py-1">
              11. Visiting friend or relative - Invitation letter or Letter of
              Introduction (LOI){" "}
            </li>
            <li className="font py-1">For sponsored - Sponsorship letter </li>
            <li className="font py-1">
              12. Confirmed onward and return Air Ticket
            </li>
            <li className="font py-1">13. Hotel Confirmation.</li>
          </ul>

          <div className="font font-medium xl:text-xl text-xl xl:pt-8 pt-8">
            Frequently Asked Questions
          </div>

          <div className="app block lg:hidden xl:block pt-2">
            {accordionData.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
            {accordionData2.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
            {accordionData3.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
            {accordionData7.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>

          {/* <div className="rounded py-6 px-4 gradient2 xl:mt-32 lg:flex items-center justify-between hidden lg:block ">
            <div className="font text-white font1 font-bold text-2xl">
              Detailed Singapore Singapore rocess & Requirements
            </div>
            <img src={passport} alt="" className="w-14" />
          </div> */}
        </div>

        {/* SIDEBAR */}
        <div className="block lg:block flex-grow mt-14 lg:mt-0 lg:mb-0 mb-12">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function SingaporeVisa() {
  return (
    <DetailPagetLayout>
      <StayDetailsPageContainer />
    </DetailPagetLayout>
  );
}
