import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogSingapore = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Singapore" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        A Futuristic Oasis - Unveiling the Smart City Initiatives
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        In the heart of Southeast Asia lies a city-state that has redefined urban living, blending
                        tradition with innovation seamlessly. Welcome to Singapore, a place where technology and
                        urban planning have converged to create a smart city that beckons travelers from around the
                        world. In this blog, we&#39;ll embark on a journey to discover Singapore&#39;s remarkable smart city
                        initiatives, demonstrating how they not only enhance daily life for residents but also make it
                        an enticing destination for tech-savvy tourists.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    The Smart Nation Vision
                </p>
                <p className="">
                    Singapore&#39;s transformation into a smart city is driven by its ambitious Smart Nation vision.
                    This vision encompasses a comprehensive strategy to harness technology and data to improve
                    the quality of life, drive economic growth, and create a sustainable urban environment.
                </p>

                <p className="font-semibold text-xl">
                    Seamless Connectivity
                </p>
                <p className="">
                    One of the cornerstones of Singapore&#39;s smart city initiatives is its commitment to providing
                    seamless connectivity. Tourists arriving in Singapore will immediately notice the city&#39;s well-
                    connected public transportation system, which includes the world-famous Mass Rapid Transit
                    (MRT) network and a fleet of smart buses. These systems offer convenient and efficient ways
                    to explore the city, making it a breeze to move from one attraction to another.
                </p>

                <p className="font-semibold text-xl">
                    Digital Payments Galore
                </p>
                <p className="">
                    Cash is almost a relic in Singapore, thanks to the widespread adoption of digital payment
                    systems. Tourists can easily pay for everything, from public transportation to street food,
                    using digital wallets and contactless payment methods. This not only simplifies transactions
                    but also adds to the convenience and speed of daily life.
                </p>



                <p className="font-semibold text-xl">
                    Smart Traffic Management
                </p>
                <p className="">
                    Getting around Singapore is not just easy; it&#39;s also efficient. The city&#39;s smart traffic
                    management systems use real-time data to optimize traffic flow, reducing congestion and
                    making commuting stress-free. Tourists can explore the city without the worry of traffic jams
                    or delays.
                </p>

                <p className="font-semibold text-xl">
                    Clean and Green Living
                </p>
                <p className="">
                    Despite its urban density, Singapore is a remarkably green city. Its commitment to
                    sustainability is evident in its lush green spaces, rooftop gardens, and vertical greenery.
                    Tourists can visit the iconic Gardens by the Bay, where the Supertrees not only provide a
                    stunning visual spectacle but also serve practical functions, such as collecting rainwater and
                    harnessing solar energy.
                </p>

                <p className="font-semibold text-xl">
                    Smart Housing Solutions
                </p>
                <p className="">
                    Even Singapore&#39;s housing solutions are innovative. The city&#39;s Housing and Development
                    Board (HDB) has incorporated smart technologies into public housing, creating smart homes
                    that enhance residents&#39; quality of life. While you might not stay in an HDB flat as a tourist,
                    it&#39;s fascinating to know how technology is integrated into daily living.
                </p>


                <p className="font-semibold text-xl">
                    A Garden in the Sky: The Jewel Changi Airport
                </p>
                <p className="">
                    As you arrive in Singapore, you&#39;ll likely pass through one of the world&#39;s most impressive
                    airports – Changi Airport. The Jewel, a multi-story, glass-domed entertainment complex, is a
                    testament to Singapore&#39;s commitment to innovation. This architectural marvel houses a
                    stunning indoor waterfall, a vast array of shops and restaurants, and even a canopy park with
                    walking trails. It&#39;s not just an airport; it&#39;s an attraction in itself.
                </p>

                <p className="font-semibold text-xl">
                    Augmented Reality Tours
                </p>
                <p className="">
                    Exploring Singapore is made even more engaging with the use of augmented reality (AR)
                    technology. Several attractions, including museums and cultural sites, offer AR-guided tours.
                    These immersive experiences allow tourists to dive deeper into Singapore&#39;s history and
                    culture while using their smartphones or AR glasses.
                </p>

                <p className="font-semibold text-xl">
                    Environmental Sustainability
                </p>
                <p className="">
                    Singapore&#39;s smart city initiatives extend to environmental sustainability. The city has
                    implemented smart waste management systems that optimize collection routes, reducing fuel
                    consumption and emissions. Additionally, eco-friendly initiatives, such as rooftop gardens
                    and solar panels, contribute to a cleaner and greener urban environment.
                </p>


                <p className="font-semibold text-xl">
                    Enhancing Tourism Experiences
                </p>
                <p className="">
                    Smart city initiatives in Singapore go beyond infrastructure and transportation; they are
                    integrated into the tourism experience. Visitors can use mobile apps to access real-time
                    information on attractions, navigate the city, and even order food from hawker centers. These
                    digital tools make exploring Singapore more accessible and enjoyable.
                </p>


                <p className="font-semibold text-lg">
                    Conclusion: A Smart City with a Heart
                </p>
                <p>
                    Singapore&#39;s smart city initiatives are a testament to its commitment to innovation,
                    sustainability, and enhancing the quality of life for its residents and visitors alike. As a tourist,
                    you&#39;ll find that these initiatives make your journey smoother, more enjoyable, and more eco-
                    friendly.
                </p>

                <p>
                    So, whether you&#39;re strolling through lush gardens, enjoying a cashless transaction, or
                    exploring the city with augmented reality, Singapore&#39;s smart city experience is sure to leave
                    you awestruck and inspired by the possibilities of urban living in the digital age. Come and
                    experience Singapore, where the future meets tradition, and smart city initiatives redefine the
                    travel experience.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/Lg5HR6r/Singapore.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogSingapore;
