import React, { useState } from 'react';

const AccordionFooter = (props: { title: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; content: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="accordion border rounded  my-2 ">
            <div className="accordion-header flex justify-between items-center p-2 " onClick={toggleAccordion}>
                <h2 className='text-white font font-regular text-base '>{props.title}</h2>
                <span className='text-white font font-regular text-base'>{isOpen ? '-' : '+'}</span>
            </div>
            {isOpen && (
                <div className="accordion-content pt-2 p-2 overflow-x-scroll border-t border-neutral-50 text-white" >
                    {props.content}
                </div>
            )}
        </div>
    );
};

export default AccordionFooter;
