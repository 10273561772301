import rightImg from "images/about-hero-right.png";
import React, { FC, useState } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import $ from 'jquery';
import quotation from "images/quotation.png";
import icon1 from "images/sample/icon1.png";
import icon2 from "images/sample/icon2.png";
import icon5 from "images/sample/icon5.png";
import icon4 from "images/sample/icon4.png";
import PagePopup from "containers/PageHome/PagePopup";
import bgg from "images/about/bgg.png";
import TestimonialSlider from "./TestimonialSlider";

export interface PageHistoryProps {
    className?: string;
}

const PageHistory: FC<PageHistoryProps> = ({ className = "" }) => {

    const totalSteps = 3;
    const [currentStep, setCurrentStep] = useState(1);

    const handleNext = () => {
        if (currentStep < totalSteps) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };
    const handleNextTab = () => {
        const nextTab = activeTab < 6 ? activeTab + 1 : 1;
        handleTabClick(nextTab);
    };

    const handlePreviousTab = () => {
        const previousTab = activeTab > 1 ? activeTab - 1 : 6;
        handleTabClick(previousTab);
    };



    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
        setActiveTab(tabNumber);
    };

    return (
        <div
            className={`font nc-PageAbout overflow-hidden relative font ${className}`}
            data-nc-id="PageAbout"
        >
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {/*<BgGlassmorphism />*/}

            {/* ======== BG GLASS ======== */}









            <div className="container py-16 lg:py-0 space-y-6 lg:space-y-1 pb-16 xl:mb-24">
                <div className="font-bold xl:text-5xl text-center">
                    Our Journey
                </div>
                <div className="tab-content text-center xl:px-52 ">
                    {activeTab === 1 &&

                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/tzrtjx5/1.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2003
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>
                    }
                    {activeTab === 2 &&
                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/QDLpkXJ/2.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2005
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>
                    }
                    {activeTab === 3 &&
                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/PrYhy6f/3.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2008
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>

                    }

                    {activeTab === 4 &&
                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/F8YdKfD/4.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2011
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>

                    }

                    {activeTab === 5 &&
                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/PrYhy6f/3.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2018
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>

                    }

                    {activeTab === 6 &&
                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/tzrtjx5/1.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2019
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>

                    }

                    {activeTab === 7 &&
                        <div className="content-center xl:pt-6 xl:text-base center">
                            <img src="https://i.ibb.co/PrYhy6f/3.jpg" className="center justify-center h-96"></img>
                            <div className="flex xl:px-16 items-center xl:pb-6 xl:pt-6">
                                <div className="font-bold font1 text-6xl w-4/12">
                                    2024
                                </div>
                                <div className="text-left w-8/12 pr-12 text-justify font1">
                                    To establish and maintain TRAWEL MART as the most highlighted travel agency.
                                    A reliable and enthusiastic tour operator with superior customer service to provide extra value
                                    added service to the customers.
                                </div>
                            </div>
                        </div>

                    }
                </div>
                <div className="tab-buttons text-center center">
                    <div className="grid xl:grid-cols-7 xl:gap-0 gap-2 xl:px-32">

                        <button
                            className={activeTab === 1 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(1)}
                        >
                            2003
                        </button>

                        <button
                            className={activeTab === 2 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(2)}
                        >
                            2005
                        </button>
                        <button
                            className={activeTab === 3 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(3)}
                        >
                            2008
                        </button>
                        <button
                            className={activeTab === 4 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(4)}
                        >
                            2011
                        </button>
                        <button
                            className={activeTab === 5 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(5)}
                        >
                            2018
                        </button>
                        <button
                            className={activeTab === 6 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(6)}
                        >
                            2024
                        </button>
                        <button
                            className={activeTab === 7 ? 'active xl:text-xl' : 'border-sky-600 border mx-2 rounded-lg xl:text-xl xl:py-0 py-2'}
                            onClick={() => handleTabClick(7)}
                        >
                            2024
                        </button>

                    </div>
                </div>

                <div className="arrow-buttons center justify-center pt-6 ">
                    <button className="arrow-button text-sky-600" onClick={handlePreviousTab}>
                        <i className="las la-long-arrow-alt-left text-4xl "></i>
                    </button>
                    <button className="arrow-button text-sky-600 pl-6" onClick={handleNextTab}>
                        <i className="las la-long-arrow-alt-right text-4xl"></i>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PageHistory;
