import React, {
  FC,
  useState,
  Fragment,
  useEffect,
  MutableRefObject,
  useRef,
} from "react";
import Slider from "react-slick";
import { DEMO_STAY_LISTINGS_BALI } from "data/listings";
import StayCardBali from "components/StayCard/StayCardBali";
import { StayDataType, TaxonomyType } from "data/types";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import SectionSliderNewCategories4 from "components/SectionSliderNewCategories/SectionSliderNewCategories4";
import { Dialog } from "@headlessui/react";
import Input from "shared/Input/Input";
import india from "images/india.png";
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import FrequentlyBali from "landing/BaliHome/FrequentlyBali";
import axios from "axios";
import DatePicker from "react-datepicker";
import PagePopup from "containers/PageHome/PagePopup";
//import ReCAPTCHA from "react-google-recaptcha";
import "react-datepicker/dist/react-datepicker.css";
import { debounce } from "lodash";

function PageEnquiryy() {
  let [isOpen, setIsOpen] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [departureError, setDepartureError] = useState(false);
  const [travelError, setTravelError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [passangerError, setPassangerError] = useState(false);
  const location = useLocation();
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [mobileNumber, setMobileNumber] = useState("");
  let [departureCity, setDepartureCity] = useState("");
  let [travelDate, setTravelDate] = useState("");
  let [destination, setDestination] = useState("");
  let [noofPassanger, setNoofPassanger] = useState("");
  const [inputDateType, setInputDateType] = useState("text");
  let [description, setDescription] = useState("");
  //const recaptcha: MutableRefObject<ReCAPTCHA | null> = useRef(null);

  useEffect(() => {
    const userDataJSON = localStorage.getItem("loggedInUser");
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setName(userData.name);
      setMobileNumber(userData.mobile);
      setEmail(userData.email);
    }
  }, []);

  const handleDateChange = (date: any) => {
    setTravelDate(date.toISOString().slice(0, 10));
  };

  const handleNameChange = debounce((value) => {
    setName(value);
  }, 300);

  const handleEmailChange = debounce((value) => {
    setEmail(value);
  }, 300);

  const handleMobileChange = debounce((value) => {
    setMobileNumber(value);
  }, 300);

  const handleDepartureChange = debounce((value) => {
    setDepartureCity(value);
  }, 300);

//   const handleDepartureChange = debounce((value) => {
//     // Check if the value contains any numbers
//     if (!/\d/.test(value)) {
//       setDepartureCity(value);
//       setDepartureError(false);
//     } else {
//       setDepartureError(true);
//     }
//   }, 300);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (name.length == 0) {
      setNameError(true);
    } else if (/\d/.test(name)) {
      setNameError(true);
    } else if (mobileNumber.length != 10) {
      setNameError(false);
      setMobileError(true);
    } else if (email.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(true);
    } else if (departureCity.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(true);
    } else if (travelDate.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(false);
      setTravelError(true);
    } else if (noofPassanger.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(false);
      setTravelError(false);
      setDestinationError(false);
      setPassangerError(true);
    } else {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      setDepartureError(false);
      setTravelError(false);
      setDestinationError(false);
      setPassangerError(false);
      const isScriptExist = document.getElementById("recaptcha-key");
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src =
          "https://www.google.com/recaptcha/api.js?render=6LcBeLYoAAAAALBMn-BMX0hGCbbC55l7kJn34n4m";
        script.id = "recaptcha-key";
        document.body.appendChild(script);
        script.onload = () => {
          // Initialize reCAPTCHA after the script is loaded
          (window as any).grecaptcha
            .execute("6LcBeLYoAAAAALBMn-BMX0hGCbbC55l7kJn34n4m", {
              action: "submit",
            })
            .then((recaptchaToken: any) => {
              console.log(recaptchaToken);
            });
        };
      }
      const submitButton = document.getElementById("submitButton");
      if (submitButton) {
        (submitButton as HTMLButtonElement).disabled = true;
        submitButton.innerHTML = "Submitting...";
      }
      const formData = {
        name: name,
        mobile: mobileNumber,
        email_address: email,
        description: description,
        url: location.pathname,
        city: departureCity,
        travel_date: travelDate,
        passenger_count: noofPassanger,
      };
      axios
        .post(
          "https://admin.trawelmart.com/admin/public/index.php/api/addContact",
          formData
        )
        .then((response: any) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.status) {
              document.getElementById("recaptcha-key")?.remove();
              setName("");
              setMobileNumber("");
              setEmail("");
              setDescription("");
              setDepartureCity("");
              setTravelDate("");
              setNoofPassanger("");
              setIsOpen(false);
              (window as any).location = "/thanks";
            } else {
              toastr.error(response.data.message);
            }
          } else {
            console.log(response.responseText);
          }
        });
    }
  };

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  function changeTextToDate() {
    setInputDateType("date");
  }

  return (
    <div className=" nc-PageHome relative overflow-hidden">
      <div className="z-50 floating-inquiry-button text-white rounded-xl px-6 py-2 font">
        <div className="flex text-white items-center">
          {/* <i className="las la-edit text-xl"></i> */}
          {/* <button
                        type="button"
                        onClick={openModal}
                        className="">
                        Quick Enquiry
                    </button> */}
          <img
            src="https://i.ibb.co/NtH1DG8/quickenquiry-f331b702e3ba2abf1406.png"
            onClick={openModal}
            className="w-20 h-20"
            alt=""
          />

          <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-25" />
              </Transition.Child>

              <div className="font fixed inset-0 overflow-y-auto">
                <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full xl:mt-12 mt-12 lg:mt-8 sm:mt-12 max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                      <div className="text-right mr-2">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-full px-2 py-1 text-sm font-medium text-blue-900  focus:outline-none focus-visible:ring-0  focus-visible:ring-offset-2"
                          onClick={closeModal}
                        >
                          X
                        </button>
                      </div>
                      <Dialog.Title
                        as="h3"
                        className="font p-4 bg-[#fbf7fb] lg:-mt-8 md:-mt-8 -mt-8 lg:pt-4 md:pt-4 pt-3 xl:text-2xl md:text-2xl text-xl font-semibold leading-6 text-black"
                      >
                        Quick Enquiry
                        <p className="xl:pt-2 md:pt-2 pt-1 font-normal text-xs text-sky-500">
                          Plan an unforgettable trip with Trawel Mart
                        </p>
                      </Dialog.Title>

                      <div className="mt-2">
                        <form onSubmit={handleSubmit}>
                          <div className="mx-auto">
                            <label className="block px-4">
                              <Input
                                type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder="Full Name"
                                className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-10 md:h-10 h-9 rounded-lg xl:mt-6 md:mt-6 mt-2"
                              />
                              {nameError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid name
                                </p>
                              )}
                            </label>
                            <label className="block px-4">
                              <Input
                                type="number"
                                value={mobileNumber}
                                onChange={(e) =>
                                  setMobileNumber(e.target.value)
                                }
                                placeholder="Phone Number"
                                className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-10 md:h-10 h-10 rounded-lg xl:mt-4 md:mt-4 mt-2"
                              />
                              {mobileError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid mobile number
                                </p>
                              )}
                            </label>
                            <label className="block px-4">
                              <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="Email ID"
                                className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-10 md:h-10 h-10 rounded-lg xl:mt-4 md:mt-4 mt-2"
                              />
                              {emailError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid email
                                </p>
                              )}
                            </label>

                            
                            <label className="block px-4">
                                                            <Input type="text" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-10 md:h-10 h-10 rounded-lg xl:mt-4 md:mt-4 mt-2" />
                                                            {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                                                        </label>

                            {/* <label className="block px-4">
                              <Input
                                type="text"
                                value={departureCity}
                                onChange={(e) =>
                                  handleDepartureChange(e.target.value)
                                }
                                placeholder="Departure City"
                                className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-10 md:h-10 h-10 rounded-lg xl:mt-4 md:mt-4 mt-2"
                              />
                              {departureError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid departure city
                                </p>
                              )}
                            </label> */}
                            <label className="block px-4">
                              <DatePicker
                                selected={
                                  travelDate ? new Date(travelDate) : null
                                }
                                onChange={handleDateChange}
                                id="travelDateInput"
                                placeholderText="Travel Date"
                                minDate={new Date()}
                                dateFormat={"d-M-Y"}
                                popperPlacement="top"
                                className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4"
                              />
                              {travelError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid travel date
                                </p>
                              )}
                            </label>
                            {/* <label className="block px-4">
                                                            <Input type="" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-lg mt-4" />
                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                                                        </label> */}
                            <label className="block px-4">
                              <Input
                                type="number"
                                value={noofPassanger}
                                onChange={(e) =>
                                  setNoofPassanger(e.target.value)
                                }
                                placeholder="No of Passenger"
                                className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-10 md:h-10 h-10 rounded-lg xl:mt-4 md:mt-4 mt-2"
                              />
                              {passangerError && (
                                <p style={{ textAlign: "start", color: "red" }}>
                                  Please enter valid number of passenger
                                </p>
                              )}
                            </label>
                            <label className="block px-4">
                              <textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder="Drop us a small description"
                                className="bg-white text-black border border-slate-300 px-3 py-1 xl:h-16 md:h-20 h-12 rounded-lg xl:mt-4 md:mt-4 mt-2"
                              />
                            </label>
                            {/* <label className="block px-4 py-2">
                                                            <ReCAPTCHA sitekey="6LeaGa4oAAAAAGDTMqt4KdWrFDqetZK6Fo-TxHeL" ref={recaptcha}/>
                                                        </label> */}
                          </div>
                          {/*<div className=">
                                                                        <button type="submit"
                                                                            className="w-full rounded-md border border-sky-600 bg-white mt-4 px-4 py-2 text-sm font-medium text-sky-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                                                        >
                                                                            Continue
                                                                        </button>
                                                                        </div>
                                                                    <p className="text-xs text-neutral-500 pt-2">
                                                                        OR
                                                                    </p>*/}
                          <div className="lg:px-4 md:px-4 px-4 xl:mt-4 md:mt-4 mt-2 xl:mb-8 md:mb-8 mb-4">
                            <button
                              type="submit"
                              id="submitButton"
                              className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75"
                            >
                              Submit Enquiry
                            </button>
                          </div>
                          {/* <p className="mt-4 text-xs text-center">
                                                        Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                    </p>
                                                    <div className="bg-[#fbf7fb] mt-6">
                                                        <div className="w-full flex items-center justify-between p-2 xl:pl-12 lg:pl-12 md:pl-12">
                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                <div className="lg:w-2/12">
                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                </div>
                                                                <div className="lg:w-9/12 pl-2">
                                                                    <span className="text-sm font-medium ">
                                                                        Super Offers
                                                                    </span>
                                                                    <p className="text-sky-700 italic font-normal text-xs ">
                                                                        Explore Great Deals
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                <div className="lg:w-2/12">
                                                                    <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                </div>
                                                                <div className="lg:w-10/12 pl-2">
                                                                    <span className="text-sm font-medium ">
                                                                        My Trips
                                                                    </span>
                                                                    <p className="text-sky-700 font-normal italic text-xs ">
                                                                        Manage Your Bookings
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                        </form>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </div>
    </div>
  );
}

export default PageEnquiryy;
