import SectionHero from "components/SectionHero/SectionHero";

// import SectionSliderNewCategoriesss from "components/SectionSliderNewCategories/SectionSliderNewCategoriesss";
import React, { useEffect } from "react";
import { TaxonomyType } from "data/types";
import SectionVideos from "containers/PageHome/SectionVideos";
import flightbannersmall from "images/airlines/flightbannersmall.png";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import mobi from "images/airlines/mobi.png"
import pay from "images/airlines/pay.png"
import search from "images/airlines/search.png"
import book from "images/airlines/book.png"
import Slider from "react-slick"
import banner from 'images/airlines/banner.jpg';
import img1 from 'images/airlines/img1.png';
import img2 from 'images/airlines/img2.png';
import img3 from 'images/airlines/img3.png';
import img4 from 'images/airlines/img4.png';
import img5 from 'images/airlines/img5.png';
import laptop from 'images/airlines/laptop.jpg'
import flight from 'images/airlines/flight.jpg'
import nature from 'images/airlines/nature.jpg'
import flightimage from 'images/airlines/flightimage.jpg'
import flightimage2 from 'images/airlines/flightimage2.jpg'
import insideflight from 'images/airlines/insideflight.jpg'
import { Link, useLocation } from "react-router-dom";
import SectionGridCategoryHotel from "components/SectionGridCategoryBox/SectionGridCategoryHotel";
import bannerflight from "images/airlines/bannerflight.jpg";
import mblbanner from "images/sample/mblbanner.jpg";



const DEMO_CATS: TaxonomyType[] = [
    {
        id: "1",
        href: "/dubai-tour-packages",
        name: "Indigo",
        taxonomy: "category",
        count: 188288,
        text: "₹5,000",
        thumbnail:
            "https://i.ibb.co/Ydd7p7z/indigo.jpg",
    },
    {
        id: "2",
        href: "/bali-tour-packages",
        name: "Emirates",
        taxonomy: "category",
        count: 188288,
        text: "₹1,500",
        thumbnail:
            "https://i.ibb.co/sjK54Gw/emirates.jpg"
    },
    {
        id: "2",
        href: "/cambodia-tour-packages",
        name: "Air India",
        taxonomy: "category",
        count: 188288,
        text: "₹6,000",
        thumbnail:
            "https://i.ibb.co/0FS7yhK/airindia.jpg",
    },
    {
        id: "2",
        href: "/vietnam-tour-packages",
        name: "Singapore Airlines",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/qy7NQHB/singapore.jpg",
    },
];


const DEMO_CATSS: TaxonomyType[] = [
    {
        id: "1",
        href: "/dubai-tour-packages",
        name: "Indigo",
        taxonomy: "category",
        count: 188288,
        text: "₹5,000",
        thumbnail:
            "https://i.ibb.co/Ydd7p7z/indigo.jpg",
    },
    {
        id: "2",
        href: "/bali-tour-packages",
        name: "Egyptair",
        taxonomy: "category",
        count: 188288,
        text: "₹1,500",
        thumbnail:
            "https://i.ibb.co/VHR51q6/egyptr.jpg"
    },
    {
        id: "2",
        href: "/cambodia-tour-packages",
        name: "Air India",
        taxonomy: "category",
        count: 188288,
        text: "₹6,000",
        thumbnail:
            "https://i.ibb.co/0FS7yhK/airindia.jpg",
    },
    {
        id: "2",
        href: "/vietnam-tour-packages",
        name: "Fly Dubai",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/L8Zpbwj/flydubai.jpg",
    },
];

function FlightHome() {

    //testimonials slider //
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: false,
        speed: 500,
        centerPadding: '10px',
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        className: "center",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            }
        ]
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-3 rounded-xl overflow-hidden group"
            >
                {/* <Link to={post.href} /> */}
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-1 p-4">
                    <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100  relative">
                        {post.date}
                    </h5>
                    <h2 className="font block xl:text-lg lg:text-base md:text-lg text-base lg:font-bold font-medium text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className=" nc-PageHome relative overflow-hidden">
            {/* ====== Banner Desktop view =======*/}
            <div className="font xl:block lg:hidden hidden" style={{
                backgroundImage: `url(${bannerflight})`,
                width: "100%",
                height: "520px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
                    <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
                </div>
            </div>


            {/* ====== Banner lg screen view =======*/}
            <div className="font xl:hidden lg:block hidden" style={{
                backgroundImage: `url(${bannerflight})`,
                width: "100%",
                height: "400px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
                    <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
                </div>
            </div>


            {/* ====== Banner md screen view =======*/}
            <div className="font xl:hidden lg:hidden md:block sm:hidden hidden" style={{
                backgroundImage: `url(${bannerflight})`,
                width: "100%",
                height: "250px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
                    <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
                </div>
            </div>


            {/* ====== Banner mbl view =======*/}
            <div className="font xl:hidden lg:hidden md:hidden sm:block block" style={{
                backgroundImage: `url(${bannerflight})`,
                width: "100%",
                height: "380px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
                    <SectionHero className="pt-10 lg:pt-8 lg:pb-6 text-center" />
                </div>
            </div>


            {/*====== 2nd section - icon Desktop View =======*/}
            <div className="bg-neutral-50 lg:-mt-6 lg:block hidden">
                <div className="container relative space-y-24 mb-12 lg:space-y-28 lg:pb-6 lg:pt-8">
                    <div className="">
                        <div className="grid lg:grid-cols-4 lg:gap-4 grid-cols-1 justify-center">
                            <div className="justify-center items-center text-center">
                                <div className="font">
                                    {/* <a className="flex items-center justify-center w-9 h-9 text-xl" href="" target="_blank" rel="noopener noreferrer"> */}
                                    <img src={search} alt="" className="w-12 h-12 ml-32 mb-3" />
                                    {/* </a> */}
                                    <span className="text-lg font-semibold text-neutral-600">
                                        Look no further
                                    </span><br />
                                    <span className="font font-regular lg:text-base text-xs text-neutral-600">
                                        Search all travel deals, in one go
                                    </span>
                                </div>
                            </div>

                            <div className="justify-center items-center text-center">
                                <div className="font">
                                    {/* <a className="flex items-center justify-center w-9 h-9 rounded-full text-xl" href="" target="_blank" rel="noopener noreferrer"> */}
                                    <img src={mobi} alt="" className="w-12 h-12 ml-32 mb-3" />
                                    {/* </a> */}
                                    <span className="text-lg font-semibold text-neutral-600">
                                        Shop with confidence
                                    </span><br />
                                    <span className="font font-regular lg:text-base text-xs text-neutral-600">
                                        No hidden fees, taxes or other nasty surprises
                                    </span>
                                </div>
                            </div>

                            <div className="justify-center items-center text-center">
                                <div className="font">
                                    {/* <a className="flex items-center justify-center w-9 h-9 rounded-full text-xl" href="" target="_blank" rel="noopener noreferrer"> */}
                                    <img src={pay} alt="" className="w-12 h-12 ml-32 mb-3" />
                                    {/* </a> */}
                                    <span className="text-lg font-semibold text-neutral-600">
                                        Pay the way you want
                                    </span><br />
                                    <span className="font font-regular lg:text-base text-xs text-neutral-600">
                                        See only sellers who support your preferred methods
                                    </span>
                                </div>
                            </div>

                            <div className="justify-center items-center text-center font  ">
                                <div className="items-center">
                                    {/* <a className="flex items-center justify-center w-9 h-9 rounded-full text-xl" href="" target="_blank" rel="noopener noreferrer"> */}
                                    <img src={book} alt="" className="w-12 h-12 ml-32 mb-3" />
                                    {/* </a> */}
                                    <span className="text-lg font-semibold text-neutral-600">
                                        Instant booking
                                    </span><br />
                                    <span className="font font-regular lg:text-base text-xs text-neutral-600">
                                        For selected sellers, book with just a couple of clicks
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Popular Airlines Section 2 Exp2 */}

            <div className="container relative  mb-6 xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6 ">
                <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 pt-6">
                    Popular Airlines
                </div>
                <div className="font font-regular lg:text-base text-xs text-neutral-600 lg:pb-6 pb-4">
                    Book flights on your favourite airlines
                </div>
                <Slider {...settings} className="items-center">
                    <div className="xl:px-3 px-1">
                        <a href="">  <img src={img1} alt="" className="rounded-lg lg:h-60 lg:w-72 md:h-36 md:w-72 h-56 w-96" />  </a>
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="">  <img src={img2} alt="" className="rounded-lg lg:h-60 lg:w-72 md:h-36 md:w-72 h-56 w-96" />  </a>
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="">  <img src={img3} alt="" className="rounded-lg lg:h-60 lg:w-72 md:h-36 md:w-72 h-56 w-96" />  </a>
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href=""> <img src={img4} alt="" className="rounded-lg lg:h-60 lg:w-72 md:h-36 md:w-72 h-56 w-96" />  </a>
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="">  <img src={img5} alt="" className="rounded-lg lg:h-60 lg:w-72 md:h-36 md:w-72 h-56 w-96" />  </a>
                    </div>
                  
                </Slider>
            </div>


            {/* book a package desktop*/}

            <div className="xl:block lg:hidden hidden md:hidden sm:hidden">
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-14 xl:px-52">
                    <article className="rounded-xl h-38" style={{ backgroundImage: `url(${flightbannersmall})` }} >
                        <h1 className="text-white pt-8 pl-8 font">Witness the excitement and speed of the world’s most prestigious <br />motorsport with an Ultimate Fan Package.</h1>
                        <button className="font gradient2 text-white lg:text-base px-12 py-3 mt-4 rounded-lg text-sm ml-8 mb-4">Book a Package</button>
                    </article>
                </div>
            </div>

            {/* book a package mobile*/}

            <div className="lg:hidden md:hidden sm:hidden xl:hidden block sm:block">
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-10 lg:px-24">
                    <article className="rounded-xl h-28" style={{ backgroundImage: `url(${flightbannersmall})` }} >
                        <h1 className="text-white text-xs pt-4 pl-4">Witness the excitement and speed<br /> of the world’s most prestigious<br /> motorsport with an Ultimate F1® Fan Package.</h1>
                        <button className="font gradient2 text-white lg:text-base px-3 py-2 mt-2 rounded-lg text-xs ml-4  mb-4">Book a Package</button>
                    </article>
                </div>
            </div>


            {/* book a package MD*/}

            <div className="font xl:hidden lg:hidden hidden md:block sm:hidden">
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-10 lg:px-24">
                    <article className="rounded-xl h-38 " style={{ backgroundImage: `url(${flightbannersmall})` }} >
                        <h1 className="text-white pt-8 pl-4 text-sm">Witness the excitement and speed of the world’s most prestigious <br />motorsport with an Ultimate F1® Fan Package.</h1>
                        <button className="font gradient2 text-white lg:text-base px-8 py-3 mt-4 rounded-lg text-sm ml-4  mb-4">Book a Package</button>
                    </article>
                </div>
            </div>

            {/* book a package MD*/}

            <div className="font xl:hidden lg:block md:hidden sm:hidden hidden">
                <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-10 lg:px-24">
                    <article className="rounded-xl h-38 " style={{ backgroundImage: `url(${flightbannersmall})` }} >
                        <h1 className="text-white pt-8 pl-4 text-sm">Witness the excitement and speed of the world’s most prestigious <br />motorsport with an Ultimate F1® Fan Package.</h1>
                        <button className="font gradient2 text-white lg:text-base px-8 py-3 mt-4 rounded-lg text-sm ml-4  mb-4">Book a Package</button>
                    </article>
                </div>
            </div>


            {/*  Start Planning Your Next Trip desktop*/}
            <div className="xl:block lg:hidden hidden md:hidden sm:hidden">
                <div className="container relative  mb-6  xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6 ">
                    <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 pt-6">
                        Start Planning Your Next Trip
                    </div>
                    <div className="font font-regular lg:text-base text-xs text-neutral-600 lg:pb-6 pb-4">
                        Thinking of travelling somewhere soon? Here are some options to help you get started
                    </div>
                    <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">
                        <div className="flex items-center rounded-xl shadow-md xl:my-0 ">
                            <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform" style={{
                                backgroundImage: `url(${flightimage})`,
                                width: "100%",
                                height: "400px",
                                backgroundSize: "cover",
                            }} >
                                <a href=""> <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-24 ml-6">Find Flights</h5> </a>
                                <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Explore Our Destinations</p>  </a>
                            </article>
                        </div>
                        <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">

                            <div className="flex items-center rounded-xl shadow-md  xl:my-0 ">
                                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${nature})`,
                                    width: "100%",
                                    height: "400px",
                                    backgroundSize: "cover",
                                }} >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base lg:mb-10">Your Perfect Package Awaits</p> </a> */}
                                    <a href=""><h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-24 ml-6 ">Book Package</h5> </a>
                                    <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Your Perfect Package</p>  </a>
                                </article>
                            </div>
                            <div className="flex-wrap">
                                <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${flightimage2})`,
                                    width: "100%",
                                    height: "192px",
                                    backgroundSize: "cover",
                                }}  >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                    <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-28 lg:ml-6 relative mt-24 ml-6">Find Out More</h5> </a>
                                    <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Travel Requirements</p>  </a>
                                </article>
                                <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform lg:mt-4" style={{
                                    backgroundImage: `url(${insideflight})`,
                                    width: "100%",
                                    height: "192px",
                                    backgroundSize: "cover",
                                }}  >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                    <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-28 lg:ml-6 relative mt-24 ml-6">Purchase add-ons</h5> </a>
                                    <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Your Experience</p>  </a>

                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*  Start Planning Your Next Trip tab landscape*/}
            <div className="font xl:hidden lg:block md:hidden sm:hidden hidden">
                <div className="container relative  mb-6  xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6 ">
                    <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 pt-6">
                        Start Planning Your Next Trip
                    </div>
                    <div className="font font-regular lg:text-base text-xs text-neutral-600 lg:pb-6 pb-4">
                        Thinking of travelling somewhere soon? Here are some options to help you get started
                    </div>
                    <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">
                        <div className="flex items-center rounded-xl shadow-md xl:my-0 ">
                            <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform" style={{
                                backgroundImage: `url(${flightimage})`,
                                width: "100%",
                                height: "300px",
                                backgroundSize: "cover",
                            }} >
                                <a href=""> <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-56 lg:ml-6 relative mt-24 ml-6">Find Flights</h5> </a>
                                <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Explore Our Destinations</p>  </a>
                            </article>
                        </div>
                        <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">

                            <div className="flex items-center rounded-xl shadow-md  xl:my-0 ">
                                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${nature})`,
                                    width: "100%",
                                    height: "300px",
                                    backgroundSize: "cover",
                                }} >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base lg:mb-10">Your Perfect Package Awaits</p> </a> */}
                                    <a href=""><h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-56 lg:ml-6 relative mt-24 ml-6 ">Book Package</h5> </a>
                                    <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Your Perfect Package</p>  </a>
                                </article>
                            </div>
                            <div className="flex-wrap">
                                <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${flightimage2})`,
                                    width: "100%",
                                    height: "142px",
                                    backgroundSize: "cover",
                                }}  >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                    <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-16 lg:ml-6 relative mt-24 ml-6">Find Out More</h5> </a>
                                    <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Travel Requirements</p>  </a>
                                </article>
                                <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform lg:mt-4" style={{
                                    backgroundImage: `url(${insideflight})`,
                                    width: "100%",
                                    height: "142px",
                                    backgroundSize: "cover",
                                }}  >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                    <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-16 lg:ml-6 relative mt-24 ml-6">Purchase add-ons</h5> </a>
                                    <a href=""> <p className="text-white pt-8 pl-8 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Your Experience</p>  </a>

                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/*  Start Planning Your Next Trip mobile*/}
            <div className="lg:hidden md:hidden sm:hidden xl:hidden block sm:block">
                <div className="container relative  mb-6  xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6 ">
                    <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 pt-6">
                        Start Planning Your Next Trip
                    </div>
                    <div className="font font-regular lg:text-base text-xs text-neutral-600 lg:pb-6 pb-4">
                        Thinking of travelling somewhere soon? Here are some options to help you get started
                    </div>
                    {/* <div>
            <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
          </div> */}
                    <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">
                        <div className="flex items-center rounded-xl shadow-md xl:my-0 my-1">
                            <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform " style={{
                                backgroundImage: `url(${flightimage})`,
                                width: "100%",
                                height: "200px",
                                backgroundSize: "cover",
                            }} >
                                <a href=""> <h5 className="nc-Badge inline-flex px-4 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-32 ml-3">Find Flights</h5> </a>
                                <a href=""> <p className="text-white pt-2 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Explore Our Destinations</p>  </a>
                            </article>
                        </div>
                        <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">

                            <div className="flex items-center rounded-xl shadow-md  xl:my-0 my-1">
                                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${nature})`,
                                    width: "100%",
                                    height: "200px",
                                    backgroundSize: "cover",
                                }} >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base lg:mb-10">Your Perfect Package Awaits</p> </a> */}
                                    <a href=""><h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-32 ml-3">Book Package</h5> </a>
                                    <a href=""> <p className="text-white pt-2 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Your Perfect Package</p>  </a>
                                </article>
                            </div>
                            <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform my-1" style={{
                                backgroundImage: `url(${flightimage2})`,
                                width: "100%",
                                height: "200px",
                                backgroundSize: "cover",
                            }}  >
                                {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-32 ml-3">Find Out More</h5> </a>
                                <a href=""> <p className="text-white pt-2 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Travel Requirements</p>  </a>
                            </article>
                            <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform my-1" style={{
                                backgroundImage: `url(${insideflight})`,
                                width: "100%",
                                height: "200px",
                                backgroundSize: "cover",
                            }}  >
                                {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-32 ml-3">Purchase Add-Ons</h5> </a>
                                <a href=""> <p className="text-white pt-2 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-base">Your Experience</p>  </a>
                            </article>
                        </div>
                    </div>
                </div>
            </div>

            {/*  Start Planning Your Next Trip md*/}
            <div className="font xl:hidden lg:hidden hidden md:block sm:hidden">
                <div className="container relative  mb-6  xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6 ">
                    <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 pt-6">
                        Start Planning Your Next Trip
                    </div>
                    <div className="font font-regular lg:text-base text-xs text-neutral-600 lg:pb-6 pb-4">
                        Thinking of travelling somewhere soon? Here are some options to help you get started
                    </div>

                    <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">
                        <div className="flex items-center rounded-xl shadow-md xl:my-0 ">
                            <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform " style={{
                                backgroundImage: `url(${flightimage})`,
                                width: "100%",
                                height: "200px",
                                backgroundSize: "cover",
                            }} >
                                <a href=""> <h5 className="nc-Badge inline-flex px-4 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-36 ml-3">Find Flights</h5> </a>
                                <a href=""> <p className="text-white pt-2 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-sm md:text-sm">Explore Our Destinations</p>  </a>
                            </article>
                        </div>
                        <div className="grid lg:grid-cols-2 xl:grid-cols-2 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">

                            <div className="flex items-center rounded-xl shadow-md  xl:my-0 ">
                                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${nature})`,
                                    width: "100%",
                                    height: "200px",
                                    backgroundSize: "cover",
                                }} >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base lg:mb-10">Your Perfect Package Awaits</p> </a> */}
                                    <a href=""><h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-80 lg:ml-6 relative mt-36 ml-3">Book Package</h5> </a>
                                    <a href=""> <p className="text-white pt-2 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-sm md:text-sm">Your Perfect Package</p>  </a>
                                </article>
                            </div>
                            <div className="flex-wrap">
                                <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform" style={{
                                    backgroundImage: `url(${flightimage2})`,
                                    width: "100%",
                                    height: "95px",
                                    backgroundSize: "cover",
                                }}  >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                    <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-16 lg:ml-6 relative mt-10 ml-3">Find Out More</h5> </a>
                                    <a href=""> <p className="text-white pt-2 pl-3 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-sm  md:text-sm">Travel Requirements</p>  </a>
                                </article>
                                <article className="h-full rounded-xl hover:shadow-2xl transition-shadow will-change-transform lg:mt-4 mt-2" style={{
                                    backgroundImage: `url(${insideflight})`,
                                    width: "100%",
                                    height: "95px",
                                    backgroundSize: "cover",
                                }}  >
                                    {/* <a href="">  <p className="text-white pt-8 pl-8 lg:pl-4 lg:pt-96 font-medium font lg:text-lg text-base">Travel Requirements</p> </a> */}
                                    <a href="">  <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100 lg:mt-16 lg:ml-6 relative mt-10 ml-3">Purchase Add-Ons</h5> </a>
                                    <a href=""> <p className="text-white pt-2 pl-3 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-sm  md:text-sm">Your Experience</p>  </a>

                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Join privilege club section desktop view*/}


            <div className=" nc-PageHome relative overflow-hidden">
                <div className="font xl:block lg:hidden hidden md:hidden sm:hidden">
                    <div className="overlay">
                        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
                            <div
                                className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                                data-nc-id="SectionHero">
                                <div className="lg:items-center text-left xl:pt-16 xl:pl-4">
                                    <div className="bg-sky-500 rounded-xl flex-shrink-0 lg:w-5/12 items-start space-y-2 pb-14 lg:mt-6 lg:pb-0 xl:p-6 xl:pr-14 lg:mr-10 xl:mr-0">
                                        <h2 className="font font-bold text-4xl md:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white">
                                            Join Privilege Club
                                        </h2>
                                        <span className="text-base md:text-2xl lg:text-xl font-normal text-white dark:text-neutral-400">
                                            Set off on a journey, where tropical vacation<br></br> dreams come true!
                                        </span><br />
                                        <button className="font border text-white lg:text-base px-12 py-2 mt-4 rounded-lg text-sm mb-4">Join Now</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> </div>


            {/* Join privilege club section tab landscape view*/}

            <div className="font xl:hidden lg:block hidden md:hidden sm:hidden">
                <div className="overlayy">
                    <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                        <div
                            className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                            data-nc-id="SectionHero">
                            <div className="lg:items-center text-left lg:pt-20">
                                <div className="bg-sky-500 flex-shrink-0 lg:w-5/12 items-start space-y-2 lg:p-6 rounded-xl lg:mt-6 mt-14 lg:pb-0 lg:mr-10 ">
                                    <h2 className="font font-bold text-4xl md:text-4xl xl:text-4xl !leading-[114%] lg:pb-2 mt-0 text-white">
                                        Join Privilege Club
                                    </h2>
                                    <span className="text-base md:text-lg lg:text-lg font-normal text-white dark:text-neutral-400">
                                        Set off on a journey, where tropical vacation<br></br> dreams come true!
                                    </span><br />
                                    <button className="font border text-white lg:text-base px-12 py-2 mt-4 rounded-lg text-sm" style={{marginBottom: "18px"}}>Join Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* Join privilege club section tab view */}
            <div className="font xl:hidden lg:hidden hidden md:block sm:hidden">
                <div className="overlayyy">
                    <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                        <div
                            className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                            data-nc-id="SectionHero">
                            <div className="lg:items-center text-left xl:pt-16 sm:pl-3">
                                <div className="bg-sky-500  flex-shrink-0 md:w-6/12 items-start space-y-2  lg:mt-2 mt-10 md:p-6 rounded-xl">
                                    <h2 className="font font-bold text-4xl md:text-2xl xl:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white">
                                        Join Privilege Club
                                    </h2>
                                    <span className="text-base md:text-base lg:text-base font-normal text-white dark:text-neutral-400 md:block ">
                                        Set off on a journey, where <br></br>  tropical vacation dreams come true!
                                    </span>
                                    <button className="font border text-white lg:text-base px-8 py-2 mt-6 rounded-lg text-sm mb-4 ">Join Now</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            {/* Join privilege club section mobile view */}


            <div className="font xl:hidden lg:hidden block md:hidden sm:block"
            > <div className="overlayyyy">
                    <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                        <div
                            className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                            data-nc-id="SectionHero">
                            <div className="lg:items-center text-left xl:pt-16 ml-4">
                                <div className="bg-sky-500 flex-shrink-0 w-10/12 items-start space-y-1 mt-10 rounded-xl p-6">
                                    <h2 className="font font-bold text-2xl md:text-3xl text-base  xl:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white pb-1">
                                        Join Privilege Club
                                    </h2>
                                    <span className="text-xs md:text-base lg:text-base font-normal text-white dark:text-neutral-400">
                                        Set off on a journey, where tropical vacation dreams come true!
                                    </span> <br />
                                    <button className="font text-xs border text-white lg:text-base px-4 py-1 mt-6 rounded-lg text-sm mb-4 ">Join Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {/* SECTION 1 desktop*/}
            <div className="font lg:block hidden xl:pt-12" style={{
                backgroundImage: `url("https://i.ibb.co/sW485bY/gridbanner.png")`,
                width: "100%",
                height: "380px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-24 lg:space-y-2 lg:mb-12">
                    <SectionGridCategoryHotel />
                </div>
            </div>

            {/* SECTION 1 mobile*/}
            <div className="font bg-neutral-50  py-6 lg:hidden block ">
                <div className="container relative space-y-24 mb-4 lg:space-y-2 lg:mb-12">
                    <SectionGridCategoryHotel />
                </div>
            </div>






            {/* ================= before Beach Section ===============*/}
            <div className="container relative lg:mt-12 space-y-24 mb-4 lg:space-y-28 lg:mb-0 md:mt-12 md:mb-6">
                <div className="grid lg:grid-cols-2 lg:gap-0 md:grid-cols-2 md:gap-2 sm:grid-cols-2 sm:gap-2">
                    <div className="">
                        <SectionVideos />
                    </div>
                    <div className="justify-center items-center text-center">
                        <div className="rounded-lg  py-6 mt-3">
                            <div className="font lg:block hidden" style={{
                                backgroundImage: `url("https://i.ibb.co/xgvV2dt/bgcustomer.png")`,

                                height: "320px",
                                backgroundSize: "cover",
                            }}>
                                <div className="customerfont font font-bold text-black xl:mt-10 lg:mt-3">
                                    Why Our
                                </div>
                                <div className="customerfont mt-10 font font-bold text-sky-600">
                                    Customers Love Us
                                </div>
                                <p className="lg:pt-8  text-2xl bannerfont font-medium">
                                    Be inspired to experience travel with us!
                                </p>
                                <a href="https://www.google.com/search?gs_ssp=eJzj4tFP1zesTM8pSU8psjBgtFI1qDBOSkw1NE02STJLNDFPTbW0MqhISU4ysTRJtDAyT01OMjIz9uIqKUosT83JTSwqAQCJ9hRD&q=trawelmart&oq=trawe&gs_lcrp=EgZjaHJvbWUqDwgBEC4YJxivARjHARiKBTINCAAQABjjAhiABBifBDIPCAEQLhgnGK8BGMcBGIoFMgYIAhBFGDkyBwgDEAAYgAQyEAgEEC4YrwEYxwEYsQMYgAQyBwgFEAAYgAQyDQgGEC4YrwEYxwEYgAQyBwgHEAAYgAQyCQgIEAAYChiABDIHCAkQABiABNIBDzEwOTE2ODcwOTFqMWoxNagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15c4b6a47ee9:0xdcb494a827ecb263,1"
                                    target="_blank">
                                    <button className="font gradient2 text-white lg:text-base px-12 py-3 mt-6 rounded-lg text-sm">
                                        View More Testimonials <i className="las la-arrow-right"></i>
                                    </button>
                                </a>
                            </div>

                            <div className="font block lg:hidden -mt-6" style={{
                                backgroundImage: `url("https://i.ibb.co/xgvV2dt/bgcustomer.png")`,

                                height: "160px",
                                backgroundSize: "cover",
                            }}>
                                <div className="text-3xl font font-bold text-black md:mt-4">
                                    Why Our
                                </div>
                                <div className="text-2xl mt-1 font font-bold text-sky-600">
                                    Customers Love Us
                                </div>
                                <p className="lg:pt-8  text-base bannerfont font-medium">
                                    Be inspired to experience travel with us!
                                </p>
                                <a href="https://www.google.com/search?gs_ssp=eJzj4tFP1zesTM8pSU8psjBgtFI1qDBOSkw1NE02STJLNDFPTbW0MqhISU4ysTRJtDAyT01OMjIz9uIqKUosT83JTSwqAQCJ9hRD&q=trawelmart&oq=trawe&gs_lcrp=EgZjaHJvbWUqDwgBEC4YJxivARjHARiKBTINCAAQABjjAhiABBifBDIPCAEQLhgnGK8BGMcBGIoFMgYIAhBFGDkyBwgDEAAYgAQyEAgEEC4YrwEYxwEYsQMYgAQyBwgFEAAYgAQyDQgGEC4YrwEYxwEYgAQyBwgHEAAYgAQyCQgIEAAYChiABDIHCAkQABiABNIBDzEwOTE2ODcwOTFqMWoxNagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15c4b6a47ee9:0xdcb494a827ecb263,1"
                                    target="_blank">
                                    <button className="font gradient2 text-white lg:text-base px-8 py-2 mt-4 rounded-lg text-sm">
                                        View More Testimonials <i className="las la-arrow-right"></i>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* ================= Beach Section ===============*/}

            <div className="container relative space-y-2 mb-24 lg:space-y-0 lg:mb-16 ">
                <div className="flex lg:flex-row justify-between items-center">
                    <div className="">
                        <div className="lg:font-semibold font-medium font lg:text-2xl text-lg ">
                            Travel Blog
                        </div>
                        <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 lg:block hidden">
                            Suggestions tailored to your interest
                        </div>
                        <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 block  lg:hidden">
                            Suggestions tailored to your interest
                        </div>
                    </div>
                </div>
                <div className="lg:mt-10 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-2 xl:gap-3 lg:gap-1 md:gap-3 ">
                    {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                </div>
            </div>
        </div>

    );
}

export default FlightHome;
