import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import { TaxonomyType } from "data/types";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionVideos from "./SectionVideos";
import awardedindiabesttourismcompanytrawelmart from "images/sample/awarded-india-best-tourism-company-trawel-mart.png";
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import icon1 from "images/sample/icon1.png";
import icon2 from "images/sample/icon2.png";
import icon3 from "images/sample/icon3.png";
import icon4 from "images/sample/icon4.png";
import { Link, useLocation } from "react-router-dom";
import besttourpackagescompanybangaloretrawelmart from "images/sample/best-tour-packages-company-bangalore-trawel-mart.jpg";
import PagePopup from "./PagePopup";
import mblbanner from "images/sample/mblbanner.jpg";
import { Dialog } from "@headlessui/react";
import axios from "axios";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import { Popover, Transition } from "@headlessui/react";
import Input from "shared/Input/Input";
import PageEnquiryy from "./PageEnquiryy";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";
import TMHomeBannerBackground from "images/sample/TM-Home-Banner-Background.jpg";
import { useInView } from "react-intersection-observer";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import Slider from "react-slick";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import LocationInput from "components/HeroSearchForm/LocationInput";
import StayDatesRangeInput from "components/HeroSearchForm/(stay-search-form)/StayDatesRangeInput";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import {
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import ClearDataButton from "components/HeroSearchForm/ClearDataButton";
import DatePicker from "react-datepicker";
import DatePickerCustomHeaderTwoMonth from "components/DatePickerCustomHeaderTwoMonth";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import ButtonSubmit from "components/HeroSearchForm/ButtonSubmit";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import { GuestsObject } from "components/HeroSearchForm/type";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import tmdubaitours from "images/sample/tmdubaitours.jpg";
import tmbalitours from "images/sample/tmbalitours.jpg";
import tmsingaporetours from "images/sample/tmsingaporetours.jpg";
import tmsrilankatours from "images/sample/tmsrilankatours.jpg";
import tmmalaysiatours from "images/sample/tmmalaysiatours.jpg";
import tmcambodiatours from "images/sample/tmcambodiatours.jpg";
import tmthailandtours from "images/sample/tmthailandtours.jpg";
import tmvietnamtours from "images/sample/tmvietnamtours.jpg";
import tmeuropetours from "images/sample/tmeuropetours.jpg";
import tmnepaltours from "images/sample/tmnepaltours.jpg";
// import tmbannerr from "images/sample/tmbannerr.jpg";
import tmphilippinestours from "images/sample/tmphilippinestours.jpg";
import tmmobilebannerr from "images/sample/tmmobilebannerr.jpg";
import tmawarddd from "images/sample/tmawarddd.png";
import tmawardd from "images/sample/tmawardd.jpg";
import tmchristmasbanner from "images/sample/tmchristmasbanner.jpg";
import tmgreecetours from "images/sample/tmgreecetours.jpg";
import tmkenyatours from "images/sample/tmkenyatours.jpg";
import tmmoroccotours from "images/sample/tmmoroccotours.jpg";
import sankranti from "images/sample/sankranti.jpg"
import homepagebanner from "images/sample/homepagebanner.png";
import desktopbannernew from "images/sample/desktopbannernew.jpg";
// import trawelmartmobilebannerfrom "images/sample/trawelmartmobilebannerfrom";
import trawelmartmobilebanner from "images/sample/trawelmartmobilebanner.jpg";
import bluebannernew from "images/sample/bluebannernew.jpg";
import bluebannernewone from "images/bluebannernewone.jpg";
import mobilebluebanner from "images/mobilebluebanner.jpg";
import latestbluebanner1 from "images/latestbluebanner1.jpg";

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "1",
    href: "/dubai-tour-packages",
    name: "Dubai",
    taxonomy: "category",
    count: 188288,
    text: "₹5,000",
    thumbnail: "https://i.ibb.co/xzYGFYQ/dubai.png",
  },
  {
    id: "2",
    href: "/bali-tour-packages",
    name: "Bali",
    taxonomy: "category",
    count: 188288,
    text: "₹1,500",
    thumbnail: "https://i.ibb.co/9h1dqdg/bali.jpg",
  },
  {
    id: "2",
    href: "/cambodia-tour-packages",
    name: "Cambodia",
    taxonomy: "category",
    count: 188288,
    text: "₹6,000",
    thumbnail: "https://i.ibb.co/nBtVT9f/cambodia.jpg",
  },
  {
    id: "2",
    href: "/vietnam-tour-packages",
    name: "Vietnam",
    taxonomy: "category",
    count: 188288,
    text: "₹4,000",
    thumbnail: "https://i.ibb.co/wyqGhBw/vietnam.webp",
  },
];

function PageHome() {
  const [landingPages, setLandingPages] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isEnquiryOpened, setIsEnquiryOpened] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [activeeTab, setActiveeTab] = useState(1);
  const [activeeeTab, setActiveeeTab] = useState(1);
  const [activeeeeTab, setActiveeeeTab] = useState(1);
  const [baliPackages, setBaliPackages] = useState([]);
  const [dubaiPackages, setDubaiPackages] = useState([]);
  const [cambodiaPackages, setCambodiaPackages] = useState([]);
  const [malaysiaPackages, setMalaysiaPackages] = useState([]);
  const [thailandPackages, setThailandPackages] = useState([]);
  const [singaporePackages, setSingaporePackages] = useState([]);
  const [vietnamPackages, setVietnamPackages] = useState([]);
  const [maldivesPackages, setMaldivesPackages] = useState([]);
  const [srilankaPackages, setSrilankaPackages] = useState([]);
  const [nepalPackages, setNepalPackages] = useState([]);
  const [philippinesPackages, setPhilippinesPackages] = useState([]);
  const [greecePackages, setGreecePackages] = useState([]);
  const [MoraccoPackages, setMoraccoPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  const handleTabClick1 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeTab(tabNumber);
  };

  const handleTabClick2 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeeTab(tabNumber);
  };

  const handleTabClick3 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeeeTab(tabNumber);
  };

  useEffect(() => {
    axios
      .get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getLandingPagesForWebsite"
      )
      .then((response) => {
        if (response.data.status) {
          setLandingPages(response.data.pages);
        }
      });

    const isLoggedInString = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(isLoggedInString ? JSON.parse(isLoggedInString) : false);
    const isEnquiryOpened = localStorage.getItem("isEnquiryOpened");
    setIsEnquiryOpened(isEnquiryOpened ? JSON.parse(isEnquiryOpened) : false);
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite"
      )
      .then((response) => {
        if (response.data.status) {
          const packages = response.data.packages;

          const filterByCity = (city: any) =>
            packages.filter((package1: any) => package1.tour_city === city);

          Promise.all([
            setBaliPackages(filterByCity("Bali")),
            setDubaiPackages(filterByCity("Dubai")),
            setSingaporePackages(filterByCity("Singapore")),
            setGreecePackages(filterByCity("Greece")),
            setCambodiaPackages(filterByCity("Cambodia")),
            setVietnamPackages(filterByCity("Vietnam")),
            setThailandPackages(filterByCity("Thailand")),
            setSrilankaPackages(filterByCity("Sri Lanka")),
            setMalaysiaPackages(filterByCity("Malaysia")),
            setNepalPackages(filterByCity("Nepal")),
            setPhilippinesPackages(filterByCity("Philippines")),
            setMoraccoPackages(filterByCity("Morocco")),
          ]);
        }
      });
  }, []);

  const [showMoreStates, setShowMoreStates] = useState<{
    [key: number]: boolean;
  }>({});

  const toggleReadMoreLess = (index: number) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [placeText, setPlaceText] = useState("");
  const [showPopover, setShowPopover] = useState(false);

  // const [startDate, setStartDate] = useState<Date | null>(
  //   new Date()
  // );
  // const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    setShowPopover(false);
  }, [false]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    // CLICK IN_SIDE
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    // CLICK OUT_SIDE
    setShowPopover(false);
  };

  const handleSelectLocation = (item: string) => {
    setPlaceText(item);
    setShowPopover(false);
  };

  const [guestAdultsInputValue, setGuestAdultsInputValue] = useState(2);
  const [guestChildrenInputValue, setGuestChildrenInputValue] = useState(1);
  const [guestInfantsInputValue, setGuestInfantsInputValue] = useState(1);

  const handleChangeData = (value: number, type: keyof GuestsObject) => {
    let newValue = {
      guestAdults: guestAdultsInputValue,
      guestChildren: guestChildrenInputValue,
      guestInfants: guestInfantsInputValue,
    };
    if (type === "guestAdults") {
      setGuestAdultsInputValue(value);
      newValue.guestAdults = value;
    }
    if (type === "guestChildren") {
      setGuestChildrenInputValue(value);
      newValue.guestChildren = value;
    }
    if (type === "guestInfants") {
      setGuestInfantsInputValue(value);
      newValue.guestInfants = value;
    }
  };

  const totalGuests =
    guestChildrenInputValue + guestAdultsInputValue + guestInfantsInputValue;

  //testimonials slider //
  const settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    className: "center",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  const settings1 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    dots: false,
    speed: 500,
    centerPadding: "10px",
    infinite: true,
    autoplaySpeed: 4000,
    autoplay: true,
    className: "center",
    responsive: [
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          centerMode:
            false /* set centerMode to false to show complete slide instead of 3 */,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          arrows: false,
        },
      },
    ],
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-3 rounded-xl overflow-hidden group"
      >
        {/* <Link to={post.href} /> */}
        <LazyLoadImage
          effect="blur"
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-1 p-4">
          <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100  relative">
            {post.date}
          </h5>
          <h2 className="font block xl:text-lg lg:text-base md:text-lg text-base lg:font-bold font-medium text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  const renderSearchValue = () => {
    return (
      <>
        {["Dubai", "Bali", "Singapore", "Vietnam"].map((item) => (
          <span
            onClick={() => handleSelectLocation(item)}
            key={item}
            className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
          >
            <span className="block text-neutral-400">
              <ClockIcon className="h-4 w-4 sm:h-6 sm:w-6" />
            </span>
            <span className="block font-medium text-neutral-700 dark:text-neutral-200">
              {item}
            </span>
          </span>
        ))}
      </>
    );
  };

  const renderRecentSearches = () => {
    return (
      <>
        <h3 className="font block mt-2 sm:mt-0 px-4 sm:px-8 font-semibold text-base sm:text-lg text-neutral-800 dark:text-neutral-100">
          Recent searches
        </h3>
        <div className="mt-2">
          {["Dubai", "Bali", "Singapore", "Vietnam"].map((item) => (
            <span
              onClick={() => handleSelectLocation(item)}
              key={item}
              className="flex px-4 sm:px-8 items-center space-x-3 sm:space-x-4 py-4 hover:bg-neutral-100 dark:hover:bg-neutral-700 cursor-pointer"
            >
              <span className="block text-neutral-400">
                <ClockIcon className="h-4 sm:h-6 w-4 sm:w-6" />
              </span>
              <span className=" block font-medium text-neutral-700 dark:text-neutral-200">
                {item}
              </span>
            </span>
          ))}
        </div>
      </>
    );
  };

  // const onChangeDate = (dates: [Date | null, Date | null]) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  // const renderInput = () => {
  //   return (
  //     <>
  //       <div className="text-neutral-300 dark:text-neutral-400">
  //         <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
  //       </div>
  //       <div className="flex-grow text-left">
  //         <span className="block xl:text-lg font-semibold">
  //           {startDate?.toLocaleDateString("en-US", {
  //             month: "short",
  //             day: "2-digit",
  //           }) || "Add dates"}
  //           {endDate
  //             ? " - " +
  //               endDate?.toLocaleDateString("en-US", {
  //                 month: "short",
  //                 day: "2-digit",
  //               })
  //             : ""}
  //         </span>
  //         <span className="block mt-1 text-sm text-neutral-400 leading-none font-light">
  //           {"Check in - Check out"}
  //         </span>
  //       </div>
  //     </>
  //   );
  // };

  // Anusha code 05-01-2024

  // const [startDate, setStartDate] = useState<Date | null>(new Date("2024/02/06"));
  // const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));
  // const [selectedMonth, setSelectedMonth] = useState<string | null>(null);

  // const onChangeDate = (dates: [Date | null, Date | null]) => {
  //   const [start, end] = dates;
  //   setStartDate(start);
  //   setEndDate(end);
  // };

  // Mahima code 05-01-2024

  const [startDate, setStartDate] = useState<Date | null>(
    new Date("2024/02/06")
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date("2024/02/23"));
  const [selectedMonth, setSelectedMonth] = useState<string | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const onChangeDate = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const renderInput = () => {
    return (
      <>
        <div className="text-neutral-300 dark:text-neutral-400">
          <CalendarIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow text-left">
          <span className="block xl:text-lg md:text-sm font-semibold">
            {selectedMonth
              ? `${selectedMonth} ${startDate?.getFullYear() || ""}`
              : "Travel Month"}
          </span>
          {/* <span className="block mt-1 text-sm text-neutral-400 font-light  leading-none">
            {"Select Month"}
          </span> */}
        </div>
      </>
    );
  };

  const handleMonthClick = (month: string) => {
    setSelectedMonth(month);
    setPopoverVisible(false);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const options = {
    items: 7,
    loop: true,
    margin: 10,
    nav: false,
    autoplay: true,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1,
        items1: 1,
      },
      600: {
        items: 2,
        items1: 2,
      },
      1000: {
        items: 4,
        items1: 4,
      },
      1100: {
        items: 3,
        items1: 3,
      },
      1400: {
        items: 5,
        items1: 5,
      },
    },
  };

  const items = [
    <div
      key={1}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmdubaitours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Dubai Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-sm"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/dubai-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers
        </div>
      </h4>
    </div>,
    <div
      key={2}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmeuropetours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Europe Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/europe-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={3}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmsingaporetours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Singapore Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/singapore-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={4}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmmalaysiatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Malaysia Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/malaysia-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={5}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmthailandtours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Thailand Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/thailand-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={6}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmbalitours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Bali Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/bali-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={7}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmsrilankatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Sri Lanka Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/srilanka-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    // Add more items as needed
  ];

  const items1 = [
    <div
      key={8}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmcambodiatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Cambodia Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/cambodia-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={9}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmvietnamtours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Vietnam Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/vietnam-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    <div
      key={10}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmkenyatours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Kenya Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/kenya-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={11}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmphilippinestours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Philippines Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/philippines-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={12}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmmoroccotours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Morocco Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/morocco-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={13}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmgreecetours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Greece Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/greece-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,
    <div
      key={14}
      className="item px-2 "
      style={{
        backgroundImage: `url(${tmnepaltours})`,
        width: "100%",
        height: "380px",
        backgroundSize: "cover",
      }}
    >
      <h4 className="absolute inset-x-0 bottom-0 ">
        <div className="p-3">
          <div className="text-center font xl:text-3xl text-3xl font-bold text-white">
            Nepal Tour
          </div>
          <div className="tmdivider"></div>
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-4 xl:gap-1 pt-3 pb-0">
            <a href="tel:080 4642 7999">
              <div className="text-center rounded-md p-1 font text-white border items-center font-semibold text-sm">
                <i className="las la-phone text-base"></i>{" "}
                <span className="text-sm">Call Now</span>
              </div>
            </a>
            <a href="/nepal-tour-packages">
              <div className="text-center rounded-md p-1 font text-black bg-white items-center font-semibold text-sm">
                View Details
              </div>
            </a>
          </div>
        </div>
        <div className="w-full font bg-orange-500 text-white font-normal small uppercase text-center">
          Book It Now And Avail  Attractive Offers

        </div>
      </h4>
    </div>,

    // Add more items as needed
  ];

  return (
    <>
      <Helmet>
        <title>
          Trawel Mart India’s leading and award winning travel agency{" "}
        </title>
        <meta
          name="description"
          content="We offer services including world tour packages, custom itineraries, expert guides, and hassle-free bookings. Explore the world with confidence and ease."
        />
        <meta
          name="keywords"
          content="Tour packages, Best tour packages, Best tour packages company, Travel Company in Bangalore, Visa Packages"
        />
      </Helmet>
      <div className=" nc-PageHome relative overflow-hidden">
        {/* ====== Banner Desktop & LG Screen view =======*/}
        <div className="font xl:block lg:block md:hidden hidden">
          <section
            style={{
              backgroundImage: `url(${latestbluebanner1})`,
              width: "100%",
              height: "560px",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className="text-center xl:pt-28 lg:pt-24">
              <p className="text-white  pb-2 text-2xl font-medium ">
                Discover the World with Trawelmart
              </p>
              <h3 className="xl:text-5xl lg:text-5xl trawelbannerfont text-white font-semibold leading-relaxed">
                Your Gateway to <br></br>Unforgettable Journeys!
              </h3>
              {/* <p className="font text-xl font-medium text-white pt-4">
                Grab Trawel Mart’s{" "}
                <span className="bg-red-500 text-white text-sm px-2 py-1 rounded-md">
                  15th Anniversary
                </span>{" "}
                Special Offers
              </p> */}
            </div>
            {/* Hero Search */}
            <div className=" lg:block z-10 mb-12 lg:mb-0  lg:-mt-42 w-full textcenter">
              <div
                className={`nc-HeroSearchForm w-full max-w-7xl py-5 lg:py-0 `}
              >
                <form
                  className="hero-sectionnew cursor-pointer xl:mt-12 w-7/12 relative flex mt-8 rounded-[20px] xl:rounded-[20px] rounded-t-2xl xl:rounded-t-2xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
                  method="GET"
                  action={
                    placeText === ""
                      ? "#!"
                      : "/filter-details/" +
                      placeText +
                      "/" +
                      (selectedMonth === null || selectedMonth === ""
                        ? ""
                        : selectedMonth)
                  }
                >
                  {/* <LocationInput className="flex-[1.5]" /> */}
                  <div
                    className={`relative flex flex-[1.5] nc-flex-1.5 `}
                    ref={containerRef}
                  >
                    <div
                      onClick={() => setShowPopover(true)}
                      className={`flex z-10 flex-1 relative pl-4 flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "" : ""
                        }`}
                    >
                      <div className="text-neutral-300 dark:text-neutral-400">
                        <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                      </div>
                      <div className="flex-grow">
                        <input
                          className={`block cursor-pointer w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                          placeholder="Destination"
                          value={placeText}
                          autoFocus={showPopover}
                          onChange={(e) => {
                            setPlaceText(e.currentTarget.value);
                          }}
                          ref={inputRef}
                        />
                        {/* <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                          <span className="line-clamp-1">
                            {!!placeText
                              ? "Destination"
                              : "Where do you want to travel?"}
                          </span>
                        </span> */}
                        {placeText && showPopover && (
                          <ClearDataButton
                            onClick={() => {
                              setPlaceText("");
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {showPopover && (
                      <div
                        className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 left-10 -right-0.5`}
                      ></div>
                    )}
                    {showPopover && (
                      <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                        {placeText
                          ? renderSearchValue()
                          : renderRecentSearches()}
                      </div>
                    )}
                  </div>
                  <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
                  {/* <StayDatesRangeInput className="flex-1" />  */}
                  <Popover className={`StayDatesRangeInput z-10 relative flex`}>
                    {({ open, close }) => (
                      <>
                        {/* <Popover.Button
                          className={`  z-10 flex-1 flex justify-between relative xl:pl-4 items-center space-x-3 focus:outline-none  ${open ? "" : ""}`}
                          onClickCapture={() => document.querySelector("html")?.click()}
                        >
                          {renderInput()}
                          {startDate && open && (
                            <ClearDataButton onClick={() => onChangeDate([null, null])} />
                          )}
                        </Popover.Button> */}

                        <Popover.Button
                          className={`z-10 flex-1 flex justify-between relative xl:pl-4 items-center space-x-3 focus:outline-none  ${open ? "" : ""
                            }`}
                          onClickCapture={() => {
                            document.querySelector("html")?.click();
                            setPopoverVisible(!popoverVisible); // Toggle the visibility of the Popover
                          }}
                        >
                          {renderInput()}
                          {startDate && open && (
                            <ClearDataButton
                              onClick={() => onChangeDate([null, null])}
                            />
                          )}
                        </Popover.Button>

                        {/* BUTTON SUBMIT OF FORM */}
                        <div className="pr-2 xl:pl-28 py-4">
                          <ButtonSubmit
                            href={
                              placeText === ""
                                ? "#!"
                                : "/filter-details/" +
                                placeText +
                                "/" +
                                (selectedMonth === null ||
                                  selectedMonth === ""
                                  ? ""
                                  : selectedMonth)
                            }
                          />
                        </div>

                        {open && (
                          <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5  dark:bg-neutral-800"></div>
                        )}

                        {/* <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                            <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                              <div className="flex-grow text-left">
                                <span className="block xl:text-lg font-semibold">
                                  {selectedMonth || "Travel Date"}
                                </span>
                                <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                  {"Select Month"}
                                </span>
                              </div>
                              <div className="grid grid-cols-4 gap-0 border-t">
                                {months.map((month, index) => (
                                  <div
                                    key={index}
                                    className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base"
                                    onClick={() => handleMonthClick(month)}
                                  >
                                    <h3 className="font-medium font">
                                      {month.substr(0, 3)}
                                    </h3>
                                    <p className="text-neutral-500 font1 text-base font-semibold">
                                      2024
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition> */}

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                            {popoverVisible && ( // Conditionally render the popover based on visibility state
                              <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                                <div className="flex-grow text-left">
                                  <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                    {"Select Month"}
                                  </span>
                                </div>
                                <div className="grid grid-cols-4 gap-0 border-t">
                                  {months.map((month, index) => (
                                    <div
                                      key={index}
                                      className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base"
                                      onClick={() => {
                                        handleMonthClick(month);
                                        close(); // Close the Popover after selecting the month
                                      }}
                                    >
                                      <h3 className="font-medium font">
                                        {month.substr(0, 3)}
                                      </h3>
                                      <p className="text-neutral-500 font1 text-base font-semibold">
                                        2024
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <div className="self-center  border-slate-200 dark:border-slate-700 h-8"></div>
                </form>
              </div>
            </div>
          </section>
        </div>

        {/* ====== Banner md screen view =======*/}
        <section
          className="xl:hidden lg:hidden md:block hidden sm:hidden"
          style={{
            backgroundImage: `url(${bluebannernewone})`,
            width: "100%",
            height: "300px",
            backgroundSize: "cover",
          }}
        >
          <div className="text-center pt-12">
            <p className="text-white font text-lg font-medium ">
              Discover the World with Trawelmart
            </p>
            <h3 className="text-3xl trawelbannerfont text-white font-semibold">
              Your Gateway to Unforgettable Journeys!
            </h3>
            {/* <p className="font text-base font-medium text-white pt-2">
              Grab Trawel Mart’s{" "}
              <span className="bg-red-500 text-white text-sm px-2 py-1 rounded-md">
                15th Anniversary
              </span>{" "}
              Special Offers
            </p> */}
          </div>
          <div className=" lg:block z-10 mb-12 -mt-8 w-full textcenter">
            <div className={`nc-HeroSearchForm w-full max-w-7xl py-5 lg:py-0 `}>
              <form
                className="hero-sectionnew w-9/12 relative flex mt-8 rounded-[20px] xl:rounded-[20px] rounded-t-2xl xl:rounded-t-2xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800"
                method="GET"
                action={
                  placeText === ""
                    ? "#!"
                    : "/filter-details/" +
                    placeText +
                    "/" +
                    (selectedMonth === null || selectedMonth === ""
                      ? ""
                      : selectedMonth)
                }
              >
                {/* <LocationInput className="flex-[1.5]" /> */}
                <div
                  className={`relative flex flex-[1.5] nc-flex-1.5 `}
                  ref={containerRef}
                >
                  <div
                    onClick={() => setShowPopover(true)}
                    className={`flex z-10 flex-1 relative pl-4 flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "" : ""
                      }`}
                  >
                    <div className="text-neutral-300 dark:text-neutral-400">
                      <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                    </div>
                    <div className="flex-grow">
                      <input
                        className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg md:text-sm font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                        placeholder="Destination"
                        value={placeText}
                        autoFocus={showPopover}
                        onChange={(e) => {
                          setPlaceText(e.currentTarget.value);
                        }}
                        ref={inputRef}
                      />
                      {/* <span className="block mt-0.5 text-sm text-neutral-400 font-light ">
                        <span className="line-clamp-1">
                          {!!placeText
                            ? "Destination"
                            : "Where do you want to travel?"}
                        </span>
                      </span> */}
                      {placeText && showPopover && (
                        <ClearDataButton
                          onClick={() => {
                            setPlaceText("");
                          }}
                        />
                      )}
                    </div>
                  </div>
                  {showPopover && (
                    <div
                      className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 left-10 -right-0.5`}
                    ></div>
                  )}
                  {showPopover && (
                    <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                      {placeText ? renderSearchValue() : renderRecentSearches()}
                    </div>
                  )}
                </div>
                <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 -ml-32"></div>
                {/* <StayDatesRangeInput className="flex-1" />  */}
                <Popover className={`StayDatesRangeInput z-10 relative flex`}>
                  {({ open, close }) => (
                    <>
                      {/* <Popover.Button
                        className={`  z-10 flex-1 flex relative xl:pl-4 items-center space-x-3 focus:outline-none  ${
                          open ? "" : ""
                        }`}
                        onClickCapture={() =>
                          document.querySelector("html")?.click()
                        }
                      >
                        {renderInput()}
                        {startDate && open && (
                          <ClearDataButton
                            onClick={() => onChangeDate([null, null])}
                          />
                        )}
                      </Popover.Button> */}
                      <Popover.Button
                        className={`z-10 flex-1 flex  relative xl:pl-4 items-center space-x-3 focus:outline-none  ${open ? "" : ""
                          }`}
                        onClickCapture={() => {
                          document.querySelector("html")?.click();
                          setPopoverVisible(!popoverVisible); // Toggle the visibility of the Popover
                        }}
                      >
                        {renderInput()}
                        {startDate && open && (
                          <ClearDataButton
                            onClick={() => onChangeDate([null, null])}
                          />
                        )}
                      </Popover.Button>

                      {/* BUTTON SUBMIT OF FORM */}
                      <div className="pr-2 xl:pl-28 py-4">
                        <ButtonSubmit
                          href={
                            placeText === ""
                              ? "#!"
                              : "/filter-details/" +
                              placeText +
                              "/" +
                              (selectedMonth === null || selectedMonth === ""
                                ? ""
                                : selectedMonth)
                          }
                        />
                      </div>

                      {open && (
                        <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5  dark:bg-neutral-800"></div>
                      )}

                      {/* <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                          <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                            <div className="flex-grow text-left">
                              <span className="block xl:text-lg font-semibold">
                                  {selectedMonth || "Travel Date"}
                                </span>
                              <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                {"Select Month"}
                              </span>
                            </div>
                            <div className="grid grid-cols-4 gap-0 border-t">
                              {months.map((month, index) => (
                                <div
                                  key={index}
                                  className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base"
                                  onClick={() => handleMonthClick(month)}
                                >
                                  <h3 className="font-medium font">
                                    {month.substr(0, 3)}
                                  </h3>
                                  <p className="text-neutral-500 font1 text-base font-semibold">
                                    2024
                                  </p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition> */}
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1"
                      >
                        <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                          {popoverVisible && ( // Conditionally render the popover based on visibility state
                            <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                              <div className="flex-grow text-left">
                                <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                  {"Select Month"}
                                </span>
                              </div>
                              <div className="grid grid-cols-4 gap-0 border-t">
                                {months.map((month, index) => (
                                  <div
                                    key={index}
                                    className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base"
                                    onClick={() => {
                                      handleMonthClick(month);
                                      close(); // Close the Popover after selecting the month
                                    }}
                                  >
                                    <h3 className="font-medium font">
                                      {month.substr(0, 3)}
                                    </h3>
                                    <p className="text-neutral-500 font1 text-base font-semibold">
                                      2024
                                    </p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
                <div className="self-center  border-slate-200 dark:border-slate-700 h-8"></div>
              </form>
            </div>
          </div>
        </section>

        {/* ====== Banner mbl view =======*/}
        {/* <div
          className="font xl:hidden lg:hidden md:hidden sm:block block"
          style={{
            backgroundImage: `url(${mblbanner})`,
            width: "100%",
            height: "380px",
            backgroundSize: "cover",
          }}
        >
          <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
            <SectionHero className="pt-10 lg:pt-8 lg:pb-6 text-center" />
          </div>
        </div> */}

        <section
          className="xl:hidden lg:hidden md:hidden block sm:block"
          style={{
            backgroundImage: `url(${mobilebluebanner})`,
            width: "100%",
            height: "360px",
            backgroundSize: "cover",
          }}
        >
          <div className="text-center pt-14">
            <p className="text-white font pb-2 text-xl font-medium ">
              Discover the World with Trawelmart
            </p>
            <h3 className="text-2xl  uppercase trawelbannerfont1 text-white font-semibold">
              Your Gateway to <br></br>Unforgettable Journeys!
            </h3>
            {/* <p className="font text-sm font-medium text-white pt-2 px-6 leading-6">
              Grab Trawel Mart’s{" "}
              <span className="bg-red-500 text-white text-xs  py-1 rounded-md">
                15th Anniversary
              </span>{" "}
              Special Offers
            </p> */}
          </div>
          {/* <div className=" lg:block z-10 mb-12 -mt-8 w-full textcenter">
              <div
                className={`nc-HeroSearchForm w-full max-w-7xl py-5 lg:py-0 `}
              >
                <form className="w-12/12 mx-2 relative flex mt-8 rounded-[20px] xl:rounded-[20px] rounded-t-2xl xl:rounded-t-2xl shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800" method="GET" action={"/filter-details/" + placeText}>
                  {/* <LocationInput className="flex-[1.5]" /> 
                  <div className={`relative flex flex-[1.5] nc-flex-1.5 `} ref={containerRef}>
                    <div
                      onClick={() => setShowPopover(true)}
                      className={`flex z-10 flex-1 relative pl-4 flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left  ${showPopover ? "" : ""
                        }`}
                    >
                      <div className="text-neutral-300 dark:text-neutral-400">
                        <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
                      </div>
                      <div className="flex-grow">
                        <input
                          className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                          placeholder="Destination"
                          value={placeText}
                          autoFocus={showPopover}
                          onChange={(e) => {
                            setPlaceText(e.currentTarget.value);
                          }}
                          ref={inputRef}
                        />
                        <span className="block mt-0.5 text-xs text-neutral-400 font-light ">
                          <span className="line-clamp-1 text-xs">{!!placeText ? "Destination" : "Where do you want to travel?"}</span>
                        </span>
                        {placeText && showPopover && (
                          <ClearDataButton
                            onClick={() => {
                              setPlaceText("");
                            }}
                          />
                        )}
                      </div>
                    </div>
                    {showPopover && (
                      <div
                        className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 left-10 -right-0.5`}
                      ></div>
                    )}
                    {showPopover && (
                      <div className="absolute left-0 z-40 w-full min-w-[300px] sm:min-w-[500px] bg-white dark:bg-neutral-800 top-full mt-3 py-3 sm:py-6 rounded-3xl shadow-xl max-h-96 overflow-y-auto">
                        {placeText ?
                          renderSearchValue() : renderRecentSearches()}
                      </div>
                    )}
                  </div>
                  <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8 -ml-32"></div>
                  {/* <StayDatesRangeInput className="flex-1" />  
                  <Popover className={`StayDatesRangeInput z-10 relative flex`}>
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`  z-10 flex-1 flex relative xl:pl-4 items-center space-x-3 focus:outline-none  ${open ? "" : ""}`}
                          onClickCapture={() => document.querySelector("html")?.click()}
                        >
                          {renderInput()}
                          {startDate && open && (
                            <ClearDataButton onClick={() => onChangeDate([null, null])} />
                          )}
                        </Popover.Button>

                        {/* BUTTON SUBMIT OF FORM
                        <div className="pr-2 xl:pl-28 py-4">
                          <ButtonSubmit href={"/filter-details/" + placeText} />
                        </div>

                        {open && (
                          <div className="h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 -inset-x-0.5  dark:bg-neutral-800"></div>
                        )}

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute left-1/2 z-10 mt-3 top-full w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-sm">
                            <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5 bg-white dark:bg-neutral-800">
                              <div className="flex-grow text-left">
                                {/* <span className="block xl:text-lg font-semibold">
                                  {selectedMonth || "Travel Date"}
                                </span> 
                                <span className="block mt-1 text-sm text-neutral-600 leading-none font-semibold uppercase text-center py-4">
                                  {"Select Month"}
                                </span>
                              </div>
                              <div className="grid grid-cols-4 gap-0 border-t">
                                {months.map((month, index) => (
                                  <div key={index} className="cursor-pointer hover:bg-neutral-100 border p-3 border-neutral-100 text-center text-base" onClick={() => handleMonthClick(month)}>
                                    <h3 className="font-medium font">{month.substr(0, 3)}</h3>
                                    <p className="text-neutral-500 font1 text-base font-semibold">2024</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                  <div className="self-center  border-slate-200 dark:border-slate-700 h-8"></div>
                </form>
              </div>
            </div> */}
        </section>

        {/*====== 2nd section - icon Desktop View =======*/}
        <div className="bg-neutral-50 lg:-mt-6   lg:block hidden">
          <div className="container relative space-y-24 mb-12 lg:space-y-28 lg:pb-2 lg:pt-2">
            <div className="">
              <div className="grid lg:grid-cols-4 lg:gap-2 grid-cols-1 xl:mt-0 lg:mt-0 md:mt-0 mt-0">
                <div className="text-center justify-center align-center">
                  <div className="p-6  justify-center align-center ">
                    <LazyLoadImage
                      src={icon1}
                      alt=""
                      className="w-14 justify-center items-center align-center"
                      effect="blur"
                    />
                    <h2 className="font text-base font-medium pl-3 ">
                      2 Decades of <br></br>experience & expertise
                    </h2>
                  </div>
                </div>

                <div className="text-center justify-center align-center">
                  <div className="p-6  justify-center align-center">
                    <LazyLoadImage
                      src={icon2}
                      alt=""
                      className="w-14"
                      effect="blur"
                    />
                    <h2 className="font text-base font-medium pl-3 ">
                      2 Lakhs+ satisfied <br></br>& thrilled customers
                    </h2>
                  </div>
                </div>

                <div className="text-center justify-center align-center">
                  <div className="p-6  justify-center align-center ">
                    <LazyLoadImage
                      src={icon3}
                      alt=""
                      className="w-14"
                      effect="blur"
                    />
                    <h2 className="font text-base font-medium pl-3 ">
                      Led by ingenious & <br></br>skillful industry experts
                    </h2>
                  </div>
                </div>

                <div className="text-center justify-center align-center">
                  <div className="p-6 justify-center align-center ">
                    <LazyLoadImage
                      src={icon4}
                      alt=""
                      className="w-14"
                      effect="blur"
                    />
                    <h2 className="font text-base font-medium pl-3 ">
                      Personalised care at<br></br> every phase of booking
                    </h2>
                  </div>
                </div>
              </div>

              {/* <div className="grid lg:grid-cols-4 lg:gap-2 grid-cols-1 xl:mt-4 lg:mt-0 md:mt-0 mt-0">
                <div className="flex items-center">
                  <LazyLoadImage src={icon1} alt="" className="w-14" effect="blur" />
                  <span className="font text-base font-regular pl-3">
                    2 Decades of <br></br>experience & expertise
                  </span>
                </div>

                <div className="flex items-center">
                  <LazyLoadImage src={icon2} alt="" className="w-14" effect="blur" />
                  <span className="font text-base font-regular pl-3">
                    2 Lakhs+ satisfied <br></br>& thrilled customers
                  </span>
                </div>

                <div className="flex items-center">
                  <LazyLoadImage src={icon3} alt="" className="w-14" effect="blur" />
                  <span className="font text-base font-regular pl-3">
                    Led by ingenious & <br></br>skilfull industry experts
                  </span>
                </div>

                <div className="flex items-center">
                  <LazyLoadImage src={icon4} alt="" className="w-14" effect="blur" />
                  <span className="font text-base font-regular pl-3">
                    Personalised care at<br></br> every phase of booking
                  </span>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {/* {!isLoggedIn && !isEnquiryOpened  && <PagePopup />} */}

        <PageEnquiryy />

        {/* long banner image desktop view ======= */}
        {/* <div className="container relative space-y-24 mb-12 lg:space-y-0 lg:mb-12 lg:px-24 lg:block hidden">
          <div className="lg:px-12">
            <LazyLoadImage
              effect="blur"
              src={tmchristmasbanner}
              alt=""
              className="rounded-xl "
            />
          </div>
        </div> */}

        {/* ==== Explore popular city desktop view ===== */}

        <div className="container relative space-y-24 mb-8 lg:space-y-28 lg:mb-12 ">
          <SectionSliderNewCategories
            categories={DEMO_CATS}
            uniqueClassName="PageHome_s1"
          />
        </div>

        {/* SECTION 1 desktop*/}
        <div
          className="font lg:block hidden"
          style={{
            backgroundImage: `url("https://i.ibb.co/sW485bY/gridbanner.png")`,
            width: "100%",
            height: "280px",
            backgroundSize: "cover",
          }}
        >
          <div className="container relative space-y-24 mb-24 lg:space-y-2 lg:mb-12">
            <SectionGridCategoryBox />
          </div>
        </div>

        {/* <div className="font lg:block hidden" style={{
        backgroundImage: `url("https://i.ibb.co/sW485bY/gridbanner.png")`,
        width: "100%",
        height: "465px",
        backgroundSize: "cover",
      }}>
        <div className="container relative space-y-24 mb-24 lg:space-y-2 lg:mb-12">
          <div className={`font nc-SectionGridCategoryBox relative`}>
            <div className="lg:font-semibold font-medium font lg:text-2xl text-base">
              Added Landing Packages
            </div>
            <div className={`grid grid-cols-2 lg:gap-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 md:grid-cols-3  xl:grid-cols-4 gap-5 xl:gap-3 lg:gap-2 sm:gap-2 md:gap-2 lg:mt-8 mt-4`}>
              {landingPages.map((item, i) => (
              <Link
              to={'/landing/' + (item as any).city.replaceAll(" ","-").toLowerCase()}
              className={`nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-lg [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
              data-nc-id="CardCategoryBox1"
              key={i}
              >
                <Badge
                  className="absolute right-2 top-2 lg:block hidden"
                  color="blue"
                  name={convertNumbThousand((item as any).count)}
                />
                <div className="relative flex-shrink-0 lg:w-20 lg:h-20 w-12 h-12 rounded-full overflow-hidden">
                  <NcImage src={(item as any).logo ? 'https://admin.trawelmart.com/admin/public/index.php/api/' + (item as any).logo : 'https://admin.trawelmart.com/admin/public/index.php/api/' + (item as any).banner_image} containerClassName="absolute inset-0" />
                </div>
                <div className=" ml-4 flex-grow overflow-hidden">
                  <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
                    <span className="font line-clamp-1">{(item as any).city}</span>
                  </h2>
                  <span
                    className={`block mt-1 lg:text-xs text-xs text-neutral-600 dark:text-neutral-400`}
                  >
                   {(item as any).count +' Tours'}
                  </span>
                </div>
              </Link>
              ))}
            </div>
          </div>
        </div>
      </div> */}

        {/* SECTION 1 mobile*/}
        <div className="font bg-neutral-50  py-6 lg:hidden block ">
          <div className="container relative space-y-24 mb-4 lg:space-y-2 lg:mb-12">
            <SectionGridCategoryBox />
          </div>
        </div>

        {/* <div className=" mt-24 hidden xl:block lg:block "  style={{
              backgroundImage: `url(${tmawardd})`,
              width: "100%",
              height: "480px",
              backgroundSize: "cover",
            }} >
          <div className="container relative space-y-24 mb-8 lg:space-y-28  px-16 ">
            <div className="flex flex-wrap items-center">
              <div className="xl:w-5/12 lg:w-6/12 md:w-6/12 pt-6">
                <img src={tmawarddd} alt="" className="pl-24" />
              </div>
              <div className="xl:-pl-24  xl:w-7/12 lg:w-6/12 md:w-6/12 py-24">
                <h1 className="font1 font-bold xl:text-5xl md:text-2xl text-white xl:pt-4">
                  Trawel Mart Been Awarded as
                </h1>
                <h2 className="font1 font-bold xl:text-5xl md:text-2xl text-white pt-3">
                  India's Best Travel Company
                </h2>
                {/* <p className="xl:text-lg text-white pt-6 leading-8 font1">
                  We now sincerely thank all of our most beloved customers, vendors, brand ambassadors,
                  <br></br>
                  media partners, friends, family, and well-wishers for their unwavering love and support.
                  <br></br>
                  We pledge to put in even more effort and produce even better work.
                </p> 
                <p className="xl:text-xl text-white xl:pt-6 pt-3 leading-8 font1 text-justify">
                  We now sincerely thank all of our most beloved customers, vendors, brand <br></br>ambassadors,

                  media partners, friends, family, and well-wishers for their <br></br>unwavering love and support.

                  We pledge to put in even more effort and <br></br>produce even better work.
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className="font bg-neutral-50  py-6 lg:hidden block ">
        <div className="container relative space-y-24 mb-4 lg:space-y-2 lg:mb-12">
          <div className={`font nc-SectionGridCategoryBox relative`}>
            <div className="lg:font-semibold font-medium font lg:text-2xl text-base">
              Added Landing Packages
            </div>
            <div className={`grid grid-cols-2 lg:gap-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 md:grid-cols-3  xl:grid-cols-4 gap-5 xl:gap-3 lg:gap-2 sm:gap-2 md:gap-2 lg:mt-8 mt-4`}>
              {landingPages.map((item, i) => (
              <Link
              to={'/landing/' + (item as any).city.replaceAll(" ","-").toLowerCase()}
              className={`nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-lg [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
              data-nc-id="CardCategoryBox1"
              key={i}
              >
                <Badge
                  className="absolute right-2 top-2 lg:block hidden"
                  color="blue"
                  name={convertNumbThousand((item as any).count)}
                />
                <div className="relative flex-shrink-0 lg:w-20 lg:h-20 w-12 h-12 rounded-full overflow-hidden">
                  <NcImage src={'https://admin.trawelmart.com/admin/public/index.php/api/' + (item as any).banner_image} containerClassName="absolute inset-0" />
                </div>
                <div className=" ml-4 flex-grow overflow-hidden">
                  <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
                    <span className="font line-clamp-1">{(item as any).city}</span>
                  </h2>
                  <span
                    className={`block mt-1 lg:text-xs text-xs text-neutral-600 dark:text-neutral-400`}
                  >
                   {(item as any).count +' Tours'}
                  </span>
                </div>
              </Link>
              ))}
            </div>
          </div>
        </div>
      </div> */}

        {/* SECTION */}

        <div className="container relative space-y-2 mb-2 lg:space-y-2 lg:mb-0 xl:mt-24 lg:mt-16">
          <h2 className="lg:font-semibold font-medium font lg:text-2xl text-base pl-2 xl:pb-4 md:pb-5 pb-4 xl:text-left md:text-left text-base">
            Trending Tours
          </h2>
          <OwlCarousel {...options}>{items}</OwlCarousel>
          <div className="owl-carousel">{items}</div>
        </div>

        <div className="container relative space-y-2 mb-2 lg:space-y-2 lg:mb-0 xl:mt-1 lg:mt-16">
          <h2 className="lg:font-semibold font-medium font lg:text-2xl  text-base pl-2 xl:pb-4 md:pb-5 pb-4 xl:text-left md:text-left text-base">
            Popular Destinations
          </h2>
          <OwlCarousel {...options}>{items1}</OwlCarousel>
          <div className="owl-carousel">{items1}</div>
        </div>

        {/* ================= before Beach Section ===============*/}
        {/* <div className="container relative lg:mt-6 space-y-24 mb-4 lg:space-y-28 lg:mb-0 md:mt-6 md:mb-6">
          <div className="grid lg:grid-cols-2 lg:gap-0 md:grid-cols-2 md:gap-2 sm:grid-cols-2 sm:gap-2">
            <div className="">
              <SectionVideos />
            </div>
            <div className="justify-center items-center text-center">
              <div className="rounded-lg  py-6 mt-3">
                <div
                  className="font lg:block hidden"
                  style={{
                    backgroundImage: `url("https://i.ibb.co/xgvV2dt/bgcustomer.png")`,

                    height: "320px",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="customerfont font font-bold text-black xl:mt-10 lg:mt-3">
                    Why Our
                  </div>
                  <div className="customerfont mt-10 font font-bold text-sky-600">
                    Customers Love Us
                  </div>
                  <p className="lg:pt-8  text-2xl bannerfont font-medium">
                    Be inspired to experience travel with us!
                  </p>
                  <a
                    href="https://www.google.com/search?gs_ssp=eJzj4tFP1zesTM8pSU8psjBgtFI1qDBOSkw1NE02STJLNDFPTbW0MqhISU4ysTRJtDAyT01OMjIz9uIqKUosT83JTSwqAQCJ9hRD&q=trawelmart&oq=trawe&gs_lcrp=EgZjaHJvbWUqDwgBEC4YJxivARjHARiKBTINCAAQABjjAhiABBifBDIPCAEQLhgnGK8BGMcBGIoFMgYIAhBFGDkyBwgDEAAYgAQyEAgEEC4YrwEYxwEYsQMYgAQyBwgFEAAYgAQyDQgGEC4YrwEYxwEYgAQyBwgHEAAYgAQyCQgIEAAYChiABDIHCAkQABiABNIBDzEwOTE2ODcwOTFqMWoxNagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15c4b6a47ee9:0xdcb494a827ecb263,1"
                    target="_blank"
                  >
                    <button className="font gradient2 text-white lg:text-base px-12 py-3 mt-6 rounded-lg text-sm">
                      View More Testimonials{" "}
                      <i className="las la-arrow-right"></i>
                    </button>
                  </a>
                </div>

                <div
                  className="font block lg:hidden -mt-6"
                  style={{
                    backgroundImage: `url("https://i.ibb.co/xgvV2dt/bgcustomer.png")`,

                    height: "160px",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="text-3xl font font-bold text-black md:mt-4">
                    Why Our
                  </div>
                  <div className="text-2xl mt-1 font font-bold text-sky-600">
                    Customers Love Us
                  </div>
                  <p className="lg:pt-8  text-base bannerfont font-medium">
                    Be inspired to experience travel with us!
                  </p>
                  <a
                    href="https://www.google.com/search?gs_ssp=eJzj4tFP1zesTM8pSU8psjBgtFI1qDBOSkw1NE02STJLNDFPTbW0MqhISU4ysTRJtDAyT01OMjIz9uIqKUosT83JTSwqAQCJ9hRD&q=trawelmart&oq=trawe&gs_lcrp=EgZjaHJvbWUqDwgBEC4YJxivARjHARiKBTINCAAQABjjAhiABBifBDIPCAEQLhgnGK8BGMcBGIoFMgYIAhBFGDkyBwgDEAAYgAQyEAgEEC4YrwEYxwEYsQMYgAQyBwgFEAAYgAQyDQgGEC4YrwEYxwEYgAQyBwgHEAAYgAQyCQgIEAAYChiABDIHCAkQABiABNIBDzEwOTE2ODcwOTFqMWoxNagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15c4b6a47ee9:0xdcb494a827ecb263,1"
                    target="_blank"
                  >
                    <button className="font gradient2 text-white lg:text-base px-8 py-2 mt-4 rounded-lg text-sm">
                      View More Testimonials{" "}
                      <i className="las la-arrow-right"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* ================= Beach Section ===============*/}

        <div className="container relative space-y-2 mb-24 lg:space-y-0 lg:mb-16 ">
          <div className="flex lg:flex-row justify-between items-center">
            <div className="">
              <div className="lg:font-semibold font-medium font lg:text-2xl text-lg ">
                Travel Blog
              </div>
              <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 lg:block hidden">
                Suggestions Tailored To Your Interest
              </div>
              <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 block  lg:hidden">
                Suggestions Tailored To Your Interest
              </div>
            </div>
            <div className=" flex text-sky-600 lg:text-left text-right font lg:font-medium font-regular lg:text-sm text-xs">
              <a href="/blogs">
                See all stories
                <span className="pl-1">
                  <i className="las la-arrow-right"></i>
                </span>
              </a>
            </div>
          </div>

          <div className="lg:mt-10 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-2 xl:gap-3 lg:gap-1 md:gap-3 ">
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
          </div>
        </div>
      </div>
    </>
  );
}

export default PageHome;
