import React, { FC, useState } from "react";
import { StayDataType } from "data/types";


export interface FrequentlyMaldivesProps {
    className?: string;
    data?: StayDataType[];
    defaultOpen?: boolean;
}

const FrequentlyMaldives: FC<FrequentlyMaldivesProps> = ({
    className = "",
    defaultOpen = false,
}) => {

    const [isOpen, setIsOpen] = useState(defaultOpen);
    const [onOpen, setOnOpen] = useState(defaultOpen);
    const [onsOpen, setOnsOpen] = useState(defaultOpen);
    const [inOpen, setInOpen] = useState(defaultOpen);
    const [innOpen, setInnOpen] = useState(defaultOpen);
    const [itOpen, setItOpen] = useState(defaultOpen);


    const renderDetail = () => {
        if (!isOpen) return null;
        return (
            <div className="border-t mt-3">
                <div className="my-1 md:my-2 space-y-5 p-3 ">
                    <p className="text-sm text-neutral-500 pl-3">
                        Your per day expenses could average to about 4k per couple including stays
                        and travel, but booking packages usually helps reduce the cost.
                    </p>
                </div>
            </div>
        );
    };



    const renderDetail2 = () => {
        if (!onOpen) return null;
        return (
            <div className="border-t mt-3">
                <div className="my-1 md:my-2 space-y-5 p-3 ">
                    <p className="text-sm text-neutral-500 pl-3">
                        Maldives is actually quite affordable as there are budget-friendly stays and travel
                        options, and food there is pretty cheap too, depending on where you eat.
                    </p>
                </div>
            </div>
        );
    };

    const renderDetail3 = () => {
        if (!onsOpen) return null;
        return (
            <div className="border-t mt-3">
                <div className="my-1 md:my-2 space-y-5 p-3 ">
                    <p className="text-sm text-neutral-500 pl-3">
                        Maldives being an island is a haven for several watersports, from scuba diving to snorkelling,
                        Maldives offers all types of water activities.
                    </p>
                </div>
            </div>
        );
    };


    const renderDetail4 = () => {
        if (!inOpen) return null;
        return (
            <div className="border-t mt-3">
                <div className="my-1 md:my-2 space-y-5 p-3 ">
                    <p className="text-sm text-neutral-500 pl-3">
                        Maldives is kid-friendly and has several activities for all ages.
                    </p>
                </div>
            </div>
        );
    };


    const renderDetail5 = () => {
        if (!innOpen) return null;
        return (
            <div className="border-t mt-3">
                <div className="my-1 md:my-2 space-y-5 p-3 ">
                    <p className="text-sm text-neutral-500 pl-3">
                        Yes, it definitely works in your favour to book an all-inclusive package,
                        as all elements of your trip will be taken care of by us, and you can travel worry-free.
                    </p>
                </div>
            </div>
        );
    };


    const renderDetail6 = () => {
        if (!itOpen) return null;
        return (
            <div className="border-t mt-3">
                <div className="my-1 md:my-2 space-y-5 p-3 ">
                    <p className="text-sm text-neutral-500 pl-3">
                        Maldives is known for its stunning natural beauty with green mountains, sapphire blue seas,
                        and diamond white sands. Besides these, Maldives also has several picturesque lagoons, waterfalls,
                        coves and caves for people to explore.
                    </p>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`font nc-SectionGridFilterCard lg:px-24 ${className}`}
            data-nc-id="SectionGridFilterCard"  >
            <div className="space-y-2 ">
                <div className={` font shadow border rounded-lg  relative lg:pt-6 xl:mt-0 mt-6 lg:pb-4 pt-4 pb-4`}>
                    <div className="pl-6">
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-2 sm:mt-2 -mt-4  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${itOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setItOpen(!itOpen)} >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                        <div className="sm:flex-row sm:items-center items-center space-y-6 sm:space-y-0">
                            <div className="font-semibold text-base md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4 ">
                                What is Maldives famous for?
                            </div>
                        </div>
                    </div>
                    {renderDetail6()}
                </div>

                <div className={` font shadow border rounded-lg relative lg:pt-6 lg:pb-4 pt-4 pb-4`}>
                    <div className="pl-6">
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-2 sm:mt-6 -mt-3 xl:-mt-0  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${innOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setInnOpen(!innOpen)} >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                        <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-semibold text-base text-left md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4">
                                Is it worth it to book an all-inclusive Maldives package?
                            </div>
                        </div>
                    </div>
                    {renderDetail5()}
                </div>

                <div className={` font shadow border rounded-lg  relative lg:pt-6 lg:pb-4 pt-4 pb-4`}>
                    <div className="pl-6">
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-2 sm:mt-6 -mt-3 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${inOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setInOpen(!inOpen)} >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                        <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-semibold text-base md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4">
                                Can I travel with kids/babies to Maldives?
                            </div>
                        </div>
                    </div>
                    {renderDetail4()}
                </div>


                <div className={` font shadow border rounded-lg  relative lg:pt-6 lg:pb-4 pt-4 pb-4`}>
                    <div className="pl-6">
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-2 sm:mt-6 -mt-3 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${onsOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setOnsOpen(!onsOpen)} >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                        <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-semibold text-base md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4">
                                What is the best activity in Maldives?
                            </div>
                        </div>
                    </div>
                    {renderDetail3()}
                </div>

                  <div className={` font shadow border rounded-lg  relative lg:pt-6 lg:pb-4 pt-4 pb-4`}>
                    <div className="pl-6">
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-2 sm:mt-6 -mt-3 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${onOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setOnOpen(!onOpen)} >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                        <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-semibold text-base md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4">
                                 How affordable is a Maldives holiday?
                            </div>
                        </div>
                    </div>
                    {renderDetail2()}
                </div>



                <div className={` font shadow border rounded-lg  relative lg:pt-6 lg:pb-4 pt-4 pb-4`}>
                    <div className="pl-6">
                        <a href="##" className="absolute inset-0" />
                        <span
                            className={`absolute right-0 bottom-auto sm:bottom-auto items-center lg:mt-2 sm:mt-6 -mt-3 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
                                }`}
                            onClick={() => setIsOpen(!isOpen)} >
                            <i className="text-xl las la-angle-down"></i>
                        </span>
                        <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                            <div className="font-semibold text-base md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-2 md:pb-4 md:pt-4">
                              How much would my average per day expenses be in Maldives?
                            </div>
                        </div>
                    </div>
                    {renderDetail()}
                </div>
            </div>
        </div>


    );
};

export default FrequentlyMaldives;
