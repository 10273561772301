import Glide from "@glidejs/glide";
import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import { useEffect } from "react";
import clientSayMain from "images/clientSayMain.png";
import clientSay1 from "images/clientSay1.png";
import clientSay2 from "images/clientSay2.png";
import clientSay3 from "images/clientSay3.png";
import clientSay4 from "images/clientSay4.png";
import clientSay5 from "images/clientSay5.png";
import clientSay6 from "images/clientSay6.png";
import quotationImg from "images/quotation.png";
import quotationImg2 from "images/quotation2.png";
import useNcId from "hooks/useNcId";
import Kiran from "images/about/Kiran.jpg";
import KiranSetty1 from "images/about/KiranSetty1.png";
import SpoorthiViswas from "images/about/SpoorthiViswas.png";
import Sundhar from "images/about/Sundhar.png";
import quotation from "images/quotation.png";

export interface SectionClientSayProps {
  className?: string;
  uniqueClassName: string;
}

const DEMO_DATA = [
  {
    id: 1,
    clientName: "Tiana Abie",
    clientAddress: "Malaysia",
    content:
      "This place is exactly like the picture posted on Chisfis. Great service, we had a great stay!",
  },
  {
    id: 2,
    clientName: "Lennie Swiffan",
    clientAddress: "London",
    content:
      "This place is exactly like the picture posted on Chisfis. Great service, we had a great stay!",
  },
  {
    id: 3,
    clientName: "Berta Emili",
    clientAddress: "Tokyo",
    content:
      "This place is exactly like the picture posted on Chisfis. Great service, we had a great stay!",
  },
];

const SectionClientSay: FC<SectionClientSayProps> = ({
  className = "",
  uniqueClassName = "",
}) => {
  const UNIQUE_CLASS = `SectionClientSay_glide_${uniqueClassName}` + useNcId();

  useEffect(() => {
    if (document.querySelector(`.${UNIQUE_CLASS}`)) {
      setTimeout(() => {
        new Glide(`.${UNIQUE_CLASS}`, {
          perView: 1,
        }).mount();
      }, 10);
    }
  }, []);

  const renderBg = () => {
    return (
      <div className="hidden md:block">
        <img className="absolute top-9 -left-20" src={clientSay1} alt="" />
        <img
          className="absolute bottom-[100px] right-full mr-40"
          src={clientSay2}
          alt=""
        />
        <img
          className="absolute top-full left-[140px]"
          src={clientSay3}
          alt=""
        />
        <img
          className="absolute -bottom-10 right-[140px]"
          src={clientSay4}
          alt=""
        />
        <img
          className="absolute left-full ml-32 bottom-[80px]"
          src={clientSay5}
          alt=""
        />
        <img className="absolute -right-10 top-10 " src={clientSay6} alt="" />
      </div>
    );
  };

  return (
    <div className="nc-SectionClientSay relative bg-sky-100 xl:p-12 p-4">
      <div className="items-center flex flex-wrap space-y-14 lg:space-y-0 lg:space-x-0  relative text-center lg:text-left lg:pb-0">
          <div className="center justify-center xl:pt-12 xl:w-6/12 lg:w-6/12">
            <img className=" text-center  xl:w-10/12 lg:w-10/12 md:w-6/12 rounded-lg" src={Kiran} alt="" />
          </div>
          <div className="space-y-1 lg:space-y-0 xl:w-6/12 lg:w-6/12">
            <h3 className="text-left xl:pt-6 xl:pb-4 lg:pt-12 font font-medium xl:text-3xl lg:text-2xl text-2xl">
              Leadership from the heart
            </h3>
            <h4 className="xl:text-base text-base text-justify font-regular leading-7 font xl:pt-0">
              Mr. Meda S Kiran Setty the closest confident and trustworthy friend to Mr. K Mohan Sundar has
              been the most ideal partner of the thriving and acclaimed travel business.
            </h4>

            <p className="xl:text-base text-base text-justify font-regular leading-7 xl:pt-2 lg:pt-2">
              He believes that high
              expectations of himself and others is the key to success. Empowered with wisdom and expertise in
              The Travel field he has shared the responsibility of building the business from ground up to its
              incredible standing in the tourism industry today. &quot;We don’t just promise a smooth booking
              experience, we are with our customers from their decision to travel with us till they return home
              with fond memories. It has been our paramount effort to put customer first&quot; he says fondly.
            </p>
            <span className="text-left block text-base xl:text-2xl text-neutral-600 dark:text-neutral-400">
              <h6 className=" font-medium lg:pt-6 lg:text-3xl text-xl">
                Meda S. Kiran Setty
              </h6>
              <p className=" font-medium text-sky-600 text-base">
                Founder & Director
              </p>
            </span>
          </div>
        </div>
      {/*<Heading desc="Let's see what people think of Chisfis" isCenter>
        Good news from far away
      </Heading>
      <div className="relative md:mb-16 max-w-2xl mx-auto">
        {renderBg()}
        <img className="mx-auto" src={clientSayMain} alt="" />
        <div className={`mt-12 lg:mt-16 relative ${UNIQUE_CLASS}`}>
          <img
            className="opacity-50 md:opacity-100 absolute -mr-16 lg:mr-3 right-full top-1"
            src={quotationImg}
            alt=""
          />
          <img
            className="opacity-50 md:opacity-100 absolute -ml-16 lg:ml-3 left-full top-1"
            src={quotationImg2}
            alt=""
          />
          <div className="glide__track " data-glide-el="track">
            <ul className="glide__slides ">
              {DEMO_DATA.map((item) => (
                <li
                  key={item.id}
                  className="glide__slide flex flex-col items-center text-center"
                >
                  <span className="block text-2xl">{item.content}</span>
                  <span className="block mt-8 text-2xl font-semibold">
                    {item.clientName}
                  </span>
                  <div className="flex items-center space-x-2 text-lg mt-2 text-neutral-400">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                      />
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={1.5}
                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                    <span>{item.clientAddress}</span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="mt-10 glide__bullets flex items-center justify-center"
            data-glide-el="controls[nav]"
          >
            {DEMO_DATA.map((item, index) => (
              <button
                key={item.id}
                className="glide__bullet w-2 h-2 rounded-full bg-neutral-300 mx-1 focus:outline-none"
                data-glide-dir={`=${index}`}
              ></button>
            ))}
          </div>
        </div>
            </div>*/}


      {/*<div className="container xl:py-12">
        <div className="items-center grid grid-cols-2 gap-24 space-y-4 lg:space-y-0 lg:space-x-0  relative lg:text-left lg:pb-4">
          <div className="space-y-1 lg:space-y-0 ">
            <h3 className="text-left xl:pt-0 xl:pb-2 pt-32 font font-medium xl:text-5xl lg:text-2xl text-2xl text-white">
              Meda S. Kiran Setty
            </h3>
            <h3 className="text-left xl:pt-0 xl:pb-2 lg:pt-2 font font-medium xl:text-xl lg:text-2xl text-2xl text-charcoal-800">
              Founder & Director
            </h3>
            <p className="text-left xl:text-lg text-base font-regular leading-8 xl:pt-0 lg:pt-2 text-justify text-white">
              Mr. Meda S Kiran Setty the closest confident and trustworthy friend to Mr. K Mohan Sundar has been the most ideal partner of the thriving and acclaimed travel business.
            </p>
            <h4 className="text-left xl:text-lg text-base font-regular leading-8 font text-justify text-white pt-2">
              He believes that high expectations of himself and others is the key to success. Empowered with wisdom and
              expertise in The Travel field he has shared the responsibility of building the business from ground up to its
              incredible standing in the tourism industry today. "We don’t just promise a smooth booking experience, we are
              with our customers from their decision to travel with us till they return home with fond memories. It has
              been our paramount effort to put customer first" he says fondly.
            </h4>
          </div>

          <div className="">

            <img className="text-center xl:w-11/12 rounded-lg" src={KiranSetty1} alt="" />
          </div>
        </div>
          </div>*/}








      {/*<div className="nc-SectionFounder relative pt-12 lg:pt-0  bg-sky-100 xl:p-12 p-4 rounded-lg"
      >
        <div className="items-center flex flex-wrap space-y-14 lg:space-y-0 lg:space-x-0  relative text-center lg:text-left lg:pb-0">
          <div className="center justify-center xl:pt-12 xl:w-6/12 lg:w-6/12">
            <img className=" text-center  xl:w-10/12 lg:w-10/12 md:w-6/12 rounded-lg" src={KiranSetty1} alt="" />
          </div>
          <div className="space-y-1 lg:space-y-0 xl:w-6/12 lg:w-6/12">
            <h3 className="text-left xl:pt-6 xl:pb-4 lg:pt-12 font font-medium xl:text-3xl lg:text-2xl text-2xl">
              Leadership from the heart
            </h3>
            <h4 className="xl:text-base text-base text-justify font-regular leading-7 font xl:pt-0">
              Mr. Meda S Kiran Setty the closest confident and trustworthy friend to Mr. K Mohan Sundar has
              been the most ideal partner of the thriving and acclaimed travel business.
            </h4>

            <p className="xl:text-base text-base text-justify font-regular leading-7 xl:pt-2 lg:pt-2">
              He believes that high
              expectations of himself and others is the key to success. Empowered with wisdom and expertise in
              The Travel field he has shared the responsibility of building the business from ground up to its
              incredible standing in the tourism industry today. &quot;We don’t just promise a smooth booking
              experience, we are with our customers from their decision to travel with us till they return home
              with fond memories. It has been our paramount effort to put customer first&quot; he says fondly.
            </p>
            <span className="text-left block text-base xl:text-2xl text-neutral-600 dark:text-neutral-400">
              <h6 className=" font-medium lg:pt-6 lg:text-3xl text-xl">
                Meda S. Kiran Setty
              </h6>
              <p className=" font-medium text-sky-600 text-base">
                Founder & Director
              </p>
            </span>
          </div>
        </div>

      </div>*/}



      {/*<div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-0 items-center relative text-center lg:text-left lg:pb-32">
        <div className="items-center justify-center w-7/12">
          <img className="w-10/12 text-center" src={KiranSetty} alt="" />
        </div>
        <div className="w-screen max-w-full xl:max-w-2xl space-y-5 lg:space-y-2">
          <h4 className="text-3xl !leading-tight font-normal text-neutral-900 md:text-4xl xl:text-4xl dark:text-neutral-100 lg:pt-32">
            Craftsmanship, as good as it gets
          </h4>
          <p className="text-base leading-7 pt-3">
            Trawel Mart has been operating on the core principle of value-added
            service to the customers with the utmost ethical standards. It is the paramount
            endeavour and mission of this elite company to offer nothing but the foremost and finest service to its worthy
            customers. Trawel Mart is invested in clients’ happiness and satisfaction. With our signature of excellence
            choosing us is a more rewarding way to travel.
          </p>
          <span className="block text-base xl:text-2xl text-neutral-6000 dark:text-neutral-400">
            <h6 className="text-black font-medium lg:pt-8">
              MEDA S KIRAN SETTY
            </h6>
            <p className="text-blue-800 text-sm">
              Founder & Director
            </p>
          </span>

        </div>

      </div>*/}


      {/*<div className="flex flex-col lg:pt-6 lg:flex-row space-y-14 lg:space-y-0 lg:space-x-0 items-center relative text-center lg:text-left lg:pb-32">
        <div className="items-center justify-center w-7/12">
          <img className="w-10/12 text-center" src={SpoorthiViswas} alt="" />
        </div>
        <div className="w-screen max-w-full xl:max-w-2xl space-y-5 lg:space-y-2">
          <h4 className="text-3xl !leading-tight font-normal text-neutral-900 md:text-4xl xl:text-4xl dark:text-neutral-100 lg:pt-32">
            Craftsmanship, as good as it gets
          </h4>
          <p className="text-base leading-7 pt-3">
            Trawel Mart has been operating on the core principle of value-added
            service to the customers with the utmost ethical standards. It is the paramount
            endeavour and mission of this elite company to offer nothing but the foremost and finest service to its worthy
            customers. Trawel Mart is invested in clients’ happiness and satisfaction. With our signature of excellence
            choosing us is a more rewarding way to travel.
          </p>
          <span className="block text-base xl:text-2xl text-neutral-6000 dark:text-neutral-400">
            <h6 className="text-black font-medium lg:pt-8">
              SPOORTHI VISHWAS
            </h6>
            <p className="text-blue-800 text-sm">
              Chief Marketing Officer
            </p>
          </span>

        </div>

    </div>*/}
    </div>
  );
};

export default SectionClientSay;
