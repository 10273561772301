
import React, { FC, useEffect } from "react";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import $ from 'jquery';
import payment from "images/logos/debitcard.png";
import './preloaderStyle.css';
import Button from "shared/Button/Button";

export interface CheckoutSuccessProps {
  className?: string;
  details?: object;
  transactionDate?: string;
  navigateToProfile?: () => void; 
}

const CheckoutSuccess: FC<CheckoutSuccessProps> = ({ className = "", details, transactionDate, navigateToProfile }) => {

  return (
    <div
      className={`font nc-PageAbout overflow-hidden relative font xl:pt-24 xl:pb-32 lg:pt-24 lg:pb-32 md:pt-24 md:pb-24 pt-16 pb-32 ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>Travel Mart</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="font flex content-center items-center justify-center space-y-0  lg:space-y-0">
        <div className="w-12/12">
          <div className=" center">
            <img className="w-64 text-center center" src={payment} alt="" />
          </div>
          <div className="text-center fonts xl:text-2xl  xl:pt-5 lg:text-4xl md:text-2xl text-xl lg:pt-4 md:pt-3 pt-3 xl:px-0 lg:px-0  md:px-0 px-4">
            Thank you for your new booking
          </div>
          <div className=" content-center text-xl font text-center xl:pt-2 xl:px-0 lg:px-0  md:px-0 px-4 lg:pt-2 md:pt-2 pt-2">
            Please find your payment details below
          </div>
          <br/>
          <table className="details-table">
            <tbody>
            <tr>
              <td>
                <b>Order ID</b><br/>{(details as any).order_id}
              </td>
              <td>
                <b>Transaction Date</b><br/>{transactionDate}
              </td>
            </tr>
              <tr>
                <td><b>Amount</b><br/>{(details as any).amount} INR</td>
              </tr>
              <tr>
                <td><b>Billing Address</b><br/>{(details as any).billing_name}<br/>{(details as any).billing_address}
                <br/>{(details as any).billing_city}<br/>{(details as any).billing_state}<br/>{(details as any).billing_country}</td>
              </tr>
              <tr>
              <td>
                <b>Mobile</b><br/>{(details as any).billing_tel}
              </td>
              <td>
                <b>Email</b><br/>{(details as any).billing_email}
              </td>
            </tr>
            </tbody>
          </table>
          <br/>
          <div className="text-center">
            <button className="px-8 py-3 rounded-full bg-sky-600 text-white text-sm" type="button" onClick={navigateToProfile}>Back to Profile</button>
          </div>
        </div>

      </div>
    </div>
  );
};

export default CheckoutSuccess;
