import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";

// const DEMO_CATS: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "New Yourk",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/64271/queen-of-liberty-statue-of-liberty-new-york-liberty-statue-64271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Singapore",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7740160/pexels-photo-7740160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Paris",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/739407/pexels-photo-739407.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "London",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/460672/pexels-photo-460672.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Tokyo",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/4151484/pexels-photo-4151484.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "2",
//     href: "/listing-stay",
//     name: "Maldives",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/3250613/pexels-photo-3250613.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
// ];

// const DEMO_CATS_2: TaxonomyType[] = [
//   {
//     id: "1",
//     href: "/listing-stay",
//     name: "Enjoy the great cold",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
//   },
//   {
//     id: "222",
//     href: "/listing-stay",
//     name: "Sleep in a floating way",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "3",
//     href: "/listing-stay",
//     name: "In the billionaire's house",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "4",
//     href: "/listing-stay",
//     name: "Cool in the deep forest",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
//   {
//     id: "5",
//     href: "/listing-stay",
//     name: "In the billionaire's house",
//     taxonomy: "category",
//     count: 188288,
//     thumbnail:
//       "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
//   },
// ];

function Cancellations() {
    return (
        <>
            <section className="font static-pages">
                <div className="static-header">
                    <h1 className="bg-sky-600 text-center text-white xl:h-52 lg:h-52 md:h-52 h-32 font-semibold xl:text-4xl lg:text-3xl md:text-2xl text-3xl font flex items-center justify-center">Cancellation Policy</h1>
                </div>
            </section>

            <section className="font container">
                <div className="xl:m-10 lg:my-12 md:my-6 my-12 border border-blueGray-600 xl:px-16 lg:px-12 md:px-6 px-4 text-justify py-10 rounded-xl">


                    <h1 className="text-xl font-medium font mt-6 ">General</h1>
                    <div className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        The cancellation policy is effective for all vacations crafted by TrawelMart from 01 September 2019.
                        TrawelMart customers eligible for refunds will receive the refund amount within 90 working days from
                        the date of cancellation or when the supplier(s) processes the refund, whichever is later. For
                        refunds related to on-trip cancellations, customers will receive the refund amount within 90 working
                        days from the date of their return or when the supplier(s) processes the refund, whichever is later.
                        For queries/clarifications, please reach out to help@trawelmart.com.
                    </div>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        The Refund amount depicted is subjected to change based on international exchange rates,
                        refunds received from suppliers and payments received from customers till date. Any change
                        in refund amount will be communicated to customers by their respective account owners.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Flights
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        On cancelling flights marked as “Non-Refundable” on the final travel vouchers, customers
                        will be eligible for a zero refund.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        For Flights marked as “Refundable” on the final travel vouchers, customers will receive a
                        refund as per the details mentioned under the “Cancellation Policy” section of the product and
                        also in the final itinerary shared over the email.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        The total refunds for flights may include components which vary as per the
                        international exchange rates. TrawelMart will not be responsible for grounded/cancelled/delayed
                        flights. Any cancellation requests for these flights will have to be placed with the respective
                        airlines. Realization of refunds would be subject to processing by the respective airline carrier.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        The onus is on the customer to ensure that his/her passport has a minimum of 1-year validity
                        and is in good condition. TrawelMart is not liable to refund a customer who is not allowed to
                        board the flight because of invalid passports (validity expired, damaged passports).
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Customers are expected to reach the airport ahead of their boarding time
                        (at least 2 hours prior to boarding time). TrawelMart is not responsible to refund
                        customers (for cases wherein airport transfers are not planned by us) who miss their
                        flights owing to delayed arrival at the airport.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        For cases wherein airport transfers are planned by TrawelMart, flight cancellations due to
                        delayed transfers owing to unforeseen circumstances specific to a region will not be borne by TrawelMart.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Details about baggage limitations (cabin and check-in) will be furnished as part of the
                        final travel vouchers. Additional costs owing to breached baggage limits will have to be
                        paid by the customer at the time of check-in.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Certain flight carriers have a mandatory web check-in policy. Failure to comply with this
                        could result in an additional cost to be paid at the airport. TrawelMart is not liable to
                        refund customers in such circumstances.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        TrawelMart will set meal preferences for customers with airline carriers upon request.
                        However, TrawelMart has no control over the availability and quality of meals served on
                        the flight. This will be controlled completely by the airline carrier.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Hotels
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        On cancelling hotels which have been marked as “Non-Refundable” on the final travel
                        vouchers, the customer will be eligible for a zero refund.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        For hotels which have been marked as “Refundable” on the final travel vouchers, refunds and their
                        timelines will be applicable as mentioned under the “Cancellation Policy” section of the product and
                        in the final itinerary shared over email. The total refunds for hotels may include components which
                        vary with international exchange rates.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        While TrawelMart strives to provide the best hotels with world-class amenities, we
                        cannot be held responsible for factors such as hotel staff behaviour, cleanliness and
                        quality of accommodation. Additional costs owing to on-trip room upgrades and additional
                        amenities will be borne by the customer. All hotels changed on-trip (Hotels booked per
                        itinerary cancelled and new hotels booked) will entail a 100% cancellation fee.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Entertaining early check-in or late check-out requests is solely based on the discretion
                        of the hotel. TrawelMart will not be able to process cancellation requests owing to non-availability
                        of these requests.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Activities
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        On cancelling activities marked as “Non-Refundable” on the final travel
                        vouchers, the customer will be eligible for a zero refund.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        For activities, which have been marked as “Refundable” on the final travel vouchers, refunds and their
                        timelines will be applicable as mentioned under the “Cancellation Policy” section of the product
                        and in the final itinerary shared over email. The total refund for activities may include components
                        which vary with international exchange rates.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Transfers
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        For all transfers, refunds and their timelines will be applicable as mentioned under the
                        “Cancellation Policy” section of the product and in the final itinerary shared over email.
                        The total refunds for transfers may include components which vary with international exchange rates.
                    </p>
                    <h1 className="text-xl font-medium font mt-6">
                        Visa & Insurance
                    </h1>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        TrawelMart acts as a facilitator for processing Visa applications. We will guide customers
                        on Visa formalities & Visa documentation for specific destinations. The discretion to
                        grant/reject Visa rests solely with the concerned embassy and TrawelMart will not be responsible
                        for rejection of any applications. The visa fee is non-refundable in case of rejected visa applications.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        While we strive to provide a seamless Visa experience to the customers, TrawelMart will not
                        be held responsible for unforeseen changes to Visa formalities levied by the embassy during
                        the document submission and processing phase.
                    </p>
                    <p className="mt-2 font xl:text-base lg:text-base md:text-base text-sm">
                        Insurance once applied is subject to 100% cancellation fee and is non-refundable.
                    </p>




                </div>
            </section>


        </>

    );
}

export default Cancellations;
