import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostTypeFeaturedIcon from "components/PostTypeFeaturedIcon/PostTypeFeaturedIcon";

export interface Card3Props {
  className?: string;
  post: PostDataType;
}

const Card3: FC<Card3Props> = ({ className = "h-full", post }) => {
  const { title, href, featuredImage, desc, categories,viewdCount, date,  postType } = post;

  return (
    <Link
      className={`border-t pt-4 nc-Card3 relative flex flex-col-reverse sm:flex-row sm:items-center p-4  group ${className}`}
      data-nc-id="Card3" to={href}
    >
      <div className="flex flex-col flex-grow">
        <div className="space-y-5 mb-4">
          {/* <CategoryBadgeList categories={categories} /> */}
          <div className="flex items-center">
              <div className="transition-colors hover:text-white duration-300 nc-Badge inline-flex px-2.5 py-1 mt-6 rounded-full font-medium text-xs relative text-sky-800 bg-sky-100 hover:bg-green-800">
                {date}
              </div>
            </div>
          <div>
            <h2
              className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100 text-xl`}
            >
              <div  className="font1 line-clamp-2" title={title}>
                {title}
              </div>
            </h2>
            <div className="flex items-center">
            <div className=" pt-2">
              <i className="las la-eye font1 text-sm"></i><span className="text-xs pl-1">{viewdCount}</span>
              </div>
              <div className="pl-6 pt-2">
              <i className="las la-clock font1 font-medium text-sm"></i><span className="text-xs pl-1"> 5 mins. read</span>
              </div>
            </div>
            <div className="hidden sm:block sm:mt-2">
              <span className="font1 text-neutral-600 dark:text-neutral-400 text-base line-clamp-3">
                {desc}  
              </span>
            </div>
            
          </div>

          {/* <PostCardMeta meta={{ ...post }} /> */}
        </div>
      </div>

      <div
        className={`block flex-shrink-0 sm:w-56 sm:ml-6 rounded-3xl overflow-hidden mb-5 sm:mb-0`}
      >
        <Link
          to={href}
          className={`block w-full h-0 aspect-h-9 sm:aspect-h-12 aspect-w-16 `}
        >
          <NcImage
            containerClassName="absolute inset-0"
            src={featuredImage}
            alt={title}
          />
          <span>
            <PostTypeFeaturedIcon
              className="absolute left-2 bottom-2"
              postType={postType}
              wrapSize="w-8 h-8"
              iconSize="w-4 h-4"
            />
          </span>
        </Link>
      </div>
    </Link>
  );
};

export default Card3;
