import Logo from "shared/Logo/Logo";
import SocialsList from "shared/SocialsList/SocialsList";
import { CustomLink } from "data/types";
import React, { useEffect, useState } from "react";
import GooglePlay from "images/articles/GooglePlay.png";
import Apple from "images/articles/Apple.png";
import AppGallery from "images/articles/AppGallery.png";
import wave from "images/sample/wave.png";
import wave2 from "images/sample/wave2.png";
import Input from "shared/Input/Input";
import air1 from "images/ICONS/air1.png";
import air2 from "images/ICONS/air2.png";
import air3 from "images/ICONS/air3.png";
import footerbackground from "images/ICONS/footerbackground.png";
import FooterNav from "components/FooterNav";
import AccordionFooter from "shared/Footer/AccordionFooter";
import 'react-loading-skeleton/dist/skeleton.css'
import Skeleton from "react-loading-skeleton";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Company",
    menus: [
      { href: "/about-us", label: "About Us" },
      { href: "/privacy-policy", label: "Privacy Policy" },
      { href: "/terms-and-conditions", label: "Terms & Conditions" },
      { href: "/cancellations", label: "Cancellation Policy" },
      { href: "#", label: "Careers" },
      { href: "#", label: "Become A Sales Partner" },
      { href: "#", label: "Podcast" },
      { href: "#", label: "Video Blogs" },
    ],
  },
  {
    id: "1",
    title: "Support",
    menus: [
      { href: "#", label: "Contact Us" },
      { href: "#", label: "Travel Agents" },
      { href: "#", label: "How to Book" },
      { href: "#", label: "FAQ" },
      { href: "#", label: "Leave Your Feedback" },
      { href: "#", label: "Travel Guidelines 2023" },
      { href: "#", label: "Customized Travel Planner" },
      { href: "#", label: "COVID-19 Public Notice" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Guest Reviews" },
      { href: "#", label: "Blog" },
      { href: "#", label: "Travel Planner" },
      { href: "#", label: "Articles By Trawel Mart" },
      { href: "#", label: "Travel Deals" },
      { href: "#", label: "Tour Status" },
      { href: "#", label: "Annual Return" },
      { href: "#", label: "CSR Policy" },
    ],
  },
];

const accordionData = [
  {
    title: 'Company',
    content: [
      <ul key="list" >
        <li className="text-xs font py-2">
          <a href="/about-us">
            About us
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/contact">
            Contact us
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/blogs">
            Blogs
          </a>
        </li>

        {/*<li className="text-xs font py-2">
          <a href="/visa-home">
            Visa
          </a>
    </li>*/}
        <li className="text-xs font py-2">
          <a href="https://www.google.com/search?gs_ssp=eJzj4tFP1zesTM8pSU8psjBgtFI1qDBOSkw1NE02STJLNDFPTbW0MqhISU4ysTRJtDAyT01OMjIz9uIqKUosT83JTSwqAQCJ9hRD&q=trawelmart&oq=trawe&gs_lcrp=EgZjaHJvbWUqDwgBEC4YJxivARjHARiKBTINCAAQABjjAhiABBifBDIPCAEQLhgnGK8BGMcBGIoFMgYIAhBFGDkyBwgDEAAYgAQyEAgEEC4YrwEYxwEYsQMYgAQyBwgFEAAYgAQyDQgGEC4YrwEYxwEYgAQyBwgHEAAYgAQyCQgIEAAYChiABDIHCAkQABiABNIBDzEwOTE2ODcwOTFqMWoxNagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15c4b6a47ee9:0xdcb494a827ecb263,1"
            target="_blank">
            Testimonials
          </a>
        </li>

      </ul>,
    ],
  },
  {
    title: 'Plan Your Trip',
    content: [
      <ul key="list" >
        <li className="text-xs font py-2">
          <a href="/bali-tour-packages">
            Bali Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/malaysia-tour-packages">
            Malaysia Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/vietnam-tour-packages">
            Vietnam Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/greece-tour-packages">
            Greece Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/morocco-tour-packages">
            Morocco Tour Package
          </a>
        </li>

        <li className="text-xs font py-2">
          <a href="/dubai-tour-packages">
            Dubai Tour Package
          </a>
        </li>
        {/*<li className="text-xs font py-2">
          <a href="/singapore-tour-packages">
            Singapore Tour Packages
          </a>
    </li>*/}
        <li className="text-xs font py-2">
          <a href="/cambodia-tour-packages">
            Cambodia Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/srilanka-tour-packages">
            Sri Lanka Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/philippines-tour-packages">
            Philippines Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/nepal-tour-packages">
            Nepal Tour Package
          </a>
        </li>

        <li className="text-xs font py-2">
          <a href="/thailand-tour-packages">
            Thailand Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/singapore-tour-packages">
            Singapore Tour Packages
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/australia-tour-packages">
            Australia Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/newzealand-tour-packages">
            New Zealand Tour Package
          </a>
        </li>
        <li className="text-xs font py-2">
          <a href="/kenya-tour-packages">
            Kenya Tour Package
          </a>
        </li>

      </ul>,
    ],
  },


]

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    },2000);
  },[]);

  return (
    <div className={`font nc-Footer relative pt-6 lg:pt-0 ${!isLoading ? 'bg-sky-600' : ''}`}>

      {/*<div className="container lg:py-10 bg-neutral-50">
        <div className="text-orange-700 font-medium text-base font">
          INTERANTIONAL TOUR PACKAGES <span className="text-neutral-500 text-base font-normal">
            Thailand Tour Packages | Sri Lanka Tour Packages | Turkey Tour Packages | Russia Tour Packages | Dubai Tour Packages | Bali Tour Packages | Singapore Tour Packages | Malaysia Tour Packages | Vietnam Tour Packages | Cambodia Tour Packages | Nepal Tour Packages | Bhutan Tour Packages | Philippines Tour Packages | Egypt Tour Packages | Australia Tour Packages | Morocco Tour Packages | Greece Tour Packages | Iceland Tour Packages
          </span>
        </div>
      </div>
      <div className="font nc-Footer  bg-neutral-50 relative border-t border-neutral-200 dark:border-neutral-700">
        <div className="container lg:py-10">
          <div className="text-orange-700 font-medium text-base font">
            MULTI DESTINATION TOUR PACKAGES <span className="text-neutral-500 text-base font-normal">
              Cambodia & Thailand Tour Packages | Cambodia & Vietnam Tour Packages | Malaysia & Singapore Tour Packages | Malaysia, Singapore & Thailand Tour Packages | Bali, Malaysia, Singapore & Thailand Tour Packages
            </span>
          </div>
        </div>
  </div>*/}

      {/*========= FOOTERONE ====== 
      <img src={wave} alt="" className="w-full h-24" />
      <div className="container footers text-white font-normal text-sm">
        <div className="flex lg:py-6 items-center">
          <div className="lg:w-7/12 lg:pl-12">
            <div className="flex pt-2">
              <img src={Apple} alt="" className="w-32 h-8" />
              <img src={AppGallery} alt="" className="w-32 h-8 px-1" />
              <img src={GooglePlay} alt="" className="w-32 h-8" />
            </div>
          </div>
          <div className="lg:w-5/12 flex ">
            <div className="font text-white text-sm font-normal px-4">
              Cookie Policy
            </div>
            <div className="font border-l text-white text-sm font-normal px-4">
              Sitemap
            </div>
            <div className="font border-l text-white text-sm font-normal px-4">
              Legal
            </div>
            <div className="font border-l text-white text-sm font-normal px-4">
              Cookie Consent
            </div>
            <div className="font border-l text-white text-sm font-normal px-4">
              Accessibility
            </div>
          </div>
        </div>
      </div>
      <div className=" container footers border-t border-blue-50 ">
        <div className="flex lg:flex-row flex-col lg:py-16">
          <div className="lg:w-8/12 flex">
            <div className="lg:w-3/12">
              <div className="pl-12">
                <h4 className="text-white font-semibold text-base pb-2">
                  Company
                </h4>
                <div className="ftDropDown">
                </div>
                <p className="text-sm text-white font-normal pt-4">
                  Blog
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  About us
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Testimonials
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Join Our Team
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Meet Our Team
                </p>
              </div>
            </div>
            <div className="lg:w-3/12">
              <div className="pl-12">
                <h4 className="text-white font-semibold text-base pb-2">
                  Help
                </h4>
                <div className="ftDropDown">
                </div>
                <p className="text-sm text-white font-normal pt-4">
                  FAQ
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Careers
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Return Policy
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Terms & Conditions
                </p>
                <p className="text-sm text-white font-normal pt-2">
                  Cancellation Policy
                </p>
              </div>
            </div>
            <div className="lg:w-3/12">
              <div className="pl-12">
                <h4 className="text-white font-semibold text-base pb-2">
                  Book
                </h4>
                <div className="ftDropDown">
                </div>
                <p className="text-sm text-white font-normal pt-4">
                  Hotels<span><i className="las la-external-link-alt pl-2"></i></span>
                </p>
                <p className="text-sm text-white font-normal pt-4">
                  Flights<span><i className="las la-external-link-alt pl-2"></i></span>
                </p>
                <p className="text-sm text-white font-normal pt-4">
                  Tour Packages<span><i className="las la-external-link-alt pl-2"></i></span>
                </p>
              </div>
            </div>
            <div className="">
              <h4 className="text-white font-semibold text-base pb-2">
                Quick Menu
              </h4>
              <div className="ftDropDown">
              </div>
              <p className="text-sm text-white font-normal pt-4">
                Cruises
              </p>
              <p className="text-sm text-white font-normal pt-2">
                Contact Us
              </p>
              <p className="text-sm text-white font-normal pt-2">
                Privacy Policy
              </p>
              <p className="text-sm text-white font-normal pt-2">
                International Tour Packages
              </p>
              <p className="text-sm text-white font-normal pt-2">
                Multi Destination Tour Packages
              </p>
            </div>
          </div>
          <div className="lg:w-4/12 text-right">
            <Logo className="lg:w-6/12" />
            <SocialsList
              className="w-full !space-x-2 pt-2 lg:pl-44 lg:pt-4 text-right"
              itemClass="flex items-center justify-center w-10 h-10 rounded-lg bg-white text-black font-normal dark:bg-neutral-800 text-2xl"
            />
          </div>
        </div>
      </div>
      <div className=" container footers border-t border-blue-50 ">
        <div className="flex lg:flex-row flex-col py-6 lg:pl-12 items-center">
          <div className="lg:w-4/12">
            <div className="text-white text-xl">
              Subcribe to our newsletter
            </div>
            <p className="font-normal text-neutral-100 text-sm pt-1">
              to receive updates on deals
            </p>
            <form className="flex lg:pt-4">
              <Input className="bg-white rounded-md lg:w-7/12" placeholder="Email Address" required />
              <button className="btnbg rounded-md lg:w-4/12 text-white lg:ml-2">
                Sign Up
              </button>
            </form>
          </div>
          <div className="lg:w-4/12  lg:pl-6">
            <div className="text-white text-sm">
              We want to hear from you
              Improving our customers' experiences whether at the airport, in the air or online <br></br>is our primary focus.
            </div>
          </div>
          <div className="lg:w-4/12 text-right lg:pl-4">
            <div className="flex lg:pt-4">
              <button className="border bprder-white rounded lg:w-5/12 text-white py-2 lg:ml-2">
                Contact Us
              </button>
              <button className="btnbg rounded lg:w-6/12 text-white py-2 lg:ml-2">
                Share Feedback <span><i className="las la-external-link-alt pl-2"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-black  py-4 text-center  text-white font font-normal text-sm">
        Copyright © 2024 Trawelmart. All Right Reserved.
      </div>*/}



      {/* =========== FOOTER TWO ========== 

      <img src={wave2} alt="" className="w-full h-24 lg:-mt-16" />*/}

      <div className="container font">
        {isLoading ? <Skeleton count={2} height={20}/> : 
        <>
          <div className="app block lg:hidden">
            {accordionData.map((item, index) => (
              <AccordionFooter key={index} title={item.title} content={item.content} />
            ))}
          </div>
        </>
        }
        {isLoading ? <Skeleton height={40} className="mt-2"/> : 
        <>
          <div className="w-full flex items-center lg:pb-16 lg:pt-6 pt-0 xl:pl-12 block lg:hidden">
            <div className="lg:w-9/12 w-full flex flex-wrap items-center">
              <div className="lg:w-3/12 w-4/12 md:w-4/12 flex items-center">
                <img src={air1} alt="" className="lg:w-16 lg:h-16 w-12 h-12 md:w-16 md:h-16" />
                <div className="text-white font-medium lg:pl-4 md:pl-4 lg:text-3xl md:text-3xl text-base">
                  1000+
                  <div className="text-white  font-normal lg:text-xl md:text-xl text-sm">
                    Partners
                  </div>
                </div>
              </div>
              <div className="lg:w-3/12 w-4/12 md:w-4/12 flex items-center">
                <img src={air2} alt="" className="lg:w-16 lg:h-16 md:w-16 md:h-16 w-12 h-12 pt-1" />
                <div className="text-white font-medium lg:pl-4 md:pl-4 lg:text-3xl md:text-3xl text-base">
                  100+
                  <div className="text-white  font-normal lg:text-xl md:text-xl text-sm">
                    Packages
                  </div>
                </div>
              </div>
              <div className="lg:w-3/12 w-4/12 md:w-4/12 flex items-center lg:ml-6 justify-center lg:pt-0 pt-6 lg:pb-0 pb-6">
                <img src={air3} alt="" className="lg:w-16 lg:h-16 md:w-16 md:h-16 w-10 h-10" />
                <div className="text-white font-medium lg:pl-4 md:pl-4 lg:text-3xl md:text-3xl text-base">
                  2 Lakhs
                  <div className="text-white  font-normal lg:text-xl md:text-xl text-sm">
                    Customers
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        }
        {isLoading ?
        <>
          <Skeleton height={14} className="mt-3" /> 
          <Skeleton height={12} className="mt-2" /> 
        </> 
        : <>
          <div className="border-t border-blue-50 lg:pt-6 pt-4 block lg:hidden" />
          <div className="flex lg:flex-row flex-col justify-between lg:pb-6 pb-4 block lg:hidden">
            <div className="lg:w-9/12 w-full lg:text-left text-center">
              <span className="text-white text-xs lg:px-4">
                <a href="/terms-and-conditions">
                  Terms & Conditions
                </a>
              </span>
              {/*<span className="text-white text-xs lg:px-4 px-2">
                <a href="/terms-and-conditions">
                  Refund Policy
                </a>
              </span>*/}
              <span className="text-white text-xs lg:px-4 px-2">
                <a href="/privacy-policy">
                  Privacy
                </a>
              </span>
              <span className="text-white text-xs lg:px-4 px-2">
                <a href="/cancellations">
                  Cancellation
                </a>
              </span>
            </div>
            <div className="lg:w-6/12 lg:text-right text-center lg:pt-0 pt-3">
              <span className="text-white text-sm text-right">
                Copyright © 2024 Trawelmart. All Right Reserved.
              </span>
            </div>
          </div>
        </>}
      </div>



      {/* Desktop view */}

      <div className="font container  text-white font-normal text-sm lg:block hidden">
        <div className="w-full flex flex-wrap lg:py-12">
          <div className="lg:w-10/12 w-full sm:w-full">
            {isLoading ? 
            <>
              <Skeleton count={4}/>
            </>
            :
            <>
              <div className="flex flex-wrap">
                <div className="lg:w-7/12 w-12/12 flex">
                  <div className="lg:w-4/12">
                    <div className="lg:pl-0">
                      <h4 className="text-white font-semibold text-base pb-2">
                        Company
                      </h4>
                      <div className="ftDropDown">
                      </div>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/about-us">
                          About Us
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/contact">
                          Contact Us
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/blogs">
                          Blogs
                        </a>
                      </p>

                      {/*<p className="text-sm text-white font-normal pt-2">
                        <a href="/visa-home">
                          Visa
                        </a>
            </p>*/}


                      <p className="text-sm text-white font-normal pt-2">
                        <a href="https://www.google.com/search?gs_ssp=eJzj4tFP1zesTM8pSU8psjBgtFI1qDBOSkw1NE02STJLNDFPTbW0MqhISU4ysTRJtDAyT01OMjIz9uIqKUosT83JTSwqAQCJ9hRD&q=trawelmart&oq=trawe&gs_lcrp=EgZjaHJvbWUqDwgBEC4YJxivARjHARiKBTINCAAQABjjAhiABBifBDIPCAEQLhgnGK8BGMcBGIoFMgYIAhBFGDkyBwgDEAAYgAQyEAgEEC4YrwEYxwEYsQMYgAQyBwgFEAAYgAQyDQgGEC4YrwEYxwEYgAQyBwgHEAAYgAQyCQgIEAAYChiABDIHCAkQABiABNIBDzEwOTE2ODcwOTFqMWoxNagCALACAA&sourceid=chrome&ie=UTF-8#lrd=0x3bae15c4b6a47ee9:0xdcb494a827ecb263,1"
                          target="_blank">
                          Testimonials
                        </a>
                      </p>
                      {/*<p className="text-sm text-white font-normal pt-2">
                        Meet Our Team
                      </p>*/}
                    </div>
                  </div>
                  <div className="lg:w-6/12">
                    <div className="pl-6">
                      <h4 className="text-white font-semibold text-base pb-2">
                        Plan Your Trip
                      </h4>
                      <div className="ftDropDown">
                      </div>
                      {/*<p className="text-sm text-white font-normal pt-4">
                        Cruises
                    </p>*/}
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/bali-tour-packages">
                          Bali Tour Package
                        </a>
                      </p>
                      {/*<p className="text-sm text-white font-normal pt-2">
                        <a href="/malaysia-tour-packages">
                          Malaysia Tour Package
                        </a>
                      </p>*/}
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/vietnam-tour-packages">
                          Vietnam Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/malaysia-tour-packages">
                          Malaysia Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/greece-tour-packages">
                          Greece Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/morocco-tour-packages">
                          Morocco Tour Package
                        </a>
                      </p>

                    </div>
                  </div>
                  <div className="lg:w-3/12">
                    <div className="lg:-ml-6">
                      {/* <h4 className="text-white font-semibold text-base pb-2">
                        Plan Your Trip
                      </h4> */}
                      <div className="">
                      </div>
                      <p className="text-sm text-white font-normal pt-11">
                        <a href="/dubai-tour-packages">
                          Dubai Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/cambodia-tour-packages">
                          Cambodia Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/srilanka-tour-packages">
                          Sri Lanka Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/philippines-tour-packages">
                          Philippines Tour Package
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/nepal-tour-packages">
                          Nepal Tour Package
                        </a>
                      </p>


                    </div>
                  </div>
                </div>
                <div className="lg:w-5/12 flex lg:pt-0 md:pt-4 pt-6">
                  <div className="lg:w-6/12 pl-6">
                    {/* <h4 className="text-white font-semibold text-base pb-2">
                        Plan Your Trip
                      </h4> */}
                    <div className="">
                    </div>
                    <p className="text-sm text-white font-normal pt-11">
                      <a href="/thailand-tour-packages">
                        Thailand Tour Package
                      </a>
                    </p>
                    <p className="text-sm text-white font-normal pt-2">
                      <a href="/singapore-tour-packages">
                        Singapore Tour Package
                      </a>
                    </p>
                    <p className="text-sm text-white font-normal pt-2">
                      <a href="/australia-tour-packages">
                        Australia Tour Package
                      </a>
                    </p>
                    <p className="text-sm text-white font-normal pt-2">
                      <a href="/newzealand-tour-packages">
                        New Zealand Tour Package
                      </a>
                    </p>
                    <p className="text-sm text-white font-normal pt-2">
                      <a href="/kenya-tour-packages">
                        Kenya Tour Package
                      </a>
                    </p>

                    {/*<div className="lg:pl-6">
                      <h4 className="text-white font-semibold text-base pb-2">
                        Help
                      </h4>
                      <div className="ftDropDown">
                      </div>
                      <p className="text-sm text-white font-normal pt-4">
                        FAQ
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/privacy-policy">
                          Privacy Policy
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        Refund Policy
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/terms-and-conditions">
                          Terms & Conditions
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/cancellations">
                          Cancellation Policy
                        </a>
                      </p>
                    </div>*/}
                  </div>
                  {/* <div className="lg:w-6/12">
                  <div className="pl-6">
                      <h4 className="text-white font-semibold text-base pb-2">
                        Book
                      </h4>
                      <div className="ftDropDown">
                      </div>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/enquiry">
                          Hotels<span><i className="las la-external-link-alt pl-2"></i></span>
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/enquiry">
                          Flights<span><i className="las la-external-link-alt pl-2"></i></span>
                        </a>
                      </p>
                      <p className="text-sm text-white font-normal pt-2">
                        <a href="/enquiry">
                          Tour Packages<span><i className="las la-external-link-alt pl-2"></i></span>
                        </a>
                      </p>
                    </div>
                  </div>*/}
                </div>
              </div>
            </>
            }
          </div>
          <div className="lg:w-2/12 w-full pt-6 justify-center lg:pt-0">
            {isLoading ? 
            <>
              <Skeleton height={30} />
            </>
            :
            <>
              <h4 className="text-white font-semibold text-xl pb-2">
                Let's stay connected
              </h4>
              <SocialsList
                className="w-full !space-x-2 pt-2 lg:pt-0 text-right"
                itemClass="flex items-center justify-center w-8 h-8 rounded-lg bg-white text-black font-normal dark:bg-neutral-800 text-xl"
              />
              <div className="text-white  font-medium hidden lg:md:block pt-4">
                <div className=" flex border-sky-800 pr-2 py-1  rounded-md items-center">
                  <a href="mailto:enquiry@trawelmart.com">
                    <i className=" text-white lg:text-xl text-xs las la-envelope pr-1"></i> enquiry@trawelmart.com
                  </a>
                </div>
              </div>
              <div className="text-white font-medium hidden lg:md:block ">
                <div className="flex border-sky-600 pr-2 py-1 rounded-md text-base items-center">
                  <a href="tel:080 4217 4405">
                    <i className=" text-white lg:text-2xl text-xs las la-phone pr-1"></i> 080 4217 4405
                  </a>
                </div>
              </div>
            </>
            }

          </div>
        </div>


        <div className="w-full flex items-center lg:pb-16 lg:pt-6 pt-4">
          {isLoading ? 
          <>
            <Skeleton height={40}/>
          </>
          :
          <>
            <div className="xl:w-9/12 lg:w-11/12 w-full flex flex-wrap items-center">
              <div className="xl:w-3/12 lg:w-3/12 w-4/12 md:w-4/12 flex items-center">
                <img src={air1} alt="" className="lg:w-16 lg:h-16 w-12 h-12 md:w-16 md:h-16" />
                <div className="text-white font-bold lg:pl-4 md:pl-4 lg:text-3xl md:text-3xl text-base">
                  1000+
                  <div className="text-white  font-normal lg:text-xl md:text-xl text-sm">
                    Partners
                  </div>
                </div>
              </div>
              <div className="xl:w-3/12 lg:w-3/12 w-4/12 md:w-4/12 flex items-center">
                <img src={air2} alt="" className="lg:w-16 lg:h-16 md:w-16 md:h-16 w-12 h-12 pt-1" />
                <div className="text-white font-bold lg:pl-4 md:pl-4 lg:text-3xl md:text-3xl text-base">
                  1000+
                  <div className="text-white  font-normal lg:text-xl md:text-xl text-sm">
                    Packages
                  </div>
                </div>
              </div>
              <div className="xl:w-3/12 lg:w-3/12 w-4/12 md:w-4/12 flex items-center justify-center lg:pt-0 pt-6 lg:pb-0 pb-6">
                <img src={air3} alt="" className="lg:w-16 lg:h-16 md:w-16 md:h-16 w-10 h-10" />
                <div className="text-white font-bold lg:pl-4 md:pl-4 lg:text-3xl md:text-3xl text-base">
                  2 Lakhs
                  <div className="text-white  font-normal lg:text-xl md:text-xl text-sm">
                    Customers
                  </div>
                </div>
              </div>
            </div>
          </>
          }
        </div>
        <div className="border-t border-blue-50 lg:pt-6 pt-4 " />
        <div className="flex lg:flex-row flex-col justify-between lg:pb-6 pb-4">
          <div className="lg:w-9/12 w-full lg:text-left text-center">
            {/*<span className="text-white text-xs lg:px-4 px-2">
              <a href="/privacy-policy">
                Refund Policy
              </a>
            </span>*/}
            {isLoading ? 
            <>
              <Skeleton height={12} />
            </>
            :
            <>
              <span className="text-white text-xs lg:px-4 px-2">
                <a href="/privacy-policy">
                  Privacy Policy
                </a>
              </span>
              <span className="text-white text-xs lg:px-4 px-2">
                <a href="/terms-and-conditions">
                  Terms & Conditions
                </a>
              </span>
              <span className="text-white text-xs lg:px-4 px-2">
                <a href="/cancellations">
                  Cancellation Policy
                </a>
              </span>
            </>
            }
          </div>
          <div className="lg:w-6/12 lg:text-right text-center lg:pt-0 pt-3">
            <span className="text-white text-sm text-right">
              {isLoading ? <Skeleton height={10} /> : 'Copyright © 2024 Trawelmart. All Right Reserved.'}
            </span>
          </div>
        </div>

      </div>


      {/* ============= FOOTER THREE =============
      <div className="font container mx-auto footers2 text-white font-normal text-sm" style={{
        height: "650px",
        backgroundImage:
          'url("https://i.ibb.co/KNns6QR/123.png")',
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}>
        <div className="container grid lg:grid-cols-5 lg:gap-2">
          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              City Guides
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Moracco
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Wellington
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Thailand
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Singapore
            </p>
          </div>

          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Top Destinations
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Singapore
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Denpasar Bali
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Sydney
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Melbourne
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              San Francisco
            </p>
          </div>


          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Tour PACKAGES
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Moracco Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Malaysia Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Bali Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Dubai Tour Packages
            </p>
          </div>

          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              International packages
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Sydney Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Thailand Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Cambodia Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Nepal Tour Packages
            </p>
          </div>


          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Popular Flights
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Bengaluru to Singapore
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Chennai to Singapore
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Chennai to Dubai
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Bengaluru to Dubai
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Bengaluru to Bali
            </p>
          </div>
        </div>
        <div className=" border-t lg:mx-32" />

        <div className="container grid lg:grid-cols-5 lg:gap-2">
          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              COMPANY
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Blog
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              About us
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Testimonials
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Join Our Team
            </p>
          </div>

          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Quick Menu
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Cruises
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Conatact Us
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Privacy Policy
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              International Tour Packages
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Multi Destination Tour Packages
            </p>
          </div>


          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Book
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              Hotels<span><i className="las la-external-link-alt pl-2"></i></span>
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Flights<span><i className="las la-external-link-alt pl-2"></i></span>
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Tour Packages<span><i className="las la-external-link-alt pl-2"></i></span>
            </p>

          </div>
          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Help
            </div>
            <p className="lg:pt-4 text-sm text-neutral-600">
              FAQ
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Cookie Policy
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Terms & Conditions
            </p>
            <p className="lg:pt-3 text-sm text-neutral-600">
              Cancellation Policy
            </p>
          </div>
          <div className="lg:py-12">
            <div className="text-sm text-black font-semibold uppercase">
              Call us :
            </div>
            <p className="lg:pt-4 text-sm font-semibold text-neutral-600">
              +91 80464 27999
            </p>
            <p className="lg:pt-3 text-sm font-semibold text-neutral-600">
              +91 80421 74405
            </p>
            <div className="text-sm text-black font-semibold uppercase mt-4">
              Email for us :
            </div>
            <p className="lg:pt-2 text-sm font-semibold text-neutral-600">
              enquiry@trawelmart.com
            </p>
          </div>
        </div>
        <div className=" border-t lg:mx-32 " />
        <div className="container w-full flex lg:pt-6 lg:pb-4 justify-between items-center">
          <div className="lg:w-4/12">
            <div className="border-blue-400 border text-blue-600 text-sm py-2 px-6 rounded-full lg:w-9/12">
              SIGN UP FOR OUR NEWSLETTER <i className="las la-envelope text-xl pl-1"></i>
            </div>
          </div>

          <div className="">
            <div className="text-base text-black">
              Follow Us
            </div>
            <SocialsList
              className="!space-x-2 pt-2  text-center"
              itemClass="flex items-center justify-center w-8 h-8  text-neutral-800 font-normal dark:bg-neutral-800 text-2xl"
            />
          </div>
        </div>
        <div className=" border-t lg:mx-32 " />
        <div className="container w-full lg:py-4">
        <div className="text-black text-sm text-center">
              Copyright © 2024 Trawelmart. All Right Reserved.
            </div>
        </div>
      </div>*/}



    </div>



  );
};

export default Footer;



{/*} <div className="font nc-Footer relative border-t border-blue-50 dark:border-neutral-700">
        <div className="container mx-auto bg-blue-50 lg:py-12">
          <div className="flex flex-wrap lg:flex-row flex-col">
            <div className="lg:w-6/12">
              <Logo className="lg:w-5/12" />
              <p className="text-base lg:pt-2 font-medium">
              Keep travelling all year round with us!
              </p>
              <div className="pt-4">
              <SocialsList
                  className="!space-x-2 pt-2 lg:pl-12 text-center"
                  itemClass="flex items-center justify-center w-8 h-8 rounded-full bg-white text-blue-800 font-normal dark:bg-neutral-800 text-xl"
                />


                <div className="lg:pt-24 text-base font-semibold text-neutral-600">
                  Download the Trawel Mart App
                </div>
                <div className="flex pt-2">
                  <img src={Apple} alt="" className="w-28 h-8"/>
                  <img src={AppGallery} alt="" className="w-28 h-8 px-1"/>
                  <img src={GooglePlay} alt="" className="w-28 h-8"/>
                </div>
              </div>

            </div>
            <div className="lg:w-6/12">
              <div className="grid lg:grid-cols-3 lg:gap-2">
                <div className="lg:-ml-6">
                  <span className="font text-blue-700 font-bold lg:text-base">
                    Company
                  </span>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Blog
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                  About us
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Testimonials
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Join Our Team
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Meet Our Team
                  </p>

                  <div className="font text-blue-700 font-bold lg:text-base lg:pt-10">
                   Help
                  </div>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    FAQ
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                  Worldwide offices
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                 Terms & Conditions
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                Cancellation Policy
                  </p>


                </div>


                <div className="lg:-ml-10">
                  <span className="font text-blue-700 font-bold lg:text-base">
                    Quick Menu
                  </span>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Cruises
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Contact Us
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Privacy Policy
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    International Tour Packages
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Multi Destination Tour Packages
                  </p>


                  <div className="font text-blue-700 font-bold lg:text-base lg:pt-10">
                    Plan Your Trip
                  </div>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Dubai Tour Packages
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Women Only Holidays
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Malaysia Tour Packages
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Thailand Tour Packages
                  </p>
                 



                 
                 
                </div>
                <div className="text-neutral-700 dark:text-neutral-200  w-12/12 font-semibold md:text-xl xl:text-base text-base !leading-[114%] lg:pb-4 lg:pt-0">
                  <div className="font text-blue-700 font-bold lg:text-base ">
                    Book
                  </div>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                     Hotels
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                    Flights
                  </p>
                  <p className="font-medium lg:text-sm leading-8 pt-2">
                  Tour Packages
                  </p>
                  <div className="font text-blue-700 font-bold lg:text-base lg:pt-8">
                    Call Us :
                  </div>
                  <div className="text-base text-neutral-600 font-medium dark:text-neutral-400 font-semibold lg:pt-3">+91 80464 27999</div>
                  <div className="text-base text-neutral-600 font-medium dark:text-neutral-400 font-semibold">+91 80421 74405</div>
                  <div className="font text-blue-700 font-bold lg:text-base mt-6">
                    Email for Us :
                  </div>
                  <span className="text-base text-neutral-600 font-medium dark:text-neutral-400 font-semibold lg:pt-4">enquiry@trawelmart.com</span>
                 
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
     
      <div className="bg-blue-50 border-t py-4 text-center border-blue-200 text-sm">
        Copyright © 2024 Trawelmart. All Right Reserved.
      </div>*/}




{/*} <div className="container ">
        <div className="grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-0 ">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="justify-self-end">
          </div>
          <div className="justify-self-end">
          </div>
          <div className="pt-6">
            <SocialsList
              className="!space-x-2"
              itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
            />
          </div>
        </div>

        <div className="grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-0 lg:pt-8">
          {widgetMenus.map(renderWidgetMenuItem)}
          <div className="text-neutral-700 dark:text-neutral-200  w-12/12 font-semibold md:text-xl xl:text-base text-base !leading-[114%] lg:pb-4 lg:pt-0">

            <div className="text-base">
              Call Us :
            </div>
            <div className="text-base text-neutral-600 font-medium dark:text-neutral-400 font-normal lg:pt-3">+91 80464 27999</div>
            <div className="text-base text-neutral-600 font-medium dark:text-neutral-400 font-normal">+91 80421 74405</div>
            <div className="mt-4 text-base">
              Mail Us :
            </div>
            <span className="text-base text-neutral-600 font-medium dark:text-neutral-400 font-normal lg:pt-4">enquiry@trawelmart.com</span>

            <div className="text-lg text-neutral-600 font-semibold dark:text-neutral-400 font-normal  lg:pt-6">
              Subscribe to our newsletter!!
            </div>

            <form className="">
              <input type="email" placeholder="Email address" className="rounded lg:mt-4 lg:mb-4 border-2 w-10/12" required />
              <button type="submit" className="bg-blue-800 px-12 py-2 text-white text-sm rounded w-10/12">
                Submit
              </button>
            </form>

          </div>
        </div>
      </div>*/}

{/*<div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-0 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="col-span-2 flex items-center md:col-span-3">
            <span className="text-sm text-black font-medium dark:text-neutral-400">
              Keep travelling all year round!
            </span>

          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
  </div>*/}