import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import tmanim from "images/sample/tmanim.jpg";
import Skeleton from "react-loading-skeleton";

export default function Pagecomingsoon() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <>
      {isLoading ? (
        <>
          <div className=" p-4 justify-center align-center items-center text-center">
            {/* <Skeleton height={60} width={"50%"} /> */}
            {/* <Skeleton /> */}
            {/* <Skeleton height={12} /> */}
            {/* <Skeleton height={12} count={2} /> */}
            {/* <Skeleton height={10} /> */}
          </div>
        </>
      ) : (
        <>
          <div className="container text-center justify-center align-center">
            <div className="justify-center align-center items-center text-center">
              <div className="p-4 justify-center align-center textcenter">
                <img
                  src={tmanim}
                  alt=""
                  className="xl:w-3/12 lg:w-3/12 md:w-3/12 w-6/12"
                />
              </div>
              <h2 className="font-bold xl:text-3xl lg:text-3xl md:text-3xl text-xl -mt-6 font">
                Packages Coming Soon!
              </h2>
              <p className="py-3 font1 font-regular xl:text-base lg:text-base text-sm">
                Currently, there are no package(s) found matching your holiday
                request.
              </p>
              <p className=" font1 font-regular xl:text-base lg:text-base text-sm xl:px-56">
                Please try exploring our other available options by checking out
                trending destinations. Our dedicated Travel Experts are on hand
                to offer you an expert guidance and support throughout the
                entirety of your trip.
              </p>
              <p className=" font1 font-regular xl:text-base lg:text-base text-sm py-4">
                Reach out to us{" "}
                <span className="text-sky-600">
                  {" "}
                  <a href="tel:080 4642 7999">080 4642 7999</a>
                </span>{" "}
                or drop an Email at{" "}
                <span className="text-sky-600">
                  <a href="mailto:enquiry@trawelmart.com">
                    enquiry@trawelmart.com
                  </a>
                </span>
              </p>
            </div>

            <a href="/">
              <button className="px-1 w-32 font-semibold gradient2 text-white ml-2 py-2 lg:mb-32 mb-32 text-sm rounded-md">
                Go Home
              </button>
            </a>

            {/* <button className="px-1 w-32  gradient2 text-white ml-2 py-2 lg:mb-32 mb-32 text-sm rounded-md">
              Go Home
            </button> */}
          </div>
        </>
      )}
    </>
  );
}
