import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import fifteenthh from "images/sample/fifteenthh.png"
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  return (
    <div
      className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="lg:items-center text-left xl:pt-12 lg:pt-20 md:pt-6">
        <div className="flex-shrink-0 xl:w-full lg:w-12/12 md:w-6/12 items-start space-y-0 pb-14 lg:pb-32 xl:pr-10 lg:mr-10 xl:mr-0">
          {/* <p className="bannerfont xl:text-4xl lg:text-4xl md:text-2xl text-3xl text-white xl:text-left lg:text-left md:text-left sm:text-left text-center">
          It’s time  to travel 
          </p>
          <h2 className="font font-extrabold text-2xl md:text-2xl xl:text-5xl lg:text-3xl !leading-[114%] xl:pb-2 lg:pb-0 md:pb-0 text-white xl:text-left lg:text-left md:text-left sm:text-left text-center">
          We bring you new <br></br>Millennium Destinations
          </h2>
          <span className="font text-base md:text-base lg:text-lg xl:text-xl text-sm font-normal text-white dark:text-neutral-400 xl:block hidden lg:hidden md:hidden xl:text-left lg:text-left md:text-left sm:text-left text-center">
          Experience latest adventure. Create lasting memories at fascinating prices.
          </span>
          <span className="font text-lg md:text-base lg:text-lg xl:text-xl text-sm font-normal text-white dark:text-neutral-400 xl:hidden -mt-2 lg:block md:block block xl:text-left lg:text-left md:text-left sm:text-left text-center">
          Experience latest adventure. Create<br></br> lasting memories at fascinating prices.
          </span> */}
          <img src={fifteenthh} alt=""/>
          {/*  <ButtonPrimary>Start your search</ButtonPrimary>*/}
        </div>
        {/*<div className="flex-grow">
         <img className="w-full" src={imagePng} alt="hero" />
  </div>*/}
      </div>

      {/* <div className="hidden lg:block z-10 mb-12 lg:mb-0 lg:-mt-40 w-full">
        <HeroSearchForm />
</div> */}
    </div>
  );
};

export default SectionHero;
