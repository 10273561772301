import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS_THAILAND } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";

export interface StayCardThailandProps {
    className?: string;
    data?: StayDataType;
    size?: "default" | "small";
}

const DEMO_DATA1 = DEMO_STAY_LISTINGS_THAILAND[0];

const StayCardThailand: FC<StayCardThailandProps> = ({
    size = "default",
    className = "",
    data = DEMO_DATA1,
}) => {
    const {
        galleryImgs,
        listingCategory,
        address,
        title,
        bedrooms,
        href,
        like,
        saleOff,
        isAds,
        price,
        reviewStart,
        reviewCount,
        id,
    } = data;

    const renderSliderGallery = () => {
        return (
            <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCardPhilippines${id}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={galleryImgs}
                    href={href}
                />
                {/*<BtnLikeIcon isLiked={like} className="absolute right-3 top-3 z-[1]" />*/}
             
                {/*  <div className="absolute bg-white w-11/12 shadow-lg rounded-lg px-4 ml-3 py-3 lg:-mt-10 md:-mt-8 -mt-8 z-50 items-center justify-center">
                    <div className="flex justify-between items-center">


                        <div className="flex xl:space-x-3">
                            <div className=" items-center space-x-1 ">
                                <i className=" las la-shoe-prints text-lg "></i>
                            </div>
                            <div className=" items-center space-x-1">
                                <i className=" las la-user-friends text-lg"></i>
                            </div>
                            <div className=" items-center space-x-1">
                                <i className=" las la-language text-lg"></i>
                            </div>
                            <div className=" items-center space-x-1">
                                <i className=" las la-clock text-lg"></i>
                            </div>
                        </div>
                        <div className="pl-6">
                            {!!reviewStart && (
                                /* <StartRating reviewCount={reviewCount} point={reviewStart} />
                                <StartRating point={reviewStart} />
                            )}
                        </div>

                    </div>
                </div>*/}
            </div>
        );
    };

    const renderContent = () => {
        return (
            <div className="">
                <div className={size === "default" ? "px-4 pt-5 space-y-2 " : "px-3 pt-4 space-y-2"} >
                    <div className="space-y-2 ">
                        <div className="flex items-center space-x-2">
                            <h2
                                className={` font-semibold capitalize ${size === "default" ? "text-base" : "text-base"
                                    }`}
                            >
                                <span className="font line-clamp-1">{title}</span>
                            </h2>
                        </div>
                        <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                            {size === "default" && (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                            )}
                            <span className="font ">{address} </span>

                        </div>
                    </div>
                    <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                       
                        <div className="">
                            <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                        </div>
                        <div className="">
                            <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" ><i className="las la-utensils"></i></a>
                        </div>
                        <div className="">
                            <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" ><i className="las la-car"></i></a>
                        </div>
                        <div className="">
                            <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" ><i className="las la-hiking"></i></a>
                        </div>
                    </nav>
                </div>

                <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                <div className=" bg-[#fff0ff]  mt-2">
                    <div className={size === "default" ? "p-4 space-y-4" : "p-3 space-y-2"} >
                        <div className="flex justify-between items-center">
                            <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                View Details
                            </div>
                            <span className="text-xl font-extrabold">
                                <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                    Starts From
                                </div>
                                <div className="font">
                                    {price}
                                    {` `}
                                    {size === "default" && (
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">

                                        </span>
                                    )}
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    };


    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="StayCard"
        >
            {renderSliderGallery()}
            <Link to={href}>{renderContent()}</Link>
        </div>
    );
};

export default StayCardThailand;
