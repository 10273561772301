import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogGreece = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Greece" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Mystical Greece: Where Ancient History Meets Modern Marvels
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Greece, a land steeped in mythology and history, beckons travellers with its timeless
                        allure. From the iconic ruins of Athens to the sun-kissed islands of the Aegean, every
                        corner of this Mediterranean gem tells a story that transcends the ages.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    Discovering Athens: A Stroll Through Antiquity
                </p>

                <p className="">
                    Begin your journey in Athens, the cradle of Western civilization. The Acropolis stands
                    majestically, a testament to the architectural prowess of ancient Greeks. As you ascend the
                    marble steps, the Parthenon's columns whisper tales of gods and heroes.
                </p>

                <p className="font-semibold text-xl">
                    Island Escapades: Santorini's Sunset Splendor
                </p>
                <p className="">
                    Hop onto a ferry and set sail for Santorini, a postcard-perfect island in the Aegean Sea. The
                    white-washed buildings cling to cliffs, creating a mesmerizing panorama against the deep blue
                    waters. Don't miss the enchanting sunset in Oia, where the sun dips below the horizon, casting
                    a warm glow over the caldera.
                </p>

                <p className="font-semibold text-xl">
                    Crete: Journey into Mythical Realms
                </p>
                <p className="">
                    Venture south to Crete, the largest Greek island. Immerse yourself in the myth of the Minotaur at the Palace
                    of Knossos. Wander through the labyrinthine ruins, imagining the echoes of ancient civilizations. Crete's diverse
                    landscapes, from rugged mountains to pristine beaches, offer a tapestry of natural wonders.
                </p>


                <p className="font-semibold text-xl">
                    Delphi: Seeking Oracle Wisdom
                </p>
                <p className="">
                    Embark on a pilgrimage to Delphi, the sacred site where the Oracle once spoke. Nestled
                    on the slopes of Mount Parnassus, this ancient sanctuary commands breath-taking views.
                    Explore the Temple of Apollo and the Theatre, where the whispers of prophecy still seem to
                    linger in the mountain breeze.
                </p>

                <p className="font-semibold text-xl">
                    Thessaloniki: Modern Vibes in Historical Surroundings
                </p>
                <p className="">
                    Heading north, the vibrant city of Thessaloniki combines history with a contemporary pulse. Visit
                    the White Tower and stroll along the waterfront promenade. Indulge in local cuisine at Modiano
                    Market, where the flavors of Greece come alive in every bite.
                </p>

                <p className="font-semibold text-xl">
                    Aegean Adventures: Navigating the Cyclades
                </p>
                <p className="">
                    Embark on a seafaring adventure through the Cyclades. Mykonos beckons with its lively
                    beaches and nightlife, while the lesser-known gems like Paros and Naxos offer tranquillity and
                    authenticity. Sail between these islands, each with its own charm and allure.
                </p>

                <p className="font-semibold text-xl">
                    Sailing the Ionian Sea: Corfu's Emerald Haven
                </p>
                <p className="">
                    Embark on a westward journey to Corfu, nestled in the embrace of the Ionian Sea. This lush
                    island, dotted with olive groves and cypress trees, offers a serene retreat. Explore the narrow
                    streets of Corfu Town, where Venetian architecture blends seamlessly with French and British influences.
                    The Old and New Fortresses guard the island's history, inviting you to step back in time.
                </p>

                <p className="font-semibold text-xl">
                    Peloponnese Peninsula: Echoes of Heroes
                </p>
                <p className="">
                    Venturing south, the Peloponnese Peninsula unfolds, a region steeped in myth and legend. In Olympia,
                    stand amidst the ancient ruins where the Olympic Games were born. The Temple of Zeus and the Stadium evoke the
                    spirits of athletes and spectators from centuries past.
                </p>

                <p className="">
                    Continue your journey to Mycenae, the realm of Agamemnon. Walk through the Lions' Gate and explore
                    the Treasury of Atreus—a beehive-shaped tomb that whispers tales of ancient royalty. The amphitheater
                    of Epidaurus beckons with its renowned acoustics, a testament to the architectural brilliance of ancient Greece.
                </p>

                <p className="font-semibold text-xl">
                    Monasteries in Meteora: A Divine Clifftop Retreat
                </p>
                <p className="">
                    Transitioning to central Greece, the otherworldly monasteries of Meteora rise dramatically from towering
                    rock formations. Perched precariously on clifftops, these sanctuaries offer a spiritual sanctuary and
                    panoramic views. Climb the steps to Varlaam and Roussanou Monasteries, where solitude meets breathtaking
                    landscapes.
                </p>

                <p className="font-semibold text-xl">
                    Greek Gastronomy: A Culinary Odyssey
                </p>
                <p className="">
                    No journey through Greece is complete without savoring its culinary delights. Indulge
                    in moussaka, souvlaki, and spanakopita. Sip on local wines and sample olive oils that capture the
                    essence of the Mediterranean. From seaside tavernas to bustling markets, Greece's gastronomic offerings
                    are a feast for the senses.
                </p>


                <p className="font-semibold text-xl">
                    Epilogue: A Never-Ending Odyssey
                </p>
                <p className="">
                    As your Greek odyssey draws to a close, the memories of ancient wonders and contemporary marvels linger.
                    Greece, with its rich history, diverse landscapes, and warm hospitality, leaves an indelible mark on those
                    fortunate enough to explore its treasures. Whether you sought the whispers of oracles, the beauty of island
                    sunsets, or the echoes of ancient heroes, Greece welcomes you to a timeless journey—a never-ending odyssey
                    that transcends the boundaries of time and captivates the heart.
                </p>


                <p className="font-semibold text-lg">
                    Conclusion: Greece, a Tapestry of Time
                </p>

                <p>
                    As your Greek odyssey concludes, you carry with you the echoes of ancient tales and the
                    vibrant spirit of modern Greece. From the classical ruins of Athens to the sun-drenched islands of the
                    Aegean, Greece weaves a tapestry of time that captivates the soul. Whether you seek history, mythology, or
                    simply the joy of discovery, Greece invites you to immerse yourself in its rich and enduring beauty.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/28W6NF5/bloggreece.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogGreece;
