import React, { ReactNode, useEffect, useState } from "react";
import { imageGallery as listingStayImageGallery } from "./listing-stay-detail/constant";
import { imageGallery1 as listingStayImageGallery1 } from "./listing-stay-detail/constant";
import { imageGallery2 as listingStayImageGallery2 } from "./listing-stay-detail/constant";
import { imageGallery3 as listingStayImageGallery3 } from "./listing-stay-detail/constant";
import { imageGallery4 as listingStayImageGallery4 } from "./listing-stay-detail/constant";
import { imageGallery5 as listingStayImageGallery5 } from "./listing-stay-detail/constant";
import { imageGallery6 as listingStayImageGallery6 } from "./listing-stay-detail/constant";
import { imageGallery7 as listingStayImageGallery7 } from "./listing-stay-detail/constant";
import { imageGallery8 as listingStayImageGallery8 } from "./listing-stay-detail/constant";
import { imageGallery9 as listingStayImageGallery9 } from "./listing-stay-detail/constant";
import { imageGallery10 as listingStayImageGallery10 } from "./listing-stay-detail/constant";
import { imageGallery11 as listingStayImageGallery11 } from "./listing-stay-detail/constant";
import { imageGallery12 as listingStayImageGallery12 } from "./listing-stay-detail/constant";
import { imageGallery13 as listingStayImageGallery13 } from "./listing-stay-detail/constant";
import { imageGallery14 as listingStayImageGallery14 } from "./listing-stay-detail/constant";
import { imageGallery15 as listingStayImageGallery15 } from "./listing-stay-detail/constant";
import { imageGallery16 as listingStayImageGallery16 } from "./listing-stay-detail/constant";
import { imageGallery17 as listingStayImageGallery17 } from "./listing-stay-detail/constant";
import { imageGallery18 as listingStayImageGallery18 } from "./listing-stay-detail/constant";
import { imageGallery19 as listingStayImageGallery19 } from "./listing-stay-detail/constant";
import { imageGallery20 as listingStayImageGallery20 } from "./listing-stay-detail/constant";
import { imageGallery21 as listingStayImageGallery21 } from "./listing-stay-detail/constant";
import { imageGallery22 as listingStayImageGallery22 } from "./listing-stay-detail/constant";
import { imageGallery23 as listingStayImageGallery23 } from "./listing-stay-detail/constant";
import { imageGallery24 as listingStayImageGallery24 } from "./listing-stay-detail/constant";
import { imageGallery25 as listingStayImageGallery25 } from "./listing-stay-detail/constant";
import { imageGallery26 as listingStayImageGallery26 } from "./listing-stay-detail/constant";
import { imageGallery27 as listingStayImageGallery27 } from "./listing-stay-detail/constant";
import { imageGallery28 as listingStayImageGallery28 } from "./listing-stay-detail/constant";
import { imageGallery29 as listingStayImageGallery29 } from "./listing-stay-detail/constant";
import { imageGallery30 as listingStayImageGallery30 } from "./listing-stay-detail/constant";
import { imageGallery31 as listingStayImageGallery31 } from "./listing-stay-detail/constant";
import { imageGallery32 as listingStayImageGallery32 } from "./listing-stay-detail/constant";
import { imageGallery33 as listingStayImageGallery33 } from "./listing-stay-detail/constant";
import { imageGallery34 as listingStayImageGallery34 } from "./listing-stay-detail/constant";
import { imageGallery35 as listingStayImageGallery35 } from "./listing-stay-detail/constant";
import { imageGallery36 as listingStayImageGallery36 } from "./listing-stay-detail/constant";
import { imageGallery37 as listingStayImageGallery37 } from "./listing-stay-detail/constant";
import { imageGallery38 as listingStayImageGallery38 } from "./listing-stay-detail/constant";
import { imageGallery39 as listingStayImageGallery39 } from "./listing-stay-detail/constant";
import { imageGallery40 as listingStayImageGallery40 } from "./listing-stay-detail/constant";
import { imageGallery41 as listingStayImageGallery41 } from "./listing-stay-detail/constant";
import { imageGallery42 as listingStayImageGallery42 } from "./listing-stay-detail/constant";
import { imageGallery43 as listingStayImageGallery43 } from "./listing-stay-detail/constant";
import { imageGallery44 as listingStayImageGallery44 } from "./listing-stay-detail/constant";
import { imageGallery45 as listingStayImageGallery45 } from "./listing-stay-detail/constant";
import { imageGallery46 as listingStayImageGallery46 } from "./listing-stay-detail/constant";
import { imageGallery47 as listingStayImageGallery47 } from "./listing-stay-detail/constant";
import { imageGallery48 as listingStayImageGallery48 } from "./listing-stay-detail/constant";
import { imageGallery49 as listingStayImageGallery49 } from "./listing-stay-detail/constant";
import { imageGallery50 as listingStayImageGallery50 } from "./listing-stay-detail/constant";
import { imageGallery51 as listingStayImageGallery51 } from "./listing-stay-detail/constant";
import { imageGallery52 as listingStayImageGallery52 } from "./listing-stay-detail/constant";
import { imageGallery53 as listingStayImageGallery53 } from "./listing-stay-detail/constant";
import { imageGallery54 as listingStayImageGallery54 } from "./listing-stay-detail/constant";
import { imageGallery55 as listingStayImageGallery55 } from "./listing-stay-detail/constant";
import { imageGallery56 as listingStayImageGallery56 } from "./listing-stay-detail/constant";
import { imageGallery57 as listingStayImageGallery57 } from "./listing-stay-detail/constant";
import { imageGallery58 as listingStayImageGallery58 } from "./listing-stay-detail/constant";
import { imageGallery59 as listingStayImageGallery59 } from "./listing-stay-detail/constant";
import { imageGallery60 as listingStayImageGallery60 } from "./listing-stay-detail/constant";
import { imageGallery61 as listingStayImageGallery61 } from "./listing-stay-detail/constant";
import { imageGallery62 as listingStayImageGallery62 } from "./listing-stay-detail/constant";
import { imageGallery63 as listingStayImageGallery63 } from "./listing-stay-detail/constant";
import { imageGallery64 as listingStayImageGallery64 } from "./listing-stay-detail/constant";
import { imageGallery65 as listingStayImageGallery65 } from "./listing-stay-detail/constant";
import { imageGallery66 as listingStayImageGallery66 } from "./listing-stay-detail/constant";
import { imageGallery67 as listingStayImageGallery67 } from "./listing-stay-detail/constant";
import { imageGallery68 as listingStayImageGallery68 } from "./listing-stay-detail/constant";
import { imageGallery69 as listingStayImageGallery69 } from "./listing-stay-detail/constant";
import { imageGallery70 as listingStayImageGallery70 } from "./listing-stay-detail/constant";
import { imageGallery71 as listingStayImageGallery71 } from "./listing-stay-detail/constant";






import { imageGallery as listingCarImageGallery } from "./listing-car-detail/constant";
import { imageGallery as listingExperienceImageGallery } from "./listing-experiences-detail/constant";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import MobileFooterSticky from "./(components)/MobileFooterSticky";
import { ListingGalleryImage } from "components/ListingImageGallery/utils/types";
import axios from "axios";


const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const [searchParams] = useSearchParams();
  const modal = searchParams?.get("modal");
  const [galleryImages, setGalleryImages] = useState<ListingGalleryImage[]>([]);

  useEffect(() => {
    async function fetchImages() {
      const images = await getImageGalleryListing();
      setGalleryImages(images);
    }

    fetchImages();
  },[])

  const handleCloseModalImageGallery = () => {
    let params = new URLSearchParams(document.location.search);
    params.delete("modal");
    navigate(`${thisPathname}/?${params.toString()}`);
  };

  const getImageGalleryListing = async () => {
    if (thisPathname?.includes("/hotel-1")) {
      return listingStayImageGallery71;
    }
    if (thisPathname?.includes("/hotel-2")) {
      return listingStayImageGallery71;
    }
    if(thisPathname?.includes("/"))
    {
      const match = thisPathname.match(/\/([^/]+)/);
      if(match)
      {
        const slug = match[1];
        const response = await axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getPagesBySlug/" + slug);
        if(response.data.status)
        {
          const photos = response.data.package.photos_details;
          if(photos)
          {
            let imageUrls = photos.split(", ").map((path: string) => `https://admin.trawelmart.com/admin/public/${path}`);
            const images : ListingGalleryImage[] = imageUrls.map(
              (item:any, index:any): ListingGalleryImage => {
                return {
                  id: index,
                  url: item,
                };
              }
            );
            return images;
          }
        }
      }
    }
    // if (thisPathname?.includes("/hotel-detail-page")) {
    //   return listingStayImageGallery70;
    // }
    // if (thisPathname?.includes("/srilanka-9nights-10days-tour-packages")) {
    //   return listingStayImageGallery69;
    // }
    // if (thisPathname?.includes("/srilanka-9nights-10days-tour-package")) {
    //   return listingStayImageGallery68;
    // }
    //  if (thisPathname?.includes("/srilanka-8nights-9days-tour-package")) {
    //   return listingStayImageGallery67;
    // }
    // if (thisPathname?.includes("/srilanka-7nights-8days-tour-packagesss")) {
    //   return listingStayImageGallery66;
    // }
    // if (thisPathname?.includes("/srilanka-7nights-8days-tour-packagess")) {
    //   return listingStayImageGallery65;
    // }
    // if (thisPathname?.includes("/srilanka-7nights-8days-tour-packages")) {
    //   return listingStayImageGallery64;
    // }
    // if (thisPathname?.includes("/srilanka-7nights-8days-tour-package")) {
    //   return listingStayImageGallery63;
    // }
    // if (thisPathname?.includes("/srilanka-6nights-7days-tour-packagess")) {
    //   return listingStayImageGallery62;
    // }
    // if (thisPathname?.includes("/srilanka-6nights-7days-tourr-packages")) {
    //   return listingStayImageGallery61;
    // }
    // if (thisPathname?.includes("/srilanka-6nights-7days-tour-packages")) {
    //   return listingStayImageGallery60;
    // }
    // if (thisPathname?.includes("/srilanka-6nights-7days-tour-package")) {
    //   return listingStayImageGallery59;
    // }
    // if (thisPathname?.includes("/srilanka-5nights-6days-tour-package")) {
    //   return listingStayImageGallery58;
    // }
    // if (thisPathname?.includes("/srilanka-4nights-5days-tour-packagess")) {
    //   return listingStayImageGallery57;
    // }
    // if (thisPathname?.includes("/srilanka-4nights-5days-tour-packages")) {
    //   return listingStayImageGallery56;
    // }
    // if (thisPathname?.includes("/srilanka-4nights-5days-tour-package")) {
    //   return listingStayImageGallery55;
    // }
    // if (thisPathname?.includes("/srilanka-3nights-4days-tour-package")) {
    //   return listingStayImageGallery54;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-bangkok-14nights-15days-tour-package")) {
    //   return listingStayImageGallery53;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-bangkok-12nights-13days-tour-package")) {
    //   return listingStayImageGallery52;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-bangkok-10nights-11days-tour-package")) {
    //   return listingStayImageGallery51;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-8nights-9days-tour-packages")) {
    //   return listingStayImageGallery50;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-8nights-9days-tour-package")) {
    //   return listingStayImageGallery49;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-6nights-7days-tour-packages")) {
    //   return listingStayImageGallery48;
    // }
    // if (thisPathname?.includes("/singapore-malaysia-6nights-7days-tour-package")) {
    //   return listingStayImageGallery47;
    // }
    // if (thisPathname?.includes("/singapore-7nights-8days-tour-package")) {
    //   return listingStayImageGallery46;
    // }
    // if (thisPathname?.includes("/singapore-6nights-7days-tour-packagess")) {
    //   return listingStayImageGallery45;
    // }
    // if (thisPathname?.includes("/singapore-6nights-7days-tour-packages")) {
    //   return listingStayImageGallery44;
    // }
    // if (thisPathname?.includes("/singapore-6nights-7days-tour-package")) {
    //   return listingStayImageGallery43;
    // }
    // if (thisPathname?.includes("/singapore-4nights-5days-tour-package")) {
    //   return listingStayImageGallery42;
    // }
    // if (thisPathname?.includes("/singapore-3nights-4days-tour-package")) {
    //   return listingStayImageGallery41;
    // }
    // if (thisPathname?.includes("/malaysia-7nights-8days-tour-package")) {
    //   return listingStayImageGallery40;
    // }
    // if (thisPathname?.includes("/malaysia-3nights-4days-tour-package")) {
    //   return listingStayImageGallery39;
    // }
    // if (thisPathname?.includes("/malaysia-5nights-6days-tour-package")) {
    //   return listingStayImageGallery38;
    // }
    // if (thisPathname?.includes("/thailand-4nights-5days-tour-packagess")) {
    //   return listingStayImageGallery37;
    // }
    // if (thisPathname?.includes("/thailand-4nights-5days-tourr-packages")) {
    //   return listingStayImageGallery36;
    // }
    // if (thisPathname?.includes("/thailand-8nights-9days-tour-package")) {
    //   return listingStayImageGallery35;
    // }
    // if (thisPathname?.includes("/thailand-10nights-11days-tour-packages")) {
    //   return listingStayImageGallery34;
    // }
    // if (thisPathname?.includes("/thailand-6nights-7days-tour-packages")) {
    //   return listingStayImageGallery33;
    // }
    // if (thisPathname?.includes("/thailand-4nights-5days-tour-packages")) {
    //   return listingStayImageGallery32;
    // }
    // if (thisPathname?.includes("/thailand-3nights-4days-tour-package")) {
    //   return listingStayImageGallery31;
    // }
    // if (thisPathname?.includes("/thailand-10nights-11days-tour-package")) {
    //   return listingStayImageGallery30;
    // }
    // if (thisPathname?.includes("/thailand-6nights-7days-tour-package")) {
    //   return listingStayImageGallery29;
    // }
    // if (thisPathname?.includes("/thailand-4nights-5days-tour-package")) {
    //   return listingStayImageGallery28;
    // }
    // if (thisPathname?.includes("/vietnam-cambodia-12nights-13days-tour-package")) {
    //   return listingStayImageGallery27;
    // }
    // if (thisPathname?.includes("/vietnam-cambodia-8nights-9days-tour-package")) {
    //   return listingStayImageGallery26;
    // }
    // if (thisPathname?.includes("/vietnam-9nights-10days-tour-package")) {
    //   return listingStayImageGallery25;
    // }
    // if (thisPathname?.includes("/vietnam-5nights-6days-tour-packages")) {
    //   return listingStayImageGallery23;
    // }
    // if (thisPathname?.includes("/vietnam-5nights-6days-tour-package")) {
    //   return listingStayImageGallery24;
    // }
    // if (thisPathname?.includes("/vietnam-4nights-5days-tour-package")) {
    //   return listingStayImageGallery22;
    // }
    // if (thisPathname?.includes("/vietnam-3nights-4days-tour-package")) {
    //   return listingStayImageGallery21;
    // }
    // if (thisPathname?.includes("/bali-7nights-8days-tour-package")) {
    //   return listingStayImageGallery17;
    // }
    // if (thisPathname?.includes("/bali-10nights-11days-tour-packages")) {
    //   return listingStayImageGallery16;
    // }
    // if (thisPathname?.includes("/bali-6nightss-7dayss-tour-package")) {
    //   return listingStayImageGallery12;
    // }
    // if (thisPathname?.includes("/bali-7night-8day-tour-packages")) {
    //   return listingStayImageGallery15;
    // }
    // if (thisPathname?.includes("/bali-7night-8day-tour-package")) {
    //   return listingStayImageGallery14;
    // }
    // if (thisPathname?.includes("/bali-7nightss-8dayss-tour-package")) {
    //   return listingStayImageGallery13;
    // }

    // if (thisPathname?.includes("/bali-6nights-7days-tour-package")) {
    //   return listingStayImageGallery10;
    // }

    // if (thisPathname?.includes("/dubai-premium-yas-island-tour-package")) {
    //   return listingStayImageGallery7;
    // }
    // if (thisPathname?.includes("/dubai-premium-6nights-7days-tour-package")) {
    //   return listingStayImageGallery6;
    // }

    // if (thisPathname?.includes("/cambodia-5nights-6days-tour-package")) {
    //   return listingStayImageGallery20;
    // }

    // if (thisPathname?.includes("/dubai-luxury-7nights-8days-tour-package")) {
    //   return listingStayImageGallery5;
    // }
    // if (thisPathname?.includes("/dubai-luxury-5nights-6days-tour-packages")) {
    //   return listingStayImageGallery4;
    // }
    // if (thisPathname?.includes("/dubai-luxury-5nights-6days-tour-package")) {
    //   return listingStayImageGallery3;
    // }
    // if (thisPathname?.includes("/dubai-6nights-7days-tour-package")) {
    //   return listingStayImageGallery2;
    // }
    // if (thisPathname?.includes("/cambodia-4nights-5days-tour-package")) {
    //   return listingStayImageGallery19;
    // }

    // if (thisPathname?.includes("/cambodia-3nights-4days-tour-package")) {
    //   return listingStayImageGallery18;
    // }
    // if (thisPathname?.includes("/bali-5nights-6days-tour-packages")) {
    //   return listingStayImageGallery11;
    // }

    // if (thisPathname?.includes("/dubai-premium-lapita-6nights-7days-tour-package")) {
    //   return listingStayImageGallery8;
    // }

    // if (thisPathname?.includes("/bali-4nights-5days-tour-packages")) {
    //   return listingStayImageGallery9;
    // }

    // if (thisPathname?.includes("/dubai-5nights-6days-tour-package")) {
    //   return listingStayImageGallery1;
    // }
    // if (thisPathname?.includes("/dubai-4nights-5days-tour-package")) {
    //   return listingStayImageGallery;
    // }
    // if (thisPathname?.includes("/listing-car-detail")) {
    //   return listingCarImageGallery;
    // }
    // if (thisPathname?.includes("/listing-experiences-detail")) {
    //   return listingExperienceImageGallery;
    // }
    return [];
  };

  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={galleryImages}
      />

      <div className="container ListingDetailPage__content">{children}</div>

      {/* OTHER SECTION */}
      {/*<div className="container py-24 lg:py-32">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
            uniqueClassName="ListingDetailPage"
          />
        </div>
        <SectionSubscribe2 className="pt-24 lg:pt-32" />
  </div>*/}
      {/* STICKY FOOTER MOBILE */}
      {/* <MobileFooterSticky /> */}
    </div>
  );
};

export default DetailPagetLayout;
