import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const BlogDubaii = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Dubai" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        All You Need To Know About Desert Safari Adventure in Dubai
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Dubai, the dazzling jewel of the United Arab Emirates, is renowned for its modern skyline,
                        luxurious hotels, and extravagant lifestyle. But beyond the city's glitzy exterior lies a
                        unique and thrilling adventure that captures the essence of the Arabian desert – the Desert Safari.
                        Whether you're a thrill-seeker, a nature lover, or a culture enthusiast, a desert safari in Dubai
                        promises an unforgettable experience. Here's all you need to know about this exhilarating adventure.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <ol>
                    <li>
                        <strong>The Types of Desert Safaris: </strong>  Dubai offers a range of desert safari options to
                        cater to different preferences. The most common types include morning, evening, and overnight safaris.
                        Each has its own charm and activities, allowing you to choose the one that suits your schedule
                        and interests.
                    </li>

                    <li>
                        <strong> Dune Bashing: An Adrenaline-Pumping Ride:  </strong> A desert safari is incomplete without
                        dune bashing. Hold on tight as skilled drivers navigate the towering sand dunes in a 4x4 vehicle.
                        Feel the adrenaline surge as you race up and down the undulating dunes, creating a roller-coaster-like
                        experience.
                    </li>

                    <li>
                        <strong> Camel Riding:   </strong>A Traditional Mode of Exploration: Embrace the age-old mode of
                        desert travel by riding a camel through the golden sands. As you sway gently atop these majestic
                        creatures, take in the vast expanse of the desert and feel a connection to the region's rich history.
                    </li>

                    <li>
                        <strong> Sandboarding:  </strong> Ride the Sand Waves: If you're a fan of adventure sports,
                        sandboarding is a must-try activity. Strap on a sandboard and glide down the dunes,
                        experiencing the thrill of riding the shifting sands.
                    </li>

                    <li>
                        <strong> Bedouin Camp Experience:  </strong> After an exhilarating day of desert activities, retreat
                        to a traditional Bedouin-style camp. Immerse yourself in Arabian hospitality, indulge in a sumptuous
                        buffet dinner featuring local cuisine, and enjoy cultural performances like belly dancing and Tanoura
                        shows.
                    </li>

                    <li>
                        <strong> Stargazing: A Celestial Spectacle:   </strong> As night falls in the desert, marvel at the
                        unobstructed night sky, dotted with a myriad of stars. The absence of city lights provides a perfect
                        opportunity for stargazing and connecting with the universe.
                    </li>

                    <li>
                        <strong> Conservation and Sustainability:  </strong>Many desert safari operators in Dubai are committed
                        to preserving the delicate desert ecosystem. Choose eco-friendly tours that prioritize the well-being
                        of the environment and respect the local culture.
                    </li>

                    <li>
                        <strong> What to Wear:  </strong> Dressing for the Desert: Opt for lightweight, breathable clothing
                        to combat the desert heat. Don't forget to bring a hat, sunglasses, and sunscreen to shield yourself
                        from the sun's rays. As the temperature drops in the evening, a light jacket or shawl may come in handy.
                    </li>
                    <li>
                        <strong>Capture the Moment:  </strong> Photography Tips: The desert landscape offers stunning
                        photo opportunities. Capture the changing colors of the sand, the dramatic dunes, and the mesmerizing
                        sunset. Remember to also capture candid moments of your fellow adventurers and the cultural performances.
                    </li>
                    <li>
                        <strong> Booking Your Safari:  </strong> Planning Your Adventure: Before embarking on your desert
                        safari, research and book with a reputable tour operator. Read reviews, compare packages, and ensure
                        that the operator follows safety guidelines and offers a well-rounded experience.
                    </li>

                </ol>

                <p>
                    A desert safari adventure in Dubai is a remarkable journey into the heart of the Arabian desert.
                    It's a chance to disconnect from the modern world, reconnect with nature, and immerse yourself in
                    the rich cultural heritage of the region. Whether you're seeking thrills, tranquility, or a blend
                    of both, a desert safari promises an experience that will leave you with lasting memories and a
                    deeper appreciation for the beauty of the desert landscape.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {post.author.displayName}
                        </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/txmh5h8/blog4.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {/* 
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/* 
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogDubaii;
