import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogVietnam = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Vietnam" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Vietnam&#39;s Cultural Heritage: A Journey through Ancient Temples and Pagodas
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Vietnam, a country with a rich and diverse cultural heritage, is a land where the ancient and the modern coexist harmoniously. Amidst the
                        bustling cities and picturesque landscapes, you&#39;ll find a treasure trove of historical sites, including ancient temples and pagodas that offer a
                        glimpse into Vietnam&#39;s spiritual and architectural past. Join us on a journey through some of Vietnam&#39;s most enchanting cultural gems.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    The Spiritual Heart of Vietnam
                </p>
                <p className="">
                    Vietnam&#39;s cultural identity is deeply intertwined with its spiritual beliefs, and this is beautifully reflected in its temples and pagodas. These
                    sacred places serve as centres of worship, meditation, and cultural expression, offering a window into the soul of the Vietnamese people.
                </p>

                <p className="font-semibold text-xl">
                    Tran Quoc Pagoda, Hanoi
                </p>
                <p className="">
                    Our journey begins in the capital city, Hanoi, where you&#39;ll find Tran Quoc Pagoda, one of the oldest Buddhist temples in Vietnam. Nestled on a
                    small island in West Lake, this pagoda exudes serenity and charm. As you stroll through its lush gardens and admire the stately pagoda, you&#39;ll
                    witness Buddhist devotees offering incense and prayers, creating a tranquil atmosphere that transports you to a different era.
                </p>



                <p className="font-semibold text-xl">
                    Temple of Literature, Hanoi
                </p>
                <p className="">
                    Just a stone&#39;s throw away lies another cultural gem, the Temple of Literature (Van Mieu-Quoc Tu Giam). Built in 1070, it was Vietnam&#39;s first
                    university and is dedicated to Confucius. The temple&#39;s architecture, with its intricate carvings and well-manicured gardens, is a testament to the
                    country&#39;s commitment to education and knowledge. Don&#39;t miss the &quot;Stelae of Doctors,&quot; which honours the country&#39;s brightest scholars.
                </p>


                <p className="font-semibold text-xl">
                    The Mystical Hue
                </p>
                <p className="">
                    Continuing our journey south to Hue, the former imperial capital of Vietnam, we encounter a city steeped in history and spirituality.
                </p>

                <p className="font-semibold text-xl">
                    Thien Mu Pagoda, Hue
                </p>
                <p className="">
                    Perched on the banks of the Perfume River, Thien Mu Pagoda is an iconic symbol of Hue. Its seven-tiered pagoda, known as the &quot;Pagoda of the
                    Celestial Lady,&quot; is a sight to behold. Inside, you&#39;ll discover beautifully adorned prayer halls and courtyards. The pagoda&#39;s history is equally
                    fascinating, with tales of monks self-immolating in protest during the Vietnam War, leaving an indelible mark on the nation&#39;s history.
                </p>


                <p className="font-semibold text-xl">
                    Imperial City, Hue
                </p>
                <p className="">
                    A short distance from Thien Mu Pagoda lies the Imperial City, a UNESCO World Heritage Site. This vast complex served as the political and
                    cultural centre of the Nguyen Dynasty. Encircled by a protective moat and massive stone walls, the Imperial City houses numerous palaces,
                    gates, and temples, each showcasing exquisite architecture and historical significance. Exploring this grandeur is like stepping back in time to
                    Vietnam&#39;s royal past.
                </p>


                <p className="font-semibold text-xl">
                    Hoi An&#39;s Timeless Charm
                </p>
                <p className="">
                    Our cultural journey continues south to Hoi An, a town renowned for its well-preserved ancient architecture and cultural heritage.
                </p>

                <p className="font-semibold text-xl">
                    Hoi An Ancient Town
                </p>
                <p className="">
                    Hoi An&#39;s charming old town is a living museum in itself. The town&#39;s narrow, lantern-lit streets are lined with traditional shop houses, each telling
                    a story of centuries-old trade and cultural exchange. Among the many historical structures, the Japanese Covered Bridge and the Assembly Halls
                    stand out for their intricate details and cultural significance.
                </p>

                <p className="font-semibold text-xl">
                    Quan Cong Temple, Hoi An
                </p>
                <p className="">
                    Located in the heart of Hoi An, Quan Cong Temple is a haven of tranquillity amidst the town&#39;s bustling streets. Dedicated to the revered Chinese
                    general Quan Cong, this temple is a prime example of Chinese architectural influence in Vietnam. The intricate woodwork and peaceful
                    atmosphere make it a place for both reflection and admiration.
                </p>

                <p className="font-semibold text-xl">
                    The Mystical Mekong Delta
                </p>
                <p className="">
                    Our cultural journey concludes in the picturesque Mekong Delta, where the pace of life slows down, and spirituality takes centre stage.
                </p>

                <p className="font-semibold text-xl">
                    Vinh Trang Pagoda, My Tho
                </p>
                <p className="">
                    Vinh Trang Pagoda, located in My Tho, is a testament to the fusion of Vietnamese, Khmer, and Chinese architectural styles. Its towering
                    structures and ornate decorations are a visual delight. Strolling through the temple grounds, you&#39;ll witness monks and devotees engaged in their
                    daily rituals, providing a glimpse into the spiritual life of the region.
                </p>

                <p className="font-semibold text-xl">
                    Cao Dai Temple, Tay Ninh
                </p>
                <p className="">
                    In the vicinity of the Mekong Delta, you&#39;ll find the intriguing Cao Dai Temple in Tay Ninh. This temple is the centre of the Cao Dai religion, a
                    unique syncretic faith that incorporates elements of Buddhism, Christianity, Taoism, and Confucianism. Visiting this temple during a religious
                    ceremony is a mesmerizing experience, with worshippers dressed in vibrant robes, chanting prayers in a captivating ritual.
                </p>

                <p className="font-semibold text-xl">
                    Embracing the Cultural Tapestry
                </p>
                <p className="">
                    Vietnam&#39;s ancient temples and pagodas are not merely historical relics but living embodiments of the country&#39;s rich culture and spiritual
                    traditions. Exploring these sacred sites takes you on a journey through time, offering a deeper understanding of Vietnam&#39;s identity and heritage.
                    Whether you seek spiritual enlightenment, architectural marvels, or a glimpse into history, Vietnam&#39;s cultural gems have something profound to
                    offer every traveller.
                </p>

                <p className="font-semibold text-lg">
                    Conclusion
                </p>

                <p>
                    As you explore these enchanting temples and pagodas, remember to approach them with respect and reverence, for they are not only monuments
                    but also places of devotion and spirituality for the Vietnamese people. Take your time to absorb the beauty, history, and spirituality that these
                    sites offer, and let them leave an indelible mark on your own journey through Vietnam.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/4Kk04LY/blogvietnam.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogVietnam;
