import React, { FC } from "react";
// import GallerySlider from "components/GallerySlider/GallerySlider";
import GallerySlider2 from "components/GallerySlider/GallerySilder2";
import { DEMO_STAY_LISTINGS_HOTEL } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";
import SocialsList from "shared/SocialsList/SocialsList";
import Logo from "shared/Logo/Logo";

export interface PropertyCardH3Props {
  className?: string;
  data?: StayDataType;
}

const DEMO_DATA = DEMO_STAY_LISTINGS_HOTEL[0];

const PropertyCardH3: FC<PropertyCardH3Props> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    galleryImgs,
    title,
    href,
    like,
    saleOff,
    address,
    isAds,
    price,
    date,
    reviewStart,
    reviewCount,
    id,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="xl:flex lg:flex md:flex sm:flex">
        <div className="flex-shrink-0 w-full sm:w-64 ">
          <GallerySlider2
            ratioClass="aspect-w-1 aspect-h-1"
            galleryImgs={galleryImgs}
            className="w-full h-full rounded-l-lg overflow-hidden will-change-transform "
            uniqueID={`PropertyCardHH_${id}`}
            href="/listing-stay-details"
          />

          {/*   {saleOff && (
            <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
       )} */}
        </div>

        <div className="flex flex-wrap p-3 space-y-3 w-full">
          <div className="lg:w-8/12 md:w-8/12 sm:w-full space-y-2 pt-2">
            <div className="xl:text-lg lg:text-base font-semibold">
              {title}
            </div>
            <div className="items-center">
              <span className="las la-star checked"></span>
              <span className="las la-star checked"></span>
              <span className="las la-star checked"></span>
              <span className="las la-star checked"></span>
              <span className="las la-star checked"></span>
              <span className="pl-2 text-xs">{address}</span>
            </div>

            <div className="flex items-center">
              <Badge name={reviewStart} className="bg-sky-100 text-sky-800"></Badge>
              <p className="text-semibold text-sm pl-2">
                Excellent
              </p>
              <p className="font-normal text-xs pl-2">
                ({reviewCount}reviews)
              </p>
            </div>
            <nav className="pt-3 mb-5 lg:pb-2 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
              <div className="flex">
                <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer"><i className="las la-wifi"></i></a>
                <p className="font-normal text-xs pl-2 pt-2">Highspeed Internet</p>
              </div>
              <div className="flex">
                <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer"><i className="las la-parking"></i></a>
                <p className="font-normal text-xs pl-2 pt-2">Parking</p>
              </div>
             </nav>

            <div className="border-t xl:mr-2 lg:mr-2 md:mr-2 " />
            <div className="xl:flex xl:justify-between items-center ">
              <div className="flex xl:item-center justify-betweeen">
                <div className="text-xs pl-2 xl:pl-4 lg:pl-0">
                    Booking.com <span className="border-l xl:ml-16 lg:ml-4 md:ml-4 sm:ml-0 ml-9 border-neutral-500 pl-3 pb-6"></span><br /> <b> AED 48,000</b> 
                </div>
                <div className="text-xs pl-2 xl:pl-6 lg:pl-0">
                    Ibis Hotel <span className="border-l xl:ml-16 xl:mb-3 lg:ml-8 lg:mb-4 ml-9 md:ml-8 ml-20 border-neutral-500 pl-3 lg:pl-2 pb-6"></span>  <br /> <b> AED 74,599</b> 
                </div>
                <div className="text-xs pl-2 xl:pl-5 lg:pl-0">
                    Wego <br /> <b> AED 20,000</b>
                </div>
              </div>
            </div>
            <div className=" mr-2" />
            <div className="xl:hidden lg:hidden md:hidden hidden sm:block">
              <div className="grid grid-cols-2 gap-2 ">
                <div className="text-2xl text-neutral-800 font-bold ">
                  {price}
                </div>
                <button className="w-10/12 gradient2 text-white ml-2 lg:ml-4 md:mt-8 py-2 lg:mb-2 text-sm rounded-md">
                  View Deals
                </button>
              </div>
            </div>

          </div>
          <div className="xl:block lg:block md:block hidden lg:w-4/12 md:w-4/12 sm:w-12/12 xl:border-l-2 lg:border-l-2 md:border-l-2 xl:border-dashed lg:border-dashed md:border-dashed text-right pt-8 justify-end space-y-0.5">
            {/* <div className="">
              <Logo className="w-16" />
            </div> */}
            <div className="uppercase text-xs text-green-500 lg:mt-4 md:mt-4">
              Super Deal Price
            </div>
            <p className="text-xs font-normal pt-1 text-neutral-500">
              Starts from
            </p>
            <div className="text-2xl text-neutral-800 font-bold ">
              {price}
            </div>
            <p className="text-xs font-normal text-neutral-800 ">
              Per Night
            </p>
            <div className=" md:ml-1">
            <button className="w-11/12 gradient2 text-white mt-6 ml-0 xl:mt-5 lg:ml-2 md:mt-7 lg:mt-7 xl:mt-0 sm:mt-2 mr-1 py-2 lg:mb-2 text-sm rounded-md">
              View Deals
            </button>
            </div>
          </div>
        </div>





        <div className="grid grid-cols-2 gap-2 items-center p-4 xl:hidden lg:hidden md:hidden block sm:hidden">
          <div className="">
            <div className="uppercase text-xs md:mt-4 text-green-500">
              Super Deal Price
            </div>
            <p className="text-xs font-normal pt-1 text-neutral-500">
              Starts from
            </p>
            <div className="text-2xl text-neutral-800 font-bold ">
              {price}
            </div>
          </div>
          <button className="w-10/12 gradient2 text-white mt-6 ml-2 md:mt-8 py-2 lg:mb-2 text-sm rounded-md">
            View Deals
          </button>
        </div>











      </div>
    );
  };

  const renderTienIch = () => {
    return (

      <div className="inline-grid grid-cols-5  gap-0 bg-blue-100 w-full py-1 px-4">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-plane-departure text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            Travel
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-hotel text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            Rooms
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-car text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
            Cab
          </span>
        </div>

        <div className="flex items-center ">
          <div className="bg-yellow-100 text-yellow-800 p-2 text-xs rounded">
            Group Tour
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-green-100 text-green-800 p-2 text-xs rounded">
            New Offers
          </div>
        </div>
      </div>

    );
  };

  const renderContent = () => {
    return (
      <div className="w-full flex-grow flex flex-col items-start">
        <div className="p-3 sm:pr-6 ">
          <div className="space-y-1 w-full">
            <div className="flex items-center space-x-2">
              <h2 className="text-lg font-medium capitalize">
                <span className="line-clamp-2">{title}</span>
              </h2>
            </div>
          </div>
        </div>
        <div className="w-full flex-grow flex flex-col items-start">
          <div className="w-full">
            {renderTienIch()}
          </div>
        </div>
        <div className="w-full flex-grow px-3 pt-2 sm:pr-6 pb-1 flex flex-col items-start bg-[#fbf7fb]">
          <div className="space-y-1 w-full">
            <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
            <div className="flex w-full justify-between items-end">
              <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2 pb-4 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <span className="">Travel</span>
                <span className="border-l border-gray pl-2">7</span>
                <span className="">Days</span>
                <span className="border-l border-gray pl-2">1</span>
                <span className="">Country</span>
                <span className="border-l border-gray pl-2">5</span>
                <span className="">Cities</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardHH group lg:mb-6 xl:my-4 lg:my-0 md:my-4 my-4 relative shadow dark:bg-neutral-900 border border-sky-100 dark:border-neutral-800 rounded-lg overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardHH w-full"
    >
      <div className="">

        <Link to={href} className="absolute inset-0"></Link>
        <div className="h-full w-full sm:items-center">
          {renderSliderGallery()}
        </div>
        <BtnLikeIcon
          colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
          isLiked={like}
          className="absolute right-5 top-5 sm:right-3 sm:top-3 "
        />
      </div>



    </div>

  );
};

export default PropertyCardH3;

