import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogMalaysiaa = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Singapore" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Malaysia Adventure: From Rainforests to Urban Delights
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Malaysia, a country blessed with natural wonders and modern marvels, beckons adventurers to explore
                        its diverse landscapes. From the lush rainforests teeming with wildlife to the vibrant urban hubs
                        pulsating with energy, Malaysia offers a thrilling journey that satisfies every traveler's wanderlust.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    Rainforest Retreats: Exploring Malaysia's Biodiversity
                </p>
                <p className="">
                    Venture into the heart of Malaysia's rainforests, where the air is thick with the scent
                    of exotic flora and the sounds of wildlife echo through the trees. Taman Negara, one of
                    the world's oldest rainforests, invites intrepid souls to embark on canopy walks, river
                    cruises, and jungle treks, providing an immersive encounter with nature's untouched beauty.
                </p>

                <p className="font-semibold text-xl">
                    Tropical Islands Paradise: Langkawi's Coastal Charm
                </p>
                <p className="">
                    Escape to the pristine beaches of Langkawi, an archipelago of 99 islands off Malaysia's
                    northwest coast. With turquoise waters, golden sands, and lush mangrove forests, Langkawi
                    offers a tranquil haven for beach lovers and water sports enthusiasts alike. Unwind under
                    swaying palms or embark on island-hopping adventures to discover secluded coves and hidden gems.
                </p>

                <p className="font-semibold text-xl">
                    Cultural Sojourn: Penang's Heritage and Street Art
                </p>
                <p className="">
                    Immerse yourself in the rich cultural tapestry of Penang, a UNESCO World Heritage site known
                    for its historic architecture and vibrant street art. Wander through George Town's colonial
                    streets, sample diverse street food, and marvel at the murals that adorn the city walls. Penang
                    seamlessly blends tradition with modernity, creating a unique and captivating atmosphere.
                </p>


                <p className="font-semibold text-xl">
                    Urban Exploration: Kuala Lumpur's Skyline Extravaganza
                </p>
                <p className="">
                    Transition from nature's embrace to the bustling energy of Kuala Lumpur, Malaysia's vibrant capital.
                    The iconic Petronas Towers stand as a symbol of modernity, offering breathtaking views of the cityscape.
                    Dive into the cultural melting pot of Chinatown, stroll through the lush KLCC Park, and indulge in the
                    diverse culinary delights that reflect Malaysia's multicultural identity.
                </p>

                <p className="font-semibold text-xl">
                    Cameron Highlands: Highland Retreat and Tea Plantations
                </p>
                <p className="">
                    Escape the tropical heat by ascending to the cool heights of the Cameron Highlands. A
                    haven for nature enthusiasts, this region boasts emerald-green tea plantations, flower-filled gardens,
                    and hiking trails with panoramic views. Sip on freshly brewed tea while overlooking the picturesque
                    landscapes, creating a serene contrast to the vibrant urban adventures.
                </p>

                <p className="font-semibold text-xl">
                    Adventure Sports Extravaganza: Borneo's Adrenaline Rush
                </p>
                <p className="">
                    For thrill-seekers, Malaysian Borneo offers an adrenaline-packed adventure. Dive into
                    the world-renowned Sipadan's underwater wonderland, conquer the heights of Mount Kinabalu,
                    or embark on a wildlife safari in Sabah's rainforests. Borneo caters to those craving both
                    natural beauty and heart-pounding excitement.
                </p>

                <p className="">
                    Malaysia's allure lies in its ability to seamlessly blend the untamed beauty of rainforests with
                    the cosmopolitan charm of its cities. From the soothing melodies of nature to the rhythmic beats
                    of urban life, Malaysia invites adventurers to embark on a journey that promises diversity, excitement,
                    and memories that linger long after the adventure concludes.
                </p>


                <p className="font-semibold text-xl">
                    Melaka's Historic Tapestry: Journey through Time
                </p>
                <p className="">
                    Delve into the historical richness of Melaka, a UNESCO World Heritage city that encapsulates Malaysia's
                    colonial past. Stroll along Jonker Street, lined with vibrant shops and historical buildings, and explore
                    the A Famosa fortress. The blend of Dutch, Portuguese, and Peranakan influences provides a captivating
                    glimpse into Malaysia's multifaceted heritage.
                </p>

                <p className="font-semibold text-xl">
                    Underwater Marvels: Tioman's Dive Paradise
                </p>
                <p className="">
                    For underwater enthusiasts, Tioman Island stands as a diver's paradise. Dive into crystal-clear
                    waters teeming with vibrant marine life and explore colorful coral reefs. Tioman's serene beaches
                    and lush jungles complement the exhilarating underwater experiences, making it a must-visit
                    destination for both divers and nature lovers.
                </p>

                <p className="font-semibold text-xl">
                    Perhentian Islands: Serenity and Snorkeling Bliss
                </p>
                <p className="">
                    Discover the untouched beauty of the Perhentian Islands, where pristine beaches meet
                    crystal-clear waters. Snorkel alongside sea turtles, explore vibrant coral gardens, and
                    unwind on secluded beaches. The laid-back atmosphere of these islands provides a perfect
                    contrast to the bustling urban centers, allowing travelers to recharge in nature's embrace.
                </p>


                <p className="font-semibold text-xl">
                    Festivals and Cultural Extravaganzas: Experiencing Malaysia's Celebrations
                </p>
                <p className="">
                    Partake in Malaysia's vibrant festivals that showcase the nation's cultural diversity. From the
                    colorful Thaipusam celebrations in Batu Caves to the lively Chinese New Year festivities in Kuala
                    Lumpur's Chinatown, these events immerse visitors in Malaysia's traditions, fostering a deep
                    appreciation for the country's cultural tapestry.
                </p>

                <p className="font-semibold text-lg">
                    Conclusion
                </p>

                <p>
                    In concluding this Malaysian adventure, the journey encapsulates the essence of a country that
                    seamlessly weaves together the threads of natural beauty, cultural heritage, and modern vibrancy.
                    Malaysia's ability to offer a diverse range of experiences, from the depths of rainforests to the
                    heights of urban skylines, creates a travel narrative that resonates with the spirit of exploration.
                    So, whether you find yourself in the bustling cityscape of Kuala Lumpur or the tranquil shores of
                    Tioman Island, Malaysia invites you to savor the richness of its landscapes and the warmth of its
                    cultural embrace. Each destination unfolds a new chapter in this adventure, leaving travelers with
                    a tapestry of memories to cherish.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/Dw7rjnr/malaysia2.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogMalaysiaa;
