import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogCambodia = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Cambodia" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Temples Beyond Angkor: Cambodia&#39;s Lesser-Known Religious Sites
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        When you think of Cambodia and its ancient temples, the majestic Angkor Wat likely comes
                        to mind first. However, this captivating Southeast Asian nation is home to a multitude of
                        lesser-known, but equally enchanting, religious sites. Join us on a journey beyond Angkor to
                        explore the hidden treasures of Cambodia&#39;s spiritual heritage.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    Banteay Chhmar Temple: The Enigmatic Giant
                </p>
                <p className="">
                    Located in northwestern Cambodia, near the Thai border, Banteay Chhmar is often referred to
                    as &quot;the Citadel of the Cats&quot; due to its numerous lion statues guarding its entrances. While it
                    may not have the fame of Angkor Wat, it&#39;s no less impressive. The temple complex dates
                    back to the 12th century and was built under the reign of Jayavarman VII, the same king
                    responsible for Angkor Thom and Ta Prohm.
                </p>
                <p className="">
                    One of the most striking features of Banteay Chhmar is its well-preserved bas-reliefs that tell
                    intricate stories from Hindu mythology and ancient Cambodian history. Exploring the
                    temple&#39;s vast halls and galleries, you&#39;ll feel like an archaeologist uncovering secrets from the
                    past. The site is relatively untouched by mass tourism, making it a serene and evocative place
                    to visit.
                </p>

                <p className="font-semibold text-xl">
                    Wat Ek Phnom: A Buddhist Oasis
                </p>
                <p className="">
                    Located just a short drive from the bustling city of Battambang in northwestern Cambodia,
                    Wat Ek Phnom offers a serene escape from urban life. This Buddhist temple, dating back to
                    the 11th century, is renowned for its well-preserved, albeit weathered, carvings. As you
                    wander through the temple grounds, you&#39;ll encounter intricate bas-reliefs depicting scenes
                    from the life of Buddha and Hindu deities.
                </p>
                <p className="">
                    The peaceful setting of Wat Ek Phnom is ideal for meditation and contemplation. It&#39;s also a
                    place of worship for local Buddhists, and visiting during a religious ceremony provides a
                    unique glimpse into Cambodian spiritual life. Don&#39;t forget to climb the nearby hill for a
                    panoramic view of the surrounding countryside, adding a touch of natural beauty to your
                    temple visit.
                </p>



                <p className="font-semibold text-xl">
                    Preah Vihear Temple: Perched on the Cliff
                </p>
                <p className="">
                    Preah Vihear Temple is a testament to Cambodia&#39;s rich cultural heritage and its occasionally
                    contentious history with Thailand. This mountaintop temple, located on the border between
                    the two countries, is known for its dramatic location atop a cliff in the Dangrek Mountains.
                    It&#39;s a UNESCO World Heritage Site, celebrated for its exceptional architecture and panoramic
                    views.
                </p>
                <p className="">
                    Dating back to the 9th century, Preah Vihear Temple is dedicated to the Hindu god Shiva. The
                    temple complex is a marvel of ancient engineering, with a series of terraces and staircases
                    leading up to the summit. As you ascend, you&#39;ll be rewarded with breathtaking vistas of the
                    Cambodian countryside below. Due to its remote location, the temple sees far fewer visitors
                    than Angkor, allowing for a more intimate and peaceful experience.
                </p>


                <p className="font-semibold text-xl">
                    Wat Banan: Cambodia&#39;s Mini Angkor
                </p>
                <p className="">
                    Often referred to as &quot;Mini Angkor,&quot; Wat Banan is a charming temple perched atop a hill in
                    Battambang Province. This temple, constructed in the 11th century, is reminiscent of the
                    more famous Angkor temples but on a smaller scale. The climb to reach Wat Banan&#39;s summit
                    involves ascending a steep staircase, but the effort is well worth it.
                </p>
                <p className="">
                    At the top, you&#39;ll be greeted by a stunning view of the countryside and the Sangker River
                    winding through the lush landscape. The temple itself features five towers, similar in style to
                    Angkor Wat, and is surrounded by peaceful forests. Wat Banan offers a serene and
                    introspective experience away from the crowds, making it a hidden gem worth discovering.
                </p>

                <p className="font-semibold text-xl">
                    Koh Ker: The Lost City
                </p>
                <p className="">
                    For those with a sense of adventure and a desire to explore ancient ruins that have been lost to
                    the jungle, Koh Ker is an extraordinary destination. Located in northern Cambodia, this
                    remote archaeological site was once the capital of the Khmer Empire during the 10th century.
                </p>
                <p className="">
                    Koh Ker&#39;s temples are characterized by their distinct prasats (tower sanctuaries), some of
                    which rise to impressive heights. The most iconic structure is the seven-tiered pyramid
                    known as Prasat Thom, offering panoramic views of the surrounding forest canopy. While it
                    takes some effort to reach, the sense of discovery and the untamed beauty of Koh Ker are
                    truly remarkable.
                </p>


                <p className="font-semibold text-xl">
                    Exploring Cambodia&#39;s Hidden Temples
                </p>
                <p className="">
                    Cambodia&#39;s lesser-known temples offer a chance to delve deeper into the country&#39;s rich
                    cultural and spiritual history. While Angkor Wat is undoubtedly awe-inspiring, these hidden
                    gems provide a more intimate and tranquil experience, allowing you to connect with
                    Cambodia&#39;s ancient past on a profound level.
                </p>
                <p className="">
                    As you venture beyond Angkor, remember to respect these sacred sites by following local
                    customs and preserving their natural surroundings. Embrace the sense of adventure that
                    comes with exploring lesser-known temples, and let the serenity and mystique of these
                    hidden treasures leave an indelible mark on your Cambodian journey.
                </p>




                <p className="font-semibold text-lg">
                    Conclusion
                </p>

                <p>
                    In the shadow of these lesser-known temples, you&#39;ll find a deeper appreciation for
                    Cambodia&#39;s diverse and enchanting cultural heritage, making your visit to this captivating
                    country all the more rewarding.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/0F0sJ2y/blogcambodia.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/* 
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogCambodia;
