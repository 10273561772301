import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const BlogDubai = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Dubai" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        8 Fascinating Facts You Didn't Know About Dubai
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Dubai, the glittering gem of the United Arab Emirates, is a city of dreams and marvels.
                        Beyond its iconic skyscrapers and luxurious lifestyle, Dubai hides a trove of intriguing facts that
                        may surprise even the most seasoned travelers. Join us on a journey to uncover eight lesser-known
                        facts about this enchanting city.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <ol>
                    <li>
                        <strong> An Oasis of Modernity: </strong> While Dubai is now synonymous with modernity and opulence,
                        its roots can be traced back to an ancient trading post and fishing village. The city's rapid
                        transformation from a modest port to a global metropolis is a testament to the visionary leadership
                        that turned the desert landscape into a bustling oasis of innovation.
                    </li>

                    <li>
                        <strong> The Palm Jumeirah Puzzle:  </strong> The Palm Jumeirah, one of Dubai's most iconic
                        landmarks, is an engineering marvel that can be seen from space. This man-made archipelago, shaped
                        like a palm tree, required over 94 million cubic meters of sand and 7 million tons of rock to create
                        its intricate design. It stands as a testament to Dubai's audacious approach to urban planning.
                    </li>

                    <li>
                        <strong> A World of Diversity:  </strong> Dubai is often referred to as a melting pot of cultures,
                        with an incredibly diverse population. While Emiratis make up a minority of the population, over
                        200 nationalities call Dubai home. This cultural mosaic is evident in the city's vibrant neighborhoods,
                        bustling markets, and an array of international cuisines.
                    </li>

                    <li>
                        <strong> Underwater Wonders:  </strong> Beneath the bustling cityscape lies a hidden world of
                        aquatic wonders. The Dubai Aquarium, located within The Dubai Mall, holds the Guinness World
                        Record for the largest acrylic panel, providing visitors with a mesmerizing view of marine life.
                        You can even experience the thrill of a shark encounter through cage diving.
                    </li>

                    <li>
                        <strong> Skiing in the Desert:   </strong> Dubai is renowned for pushing the boundaries of possibility,
                        and the Ski Dubai indoor ski resort is a prime example. Located within the Mall of the Emirates, this
                        winter wonderland boasts real snow and a ski slope, allowing visitors to experience the thrill of skiing
                        and snowboarding in the heart of the desert.
                    </li>

                    <li>
                        <strong> From Oil to Tourism:   </strong> Dubai's economy has undergone a remarkable transformation from
                        its oil-dependent roots. Today, oil contributes to a mere fraction of the city's GDP, with tourism,
                        real estate, and finance driving its economic engine. This diversification strategy has enabled Dubai
                        to flourish as a global hub of commerce and innovation.
                    </li>

                    <li>
                        <strong> Islands in the Sun:  </strong> Dubai is home to a collection of awe-inspiring man-made
                        islands that defy imagination. Apart from the Palm Jumeirah, other ambitious projects include The
                        World, an archipelago of islands shaped like a world map, and Bluewaters Island, home to the iconic
                        Ain Dubai, the world's largest observation wheel.
                    </li>

                    <li>
                        <strong> A Museum of the Future:  </strong> Dubai's commitment to innovation and technology is embodied
                        in the striking Museum of the Future. Currently under construction, this architectural marvel will showcase
                        cutting-edge technologies and ideas that shape the future of humanity. It stands as a symbol of Dubai's
                        relentless pursuit of progress and creativity.
                    </li>

                </ol>

                <p>
                    In conclusion, Dubai is a city that constantly captivates and surprises with its blend of tradition
                    and modernity, innovation and luxury. Beyond its stunning skyline and extravagant lifestyle, these
                    lesser-known facts reveal a city that is continuously redefining possibilities.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {post.author.displayName}
                        </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/ypNMLTd/blog1.png"
            />

            <div className="nc-SingleContent container space-y-10">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {/*  */}
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogDubai;
