import { DEMO_POSTS_THAILAND } from "data/postss";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const BlogBali = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Bali" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Flavors of Paradise: Exploring Bali&#39;s Culinary Delights
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        &quot;Taste the world, one plate at a time.
                        Discovering the essence of a place is like tasting and relishing its history, culture, and
                        soul one bite at a time.&quot;
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="">
                    Bali, often referred to as the &quot;Island of the Gods,&quot; is a tropical paradise renowned for
                    its breathtaking landscapes, rich culture, and warm hospitality. However, one aspect
                    of Bali that truly captivates the senses is its diverse and delectable cuisine. From
                    humble street stalls to upscale restaurants, Bali offers a gastronomic journey that is
                    nothing short of a culinary adventure.
                </p>

                <p className="font-semibold text-lg">
                    Street Food Delights
                </p>

                <p className="">
                    As you explore the bustling streets of Bali, you&#39;ll encounter an array of street food
                    stalls that beckon with enticing aromas and flavors. These humble stalls offer a taste
                    of authentic Balinese cuisine, often at incredibly affordable prices.
                </p>

                <li>
                    <strong> Nasi Goreng:</strong> Your foodie adventure in Bali must begin with Nasi Goreng, the
                    country&#39;s version of fried rice. Fragrant with spices and often served with a fried egg
                    on top, this dish is a delicious introduction to Indonesian flavors.
                </li>

                <li>
                    <strong> Mie Goreng:</strong> Similar to Nasi Goreng, Mie Goreng is a mouthwatering fried noodle
                    dish that combines noodles with vegetables, meat or seafood, and a flavourful sauce.
                </li>

                <li>
                    <strong> Satay:</strong> Satay is a quintessential Indonesian dish loved by locals and visitors alike.
                    These skewers of marinated and grilled meat or tofu are often served with a peanut
                    sauce that&#39;s both savoury and slightly sweet.
                </li>

                <li>
                    <strong> Nasi Campur:</strong> For a taste of Bali&#39;s diversity, try Nasi Campur, a mixed rice dish
                    that includes a variety of small portions of meat, vegetables, peanuts, eggs, and fried-

                    shrimp krupuk.
                </li>

                <li>
                    <strong>Bakso:</strong> This hearty soup is made with meatball dumplings served in a flavourful
                    broth, often garnished with fried shallots and sambal chili sauce.
                </li>
                <li>
                    <strong> Pisang Goreng:</strong> Don&#39;t forget to satisfy your sweet tooth with Pisang Goreng, deep-
                    fried bananas coated in batter. These crispy treats are often served with a dusting of
                    sugar or a drizzle of chocolate.
                </li>


                <p className="font-semibold text-lg">
                    Upscale Dining Experiences
                </p>

                <p className="">
                    While street food offers an authentic and affordable taste of Bali, the island is also
                    home to a thriving upscale dining scene. Bali&#39;s luxury restaurants combine world-
                    class cuisine with stunning views and elegant atmospheres.
                </p>

                <li>
                    <strong> Locavore:</strong> For a fine dining experience that celebrates Indonesian ingredients,
                    Locavore is a must-visit. This restaurant showcases the best of Bali&#39;s produce in
                    innovative dishes that are a feast for the senses.
                </li>

                <li>
                    <strong>Kubu at Mandapa:</strong> If you&#39;re seeking a romantic dining experience, Kubu at
                    Mandapa is nestled in bamboo cocoons overlooking the Ayung River. Here, you can
                    savour Mediterranean-European cuisine while surrounded by the serene sounds of the
                    river.
                </li>

                <li>
                    <strong> Mozaic:</strong> Mozaic, located in Ubud, is known for its exquisite tasting menus that
                    combine French cooking techniques with Indonesian ingredients. It&#39;s a place where
                    artistry and culinary expertise converge.
                </li>
                <li>
                    <strong> Swept Away at The Samaya:</strong> This idyllic restaurant offers dining by the riverside.
                    Enjoy a leisurely meal with dishes that incorporate fresh, local ingredients prepared
                    with a creative flair.
                </li>
                <li>
                    <strong>The Bridge Bali:</strong> Situated in the heart of Seminyak, The Bridge Bali offers a fusion
                    of Asian and Western flavors. The restaurant&#39;s unique feature is a glass-bottom floor
                    that allows diners to watch the gentle flow of the river below.
                </li>



                <p className="font-semibold text-lg">
                    Must-Try Dishes and More
                </p>

                <p className="">
                    While exploring Bali&#39;s culinary landscape, there are several must-try dishes and
                    experiences that should not be missed.
                </p>

                <li>
                    <strong> Babi Guling:</strong> This is a quintessential Balinese dish, a succulent and flavourful roast
                    pig typically reserved for special occasions. Savor the crispy skin and tender meat.
                </li>

                <li>
                    <strong> Bebek Betutu:</strong> Duck lovers will appreciate Bebek Betutu, a slow-cooked duck dish
                    infused with a rich blend of spices and coconut milk, then wrapped in banana leaves
                    and grilled to perfection.
                </li>

                <li>
                    <strong> Traditional Balinese Warungs:</strong> Don&#39;t hesitate to dine at traditional Balinese
                    warungs, family-run eateries that offer a glimpse into the heart of Balinese cuisine.
                    These unassuming establishments serve authentic dishes that vary by region.
                </li>

                <li>
                    <strong>Bali Coffee and Luwak Coffee:</strong> Coffee enthusiasts should indulge in Bali&#39;s famous
                    coffee, and for the adventurous, try Luwak coffee, famously known as &quot;civet coffee.&quot;
                </li>

                <p>
                    In conclusion, a foodie adventure in Bali is a journey through a culinary wonderland
                    that caters to all tastes and budgets. From the vibrant street food scene to the upscale
                    dining experiences, Bali&#39;s cuisine reflects the island&#39;s rich culture and diverse
                    landscapes. Whether you&#39;re savouring street food classics or indulging in gourmet
                    creations, the flavors of Bali will leave an indelible mark on your palate and heart,
                    making it a truly unforgettable foodie destination.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {post.author.displayName}
                        </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/RNNnwGN/baliblog.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {/*  
                        {DEMO_POSTS_THAILAND.filter((_, i) => i < 1).map(renderPostRelated)}
                        {/* 
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogBali;
