import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS_VILLAS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";
import SocialsList from "shared/SocialsList/SocialsList";
import Logo from "shared/Logo/Logo";

export interface PropertyCardMaldivesProps {
    className?: string;
    data?: StayDataType;
}
const DEMO_DATA = DEMO_STAY_LISTINGS_VILLAS[0];


const PropertyCardMaldives: FC<PropertyCardMaldivesProps> = ({
    className = "",
    data = DEMO_DATA,
}) => {
    const {
        galleryImgs,
        title,
        href,
        like,
        saleOff,
        address,
        isAds,
        price,
        date,
        reviewStart,
        reviewCount,
        id,
    } = data;

    const renderSliderGallery = () => {
        return (
            <div className="xl:flex lg:flex md:flex sm:flex">
               
                    <GallerySlider
                        ratioClass="aspect-w-1 aspect-h-1"
                        galleryImgs={galleryImgs}
                        className="xl:w-8/12 md:w-8/12 lg:w-8/12 w-full xl:h-64 lg:h-64 md:h-64 rounded-l-lg overflow-hidden will-change-transform "
                        uniqueID={`PropertyCardH_${id}`}
                        href="/"
                    />

                    {/*   {saleOff && (
            <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
       )} */}
                

                <div className="flex flex-wrap p-3 space-y-3 w-full">
                    <div className="lg:w-7/12 md:w-7/12 sm:w-full space-y-0 pt-2">
                        <div className="xl:text-lg lg:text-base text-base font-semibold">
                            {title}
                        </div>
                        <div className="items-center">
                            <span className="las la-star checked"></span>
                            <span className="las la-star checked"></span>
                            <span className="las la-star checked"></span>
                            <span className="las la-star checked"></span>
                            <span className="las la-star checked"></span>
                            {/* <span className="pl-2 text-xs">{address}</span> */}
                            <span className="font-normal text-xs pl-2">
                                ({reviewCount}reviews)
                            </span>
                        </div>
                       
                        <div className="flex items-center font xl:pt-6 lg:pt-4 md:pt-6 pt-4">
                            <div className="">
                                <i className="las la-user xl:text-xl text-sky-600"></i>
                            </div>
                            <div className="items-center ">
                                <h3 className="pl-2 text-xs uppercase font-semibold font">Occupancy</h3>
                                <div className="pl-2 text-xs pt-1">{address}</div>
                            </div>
                        </div>

                        <div className="flex items-center font xl:pt-4 lg:pt-4 md:pt-4 pt-4">
                            <div className="">
                                <i className="las la-home xl:text-xl text-sky-600"></i>
                            </div>
                            <div className="items-center">
                                <h3 className="pl-2 text-xs uppercase font-semibold font">No of Villas</h3>
                                <div className="pl-2 text-xs pt-1">{date}</div>
                            </div>
                        </div>

                        <div className="flex items-center font xl:pt-4 lg:pt-4 md:pt-4 pt-4">
                            <div className="">
                                <i className="las la-expand xl:text-xl text-sky-600"></i>
                            </div>
                            <div className="items-center">
                                <h3 className="pl-2 text-xs uppercase font-semibold font">Room Size</h3>
                                <div className="pl-2 text-xs pt-1 font">30M²</div>
                            </div>
                        </div>

                    </div>
                    <div className="pl-3 xl:block lg:block md:block block lg:w-5/12 md:w-5/12 sm:w-12/12 xl:border-l-2 lg:border-l-2 md:border-l-2 xl:border-dashed lg:border-dashed md:border-dashed text-left pt-8 justify-start space-y-0.5">
                        
                        <span className="font nc-Badge text-xs bg-sky-100 text-sky-700 px-4 py-1 xl:-mt-4 rounded-full">Standard </span>
                        <p className="text-base font-normal pt-4 text-neutral-500">
                            <i className="las la-clock text-sky-600 text-base pr-1"></i><span className="text-xs font-medium text-black uppercase">Check-In -</span> 2 PM
                        </p>
                        <p className="text-base font-normal pt-2 text-neutral-500">
                            <i className="las la-clock text-sky-600 text-base pr-1"></i><span className="text-xs font-medium text-black uppercase">Check-Out -</span> 12 PM
                        </p>
                       
                        <nav className="pt-4 pb-2 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                        
                            <div className="">
                                <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Facebook">
                                    <i className="las la-wifi"></i></a></div>
                            <div className="">
                                <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Facebook">
                                    <i className="las la-concierge-bell"></i></a></div>
                            <div className=""><a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Facebook">
                                <i className="las la-utensils"></i></a></div></nav>
                    
                    </div>
                </div>


            </div>
        );
    };

    const renderTienIch = () => {
        return (

            <div className="inline-grid grid-cols-5  gap-0 bg-blue-100 w-full py-1 px-4">
                <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                        <i className="las la-plane-departure text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        Travel
                    </span>
                </div>

                {/* ---- */}
                <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                        <i className="las la-hotel text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        Rooms
                    </span>
                </div>

                {/* ---- */}
                <div className="flex items-center space-x-2">
                    <span className="hidden sm:inline-block">
                        <i className="las la-car text-lg"></i>
                    </span>
                    <span className="text-xs text-neutral-500 dark:text-neutral-400">
                        Cab
                    </span>
                </div>

                <div className="flex items-center ">
                    <div className="bg-yellow-100 text-yellow-800 p-2 text-xs rounded">
                        Group Tour
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="bg-green-100 text-green-800 p-2 text-xs rounded">
                        New Offers
                    </div>
                </div>
            </div>

        );
    };

    const renderContent = () => {
        return (
            <div className="w-full flex-grow flex flex-col items-start">
                <div className="p-3 sm:pr-6 ">
                    <div className="space-y-1 w-full">
                        <div className="flex items-center space-x-2">
                            <h2 className="text-lg font-medium capitalize">
                                <span className="line-clamp-2">{title}</span>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="w-full flex-grow flex flex-col items-start">
                    <div className="w-full">
                        {renderTienIch()}
                    </div>
                </div>
                <div className="w-full flex-grow px-3 pt-2 sm:pr-6 pb-1 flex flex-col items-start bg-[#fbf7fb]">
                    <div className="space-y-1 w-full">
                        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
                        <div className="flex w-full justify-between items-end">
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2 pb-4 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-5 w-5"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span className="">Travel</span>
                                <span className="border-l border-gray pl-2">7</span>
                                <span className="">Days</span>
                                <span className="border-l border-gray pl-2">1</span>
                                <span className="">Country</span>
                                <span className="border-l border-gray pl-2">5</span>
                                <span className="">Cities</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div
            className={`nc-PropertyCardH group lg:mb-6 xl:my-4 lg:my-0 md:my-4 my-4 relative shadow dark:bg-neutral-900 border border-sky-100 dark:border-neutral-800 rounded-lg overflow-hidden hover:shadow-xl transition-shadow ${className}`}
            data-nc-id="PropertyCardH w-full"
        >
            <div className="">

                <Link to={href} className="absolute inset-0"></Link>
                <div className="h-full w-full sm:items-center">
                    {renderSliderGallery()}
                </div>
                <BtnLikeIcon
                    colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
                    isLiked={like}
                    className="absolute right-5 top-5 sm:right-3 sm:top-3 "
                />
            </div>



        </div>

    );
};

export default PropertyCardMaldives;
