import React, { FC, useState } from "react";
import { DEMO_STAY_LISTINGS, DEMO_STAY_LISTINGS_HOTEL } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import TabFilters2 from "./TabFilters2";
import Heading2 from "components/Heading/Heading2";
import PropertyCardH2 from "components/PropertyCardH/PropertyCardH2";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import Input from "shared/Input/Input";
import FilterSearchForm from "components/HeroSearchForm/FilterSearchForm";
import PropertyCardH3 from "components/PropertyCardH/PropertyCardH3";

export interface HotelSectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
  defaultOpen?: boolean;
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS_HOTEL.filter((_, i) => i < 9);

const HotelSectionGridFilterCard: FC<HotelSectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
  defaultOpen = true,
}) => {

  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [onOpen, setOnOpen] = useState(defaultOpen);
  const [onsOpen, setOnsOpen] = useState(defaultOpen);
  const [inOpen, setInOpen] = useState(defaultOpen);
  const [innOpen, setInnOpen] = useState(defaultOpen);
  const [itOpen, setItOpen] = useState(defaultOpen);


  const [issOpen, setIssOpen] = useState(false);

  const toggleSidenav = () => {
    setIssOpen(!issOpen);
  };

  const sidenavClass = issOpen ? 'sidenav open' : 'sidenav';


  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="">
        <div className="my-1 md:my-1 space-y-5">
          <div className="">
            <div className="pb-6 pt-2">
              <div className="flex items-center">
                <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
                <label htmlFor="vehicle1" className="xl:text-sm text-base pl-2 mb-2"> Today's Deals (136)</label><br />
              </div>
              <div className="flex items-center">
                <input type="checkbox" id="vehicle2" name="vehicle2" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
                <label htmlFor="vehicle2" className="xl:text-sm text-base pl-2 mb-2"> Breakfast Included</label><br />
              </div>
              <div className="flex items-center">
                <input type="checkbox" id="vehicle3" name="vehicle3" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
                <label htmlFor="vehicle3" className="xl:text-sm text-base pl-2 mb-2"> Free Cancellations</label><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };




  const renderDetail2 = () => {
    if (!onOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="pb-6 pt-2">
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Bangalore</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Mumbai</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Chennai</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Kolkata</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> New Delhi</label><br />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderDetail3 = () => {
    if (!onsOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="pb-6 pt-2">
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Customized Holidays</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Family</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Women's Special</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Senior's Special</label><br />
            </div>
            <div className="flex items-center">
              <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike" className="border-2 border-neutral-400 w-5 h-5 mb-3" />
              <label className="xl:text-sm text-base pl-2 mb-2"> Road Trips</label><br />
            </div>
          </div>
        </div>
      </div>
    );
  };


  const renderDetail4 = () => {
    if (!inOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="pb-6 pt-2">
            <div className="flex items-center justify-between">
              <div className="font-normal text-sm text-sky-600">
                min : <span className="text-xs text-black font-medium">
                  3 Days
                </span>
              </div>
              <div className="font-normal text-sm text-sky-600">
                max : <span className="text-xs text-black font-medium">
                  12 Days
                </span>
              </div>
            </div>
            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-2 grid-cols-2 gap-2 py-2 lg:pt-4 md:pt-1 xl:py-0 lg:py-0 md:py-2">

              <div className="border rounded border-neutral-400 text-center text-xs py-2">
                1 - 2 days
              </div>
              <div className="border rounded border-neutral-400 text-center text-xs py-2">
                2 - 6 days
              </div>
            </div>
            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-2 grid-cols-2 gap-2 lg:pt-4 md:pt-1 xl:py-2 lg:py-0 md:py-2">

              <div className="border rounded border-neutral-400 text-center text-xs py-2">
                6 - 7 days
              </div>
              <div className="border rounded border-neutral-400 text-center text-xs py-2">
                7 - 12 days
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const renderDetail5 = () => {
    if (!innOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="pb-6 pt-2">
            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-2 grid-cols-2 gap-2 py-2 lg:pt-4 md:pt-1 xl:py-0 lg:py-0 md:py-2">

              <div className="border rounded border-neutral-400 pl-3 text-xs py-2">
                All
              </div>
              <div className="border rounded border-neutral-400 pl-3 text-xs py-2">
                International
              </div>
            </div>
            <div className="lg:pt-2">
              <div className="border rounded border-neutral-400 px-6 text-xs py-2">
                Multi Destinations Tour Packages
              </div>
              <div className="border rounded border-neutral-400 px-6 mt-2 text-xs py-2">
                Women Only Holidays
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };


  const renderDetail6 = () => {
    if (!itOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="pb-6 pt-2">
            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-2 grid-cols-2 gap-2 py-2 lg:pt-4 md:pt-1 xl:py-0 lg:py-0 md:py-2">
              <div className="border rounded border-neutral-400 pl-2 text-xs py-2">
                ₹0 - ₹20,000
              </div>
              <div className="border rounded border-neutral-400 pl-2 text-xs py-2">
                ₹20,000 - ₹40,000
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 lg:pt-4">
              <div className="border rounded border-neutral-400 pl-2 text-xs py-2">
                ₹40,000 - ₹60,000
              </div>
              <div className="border rounded border-neutral-400  pl-2 text-xs py-2">
                ₹60,000 & above
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={`font relative nc-HotelSectionGridFilterCard md:pt-4 ${className}`}
      data-nc-id="HotelSectionGridFilterCard"
    >

      {/* desktop filter details */}
    
      <div className="w-full relative lg:pt-6">
        <div className="xl:flex lg:flex">
          <div className="xl:w-3/12 lg:w-4/12 xl:block lg:block md:hidden hidden">
            <div className="p-4 rounded-lg  border border-neutral-200 shadow-xl sticky top-0">
              {/*  <div className="mb-8 lg:mb-6 md:mb-2">
                <TabFilters2 />
                </div>*/}
                <div>
                    <button className="w-11/12 gradient2 text-white xl:mt-0 xl:ml-3 xl:mb-5 mt-2 ml-2 py-2 lg:mb-2 text-sm rounded-md">View Map</button>
                </div>
              <div className="flex justify-between">
                <div className="text-sm font font-semibold pb-2">
                  799 of 799 results
                </div>
                <div className="text-green-700 text-sm pb-2">
                  Clear
                </div>
              </div>

              {/* sixth filter */}
              <div className="border-t"></div>
              <div
                className={`font  relative lg:pt-6 lg:pb-4`}>
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  {/*<a href="##" className="absolute inset-0" />*/}
                <span
                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${itOpen ? "transform -rotate-180" : ""
                    }`}
                  onClick={() => setItOpen(!itOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
                <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                    Price Range
                  </div>
                </div>
                {renderDetail6()}
              </div>

              {/* Fourth filter */}
              <div className="border-t"></div>
              <div
                className={`font  relative lg:pt-6 lg:pb-4`}>
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  {/*<a href="##" className="absolute inset-0" />*/}
                <span
                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${inOpen ? "transform -rotate-180" : ""
                    }`}
                  onClick={() => setInOpen(!inOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
                <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                    Tour Duration
                  </div>
                </div>
                {renderDetail4()}
              </div>

              {/* Fifth filter */}
              <div className="border-t"></div>
              <div
                className={`font  relative lg:pt-6 lg:pb-4`}>
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  {/*<a href="##" className="absolute inset-0" />*/}
                <span
                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${innOpen ? "transform -rotate-180" : ""
                    }`}
                  onClick={() => setInnOpen(!innOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
                <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                    Package Type
                  </div>
                </div>
                {renderDetail5()}
              </div>


              <div className="border-t"></div>

              {/* First filter */}
              <div
                className={`font  relative lg:pt-6 lg:pb-4 `}>
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  {/*<a href="##" className="absolute inset-0" />*/}
                <span
                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
                    }`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
                <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                    Popular Filters
                  </div>
                </div>
                {renderDetail()}
              </div>

              {/* Second filter */}
              <div className="border-t"></div>
              <div
                className={`font  relative lg:pt-6 lg:pb-4`}>
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  {/*<a href="##" className="absolute inset-0" />*/}
                <span
                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${onOpen ? "transform -rotate-180" : ""
                    }`}
                  onClick={() => setOnOpen(!onOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
                <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                    Departure City
                  </div>
                </div>
                {renderDetail2()}
              </div>
              {/* Third filter */}
              <div className="border-t"></div>
              <div
                className={`font  relative lg:pt-6 lg:pb-4`}>
                {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                  {/*<a href="##" className="absolute inset-0" />*/}
                <span
                  className={`absolute right-0 bottom-0 sm:bottom-auto items-center lg:mt-2  sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${onsOpen ? "transform -rotate-180" : ""
                    }`}
                  onClick={() => setOnsOpen(!onsOpen)}
                >
                  <i className="text-xl las la-angle-down"></i>
                </span>
                <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                  <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                    Speciality Tours
                  </div>
                </div>
                {renderDetail3()}
              </div>
            </div>
          </div>










          {/* Second Col filter section starts */}

          <div className="xl:w-9/12 md:12/12 xl:pl-6 lg:w-10/12 lg:pl-4 xl:pt-0 lg:pt-0 md:pt-0 xl:-mt-0 lg:-mt-0 md:-mt-12 -mt-32">

            {/* tab view filter details */}
            <div className="xl:hidden lg:hidden md:block block sm:block">
              <div id="mySidenav" className={sidenavClass}>
                <a href="javascript:void(0)" className="closebtn" onClick={toggleSidenav}>&times;</a>
                <a>
                  <div
                    className={`font  relative lg:pt-6 lg:pb-4  pb-4 items-center`}>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                    <div className="absolute inset-0" />
                    <span
                      className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${itOpen ? "transform -rotate-180" : ""
                        }`}
                      onClick={() => setItOpen(!itOpen)}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                    <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                      <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                        Price Range
                      </div>
                    </div>
                    {renderDetail6()}
                  </div>
                  <div className="border-t"></div>
                  <div
                    className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      {/*<a href="##" className="absolute inset-0" />*/}
                    <span
                      className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${inOpen ? "transform -rotate-180" : ""
                        }`}
                      onClick={() => setInOpen(!inOpen)}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                    <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                      <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                        Tour Duration
                      </div>
                    </div>
                    {renderDetail4()}
                  </div>

                  {/* Fifth filter */}
                  <div className="border-t"></div>
                  <div
                    className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      {/*<a href="##" className="absolute inset-0" />*/}
                    <span
                      className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${innOpen ? "transform -rotate-180" : ""
                        }`}
                      onClick={() => setInnOpen(!innOpen)}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                    <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                      <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                        Package Type
                      </div>
                    </div>
                    {renderDetail5()}
                  </div>


                  <div className="border-t"></div>

                  {/* First filter */}
                  <div
                    className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      {/*<a href="##" className="absolute inset-0" />*/}
                    <span
                      className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-4 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
                        }`}
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                    <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                      <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                        Popular Filters
                      </div>
                    </div>
                    {renderDetail()}
                  </div>

                  {/* Second filter */}
                  <div className="border-t"></div>
                  <div
                    className={`font  relative lg:pt-6 lg:pb-4 pt-4 pb-4`}>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      {/*<a href="##" className="absolute inset-0" />*/}
                    <span
                      className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-2 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${onOpen ? "transform -rotate-180" : ""
                        }`}
                      onClick={() => setOnOpen(!onOpen)}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                    <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                      <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                        Departure City
                      </div>
                    </div>
                    {renderDetail2()}
                  </div>



                  {/* Third filter */}
                  <div className="border-t"></div>
                  <div
                    className={`font  relative lg:pt-6 lg:pb-4 pt-6 pb-4`}>
                    {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
                      {/*<a href="##" className="absolute inset-0" />*/}
                    <span
                      className={`absolute right-0 bottom-0 top-0 sm:bottom-auto items-center lg:mt-2 md:mt-6 mt-4 sm:-translate-y-1/2 w-10 h-10 flex items-center justify-center cursor-pointer ${onsOpen ? "transform -rotate-180" : ""
                        }`}
                      onClick={() => setOnsOpen(!onsOpen)}
                    >
                      <i className="text-xl las la-angle-down"></i>
                    </span>
                    <div className="flex flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
                      <div className="font-medium text-xl md:text-lg xl:text-base !leading-[114%] lg:pb-2 lg:pt-0 lg:pb-0 md:pb-4 md:pt-4">
                        Speciality Tours
                      </div>
                    </div>
                    {renderDetail3()}
                  </div>
                </a>

                {/* Add more links here */}
              </div>
              <div className="bg-sky-100 rounded w-44 mt-8 mb-4">
                <span onClick={toggleSidenav} className="openbtn flex text-sm ">
                  <img src="https://i.ibb.co/Ry07c5W/filter.png" className="pl-4 w-8 text-blue-600"></img>
                  <span className="pl-2 text-sm ">Filter Details</span>
                </span>
              </div>
              {/* Your main content here */}
            </div>




            <div className=" xl:block lg:block md:block hidden">
              <div className="p-4 grid xl:grid-cols-5 xl:gap-2 lg:grid-cols-5 lg:gap-1 md:grid-cols-5 md:gap-2 xl:mb-2 shadow border border-neutral-200 rounded ">
                <div className="border-r">
                  Recommend
                </div>
                <div className="border-r">
                  <Popover className="relative mr-4">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`${open ? "" : "text-opacity-90"}
                group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                          <div className={` inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`} role="button">
                            <span>Price</span>
                            <ChevronDownIcon
                              className={`${open ? "-rotate-180" : "text-opacity-70 "} ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                              aria-hidden="true"
                            />
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                        >
                          <Popover.Panel className="absolute z-40 w-52 max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                            <div className="overflow-hidden rounded-md border border-neutral-200 shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                              <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                                <Link
                                  to="##"
                                  className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                >
                                  <span className="flex items-center">
                                    <span className="text-xs font-normal text-gray-500 dark:text-neutral-400">
                                      Sort By :
                                    </span>
                                  </span>
                                  <span className="block text-sm py-2 ">
                                    Lowest Price
                                  </span>
                                  <span className="block text-sm py-2 ">
                                    Highest Price
                                  </span>
                                  <span className="block text-sm py-2">
                                    Best Discount (%)
                                  </span>
                                  <span className="block text-sm py-2">
                                    Best Savings (₹)
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                <div className="border-r">
                  <Popover className="relative mr-4">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`${open ? "" : "text-opacity-90"}
                group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                          <div className={` inline-flex items-center `} role="button">
                            <span>Guest Rating</span>
                            <ChevronDownIcon
                              className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                              aria-hidden="true"
                            />
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-40 w-52 max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                            <div className="overflow-hidden rounded-2xl shadow-lg focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">

                              {/* FOOTER */}
                              <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                                <Link
                                  to="##"
                                  className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                >
                                  <span className="flex items-center">
                                    <span className="text-xs font-normal text-gray-500 dark:text-neutral-400">
                                      Highest Rated By :
                                    </span>
                                  </span>
                                  <span className="block flex items-center text-sm py-2 ">
                                    <i className="las la-globe"></i>
                                    <p className="pl-2">All Travellers</p>
                                  </span>
                                  <span className="block flex items-center text-sm py-2 ">
                                    <i className="las la-globe"></i>
                                    <p className="pl-2">Solo Travellers</p>
                                  </span>
                                  <span className="block flex items-center text-sm py-2 ">
                                    <i className="las la-globe"></i>
                                    <p className="pl-2">Business Travellers</p>
                                  </span>
                                  <span className="block flex items-center text-sm py-2 ">
                                    <i className="las la-globe"></i>
                                    <p className="pl-2">Family Travellers</p>
                                  </span> <span className="block flex items-center text-sm py-2 ">
                                    <i className="las la-globe"></i>
                                    <p className="pl-2">Couple Travellers</p>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                <div className="border-r">
                  <Popover className="relative mr-4">
                    {({ open, close }) => (
                      <>
                        <Popover.Button
                          className={`${open ? "" : "text-opacity-90"}
                group rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
                        >
                          <div className={` inline-flex items-center `} role="button">
                            <span>Distance</span>
                            <ChevronDownIcon
                              className={`${open ? "-rotate-180" : "text-opacity-70 "}
                  ml-1 h-4 w-4 text-black group-hover:text-opacity-80 transition ease-in-out duration-150 `}
                              aria-hidden="true"
                            />
                          </div>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className="absolute z-40 w-screen max-w-xs px-4 mt-4 transform -translate-x-1/2 left-1/2 sm:px-0">
                            <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">

                              {/* FOOTER */}
                              <div className="p-4 bg-neutral-50 dark:bg-neutral-700">
                                <Link
                                  to="##"
                                  className="flow-root px-2 py-2 space-y-0.5 transition duration-150 ease-in-out rounded-md focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                >
                                  <span className="flex items-center">
                                    <span className="text-sm font-medium ">
                                      Documentation
                                    </span>
                                  </span>
                                  <span className="block text-sm text-gray-500 dark:text-neutral-400">
                                    Start integrating products and tools
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                <div className="">
                  Stars <i className="las la-sort-amount-up-alt"></i>
                </div>
              </div>
            </div>


            {/* Search Form */}
            <form className="xl:my-3 lg:my-3 md:mt-3 my-3 font ">
              <div className="nosubmit">
                <input type="search" placeholder="Search by Hotel Name" className="nosubmit"/>
                </div></form>


            <div className="xl:mt-0 lg:mt-0 md:mt-3 p-3 flex items-center bg-neutral-50 border border-neutral-200 mb-3 shadow rounded justify-between">
              <div className="xl:block lg:block md:block hidden">
                <div className="text-base flex items-center font-medium pl-2 ">
                  <img src="https://i.ibb.co/ySY5BFQ/pricetag.webp" alt="" className="w-8 pr-2" />
                  Simply <span className="text-green-600 px-1 font-semibold "> login </span> right now to unlock member rates and save up to 50%!
                </div>
              </div>
              <div className="text-base flex items-center font-medium pl-2 xl:hidden lg:hidden md:hidden block">
                <img src="https://i.ibb.co/ySY5BFQ/pricetag.webp" alt="" className="w-8 pr-2" />
                Simply <span className="text-green-600 px-1 font-semibold "> login </span>
              </div>
              <button className="gradient2 rounded-md text-white text-xs px-6 py-2 uppercase">
                Login
              </button>
            </div>

            {data.map((stay) => (
              <PropertyCardH3 key={stay.id} data={stay} />
            ))}


          </div>
        </div>
      </div>

      <div className="flex mt-16 justify-center items-center">
        <Pagination />
      </div>






    </div>

  );
};

export default HotelSectionGridFilterCard;
