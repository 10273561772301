import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import React, { FC } from "react";
import Select from "shared/Select/Select";
import CommonLayout from "./CommonLayout";
import FormItem from "./FormItem";

export interface PageAddListings2Props { }

const PageAddListings2: FC<PageAddListings2Props> = () => {
  return (
    <CommonLayout
      index="03"
      backtHref=""
      nextHref="/add-listing-4"
    >
      <>
        <h2 className="text-sm font-medium pb-2">Add guests (Max 6 guests at a time)</h2>
        <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* FORM */}
        <div className="space-y-4 mt-4">
          {/* ITEM */}
          {/*<FormItem label="Acreage (m2)">
            <Select>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="300">300</option>
              <option value="400">400</option>
              <option value="500">500</option>
            </Select>
  </FormItem>*/}

          <div className="flex flex-col lg:flex-row items-center  ">
            <div className="">
              <i className="las la-male text-2xl text-neutral-500 dark:text-neutral-400"></i>
            </div>
            <div className="lg:w-full">
              <NcInputNumber label="Adults" defaultValue={1} />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center  ">
            <div className="">
              <i className="las la-child text-2xl text-neutral-500 dark:text-neutral-400"></i>
            </div>
            <div className="lg:w-full">
              <NcInputNumber label="Child" defaultValue={0} />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row items-center  ">
            <div className="">
              <i className="las la-baby text-2xl text-neutral-500 dark:text-neutral-400"></i>
            </div>
            <div className="lg:w-full">
              <NcInputNumber label="Infant" defaultValue={0} />
            </div>
          </div>
        </div>
      </>
    </CommonLayout>
  );
};

export default PageAddListings2;
