import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories5 from "components/SectionSliderNewCategories/SectionSliderNewCategories5";
import React from "react";
import SectionGridFeatureHotel from "containers/PageHome/SectionGridFeatureHotel";
import { TaxonomyType } from "data/types";
import SectionGridCategoryHotel from "components/SectionGridCategoryBox/SectionGridCategoryHotel";
import SectionVideos from "containers/PageHome/SectionVideos";
import awardedindiabesttourismcompanytrawelmart from "images/sample/awarded-india-best-tourism-company-trawel-mart.jpg"
import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import NcImage from "shared/NcImage/NcImage";
import { Link } from "react-router-dom";
import besttourpackagescompanybangaloretrawelmart from "images/sample/best-tour-packages-company-bangalore-trawel-mart.jpg";
import PagePopup from "containers/PageHome/PagePopup";
import Slider from "react-slick";
import newdelhi from 'images/hotels/newdelhi.jpg'
import banglore from 'images/hotels/banglore.jpg'
import mumbai from 'images/hotels/mumbai1.jpg'
import chennai from 'images/hotels/chennai.jpg'
import jaipur from 'images/hotels/jaipur.jpg'
import page1 from 'images/hotels/page1.jpg'
import page2 from 'images/hotels/page2.jpg'
import page3 from 'images/hotels/page3.jpg'
import page4 from 'images/hotels/page4.jpg'
import page5 from 'images/hotels/page1.jpg'
import page6 from 'images/hotels/page2.jpg'
import page7 from 'images/hotels/page3.jpg'
import page8 from 'images/hotels/page4.jpg'
import beach1 from 'images/hotels/beach1.jpg'
import beach2 from 'images/hotels/beach2.jpg'
import beach3 from 'images/hotels/beach3.jpg'
import beach4 from 'images/hotels/beach4.jpg'
import beach5 from 'images/hotels/beach5.jpg'
import beach6 from 'images/hotels/beach6.jpg'
import beach7 from 'images/hotels/beach7.jpg'
import beach8 from 'images/hotels/beach8.jpg'
import beach9 from 'images/hotels/beach9.jpg'
import beach10 from 'images/hotels/beach10.jpg'
import beach11 from 'images/hotels/beach11.jpg'
import beach12 from 'images/hotels/beach12.jpg'
import beach13 from 'images/hotels/beach13.jpg'
import beach14 from 'images/hotels/beach14.jpg'
import beach15 from 'images/hotels/beach15.jpg'
import beach16 from 'images/hotels/beach16.jpg'
import beach17 from 'images/hotels/beach17.jpg'
import beach18 from 'images/hotels/beach18.jpg'
import beach19 from 'images/hotels/beach19.jpg'
import outdoor from 'images/hotels/outdoor.jpg'
import outdoor1 from 'images/hotels/outdoor1.jpg'
import outdoor2 from 'images/hotels/outdoor2.jpg'
import outdoor3 from 'images/hotels/outdoor3.jpg'
import outdoor4 from 'images/hotels/outdoor4.jpg'
import outdoor5 from 'images/hotels/outdoor5.jpg'
import outdoor6 from 'images/hotels/outdoor6.jpg'
import outdoor7 from 'images/hotels/outdoor7.jpg'
import outdoor8 from 'images/hotels/outdoor8.jpg'
import outdoor9 from 'images/hotels/outdoor9.jpg'
import outdoor10 from 'images/hotels/outdoor10.jpg'

import city1 from 'images/hotels/city1.jpg'
import city2 from 'images/hotels/city2.jpg'
import city3 from 'images/hotels/city3.jpg'
import city4 from 'images/hotels/city4.jpg'
import city5 from 'images/hotels/beach19.jpg'
import city6 from 'images/hotels/city6.webp'
import city7 from 'images/hotels/city7.jpg'
import city8 from 'images/hotels/city8.jpg'
import city9 from 'images/hotels/city9.jpg'
import city10 from 'images/hotels/city10.jpg'
import city11 from 'images/hotels/city11.jpg'
import city12 from 'images/hotels/city12.jpg'
import city13 from 'images/hotels/city13.jpg'

import rmc1 from 'images/hotels/rmc1.jpg'
import rmc2 from 'images/hotels/rmc2.jpg'
import rmc3 from 'images/hotels/rmc3.jpg'
import rmc4 from 'images/hotels/rmc4.jpg'
import rmc5 from 'images/hotels/rmc4.jpg'
import rmc6 from 'images/hotels/rmc6.jpg'
import rmc7 from 'images/hotels/rmc7.jpg'
import rmc8 from 'images/hotels/rmc8.jpg'
import rmc9 from 'images/hotels/rmc9.jpg'
import rmc10 from 'images/hotels/rmc10.jpg'


import insideflight from 'images/airlines/insideflight.jpg'
import DubaiTrourPackage from 'images/sample/Dubai-Trour-Package.jpg';
import besttravelpackagesfordubaiaindubai from 'images/sample/best-travel-packages-for-dubai-ain-dubai.jpg';
import dubaitravelpackageinbangaloredubaifuture from 'images/sample/dubai-travel-package-in-bangalore-dubai-future.jpg';
import dubaibesttravelpackagesburjalarab from 'images/sample/dubai-best-travel-packages-burj-al-arab.jpg';
import dubaitourpackage from 'images/sample/dubai-tour-package.jpg';
import { useState } from "react";



const settings = {
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: true,
  arrows: true,
  dots: false,
  speed: 500,
  centerPadding: '10px',
  infinite: true,
  autoplaySpeed: 3000,
  autoplay: false,
  className: "center",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
        slidesToScroll: 1,
        arrows: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    }
  ]
};


const settingss = {
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  arrows: true,
  dots: false,
  speed: 500,
  centerPadding: '10px',
  infinite: true,
  autoplaySpeed: 3000,
  autoplay: false,
  className: "center",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
        slidesToScroll: 1,
        arrows: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    }
  ]
};



const settings1 = {
  slidesToShow: 4,
  slidesToScroll: 1,
  centerMode: true,
  arrows: true,
  dots: false,
  speed: 500,
  centerPadding: '10px',
  infinite: true,
  autoplaySpeed: 3000,
  autoplay: false,
  className: "center",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
        slidesToScroll: 1,
        arrows: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    }
  ]
};


const settings2 = {
  slidesToShow: 5,
  slidesToScroll: 1,
  centerMode: true,
  arrows: true,
  dots: false,
  speed: 500,
  centerPadding: '10px',
  infinite: true,
  autoplaySpeed: 3000,
  autoplay: false,
  className: "center",
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
        slidesToScroll: 1,
        arrows: true,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: false,
        arrows: false,
      }
    }
  ]
};


function HotelHome() {

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };


  const [activeeTab, setActiveeTab] = useState(1);

  const handleTabClick1 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeTab(tabNumber);
  };




  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-3 rounded-xl overflow-hidden group"
      >
        {/* <Link to={post.href} /> */}
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-1 p-4">
          <h5 className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium lg:text-xs text-xs relative text-blue-800 bg-blue-100  relative">
            {post.date}
          </h5>
          <h2 className="font block xl:text-lg lg:text-base md:text-lg text-base lg:font-bold font-medium text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

        </div>
        <Link to={post.href} />
      </div>
    );
  };


  return (
    <div className=" nc-PageHome relative overflow-hidden">
      {/* ====== Banner Desktop view =======*/}
      <div className="font xl:block lg:hidden hidden" style={{
        backgroundImage: `url(${besttourpackagescompanybangaloretrawelmart})`,
        width: "100%",
        height: "520px",
        backgroundSize: "cover",
      }}>
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
          <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
        </div>
      </div>


      {/* ====== Banner lg screen view =======*/}
      <div className="font xl:hidden lg:block hidden" style={{
        backgroundImage: `url(${besttourpackagescompanybangaloretrawelmart})`,
        width: "100%",
        height: "400px",
        backgroundSize: "cover",
      }}>
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
          <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
        </div>
      </div>


      {/* ====== Banner md screen view =======*/}
      <div className="font xl:hidden lg:hidden md:block sm:hidden hidden" style={{
        backgroundImage: `url(${besttourpackagescompanybangaloretrawelmart})`,
        width: "100%",
        height: "250px",
        backgroundSize: "cover",
      }}>
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
          <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
        </div>
      </div>


      {/* ====== Banner mbl view =======*/}
      <div className="font xl:hidden lg:hidden md:hidden sm:block block" style={{
        backgroundImage: `url(${besttourpackagescompanybangaloretrawelmart})`,
        width: "100%",
        height: "200px",
        backgroundSize: "cover",
      }}>
        <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
          <SectionHero className="pt-10 lg:pt-8 lg:pb-6 " />
        </div>
      </div>

      {/* Book International Hotels & Homestays */}
      <div className="container relative  mb-6  xl:mb-16 xl:mt-10 lg:mb-6 lg:mt-10 mt-6 ">
        <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 xl:pb-2 pt-6 md:pb-2 md:pt-2">
          Book International Hotels & Homestays
        </div>

        <div className="  space-y-24 mb-6 md:mb-3 lg:space-y-2 xl:mb-16 xl:mt-4 lg:mb-6 lg:mt-3 mt-6 md:mt-3">
          <Slider {...settings1} className="items-center">
            <div className="xl:px-3 px-1">
              <img src={page1} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page2} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page3} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page4} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page5} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page6} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page7} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
            <div className="xl:px-3 px-1">
              <img src={page8} alt="" className="rounded-lg xl:h-40 lg:h-36 md:h-24" />
            </div>
          </Slider>
        </div>
      </div>

      {/* quick and easy trip planner desktop ,tab ,tab landscape , mobile landscape view buttons */}
      <div className="xl:block sm:block hidden">
      <div className="container pb-6 md:pb-2 lg:py-0 space-y-6 lg:space-y-2 pb-4 xl:mb-12">
        <div className="font pt-6 xl:pt-0 ">
          <span className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4"> Quick and Easy Trip Planner </span><br />
          <p className="font xl:font-regular xl:pt-1 lg:text-base text-xs text-neutral-400 lg:pb-1 pb-1 md:pb-0">Pick a vibe and explore the top destinations in India</p>
        </div>
        <div className="tab-buttons text-center">
          <div className="flex xl:gap-0 gap-2 ">

            <button
              className={activeTab === 1 ? 'activenameee xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font  border mx-1  xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(1)}
            >
              Beach
            </button>
            <button
              className={activeTab === 2 ? 'activenameee xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font border mx-1 xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(2)}
            >
              Outdoors
            </button>
            <button
              className={activeTab === 3 ? 'activenameee xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font  border mx-1  xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(3)}
            >
              Romance
            </button>
            <button
              className={activeTab === 4 ? 'activenameee xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font  border mx-1  xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(4)}
            >
              City
            </button>
          </div>
        </div>

        <div className="tab-content text-center">
          {activeTab === 1 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className=" space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
                <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach10} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach7} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach4} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach6} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach5} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach6} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach7} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach8} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach1} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach2} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}

          {activeTab === 2 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor1} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor2} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor3} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor4} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor5} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor6} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor7} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor8} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor9} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor10} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}

          {activeTab === 3 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc1} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc2} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc3} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc4} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc5} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc6} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc7} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc8} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc9} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={rmc10} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}
          {activeTab === 4 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city1} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city2} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city3} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city11} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city12} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city13} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city7} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city8} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city9} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={city10} alt="" className="rounded-lg xl:h-36 ml-6" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}
        </div>
      </div>
      </div>

      {/* quick and easy trip planner mobile view only buttons */}
      <div className="font xl:hidden lg:hidden md:hidden sm:block block">
      <div className="container pb-1 md:pb-2 lg:py-0 space-y-6 lg:space-y-2 xl:mb-12">
        <div className="font pt-6 xl:pt-0 ">
          <span className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4"> Quick and Easy Trip Planner </span><br />
          <p className="font xl:font-regular xl:pt-1 lg:text-base text-xs text-neutral-400 lg:pb-1 pb-1 md:pb-0">Pick a vibe and explore the top destinations in India</p>
        </div>
        <div className="tab-buttons text-center">
          <div className="flex xl:gap-0 gap-2 ">

            <button
              className={activeTab === 1 ? 'xl:activenameee activename2 xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font  border mx-1  xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(1)}
            >
              Beach
            </button>
            <button
              className={activeTab === 2 ? 'xl:activenameee activename2 xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font border mx-1 xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(2)}
            >
              Outdoors
            </button>
            <button
              className={activeTab === 3 ? 'xl:activenameee activename2 xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font  border mx-1  xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(3)}
            >
              Romance
            </button>
            <button
              className={activeTab === 4 ? 'xl:activenameee activename2 xl:text-sm sm:text-sm text-xs ' : 'text-xs px-3 py-2 font  border mx-1  xl:mx-1 xl:px-8 xl:py-2 sm:px-3 sm:py-0 sm:mx-1 rounded-full xl:text-sm sm:text-sm md:px-8 md:py-2'}
               onClick={() => handleTabClick(4)}
            >
              City
            </button>
          </div>
        </div>

        <div className="tab-content text-center">
          {activeTab === 1 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className=" space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach1} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach2} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach3} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach8} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach5} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach6} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach7} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach8} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach9} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach10} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}

          {activeTab === 2 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor1} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor2} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor3} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor4} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor5} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor6} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor7} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor8} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor9} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={outdoor10} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}

          {activeTab === 3 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach1} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach2} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach3} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach8} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach5} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach6} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach7} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach8} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach9} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach10} alt="" className="rounded-lg xl" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>
                  </div>
                </Slider>
              </div>
            </div>}

          {activeTab === 4 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-6 mt-6">
              <Slider {...settings} className="items-center">
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach10} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Morjim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach7} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Baga</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">827 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach4} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1 ml-5">Arpora</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">157 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach6} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Arambol</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">127 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach5} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Anjuna</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">325 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach6} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Vagator</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">896 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach7} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Puri</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">26 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach8} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Daman</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">189 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach1} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">candolim</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">87 km away</p>

                  </div>
                  <div className="xl:px-2 px-1 sm:text-left text-left">
                    <img src={beach2} alt="" className="rounded-lg xl:h-36" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 pt-2 sm:pl-2 pl-2 font-medium truncate xl:text-left xl:pt-2 xl:pl-1  ml-5">Mandrem</p>
                    <p className="font text-sm sm:text-xs xl:text-sm text-neutral-600 truncate  xl:text-left sm:pl-2 pl-2 xl:pt-0  xl:pl-1  ml-5">227 km away</p>

                  </div>
                </Slider>
              </div>
            </div>}
        </div>
      </div>
      </div>

      {/* Trending destinations */}

      <div className="">
        <div className="container relative  mb-6  xl:mb-16 xl:mt-2 lg:mb-16 mt-0">
          <div className="lg:font-semibold font-medium font lg:text-2xl text-base lg:pt-0 pt-6 sm:pt-2">
            Trending Destinations
          </div>
          <div className="font font-regular lg:text-base text-xs text-neutral-600 lg:pb-6 pb-4">
            Most Popular Choices for Travellers from India
          </div>
          <div className="grid lg:grid-cols-1 xl:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 xl:gap-4 sm:gap-2">
            <div className="grid lg:grid-cols-2 xl:grid-cols-2 md:grid-cols-2 md:gap-4 sm:grid-cols-2 xl:gap-4 lg:gap-4 items-center rounded-xl xl:my-0 ">
              <div className="font">
                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform mb-4 xl:mb-0" style={{
                  backgroundImage: `url(${newdelhi})`,
                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                }} >
                  <a href=""> <p className="text-white pt-4 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-lg xl:text-3xl xl:pt-5 ">New Delhi</p>  </a>
                </article>
              </div>
              <div className="font">
                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform mb-4 xl:mb-0" style={{
                  backgroundImage: `url(${banglore})`,
                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                }} >
                  <a href=""> <p className="text-white pt-4 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-lg xl:text-3xl xl:pt-5">Banglore</p>  </a>
                </article>
              </div>
            </div>
            <div className="flex grid lg:grid-cols-3 xl:grid-cols-3  md:grid-cols-3 md:gap-4 sm:grid-cols-2 xl:gap-4 lg:gap-4 items-center rounded-xl xl:my-0 ">
              <div>
                <article className="h-full rounded-xl md:h-32 hover:shadow-2xl transition-shadow will-change-transform mb-4 xl:mb-0" style={{
                  backgroundImage: `url(${mumbai})`,
                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                }} >
                  <a href=""> <p className="text-white pt-4 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-lg xl:text-3xl xl:pt-5">Mumbai</p>  </a>
                </article>
              </div>
              <div>
                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform mb-4 xl:mb-0" style={{
                  backgroundImage: `url(${chennai})`,
                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                }} >
                  <a href=""> <p className="text-white pt-4 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-lg xl:text-3xl xl:pt-5">Chennai</p>  </a>
                </article>
              </div>
              <div>
                <article className="h-full rounded-xl  hover:shadow-2xl transition-shadow will-change-transform mb-4 xl:mb-0" style={{
                  backgroundImage: `url(${jaipur})`,
                  width: "100%",
                  height: "300px",
                  backgroundSize: "cover",
                }} >
                  <a href=""> <p className="text-white pt-4 pl-4 lg:pl-6 lg:pt-2 font-medium font lg:text-lg text-lg xl:text-3xl xl:pt-5">Jaipur</p>  </a>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="xl:block lg:hidden hidden md:hidden sm:hidden">
      <div className="container pb-2 md:pb-3 lg:py-0 space-y-6 lg:space-y-2 pb-2 xl:mb-16">
        <div className="tab-buttons text-center">
          <div className="flex xl:gap-0 gap-2 xl:pl-24">
            <button
              className={activeeTab === 1 ? 'activenamee md:text-sm xl:text-base' : 'font sm:text-base md:text-sm mx-2 xl:mx-1 xl:px-6 xl:py-4 rounded-full xl:text-base xl:py-0 py-2'}
              onClick={() => handleTabClick1(1)}
            >
              Beach Vacations
            </button>
            <button
              className={activeeTab === 2 ? 'activenamee md:text-sm xl:text-base' : 'font sm:text-base  md:text-sm mx-2 xl:mx-1 xl:px-6 xl:py-4 rounded-full xl:text-base xl:py-0 py-2'}
              onClick={() => handleTabClick1(2)}
            >
              Weekend Getaways
            </button>
            <button
              className={activeeTab === 3 ? 'activenamee md:text-sm xl:text-base' : 'font sm:text-base  md:text-sm mx-2 xl:mx-1 xl:px-6 xl:py-4 rounded-full xl:text-base xl:py-0 py-2'}
              onClick={() => handleTabClick1(3)}
            >
              Mountains Calling
            </button>
            <button
              className={activeeTab === 4 ? 'activenamee md:text-sm xl:text-base' : 'font sm:text-base  md:text-sm mx-2 xl:mx-1 xl:px-6 xl:py-4 rounded-full xl:text-base xl:py-0 py-2'}
              onClick={() => handleTabClick1(4)}
            >
              Stay Like Royals
            </button>
            <button
              className={activeeTab === 5 ? 'activenamee md:text-sm xl:text-base' : 'font sm:text-base  md:text-sm mx-2 xl:mx-1 xl:px-6 xl:py-4 rounded-full xl:text-base xl:py-0 py-2'}
              onClick={() => handleTabClick1(5)}
            >
              Indian Pilgrimages
            </button>
            <button
              className={activeeTab === 6 ? 'activenamee md:text-sm xl:text-base' : 'font sm:text-base  md:text-sm mx-2 xl:mx-1 xl:px-6 xl:py-4 rounded-full xl:text-base xl:py-0 py-2'}
              onClick={() => handleTabClick1(6)}
            >
              Party Destinations
            </button>
          </div>
        </div>

        <div className="tab-content text-center">
          {activeeTab === 1 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className=" space-y-24 mb-3 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-10 mt-6">
                <Slider {...settingss} className="items-center">
                  <div className="xl:px-2 px-1">
                    <img src={beach11} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-3">Morjim</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach12} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach13} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Baga</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach14} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach15} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arpora</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach16} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                </Slider>
              </div>
            </div>}

          {activeeTab === 2 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-3 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-10 mt-6">
              <Slider {...settingss} className="items-center">
                  <div className="xl:px-2 px-1">
                    <img src={beach17} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-3">Morjim</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach18} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={rmc1} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Baga</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach11} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={outdoor7} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arpora</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach13} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                </Slider>
              </div>
            </div>}

          {activeeTab === 3 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-3 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-10 mt-6">
              <Slider {...settingss} className="items-center">
                  <div className="xl:px-2 px-1">
                    <img src={rmc1} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-3">Morjim</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={rmc2} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={rmc3} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Baga</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach12} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={rmc8} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arpora</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach9} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                </Slider>
              </div>
            </div>}

          {activeeTab === 4 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-3 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-10 mt-6">
              <Slider {...settingss} className="items-center">
                  <div className="xl:px-2 px-1">
                    <img src={beach11} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-3">Morjim</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach12} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city1} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Baga</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach14} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach15} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arpora</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach16} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                </Slider>
              </div>
            </div>}

          {activeeTab === 5 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-3 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-10 mt-6">
              <Slider {...settingss} className="items-center">
                  <div className="xl:px-2 px-1">
                    <img src={city1} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-3">Morjim</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city2} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city3} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Baga</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city4} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city5} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arpora</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city6} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                </Slider>   
              </div>
            </div>}

          {activeeTab === 6 &&
            <div className="content-center xl:px-3 xl:text-xl">
              <div className="space-y-24 mb-3 lg:space-y-2 xl:mb-16 xl:mt-6 lg:mb-16 lg:mt-10 mt-6">
              <Slider {...settingss} className="items-center">
                  <div className="xl:px-2 px-1">
                    <img src={outdoor} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-3">Morjim</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach18} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach19} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Baga</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={beach11} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={city10} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arpora</p>
                  </div>
                  <div className="xl:px-2 px-1">
                    <img src={outdoor1} alt="" className="rounded-md xl:h-40 xl:w-72" />
                    <p className="font text-base sm:text-base xl:text-base text-neutral-900 dark:text-neutral-100 font-medium truncate xl:text-left xl:pt-2 xl:pl-1">Arambol</p>
                  </div>
                </Slider>
              </div>
            </div>}
        </div>
      </div>
      </div>

     {/* Trawel blog section */}

      <div className="container relative space-y-2 mb-24 lg:space-y-0 lg:mb-16 ">
        <div className="flex lg:flex-row justify-between items-center">
          <div className="">
            <div className="lg:font-semibold font-medium font lg:text-2xl text-lg ">
            Travel Blog
            </div>
            <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 lg:block hidden">
            Suggestions tailored to your interest
            </div>
            <div className="font font-normal lg:text-base text-xs text-neutral-600 lg:pb-6 block  lg:hidden">
            Suggestions tailored to your interest
            </div>
          </div>
        </div>
        <div className="lg:mt-10 grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-4 gap-2 xl:gap-3 lg:gap-1 md:gap-3 ">
          {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
        </div>
      </div>
    </div>

  );
}

export default HotelHome;
