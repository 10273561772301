import React, { FC, useState } from "react";

import email from "images/email.png"
import pic from "images/wepik-export-20230614115601ljhY.png"
import car from "images/sedan-car-front.png"
import plane from "images/aircraft.png"
import restaurant from "images/cutlery.png"
import building from "images/apartment.png"
import trek from "images/hiker.png"


export interface FlightCard7Props {
  className?: string;
  defaultOpen?: boolean;
  data: {
    id: string;
    airlines: {
      logo: string;
      name: string;
    };
    price: string;
  };
}

const FlightCard7: FC<FlightCard7Props> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // const renderDetailTop = () => {
  //   return (
  //     <div>
  //         <div className="flex flex-col md:flex-row ">
  //         <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
  //           <img src={data.airlines.logo} className="w-10" alt="" />
  //         </div>
  //         <div className="flex my-5 md:my-0">
  //           <div className="flex-shrink-0 flex flex-col items-center py-2">
  //             <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  //             <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
  //             <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  //           </div>
  //           <div className="ml-4 space-y-10 text-sm">
  //             <div className="flex flex-col space-y-1">
  //               <span className=" text-neutral-500 dark:text-neutral-400">
  //                 Monday, August 12 · 10:00
  //               </span>
  //               <span className=" font-semibold">
  //                 Tokyo International Airport (HND)
  //               </span>
  //             </div>
  //             <div className="flex flex-col space-y-1">
  //               <span className=" text-neutral-500 dark:text-neutral-400">
  //                 Monday, August 16 · 10:00
  //               </span>
  //               <span className=" font-semibold">
  //                 Singapore International Airport (SIN)
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
  //         <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
  //           <li>Trip time: 7 hours 45 minutes</li>
  //           <li>ANA · Business class · Boeing 787 · NH 847</li>
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="flex mt-4 mr-2">
            <div className="w-full border rounded-2xl ">
              <div className="p-4  rounded-t-xl text-center">
                <h2 className="font text-sm lg:text-xl font-semibold inline md:text-">Flights from Bengaluru to Dubai</h2>
                {/* <span> - 7 Nights stay</span> */}
              </div>

              <hr />
              <div className="flex bg-sky-100 ">
                <div className="lg:w-2/12 md:w-3/12 w-2/12 bg-[#fbf7fb] p-3 sm:p-2 bg-sky-100 lg:bg-sky-100">
                  <h2 className="lg:font-semibold font-semibold font lg:text-xs text-xs font text-center xl:pl-7 lg:text-left lg:pl-3 md:text-left md:pl-6">FARES</h2>
                  <h1 className="sm:w-52 lg:w-0"></h1>
                </div>

                <div className="lg:w-1/12 md:w-2/12 w-1/12 bg-[#fbf7fb] p-3 sm:p-2 md:p-0 md:mt-2 bg-sky-100 xl:mt-0 lg lg:mt-0 lg:bg-sky-100  text-left xl:text-center lg:text-left lg:p-1 lg:pt-2">
                  <p className="lg:font-semibold font-semibold font lg:text-xs text-xs font">CABIN BAG</p>
                </div>

                <div className="lg:w-1/12 md:w-2/12 w-1/12 bg-[#fbf7fb] p-3 sm:p-2 md:p-0 md:mt-2 bg-sky-100 lg:bg-sky-100 ">
                  <p className="lg:font-semibold font-semibold font lg:text-xs text-xs font text-center md:text-left">CHECK-IN</p>
                </div>

                <div className="lg:w-2/12 md:w-2/12 w-2/12 bg-[#fbf7fb] p-3 sm:p-2 md:p-0 md:mt-2 bg-sky-100 lg:bg-sky-100">
                  <p className="lg:font-semibold font-semibold font lg:text-xs text-xs font text-center md:text-left xl:text-left lg:text-left lg:pl-1 xl:pl-7">CANCELLATION</p>
                </div>

                <div className="xl:block lg:block md:hidden block sm:hidden lg:w-2/12 w-2/12 bg-[#fbf7fb] p-3 sm:p-2  bg-sky-100 lg:bg-sky-100 ">
                  <p className="lg:font-semibold font-semibold font lg:text-xs text-xs font xl:text-left lg:text-left lg:pl-1 xl:pl-10">DATE CHANGE</p>
                </div>

                <div className="xl:block lg:block md:hidden block sm:hidden lg:w-2/12 w-2/12 bg-[#fbf7fb] p-3  sm:p-2 bg-sky-100 lg:bg-sky-100 ">
                  <p className="lg:font-semibold font-semibold font lg:text-xs text-xs font xl:text-left xl:pl-12 lg:text-left">MEAL</p>
                </div>

                <div className="lg:w-3/12 md:w-2/12 w-3/12 bg-[#fbf7fb] p-3  sm:p-2 text-left bg-sky-100 lg:bg-sky-100 ">
                  <p className="lg:font-semibold font lg:text-xs text-xs font "></p>
                </div>

              </div>

              <hr />
              <div className="flex">
                <div className="lg:w-2/12 md:w-3/12 bg-[#fbf7fb] lg:p-4 p-2  md:p-3 xl:pl-9 md:pl-8">
                  <h2 className="lg:font-bold font-semibold font text-xs text-left lg:pb-1">Economy hbo</h2>
                  <p className="text-xs text-left lg:pb-1">Fare offered by airline.</p>
                 <div className="text-xs border border-yellow-600  rounded-lg  py-1 text-yellow-600 w-28 mt-1 pl-1">hand baggage only</div>
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-left">7 Kgs</p>
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-red-500 text-left">0 Kgs</p>
                </div>
 
                <div className="lg:w-2/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left ">
                  <p className="text-xs lg:pb-2">Cancellation Fee starting</p>
                  <p className="text-xs ">₹ 9,000</p>
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs lg:pb-2">Date Change Fee starting</p>
                  <p className="text-xs ">₹ 13,050</p>
                  <div className="text-xs w-28 "></div>
                </div>

                <div className="lg:w-2/12  bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs ">Get complimentary meals</p>
                </div>

                <div className=" bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xl font-extrabold text-sky-600 xl:pl-2 xl:pb-1 xl:ml-16  lg:ml-0 md:pl-12"> ₹ 14,118</p>
                  <div className="w-44 xl:ml-4">
                  {/* <button className="font text-xs text-sky-600 border-sky-500 border xl:rounded-xl xl:mt-2 px-3 py-1 font-medium md:rounded-xl">Lock Price</button> */}
                  <button className="font text-xs text-white gradient2 lg:ml-12 md:ml-12 rounded-lg xl:ml-16 xl:mt-2 xl:px-3 xl:py-1.5 px-3 py-1 font-medium ">Book Now</button>
                </div>
                </div>
              </div>

                {/* <div className="lg:w-5/12 p-4 flex items-center">
                  <div className="flex items-center">
                    <img src={building} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Check in to Hampton By Hilton Dubai Airport - Holidays Selections, 3 Star
                    </h2>
                  </div>
                </div> */}

                {/* <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2

                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The stunning architecture of the area is showcased in full glory, including the National Bank of Dubai.
                    </h2>
                  </div>
                </div> */}

              <hr />
                 <div className="flex">
                <div className="lg:w-2/12 md:w-3/12 bg-[#fbf7fb] lg:p-4 p-2 md:p-3 xl:pl-9 md:pl-8">
                  <h2 className="lg:font-bold font-semibold font text-xs text-left lg:pb-1">MMTSPECIAL</h2>
                  <p className="text-xs text-left lg:pb-1">Fare by MakeMyTrip</p>
                 <div className="text-xs border border-yellow-600  rounded-lg py-1 text-yellow-600 w-28 mt-1 pl-1">hand baggage only</div>
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-left">7 Kgs</p>
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-red-500 text-left">0 Kgs</p>
                </div>

                <div className="lg:w-2/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left ">
                  <p className="text-xs lg:pb-2">Cancellation Fee starting</p>
                  <p className="text-xs ">₹ 9,000</p>
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs lg:pb-2">Date Change Fee starting</p>
                  <p className="text-xs ">₹ 13,050</p>
                  <div className="text-xs w-28 "></div>
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs ">Get complimentary meals</p>
                </div>

                <div className=" bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xl font-extrabold text-sky-600 xl:pl-2 xl:pb-1 xl:ml-16  lg:ml-0 md:pl-12"> ₹ 14,778</p>
                  <div className="w-44 xl:ml-4">
                  {/* <button className="font text-xs text-sky-600 border-sky-500 border xl:rounded-xl xl:mt-2 px-3 py-1 font-medium md:rounded-xl">Lock Price</button> */}
                  <button className="font text-xs text-white gradient2 lg:ml-12 md:ml-12 rounded-lg xl:ml-16 xl:mt-2 xl:px-3 xl:py-1.5 px-3 py-1 font-medium ">Book Now</button>
                </div>
                </div>
              </div>

              <hr />
              <div className="flex">
                <div className="lg:w-2/12 md:w-3/12 bg-[#fbf7fb] lg:p-4 p-2 md:p-3 xl:pl-9 md:pl-8">
                  <h2 className="lg:font-bold font-semibold font text-xs text-left lg:pb-1">Economy Choice </h2>
                  <p className="text-xs text-left lg:pb-1">Fare offered by airline.</p>
                  <div className="text-xs  w-28 "></div>               
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-left">7 Kgs</p>
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-left">30 Kgs</p>
                </div>

                <div className="lg:w-2/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left ">
                  <p className="text-xs lg:pb-2">No airline cancellation fee</p>
                  {/* <p className="text-xs ">₹ 9,000</p> */}
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs lg:pb-2">Date Change Fee starting</p>
                  <p className="text-xs ">₹ 2,000</p>
                  <div className="text-xs  w-28 "></div>
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs ">Get complimentary meals</p>
                </div>

                <div className=" bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xl font-extrabold text-sky-600 xl:pl-2 xl:pb-1 xl:ml-16  lg:ml-0 md:pl-12"> ₹ 15,136</p>
                  <div className="w-44 xl:ml-4">
                  {/* <button className="font text-xs text-sky-600 border-sky-500 border xl:rounded-xl xl:mt-2 px-3 py-1 font-medium md:rounded-xl">Lock Price</button> */}
                  <button className="font text-xs text-white gradient2 lg:ml-12 md:ml-12 rounded-lg xl:ml-16 xl:mt-2 xl:px-3 xl:py-1.5 px-3 py-1 font-medium ">Book Now</button>
                </div>
                </div>
              </div>

              <hr className=" w-full" />
              <div className="flex">
                <div className="lg:w-2/12 md:w-3/12 bg-[#fbf7fb] lg:p-4 p-2 md:p-3 xl:pl-9 md:pl-8">
                  <h2 className="lg:font-bold font-semibold font text-xs text-left lg:pb-1">Economy Choice Plus</h2>
                  <p className="text-xs text-left lg:pb-1">Fare offered by airline.</p>
                  <div className="text-xs  w-28 "></div>               
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-left">7 Kgs</p>
                </div>

                <div className="lg:w-1/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xs text-left">35 Kgs</p>
                </div>

                <div className="lg:w-2/12 md:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left ">
                  <p className="text-xs lg:pb-2">No airline cancellation fee</p>
                  {/* <p className="text-xs ">₹ 9,000</p> */}
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs lg:pb-2">Date Change Fee starting</p>
                  <p className="text-xs ">₹ 2,000</p>
                  <div className="text-xs  w-28 "></div>
                </div>

                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 md:p-3 text-left xl:block lg:block md:hidden block sm:hidden">
                  <p className="text-xs ">Get complimentary meals</p>
                </div>

                <div className=" bg-[#fbf7fb] p-4 md:p-3">
                  <p className="text-xl font-extrabold text-sky-600 xl:pl-2 xl:pb-1 xl:ml-16  lg:ml-0 md:pl-12"> ₹ 18,569</p>
                  <div className="w-44 xl:ml-4">
                  {/* <button className="font text-xs text-sky-600 border-sky-500 border xl:rounded-xl xl:mt-2 px-3 py-1 font-medium md:rounded-xl">Lock Price</button> */}
                  <button className="font text-xs text-white gradient2 lg:ml-12 md:ml-12 rounded-lg xl:ml-16 xl:mt-2 xl:px-3 xl:py-1.5 px-3 py-1 font-medium ">Book Now</button>
                </div>
                </div>
              </div>

              {/* <hr className=" w-full" />

              <div className="flex">
                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 ">
                  <h2 className="font-bold font">Day 5</h2>
                  <p>Jul 10, Mon</p>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The Aquaventure Park and Lost Chambers Enjoy a water-themed day of adventure at one of Dubai’s most iconic structures, Atlantis The Palm.
                    </h2>
                  </div>
                </div>

              </div> */}

              {/* <hr className=" w-full" />

              <div className="flex ">
                <div className="lg:w-2/12 bg-[#fbf7fb] rounded-bl-2xl p-4 ">
                  <h2 className="font-bold font">Day 6</h2>
                  <p>Jul 11, Tue</p>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center border-l ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2

                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      A large building in the shape of a frame, it is an architectural marvel that stands out against the Dubai city skyline! It offers a breath-taking view of Old and New Dubai!
                    </h2>
                  </div>
                </div>
              </div> */}


              {/* <hr className=" w-full" />

              <div className="flex ">
                <div className="lg:w-2/12 bg-[#fbf7fb] rounded-bl-2xl p-4 ">
                  <h2 className="font-bold font">Day 7</h2>
                  <p>Jul 12, Wed</p>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The Ferrari Theme Park is another attraction to thrill your senses, not for the faint-hearted!
                    </h2>
                  </div>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>
              </div> */}

              
              {/* <hr className=" w-full" />
              <div className="flex ">
                <div className="lg:w-2/12 bg-[#fbf7fb] rounded-bl-2xl p-4 ">
                  <h2 className="font-bold font">Day 8</h2>
                  <p>Jul 13, Thu</p>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={building} alt="no" className="mr-4 w-6 h-6" />
                    <h2

                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Checkout from Hotel in Dubai
                    </h2>
                  </div>
                </div>
              </div> */}

            </div>
          </div>



          {/* <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            Transit time: 15 hours 45 minutes - Bangkok (BKK)
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-700" /> */}
        </div>
        {/* {renderDetailTop()} */}
      </div>
    );
  };                      

  return (
    <div
      className={`font nc-FlightCardgroup relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={`font sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 lg:w-10 w-6 h-5 lg:h-10 mb-6 mr-2 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="lg:text-xl text-sm  las la-angle-down"></i>
        </span>
        <div className="xl:block lg:hidden hidden md:hidden sm:hidden"> 
        <div className="grid lg:grid-cols-5 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
         
          <div className="flex w-24 lg:w-32 flex-shrink-0 lg:ml-6 items-right rounded-full">
            <img src={"https://i.ibb.co/Y0GDqp4/vistara1.png"} className="w-12 pl-2 pt-4 lg:pt-3 lg:pb-3 rounded-full lg:w-16" alt="" />
            <div className="font-bold lg:text-lg text-right w-10 lg:pt-5 lg:pl-3">Vistara</div>
            {/* <p className="text-xs lg:text:xs sm:text-sm text-neutral-500 font-normal mt-0.5">I5 788, I5 337</p> */}
          </div>

          {/* <div className="text-xs lg:block lg:text-left">
            <div className="font-bold lg:text-lg">Etihad Airways</div>
          </div> */}

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">23:15</span>
            <div className="font-regular text-xs">Singapore</div>
          </div>

          <div className="lg:font-medium">
            <p className="font lg:text-lg text-xs">02 h 05 m</p>
            <div className="ftDropDownnew"></div>
            <p className="font-regular text-xs text-sky-600 lg:pt-1">Non stop</p>
          </div>

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">01:20</span>
            <div className="font-regular text-xs">Kolkata</div>
            <p className="font-regular text-xs">(437 KM from Singapore)</p>
          </div>
          {/* FOR MOBILE RESPONSIVE */}
          {/* <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>11:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  HND
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>20:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  SIN
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>7h 45m</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div> */}

          {/* TIME - NAME */}
          {/* <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">11:00 - 20:00</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.airlines.name}
            </div>
          </div> */}

          {/* TIMME */}
          {/* <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg"> HND - SIN</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              7 hours 15 minutes
            </div>
          </div> */}

          {/* TYPE */}
          {/* <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">1 stop</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              2 hours 15 minutes BKK
            </div>
          </div> */}

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="lg:text-xl text-sm font-extrabold text-sky-600">
                {data.price}
              </span>
            </div>
            <div className="text-xs lg:text:xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              Best Price Trip
            </div>
          </div>
          </div>
        </div>


          {/* section mobile view  */}

      <div className="lg:hidden md:hidden sm:hidden xl:hidden block sm:block">
        <div className="grid grid-cols-3 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="flex lg:w-32 flex-shrink-0 lg:ml-7">
            <img src={"https://i.ibb.co/Y0GDqp4/vistara1.png"} className="w-16 h-12 pl-4 mt-4 lg:pt-1 rounded-lg lg:w-20" alt="" />
            {/* <p className="font-bold lg:text-lg">Etihad Airways </p> */}
          </div>

          <div className="text-xs lg:block ">
            <div className="font-bold text-base text-left">Vistara</div>
          </div>

          {/* <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">04:30</span>
            <div className="font-regular text-xs">Bengaluru</div>
          </div>

          <div className="lg:font-medium">
            <p className="font lg:text-lg text-xs">04h 10m</p>
            <p className="font-regular text-xs text-sky-600">Non stop</p>
          </div>

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">07:00</span>
            <div className="font-regular text-xs">Abu Dhabi</div>
            <p className="font-regular text-xs">(127 KM from Dubai)</p>
          </div> */}
          
          {/* FOR MOBILE RESPONSIVE */}
          {/* <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>11:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  HND
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>20:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  SIN
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>7h 45m</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div> */}

          <div className="flex-[4] whitespace-nowrap sm:text-right mr-3">
            <div>
              <span className="lg:text-xl text-lg font-extrabold text-sky-600">
                {data.price}
              </span>
            </div>
            <div className="text-xs lg:text:xs sm:text-sm text-neutral-500 font-normal mt-0.5 mb-2">
              Best Price Trip
            </div>
          </div>
        </div>
        </div>



         {/* section mobile landscape  view  */}

      <div className="font xl:hidden lg:hidden hidden md:block sm:hidden">
        <div className="grid grid-cols-5 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="flex w-24 lg:w-32 flex-shrink-0 lg:ml-6 items-right">
            <img src={"https://i.ibb.co/Y0GDqp4/vistara1.png"} className="w-12 pl-2 pt-4 md:pt-3 md:pb-3 rounded-lg lg:w-16" alt="" />
            <div className="font-bold lg:text-lg text-right w-10 md:pt-5 md:pl-3">Vistara</div>
          </div>

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">23:15</span>
            <div className="font-regular text-xs">Mumbai</div>
          </div>

          <div className="lg:font-medium">
            <p className="font lg:text-lg text-xs">04h 10m</p>
            <div className="ftDropDownneww"></div>
            <p className="font-regular text-xs text-sky-600">Non stop</p>
          </div>

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">07:00</span>
            <div className="font-regular text-xs">Abu Dhabi</div>
            <p className="font-regular text-xs">(127 KM from Dubai)</p>
          </div>

          {/* <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">04:30</span>
            <div className="font-regular text-xs">Bengaluru</div>
          </div>

          <div className="lg:font-medium">
            <p className="font lg:text-lg text-xs">04h 10m</p>
            <p className="font-regular text-xs text-sky-600">Non stop</p>
          </div>

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">07:00</span>
            <div className="font-regular text-xs">Abu Dhabi</div>
            <p className="font-regular text-xs">(127 KM from Dubai)</p>
          </div> */}
          
          {/* FOR MOBILE RESPONSIVE */}
          {/* <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>11:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  HND
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>20:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  SIN
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>7h 45m</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div> */}

          <div className="flex-[4] whitespace-nowrap md:text-right mr-3 md:pl-20">
            <div>
              <span className="lg:text-xl text-lg font-extrabold text-sky-600 md:pl-8">
                {data.price}
              </span>
            </div>
            <div className="text-xs lg:text:xs sm:text-sm text-neutral-500 font-normal mt-0.5 mb-2">
              Best Price Trip
            </div>
          </div>
        </div>
        </div>



        {/* section Tab landscape  view  */}

      <div className="font xl:hidden lg:block md:hidden sm:hidden hidden">
      <div className="grid lg:grid-cols-5 flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
         
          <div className="flex w-24 lg:w-32 flex-shrink-0 lg:ml-6 items-right rounded-full">
            <img src={"https://i.ibb.co/Y0GDqp4/vistara1.png"} className="w-12 pl-2 pt-4 lg:pt-3 lg:pb-3 rounded-full lg:w-16" alt="" />
            <div className="font-bold lg:text-lg text-right w-10 lg:pt-5 lg:pl-3">Vistara</div>
            {/* <p className="text-xs lg:text:xs sm:text-sm text-neutral-500 font-normal mt-0.5">I5 788, I5 337</p> */}
          </div>

          {/* <div className="text-xs lg:block lg:text-left">
            <div className="font-bold lg:text-lg">Etihad Airways</div>
          </div> */}

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">23:15</span>
            <div className="font-regular text-xs">New Delhi</div>
          </div>

          <div className="lg:font-medium">
            <p className="font lg:text-lg text-xs">04h 10m</p>
            <p className="font-regular text-xs text-sky-600">Non stop</p>
          </div>

          <div className="lg:font-medium">
            <span className="font lg:text-lg text-xs line-clamp-1">07:00</span>
            <div className="font-regular text-xs">Abu Dhabi</div>
            <p className="font-regular text-xs">(127 KM from Dubai)</p>
          </div>
          {/* FOR MOBILE RESPONSIVE */}
          {/* <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>11:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  HND
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>20:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  SIN
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>7h 45m</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div> */}

          {/* TIME - NAME */}
          {/* <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">11:00 - 20:00</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.airlines.name}
            </div>
          </div> */}

          {/* TIMME */}
          {/* <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg"> HND - SIN</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              7 hours 15 minutes
            </div>
          </div> */}

          {/* TYPE */}
          {/* <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">1 stop</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              2 hours 15 minutes BKK
            </div>
          </div> */}

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="lg:text-xl text-sm font-extrabold text-sky-600">
                {data.price}
              </span>
            </div>
            <div className="text-xs lg:text:xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              Best Price Trip
            </div>
          </div>
          </div>
        </div>


      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCard7;
