import React, { FC, useState, Fragment, useEffect } from "react";
import Slider from "react-slick";
import { DEMO_STAY_LISTINGS_Newzealand } from "data/listings";
import { StayDataType, TaxonomyType } from "data/types";
import SectionGridFeaturePlaces from "containers/PageHome/SectionGridFeaturePlaces";
import SectionSliderNewCategories4 from "components/SectionSliderNewCategories/SectionSliderNewCategories4";
import { Dialog } from '@headlessui/react'
import Input from "shared/Input/Input";
import india from "images/india.png"
import { Link, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import StayCardNewzealand from "components/StayCard/StayCardNewzealand";
import axios from "axios";
import FrequentlyMaldives from "./FrequentlyMaldives";



const DEMO_CATS: TaxonomyType[] = [
    {
        id: "1",
        href: "/",
        name: "Resort",
        taxonomy: "category",
        count: 188288,
        text: "₹5,000",
        thumbnail:
            "https://i.ibb.co/thDy1qp/maldives1.jpg",
    },
    {
        id: "2",
        href: "/",
        name: "Hotels",
        taxonomy: "category",
        count: 188288,
        text: "₹1,500",
        thumbnail:
            "https://i.ibb.co/mq8R4sr/maldives2.jpg"
    },
    {
        id: "3",
        href: "/",
        name: "Guest House",
        taxonomy: "category",
        count: 188288,
        text: "₹6,000",
        thumbnail:
            "https://i.ibb.co/dgNz8SY/maldives3.jpg",
    },
    {
        id: "4",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/vHC9vzd/maldives4.jpg",
    },
    {
        id: "5",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/TwwSnVJ/maldives5.webp",
    },
    {
        id: "6",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/CVH2whR/maldives6.jpg",
    },

    {
        id: "7",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/W6Sr4S2/maldives7.webp",
    },

    {
        id: "8",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/5jS70w7/maldives8.webp",
    },
    {
        id: "9",
        href: "/listing-stay",
        name: "Inn",
        taxonomy: "category",
        count: 188288,
        text: "₹4,000",
        thumbnail:
            "https://i.ibb.co/QkpKNnS/maldives9.webp",
    },
];

// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS_Newzealand.filter((_, i) => i < 4);

function MaldivesHome() {
    let [isOpen, setIsOpen] = useState(false);
   const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [departureError, setDepartureError] = useState(false);
    const [travelError, setTravelError] = useState(false);
    const [destinationError, setDestinationError] = useState(false);
    const [passangerError, setPassangerError] = useState(false);
    const location = useLocation();
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [mobileNumber, setMobileNumber] = useState('');
    let [departureCity, setDepartureCity] = useState('');
    let [travelDate, setTravelDate] = useState('');
    let [destination, setDestination] = useState('');
    let [noofPassanger, setNoofPassanger] = useState('');
    const [inputDateType, setInputDateType] = useState('text');
    let [description, setDescription] = useState('Enquiring for Newzealand Package');

    useEffect(() => {
       const userDataJSON = localStorage.getItem('loggedInUser');
        if (userDataJSON) {
            const userData = JSON.parse(userDataJSON);
            setName(userData.name);
            setMobileNumber(userData.mobile);
            setEmail(userData.email);
            setDepartureCity(userData.departure);
            setTravelDate(userData.travel);
            setDestination(userData.destination);
            setNoofPassanger(userData.Passanger);
        }
    }, []);

    const handleSubmit = () => {
        if (name.length == 0) {
            setNameError(true);
        }
        else if (/\d/.test(name)) {
            setNameError(true);
        }
        else if (mobileNumber.length != 10) {
            setNameError(false);
            setMobileError(true);
        }
        else if (email.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(true);
        }
        else if (departureCity.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(true);
        }
        else if (travelDate.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(false);
            setTravelError(true);
        }
        else if (noofPassanger.length == 0) {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(false);
            setTravelError(false);
            setDestinationError(false);
            setPassangerError(true);
        }
        else {
            setNameError(false);
            setMobileError(false);
            setEmailError(false);
            setDepartureError(false);
            setTravelError(false);
            setDestinationError(false);
            setPassangerError(false);
            const submitButton = document.getElementById("submitButton");
            if (submitButton) {
                (submitButton as HTMLButtonElement).disabled = true;
                submitButton.innerHTML = "Submitting...";
            }
            const formData = { name: name, mobile: mobileNumber, email_address: email, description: description, url: location.pathname, city: departureCity, travel_date: travelDate, passenger_count: noofPassanger };;
            axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addContact", formData)
                .then((response: any) => {
                    console.log(response);
                    if (response.status == 200) {
                        if (response.data.status) {
                            (window as any).location = '/thanks';
                        }
                        else {
                            toastr.error(response.data.message);
                        }
                    }
                    else {
                        console.log(response.responseText);
                    }
                });
        }
    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
    //testimonials slider //
    const settings = {
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: false,
        speed: 500,
        centerPadding: '10px',
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: false,
        className: "center",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            }
        ]
    };

    const settingss = {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        arrows: true,
        dots: false,
        speed: 500,
        centerPadding: '10px',
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: false,
        className: "center",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: false, /* set centerMode to false to show complete slide instead of 3 */
                    slidesToScroll: 1,
                    arrows: true,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false,
                    arrows: false,
                }
            }
        ]
    };
    const renderCard = (stay: StayDataType) => {
        return <StayCardNewzealand key={stay.id} data={stay} />;
    };


    return (
        <div className=" nc-PageHome relative overflow-hidden">
            {/* GLASSMOPHIN */}
            <div className="font xl:block lg:hidden md:hidden hidden" style={{
                backgroundImage: `url("https://i.ibb.co/GC9fMQd/Maldives-main-ban.png")`,
                width: "100%",
                height: "400px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left xl:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 pb-14 lg:mt-6 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                                <p className="text-4xl bannerfont  text-white">
                                    Discover Paradise
                                </p>
                                <h2 className="font font-bold text-4xl md:text-5xl xl:text-4xl !leading-[114%] lg:pb-3 mt-0 text-white">
                                    Straight to the Paradise!
                                </h2>
                                <span className="text-base md:text-lg lg:text-lg font-normal text-white dark:text-neutral-400">
                                    The tropical pearl of the Indian Ocean, tickling your <br></br>senses and teleporting you directly to paradise!
                                </span>
                                <div className="flex pt-4">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                        Enquire Now
                                    </button>
                                    <Transition appear show={isOpen} as={Fragment}>
                                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0"
                                                enterTo="opacity-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100"
                                                leaveTo="opacity-0"
                                            >
                                                <div className="fixed inset-0 bg-black bg-opacity-25" />
                                            </Transition.Child>

                                            <div className="font fixed inset-0 overflow-y-auto">
                                                <div className="mt-6 flex min-h-full items-center justify-center p-4 text-center">
                                                    <Transition.Child
                                                        as={Fragment}
                                                        enter="ease-out duration-300"
                                                        enterFrom="opacity-0 scale-95"
                                                        enterTo="opacity-100 scale-100"
                                                        leave="ease-in duration-200"
                                                        leaveFrom="opacity-100 scale-100"
                                                        leaveTo="opacity-0 scale-95"
                                                    >
                                                        <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                                                            <div className="text-right mt-2 mr-2">
                                                                <button
                                                                    type="button"
                                                                    className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                                    onClick={closeModal}
                                                                >
                                                                    X
                                                                </button>
                                                            </div>

                                                            <Dialog.Title
                                                                as="h3"
                                                                className="font p-4 bg-[#fbf7fb] lg:-mt-8 -mt-8 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                            >
                                                                Quick Enquiry
                                                                <p className="pt-2 font-normal text-xs text-sky-500">
                                                                    Plan an unforgettable trip with Trawel Mart
                                                                </p>
                                                            </Dialog.Title>

                                                            <div className="mt-2">
                                                                <form>
                                                                    <div className="mx-auto">
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                                                            {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                            {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                                            {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                                                                        </label>
                                                                        <label className="block xl:px-16 px-4">
                                                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                                                        </label>
                                                                    </div>
                                                                    {/*<div className="xl:px-16">
                                                                        <button type="submit"
                                                                            className="w-full rounded-md border border-sky-600 bg-white mt-4 px-4 py-2 text-sm font-medium text-sky-600 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                                                        >
                                                                            Continue
                                                                        </button>
                                                                        </div>
                                                                    <p className="text-xs text-neutral-500 pt-2">
                                                                        OR
                                                                    </p>*/}
                                                                    <div className="xl:px-16 px-4 mt-4">
                                                                        <button type="submit"
                                                                            id="submitButton"
                                                                            className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                                                            Submit Enquiry
                                                                        </button>
                                                                    </div>
                                                                    <p className="mt-4 text-xs text-center">
                                                                        Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                                    </p>
                                                                    <div className="bg-[#fbf7fb] mt-6">
                                                                        <div className="w-full flex items-center justify-between p-2 xl:pl-12 lg:pl-12 md:pl-12 pl-2">
                                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                                <div className="lg:w-2/12">
                                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                                </div>
                                                                                <div className="lg:w-9/12 pl-2">
                                                                                    <span className="text-sm font-medium ">
                                                                                        Super Offers
                                                                                    </span>
                                                                                    <p className="text-sky-700 italic font-normal text-xs ">
                                                                                        Explore Great Deals
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                                <div className="lg:w-2/12">
                                                                                    <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                                                                </div>
                                                                                <div className="lg:w-10/12 pl-2">
                                                                                    <span className="text-sm font-medium ">
                                                                                        My Trips
                                                                                    </span>
                                                                                    <p className="text-sky-700 font-normal italic text-xs ">
                                                                                        Manage Your Bookings
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </Dialog.Panel>
                                                    </Transition.Child>
                                                </div>
                                            </div>
                                        </Dialog>
                                    </Transition>
                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*=== banner LG Screen === */}

            <div className="font xl:hidden lg:block md:hidden hidden" style={{
                backgroundImage: `url("https://i.ibb.co/GC9fMQd/Maldives-main-ban.png")`,
                width: "100%",
                height: "350px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left lg:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:mt-6 lg:mt-6 mt-14 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                                <p className="text-4xl bannerfont  text-white">
                                    Discover Paradise
                                </p>
                                <h2 className="font font-bold text-4xl md:text-4xl xl:text-4xl !leading-[114%] lg:pb-2 mt-0 text-white">
                                    Straight to the Paradise!
                                </h2>
                                <span className="text-base md:text-lg lg:text-base font-normal text-white dark:text-neutral-400 lg:block ">
                                    The tropical pearl of the Indian Ocean, tickling your <br></br>senses and teleporting you directly to paradise!
                                </span>
                                <div className="flex pt-2">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                        Enquire Now
                                    </button>
                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/*=== banner MD Screen === */}
            <div className="font xl:hidden lg:hidden md:block hidden" style={{
                backgroundImage: `url("https://i.ibb.co/GC9fMQd/Maldives-main-ban.png")`,
                width: "100%",
                height: "240px",
                backgroundSize: "cover",
            }}>
                <div className="container relative space-y-24 mb-6 lg:space-y-28 lg:mb-2 ">
                    <div
                        className={` nc-SectionHero flex flex-col-reverse lg:flex-col relative`}
                        data-nc-id="SectionHero">
                        <div className="lg:items-center text-left xl:pt-16 ">
                            <div className="flex-shrink-0 lg:w-12/12 items-start space-y-2 xl:pb-14 lg:pb-14 xl:mt-6 lg:mt-2 mt-10 lg:pb-52 xl:pr-14 lg:mr-10 xl:mr-0">
                                <p className="text-2xl bannerfont  text-white">
                                    Discover Paradise
                                </p>
                                <h2 className="font font-bold text-4xl md:text-3xl xl:text-4xl !leading-[114%] lg:pb-0 mt-0 text-white">
                                    Straight to the Paradise!
                                </h2>
                                <span className="text-base md:text-base lg:text-base font-normal text-white dark:text-neutral-400 md:block ">
                                    The tropical pearl of the Indian Ocean, tickling your <br></br>senses and teleporting you directly to paradise!
                                </span>
                                <div className="flex pt-2">
                                    <button
                                        type="button"
                                        onClick={openModal}
                                        className="bg-orange-400  rounded px-8 py-2 text-white font-normal text-sm">
                                        Enquire Now
                                    </button>
                                    <a href="/contact">
                                        <button className="bg-sky-700  rounded px-8 py-2 text-white  font-normal text-sm  ml-4 ">
                                            Contact Us
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

             {/* =========== RESORT ================ */}

            <div className="container relative  mb-6 lg:space-y-2 xl:mb-16 xl:mt-10 lg:mb-16 md:mb-8 lg:mt-10 mt-6">
                <div>
                    <div className="font text-black font-bold text-2xl xl:pb-4 pb-4">Resort</div>
                </div>
                <Slider {...settingss} className="items-center">
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/XSLkrG1/oblunew.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/BV7D60G/obluhelengele.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Oblu Nature Helengeli</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/N39NdLD/oblu1.webp"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/FV6SxQd/oblu2.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Oblu Xperience Ailafush</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/Scj142y/sun.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/HtYSwNg/sunlogo.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Sun Siyam olhuveli</p>
                            </div>
                        </div>                        
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/c20DqhG/Adaaran-Select-Meedhupparu.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/zbW8rKB/adaranlogo.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Adaaran Meedhupparu</p>
                            </div>
                        </div>                      
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/7zn8gYG/adaran.webp"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/zbW8rKB/adaranlogo.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 lg:w-8 lg:h-8 rounded-full xl:mt-1 xl:block md:hidden lg:block" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Adaaran Fushi</p>
                            </div>
                        </div>                      
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/PCgZZpw/dhifguru.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/8x9gHxY/logo.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Dhigufaru Island Resort</p>
                            </div>
                        </div>                        
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/0nk4VpC/p7.webp"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/gVW655q/paradise.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Paradise Island Resort</p>
                            </div>
                        </div>                    
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/xG0gmdd/sun.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/n3JVv7j/sunlogo.jpg" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 md:ml-2">Sun Island Resort</p>
                            </div>
                        </div>                     
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/YQVwWBC/centara.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/nsf9Q87/Centara-logo-260x340.jpg" className="xl:w-10 xl:h-10 md:w-8 md:h-8 rounded-full xl:mt-1 xl:block md:hidden lg:block" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3">Centara Ras Fushi</p>
                            </div>
                        </div>                       
                    </div>
                    <div className="xl:px-3 px-1">
                        <a href="/Hotel-1"><img className="object-cover xl:w-96 xl:h-72 md:w-64 md:h-52 h-56 w-96 rounded-2xl" src="https://i.ibb.co/pb4Y117/kandima.jpg"  alt="" /></a>
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div className="hidden xl:block md:hidden lg:block">
                                <img src="https://i.ibb.co/r31mxks/Kandima-Maldives-Logo-420x420.png" className="xl:w-10 xl:h-10 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font lg:text-lg md:text-sm text-sm sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 lg:ml-2">Kandima Maldives</p>
                            </div>
                        </div>                       
                    </div>
                </Slider>

                {/* </div> */}
            </div>



            {/* =========== IMAGE SLIDER ================ */}
            <div className="container relative space-y-24 mb-6 lg:space-y-2 xl:mb-16 xl:mt-10 lg:mb-16 lg:mt-10 mt-6">
                <Slider {...settings} className="items-center">
                    <div className="xl:px-3 px-1">
                        <img src="https://i.ibb.co/x1pz9fn/Maldives-ban1.png" alt="" className="rounded-lg" />
                    </div>
                    <div className="xl:px-3 px-1">
                        <img src="https://i.ibb.co/nc843kV/Maldives-ban2.png" alt="" className="rounded-lg" />
                    </div>
                    <div className="xl:px-3 px-1">
                        <img src="https://i.ibb.co/w7fshQj/Maldives-ban3.png" alt="" className="rounded-lg" />
                    </div>
                    <div className="xl:px-3 px-1">
                        <img src="https://i.ibb.co/64RBsPp/Maldives-ban4.png" alt="" className="rounded-lg" />
                    </div>
                </Slider>
            </div>


            {/* =========== Top Visit Place ================ */}
            <div className="container relative space-y-2 mb-8 lg:space-y-2 lg:mb-16 lg:mt-10">
                <div className="font text-black font-semibold text-xl pb-4">
                    Top Places to Visit in Maldives
                </div>
                <div className="grid lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">
                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/QkpKNnS/maldives9.webp" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Fulhadoo Island
                            </h4>
                            <div className="flex  items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    5
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/8xzYqjY/istanbul-36h-basilica.jpg" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Sun Island
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-1">
                                    5
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/15/81/e6/a0/img-20181123-163637-largejpg.jpg?w=500&h=-1&s=1" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Sultanahmet District
                            </h4>
                            <div className="flex  items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    4.8
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://dynamic-media-cdn.tripadvisor.com/media/photo-o/14/ac/57/31/suleymaniye.jpg?w=500&h=400&s=1" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Suleymaniye Mosque
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    4.0
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="grid lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2">
                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/L1XdY5T/topkapi-palace-museum.jpg" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Topkapi Palace
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    4.9
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/0GxKspc/efes-gezisi-ulasim-rehber.jpg" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Ancient City of Ephesus
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    4.6
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/k6jWNQj/blue-mosque-istanbul.jpg" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Blue Mosque
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    4.6
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center border border-sky-100 rounded-lg shadow-md py-2 px-2 xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/CK3Z2dC/bosphorus-yalis.jpg" alt="" className="w-20 h-20 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Bosphorus Strait
                            </h4>
                            <div className="flex  items-center ">
                                <div>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                    <span className="las la-star checked text-xs"></span>
                                </div>
                                <div className="pl-2">
                                    4.0
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container relative space-y-24 mb-6 lg:space-y-0 lg:mb-10 lg:px-24">
                <div className="lg:px-12">
                    <img src="https://i.ibb.co/syyf0rZ/Maldives-minban.png" alt="" className="rounded-xl " />
                </div>
            </div>

            {/* =========== Bali Tour Packages ================ */}

            <div className="container relative space-y-2 mb-12 lg:space-y-2 lg:mb-8 lg:mt-4">
                <div className="font text-black font-bold text-2xl pb-4">
                    Maldives Tour Packages
                </div>
                <div
                    className={`grid gap-6 md:gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 `} >
                    {DEMO_DATA.map((stay) => renderCard(stay))}
                </div>
            </div>


            {/* =========== Popular Destinations ================ */}

            <div className="container relative space-y-2 mb-12 lg:space-y-2 lg:mb-16 lg:mt-8">
                <SectionSliderNewCategories4
                    categories={DEMO_CATS}
                    uniqueClassName="PageHome_s1"
                />
            </div>

            {/* =========== Logos ================ */}  
            <div className="container relative mb-12 lg:mb-16 xl:mb-24 lg:mt-10">
                <div className="lg:font-semibold font-medium font lg:text-2xl text-base xl:pt-6 lg:pt-0 pt-6 text-center">Activities</div>
                <div className="font font-regular text-center lg:text-base text-xs text-neutral-600 pb-5 xl:pb-10">The tropical pearl of the Indian Ocean, tickling your senses and teleporting you directly to paradise!</div>
                <div className="grid xl:grid-cols-6 lg:grid-cols-6 md:grid-cols-6 grid-cols-3">
                    <div className="border-b border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-7 md:mt-4 lg:mt-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/MgWdPrT/27.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Snorkelling</p>
                            </div>
                        </div>  
                    </div>
                    <div className="border-b border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-4 md:mt-4 lg:mt-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/P6GKhHK/12.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Bicycle</p>
                            </div>
                        </div>  
                    </div>
                    <div className="border-b xl:border-r lg:border-r border-r-0 md:border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-7 md:mt-4 lg:mt-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/S7qQvst/23.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Indoor Sports</p>
                            </div>
                        </div>  
                    </div>
                    <div className="border-b border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-5 md:mt-4 lg:mt-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/Sd8CkyK/22.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Water Sports</p>
                            </div>
                        </div>  
                    </div>
                    <div className="border-b border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-0 md:mt-4 lg:mt-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/zPYLWg6/26.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Yoga</p>
                            </div>
                        </div>  
                    </div>
                    

                    <div className="border-b">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-1 md:mt-4 lg:mt-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/f0YNkHK/25.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Diving</p>
                            </div>
                        </div>  
                    </div>

                    <div className="border-b border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-0 md:mt-4 lg:mt-4 my-1">
                            <div>
                                <img src="https://i.ibb.co/pWXNthS/7.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Wedding</p>
                            </div>
                        </div>  
                    </div>
                    <div className="border-b border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-2 md:mt-4 lg:mt-4 my-1">
                            <div>
                                <img src="https://i.ibb.co/jr624TB/20.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Prasailling</p>
                            </div>
                        </div>  
                    </div>
                    <div className="border-b xl:border-r lg:border-r border-r-0 md:border-r">
                        <div className="flex items-center xl:py-2 xl:px-2 xl:mt-0 mt-5 md:mt-4 lg:mt-4 my-1">
                            <div>
                                <img src="https://i.ibb.co/31sQzyW/13.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Cinema Facility</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block border-b border-r ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/71RSc6X/19.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Kids Club</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block  border-b border-r  ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/9vP8ZQN/18.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Surfing</p>
                            </div>
                        </div>  
                    </div>
                    

                    <div className="hidden xl:block lg:block md:block sm:block  border-b ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/WfbgDz3/6.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Butler Services</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block   border-r  ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/xMVZSn4/3.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Private Dining</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block  border-r  ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/qxHxch0/1.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Wi fi</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block   border-r  ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/VCdBZMp/9.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Live Music</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block  border-r  ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/r70M8WQ/5.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Honeymooners</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block border-r  ">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/vQyv3mZ/8.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font xl:text-base  lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Swimming Pool</p>
                            </div>
                        </div>  
                    </div>
                    <div className="hidden xl:block lg:block md:block sm:block">
                        <div className="flex items-center py-2 px-2 xl:my-0 my-1">
                            <div>
                                <img src="https://i.ibb.co/nRh9Gvt/10.png" className="xl:w-12 xl:h-12 md:w-8 md:h-8 rounded-full xl:mt-1" alt="" />
                            </div>
                            <div>
                                <p className="font  xl:text-base lg:text-sm md:text-xs text-xs sm:text-base text-neutral-900 dark:text-neutral-100 lg:font-semibold font-medium truncate xl:mt-0  truncate xl:ml-3 xl:pr-0 lg:pr-0 md:pr-0 pr-3">Gym/Health</p>
                            </div>
                        </div>  
                    </div>
                    
                    
                </div>
            </div>


            {/* =========== Mauritius Tour Packages ================ */}

            <div className="container relative space-y-2 mb-24 lg:space-y-2 lg:mb-24 lg:mt-12">
                <div className="font text-black font-bold text-2xl pb-0 text-center">
                    Maldives Frequently Asked Questions
                </div>
                <p className="font text-neutral-500 text-base text-center items-center justify-center lg:pb-6 xl:block hidden">
                    We help you prepare for your trip and ensure an effortless <br />and enjoyable travel experience.
                </p>
                <p className="font text-neutral-500 text-base text-center items-center justify-center lg:pb-6 xl:hidden block ">
                    We help you prepare for your trip and ensure an effortless and enjoyable travel experience.
                </p>
                <FrequentlyMaldives />
            </div>


            {/* =========== Singapore Tour Packages ================ 

            <div className="container relative space-y-2 mb-24 lg:space-y-2 lg:mb-24 lg:mt-12">
                <div className="font text-black font-bold text-2xl pb-0 text-center">
                    Newzealand Frequently Asked Questions
                </div>
                <p className="font text-neutral-500 text-base text-center items-center justify-center lg:pb-6 xl:block hidden">
                    We help you prepare for your trip and ensure an effortless <br />and enjoyable travel experience.
                </p>
                <p className="font text-neutral-500 text-base text-center items-center justify-center lg:pb-6 xl:hidden block ">
                    We help you prepare for your trip and ensure an effortless and enjoyable travel experience.
                </p>
                <FrequentlyNewzealand />
            </div>*/}

        </div>

    );
}

export default MaldivesHome;
