import React, { Component } from "react";

interface WizardState {
  currentStep: number;
  destination: string;
  departureDate: string;
  returnDate: string;
  travelers: number;
  budget: number;
}

const totalSteps = 5; // Number of steps

class Wizard extends Component<{}, WizardState> {
  constructor(props: {}) {
    super(props);

    this.state = {
      currentStep: 1,
      destination: "",
      departureDate: "",
      returnDate: "",
      travelers: 1,
      budget: 1000,
    };
  }

  nextStep = () => {
    this.setState((prevState: { currentStep: number; }) => ({
      currentStep: prevState.currentStep + 1,
    }));
  };

  previousStep = () => {
    this.setState((prevState: { currentStep: number; }) => ({
      currentStep: prevState.currentStep - 1,
    }));
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    this.setState({ [name]: value } as unknown as Pick<WizardState, keyof WizardState>);
  };

  render() {
    const { currentStep, destination, departureDate, returnDate, travelers, budget } = this.state;

    // Calculate the progress width for the progress bar
    const progressWidth = ((currentStep - 1) / (totalSteps - 1)) * 100;

    return (
      <div className="travel-form">
        <div className="progress-bar">
          <div className="progress" style={{ width: `${progressWidth}%` }} />
        </div>
        <div className="form">
          <h2>Step {currentStep}</h2>
          {currentStep === 1 && (
            <div>
              <input
                type="text"
                name="destination"
                placeholder="Destination"
                value={destination}
                onChange={this.handleChange}
              />
            </div>
          )}
          {currentStep === 2 && (
            <div>
              <input
                type="date"
                name="departureDate"
                placeholder="Departure Date"
                value={departureDate}
                onChange={this.handleChange}
              />
              <input
                type="date"
                name="returnDate"
                placeholder="Return Date"
                value={returnDate}
                onChange={this.handleChange}
              />
            </div>
          )}
          {currentStep === 3 && (
            <div>
              <select
                name="travelers"
                value={travelers}
                onChange={this.handleChange}
              >
                <option value={1}>1 traveler</option>
                <option value={2}>2 travelers</option>
                <option value={3}>3 travelers</option>
                {/* Add more options as needed */}
              </select>
            </div>
          )}
          {currentStep === 4 && (
            <div>
              <input
                type="number"
                name="budget"
                placeholder="Budget (USD)"
                value={budget}
                onChange={this.handleChange}
              />
            </div>
          )}
          <div className="buttons">
            {currentStep > 1 && (
              <button onClick={this.previousStep}>Previous</button>
            )}
            {currentStep < totalSteps ? (
              <button onClick={this.nextStep}>Next</button>
            ) : (
              <button type="submit">Submit</button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Wizard;
