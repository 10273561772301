import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS, DEMO_STAY_LISTINGS_MALAYSIA, DEMO_STAY_LISTINGS_SINGAPORE, DEMO_STAY_LISTINGS_THAILAND } from "data/listings";
import { DEMO_STAY_LISTINGS_BALI } from "data/listings";
import { DEMO_STAY_LISTINGS_VIETNAM } from "data/listings";
import { DEMO_STAY_LISTINGS_CAMBODIA } from "data/listings";
import { DEMO_STAY_LISTINGS_SRILANKA } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import StayCardBali from "components/StayCard/StayCardBali";
import StayCardVietnam from "components/StayCard/StayCardVietnam";
import StayCardCambodia from "components/StayCard/StayCardCambodia";
import StayCardThailand from "components/StayCard/StayCardThailand";
import StayCardSrilanka from "components/StayCard/StayCardSrilanka";
import StayCardMalaysia from "components/StayCard/StayCardMalaysia";
import axios from "axios";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import StayCardSingapore from "components/StayCard/StayCardSingapore";


// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 9);
const DEMO_DATA1: StayDataType[] = DEMO_STAY_LISTINGS_BALI.filter((_, i) => i < 9);
const DEMO_DATA2: StayDataType[] = DEMO_STAY_LISTINGS_VIETNAM.filter((_, i) => i < 7);
const DEMO_DATA4: StayDataType[] = DEMO_STAY_LISTINGS_THAILAND.filter((_, i) => i < 13);
const DEMO_DATA3: StayDataType[] = DEMO_STAY_LISTINGS_CAMBODIA.filter((_, i) => i < 3);
const DEMO_DATA5: StayDataType[] = DEMO_STAY_LISTINGS_SRILANKA.filter((_, i) => i < 16);
const DEMO_DATA6: StayDataType[] = DEMO_STAY_LISTINGS_MALAYSIA.filter((_, i) => i < 10);
const DEMO_DATA7: StayDataType[] = DEMO_STAY_LISTINGS_SINGAPORE.filter((_, i) => i < 13);
//
export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  stayListingsPhilippines?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DEMO_DATA,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Trawel Mart recommends for you",
  headingIsCenter,
  tabs = ["Dubai", "Phillippines", "Thailand", "Vietnam"],
}) => {
  const renderCard = (stay: StayDataType) => {
    return <StayCard key={stay.id} data={stay} />;
  };
  const renderCard1 = (stay: StayDataType) => {
    return <StayCardBali key={stay.id} data={stay} />;
  };
  const renderCard2 = (stay: StayDataType) => {
    return <StayCardVietnam key={stay.id} data={stay} />;
  };
  const renderCard3 = (stay: StayDataType) => {
    return <StayCardCambodia key={stay.id} data={stay} />;
  };

  const renderCard4 = (stay: StayDataType) => {
    return <StayCardThailand key={stay.id} data={stay} />;
  };

  const renderCard5 = (stay: StayDataType) => {
    return <StayCardSrilanka key={stay.id} data={stay} />;
  };
  const renderCard6 = (stay: StayDataType) => {
    return <StayCardMalaysia key={stay.id} data={stay} />;
  };
  const renderCard7 = (stay: StayDataType) => {
    return <StayCardSingapore key={stay.id} data={stay} />;
  };

  const [baliPackages, setBaliPackages] = useState([]);
  const [dubaiPackages, setDubaiPackages] = useState([]);
  const [cambodiaPackages, setCambodiaPackages] = useState([]);
  const [malaysiaPackages, setMalaysiaPackages] = useState([]);
  const [thailandPackages, setThailandPackages] = useState([]);
  const [singaporePackages, setSingaporePackages] = useState([]);
  const [vietnamPackages, setVietnamPackages] = useState([]);
  const [maldivesPackages, setMaldivesPackages] = useState([]);
  const [srilankaPackages, setSrilankaPackages] = useState([]);
  const [nepalPackages, setNepalPackages] = useState([]);
  const [philippinesPackages, setPhilippinesPackages] = useState([]);
  const [greecePackages, setGreecePackages] = useState([]);
  const [MoraccoPackages, setMoraccoPackages] = useState([]);

  useEffect(() => {
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite")
      .then((response) => {
        if (response.data.status) {
          const packages = response.data.packages;
  
          const filterByCity = (city:any) => packages.filter((package1:any) => package1.tour_city === city);
  
          Promise.all([
            setBaliPackages(filterByCity("Bali")),
            setCambodiaPackages(filterByCity("Cambodia")),
            setVietnamPackages(filterByCity("Vietnam")),
            setThailandPackages(filterByCity("Thailand")),
          ]);
        }
      });
  }, []);

  useEffect(() => {
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite")
      .then((response) => {
        if (response.data.status) {
          const packages = response.data.packages;
  
          const filterByCity = (city:any) => packages.filter((package1:any) => package1.tour_city === city);
  
          Promise.all([
            setDubaiPackages(filterByCity("Dubai")),
          ]);
        }
      });
  }, []);

  useEffect(() => {
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite")
      .then((response) => {
        if (response.data.status) {
          const packages = response.data.packages;
  
          const filterByCity = (city:any) => packages.filter((package1:any) => package1.tour_city === city);
  
          Promise.all([
            setSingaporePackages(filterByCity("Singapore")),
            setSrilankaPackages(filterByCity("Sri Lanka")),
            setMalaysiaPackages(filterByCity("Malaysia")),
            setNepalPackages(filterByCity("Nepal")),
            setPhilippinesPackages(filterByCity("Philippines")),
          ]);
        }
      });
  }, []);

  useEffect(() => {
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getTourPackagesForWebsite")
      .then((response) => {
        if (response.data.status) {
          const packages = response.data.packages;
  
          const filterByCity = (city:any) => packages.filter((package1:any) => package1.tour_city === city);
  
          Promise.all([
            setGreecePackages(filterByCity("Greece")),
            setMoraccoPackages(filterByCity("Morocco")),
          ]);
        }
      });
  }, []);


  const [activeTab, setActiveTab] = useState(1);
  const [activeeTab, setActiveeTab] = useState(1);
  const [activeeeTab, setActiveeeTab] = useState(1);
  const [activeeeeTab, setActiveeeeTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  const handleTabClick1 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeTab(tabNumber);
  };

  const handleTabClick2 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeeTab(tabNumber);
  };

  const handleTabClick3 = (tabNumber: React.SetStateAction<number>) => {
    setActiveeeeTab(tabNumber);
  };

  const [showMoreStates, setShowMoreStates] = useState<{ [key: number]: boolean }>({});

  const toggleReadMoreLess = (index:number) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };


  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoPlay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: false,
          speed: 500,
          slidesToShow: 2,
          slidesToScroll: 1,
          autoPlay: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
    // Add any other settings you need
  };






  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      {/*<HeaderFilter
        tabActive={"Dubai"}
        subHeading={subHeading}
        tabs={tabs}
        heading={heading}
        onClickTab={() => { }}
      />
      {/* <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4">
        Newly Added Packages
      </div>
      <div
        className={`grid gap-6 sm:gap-5 md:gap-5 lg:gap-2 xl:gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {packages.map((item,index) => (
          <div
              className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow`}
              data-nc-id="StayCard"
              key={index}
          >
              <div className="relative w-full">
                <GallerySlider
                    uniqueID={`StayCard_${(item as any).id}`}
                    ratioClass="aspect-w-4 aspect-h-3 "
                    galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                    href={'/package/' + (item as any).id}
                />
              </div>
              <Link to={'/package/' + (item as any).id}>
              <div className="">
                <div className="px-4 pt-5 space-y-2 " >
                  <div className="space-y-2 ">
                      <div className="flex items-center space-x-2">
                          <h2
                              className={` lg:font-medium font-regular capitalize text-base
                                  }`}
                          >
                              <span className="font line-clamp-1">{(item as any).tour_title}</span>
                          </h2>
                      </div>
                      <div className="flex items-center text-neutral-600 dark:text-neutral-400 text-xs space-x-2 ">
                      <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                        </svg>
                          <span className="font ">{(item as any).tour_city} </span>
                          <span className="font nc-Badge bg-sky-100 text-sky-700 px-4 py-1 rounded-full">{(item as any).tour_type} </span>
                          
                      </div>
                  </div>
                  <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                          
                      <div className="">
                          <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                      </div>
                      <div className="">
                          <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-utensils"></i></a>
                      </div>
                      <div className="">
                          <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-bus"></i></a>
                      </div>
                      <div className="">
                          <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hiking"></i></a>
                      </div>
                  </nav>
                </div>
                          
                <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                  <div className=" bg-[#fff0ff]  mt-2">
                    <div className="p-4 space-y-4" >
                        <div className="flex justify-between items-center">
                            <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                View Details
                            </div>
                            <span className="text-xl font-extrabold">
                                <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                    Starts From
                                </div>
                                <div className="font">
                                    {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                    <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                </div>
                            </span>
                        </div>
                    </div>
                </div>
              </div>
              </Link>
          </div>
        ))}
      </div> */}

      <div className="w-full xl:mt-16 md:mt-12 mt-12">
        <div className="xl:flex lg:flex md:flex sm:flex items-center">
          <div className="xl:w-full lg:w-full md:w-full w-full">
            <h2 className="font-semibold font xl:text-2xl md:text-2xl pl-4 text-base">
              South East Asia
            </h2>
          </div>
          <div className="xl:w-6/12 lg:w-6/12 md:w-8/12 w-12/12 sm:w-8/12 justify-end items-end pr-4">
            <div className="tab-buttons text-center xl:mt-4">
              <div className="xl:flex lg:flex md:flex xl:gap-2 gap-1 grid grid-cols-3 ">
                <button
                  className={activeeeeTab === 1 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick3(1)}
                >
                  Bali
                </button>
                <button
                  className={activeeeeTab === 2 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick3(2)}
                >
                  Cambodia
                </button>
                <button
                  className={activeeeeTab === 3 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick3(3)}
                >
                  Vietnam
                </button>
                <button
                  className={activeeeeTab === 4 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick3(4)}
                >
                  Thailand
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {activeeeeTab === 1 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
            {baliPackages.map((item:any,index:any) => (
                <div
                    className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border mx-1 border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                    data-nc-id="StayCard"
                >
                  <div className="relative w-full">
                    <GallerySlider
                        uniqueID={`StayCardMaldives_${index}`}
                        ratioClass="aspect-w-4 aspect-h-3 "
                        galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                        href={'/' + (item as any).slug}
                    />
                  </div>
                  <div className="">
                      <div className="px-4 pt-5 space-y-2 " >
                          <div className="space-y-2 ">
                              <div className="flex items-center space-x-2">
                                  <h2
                                      className={` lg:font-medium font-regular capitalize text-base
                                          }`}
                                  >
                                      <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                  </h2>
                              </div>
                              <div className="flex items-center text-neutral-600 dark:text-neutral-400 text-xs space-x-2 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span className="font ">{(item as any).tour_city}</span>
                                {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                                <span className="font nc-Badge bg-sky-100 text-sky-700 px-4 py-1 rounded-full">{(item as any).tour_type} </span>
                              </div>
                          </div>
                          {(item as any).night_description && (
                                  <div className="card">
                                    <p className="font text-xs font-normal">
                                      {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                                      <span>
                                        {showMoreStates[(item as any).id] && (
                                          <p className="font text-xs font-normal">
                                            {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                              <span key={i}>{night}{(item as any).night_description.split(", ").length === (i+3) ? ' ' : ', '}</span>
                                            ))}
                                          </p>
                                        )}
                                      </span>
                                      {(item as any).night_description.split(", ").length > 2 && (
                                        <span>
                                            <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                            {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                            </button>
                                        </span>
                                      )}
                                    </p>
                                  </div>
                                )}
                          <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-utensils"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-car"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hiking"></i></a>
                              </div>
                          </nav>
                      </div>
                      <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                      <div className=" bg-[#fff0ff]  mt-2">
                          <div className="p-4 space-y-4" >
                              <div className="flex justify-between items-center">
                                <Link to={'/' + (item as any).slug}>
                                  <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                      View Details
                                  </div>
                                </Link>
                                  <span className="text-xl font-extrabold">
                                      <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                          Starts From
                                      </div>
                                      <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                      </div>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>

        </>
      }
      {activeeeeTab === 2 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0  mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
            {cambodiaPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardSrilanka${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>  
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
      {activeeeeTab === 3 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0  mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
            {vietnamPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardSrilanka${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}  
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
      {activeeeeTab === 4 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0  mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
            {thailandPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardSrilanka${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>  
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
      
      <div className="w-full xl:mt-16 md:mt-12 mt-12">
        <div className="flex items-center">
          <div className="xl:w-11/12 lg:w-11/12 md:w-11/12 w-10/12">
            <h2 className="lg:font-semibold font-medium font lg:text-2xl md:text-2xl pl-4 xl:pb-0 md:pb-5 pb-4 xl:text-left md:text-left text-base">
              Middle East 
            </h2>
          </div>
          <div className="xl:w-1/12 lg:w-1/12 md:w-1/12 w-3/12 sm:w-8/12 justify-end items-end ">
            <div className="tab-buttons text-center xl:mt-4">
              <div className="flex xl:gap-2 gap-2 ">
                <button
                  className={activeTab === 1 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick(1)}
                >
                  Dubai
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeTab === 1 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
              {dubaiPackages.map((item:any,index:any) => (
                <div
                    className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border mx-1 border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                    data-nc-id="StayCard"
                >
                  <div className="relative w-full">
                    <GallerySlider
                        uniqueID={`StayCardDubai_${index}`}
                        ratioClass="aspect-w-4 aspect-h-3 "
                        galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                        href={'/' + (item as any).slug}
                    />
                  </div>
                  <div className="">
                      <div className="px-4 pt-5 space-y-2 items-center" >
                          <div className="space-y-2 ">
                              <div className="flex items-center space-x-2">
                                  <h2
                                      className={` lg:font-medium font-regular capitalize text-base
                                          }`}
                                  >
                                      <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                  </h2>
                              </div>
                              <div className="flex items-center text-neutral-600 dark:text-neutral-400 text-xs space-x-2 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span className="font ">{(item as any).tour_city}</span>
                                {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                                <span className="font nc-Badge bg-sky-100 text-sky-700 px-4 py-1 rounded-full">{(item as any).tour_type} </span>
                                
                              </div>
                          </div>
                          {(item as any).night_description && (
                            <div className="card">
                              <p className="font text-xs font-normal">
                                {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                                <span>
                                  {showMoreStates[(item as any).id] && (
                                    <p className="font text-xs font-normal">
                                      {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                        <span key={i}>{night}, </span>
                                      ))}
                                    </p>
                                  )}
                                </span>
                                {(item as any).night_description.split(", ").length > 2 && (
                                  <span>
                                      <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                      {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                      </button>
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                          <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-utensils"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-car"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hiking"></i></a>
                              </div>
                          </nav>
                      </div>
                      <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                      <div className=" bg-[#fff0ff]  mt-2">
                          <div className="p-4 space-y-4" >
                              <div className="flex justify-between items-center">
                                <Link to={'/' + (item as any).slug}>
                                  <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                      View Details
                                  </div>
                                </Link>
                                  <span className="text-xl font-extrabold">
                                      <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                          Starts From
                                      </div>
                                      <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                      </div>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </>}

        <div className="w-full xl:mt-16 md:mt-12 mt-12">
      <div className="xl:flex lg:flex md:flex sm:flex items-center">
          <div className="xl:w-full lg:w-8/12 md:w-2/12">
            <h2 className="lg:font-semibold font-medium font lg:text-2xl md:text-2xl pl-4 xl:pb-0 md:pb-5 pb-4 xl:text-left md:text-left text-base">
              Asia 
            </h2>
          </div>
          <div className="xl:w-9/12 lg:w-8/12 md:w-10/12 w-12/12 sm:w-8/12 justify-end items-end pr-4">
            <div className="tab-buttons text-center xl:mt-4">
            <div className="xl:flex lg:flex md:flex xl:gap-2 gap-1 grid grid-cols-3 ">
                <button
                  className={activeeTab === 1 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick1(1)}
                >
                  Singapore
                </button>
                <button
                  className={activeeTab === 2 ? 'rounded font border px-10 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-10 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick1(2)}
                >
                  SriLanka
                </button>
                <button
                  className={activeeTab === 3 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick1(3)}
                >
                  Malaysia
                </button>
                <button
                  className={activeeTab === 4 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick1(4)}
                >
                  Nepal
                </button>
                <button
                  className={activeeTab === 5 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick1(5)}
                >
                  Philippines
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {activeeTab === 1 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
            {singaporePackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardSingapore${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>  
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
      {activeeTab === 2 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0  mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
            {srilankaPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardSrilanka${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}  
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
        {activeeTab === 3 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
              {malaysiaPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardMalaysia${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}  
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
        {activeeTab === 4 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
              {nepalPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardNepal${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>  
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}
        {activeeTab === 5 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
              {philippinesPackages.map((item:any, index: any) => (
              <div
                  className={`nc-StayCard group relative bg-white dark:bg-neutral-900 mx-1 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                  data-nc-id="StayCard"
              >
                <div className="relative w-full">
                    <GallerySlider
                      uniqueID={`StayCardNepal${index}`}
                      ratioClass="aspect-w-4 aspect-h-3 "
                      galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                      href={'/' + (item as any).slug}
                    />
                </div>
                <div className="">
                    <div className="px-4 pt-5 space-y-2 ">
                        <div className="space-y-2 ">
                            <div className="flex items-center space-x-2">
                                <h2
                                    className={` font-semibold capitalize text-base }`}
                                >
                                    <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                </h2>
                            </div>
                            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs space-x-2 ">
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                              >
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                  />
                                  <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={1.5}
                                      d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                              </svg>
                              <span className="font ">{(item as any).tour_city} </span>  
                              {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                            </div>
                        </div>
                        {(item as any).night_description && (
                          <div className="card">
                            <p className="font text-xs font-normal">
                              {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                              <span>
                                {showMoreStates[(item as any).id] && (
                                  <p className="font text-xs font-normal">
                                    {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                      <span key={i}>{night}, </span>
                                    ))}
                                  </p>
                                )}
                              </span>
                              {(item as any).night_description.split(", ").length > 2 && (
                                <span>
                                    <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                    {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                    </button>
                                </span>
                              )}
                            </p>
                          </div>
                        )}
                        <nav className=" nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hotel"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-utensils"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-car"></i></a>
                            </div>
                            <div className="">
                                <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title=""><i className="las la-hiking"></i></a>
                            </div>
                        </nav>
                    </div>
                    <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                    <div className=" bg-[#fff0ff]  mt-2">
                        <div className="p-4 space-y-4" >
                            <div className="flex justify-between items-center">
                              <Link to={'/' + (item as any).slug}>
                                <div className="font text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                    View Details
                                </div>
                              </Link>
                                <span className="text-xl font-extrabold">
                                    <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                        Starts From
                                    </div>
                                    <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              ))}
            </Slider>
          </div>
        </>}

      <div className="w-full xl:mt-16 md:mt-12 mt-12">
        <div className="flex items-center">
          <div className="xl:w-full lg:w-full md:w-full w-10/12">
            <h2 className="lg:font-semibold font-medium font lg:text-2xl md:text-2xl pl-4 xl:pb-0 md:pb-5 pb-4 xl:text-left md:text-left text-base">
              Discover Europe 
            </h2>
          </div>
          <div className="xl:w-3/12 lg:w-3/12 md:w-5/12 w-8/12 sm:w-8/12 justify-end items-end pr-4">
            <div className="tab-buttons text-center xl:mt-4">
              <div className="flex xl:gap-2 gap-2 ">
                <button
                  className={activeeeTab === 1 ? 'rounded font border px-10 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-10 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick2(1)}
                >
                  Greece
                </button>
                <button
                  className={activeeeTab === 2 ? 'rounded font border px-6 py-2 gradient2 text-white xl:text-base font-medium sm:text-sm text-xs ' : 'rounded font border border-sky-600  px-6 py-2 xl:text-base font-medium sm:text-sm text-xs '}
                  onClick={() => handleTabClick2(2)}
                >
                  Morocco
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {activeeeTab === 1 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
              {greecePackages.map((item:any,index:any) => (
                <div
                    className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border mx-1 border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                    data-nc-id="StayCard"
                >
                  <div className="relative w-full">
                    <GallerySlider
                        uniqueID={`StayCardDubai_${index}`}
                        ratioClass="aspect-w-4 aspect-h-3 "
                        galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                        href={'/' + (item as any).slug}
                    />
                  </div>
                  <div className="">
                      <div className="px-4 pt-5 space-y-2 " >
                          <div className="space-y-2 ">
                              <div className="flex items-center space-x-2">
                                  <h2
                                      className={` lg:font-medium font-regular capitalize text-base
                                          }`}
                                  >
                                      <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                  </h2>
                              </div>
                              <div className="flex items-center text-neutral-600 dark:text-neutral-400 text-xs space-x-2 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span className="font ">{(item as any).tour_city}</span>
                                {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                                <span className="font nc-Badge bg-sky-100 text-sky-700 px-4 py-1 rounded-full">{(item as any).tour_type} </span>
                              </div>
                          </div>
                          {(item as any).night_description && (
                            <div className="card">
                              <p className="font text-xs font-normal">
                                {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                                <span>
                                  {showMoreStates[(item as any).id] && (
                                    <p className="font text-xs font-normal">
                                      {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                        <span key={i}>{night}, </span>
                                      ))}
                                    </p>
                                  )}
                                </span>
                                {(item as any).night_description.split(", ").length > 2 && (
                                  <span>
                                      <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                      {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                      </button>
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                          <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-utensils"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-car"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hiking"></i></a>
                              </div>
                          </nav>
                      </div>
                      <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                      <div className=" bg-[#fff0ff]  mt-2">
                          <div className="p-4 space-y-4" >
                              <div className="flex justify-between items-center">
                                <Link to={'/' + (item as any).slug}>
                                  <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                      View Details
                                  </div>
                                </Link>
                                  <span className="text-xl font-extrabold">
                                      <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                          Starts From
                                      </div>
                                      <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                      </div>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </>}

      {activeeeTab === 2 &&
        <>
          <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-1 mt-6">

          </div>
          <div
            className={`xl:gap-0 mx-2 ${gridClass}`}
          >
            <Slider {...settings}>
              {MoraccoPackages.map((item:any,index:any) => (
                <div
                    className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border mx-1 border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow `}
                    data-nc-id="StayCard"
                >
                  <div className="relative w-full">
                    <GallerySlider
                        uniqueID={`StayCardDubai_${index}`}
                        ratioClass="aspect-w-4 aspect-h-3 "
                        galleryImgs={(item as any).photos_details.split(", ").map((img:string) => `https://admin.trawelmart.com/admin/public/${img}`)}
                        href={'/' + (item as any).slug}
                    />
                  </div>
                  <div className="">
                      <div className="px-4 pt-5 space-y-2 " >
                          <div className="space-y-2 ">
                              <div className="flex items-center space-x-2">
                                  <h2
                                      className={` lg:font-medium font-regular capitalize text-base
                                          }`}
                                  >
                                      <span className="font line-clamp-1">{(item as any).tour_title}</span>
                                  </h2>
                              </div>
                              <div className="flex items-center text-neutral-600 dark:text-neutral-400 text-xs space-x-2 ">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                                    />
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={1.5}
                                        d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                                    />
                                </svg>
                                <span className="font ">{(item as any).tour_city}</span>
                                {(item as any).is_flight === "Yes" && (
                                  <span className="font  text-neutral-600 dark:text-neutral-400 text-xs space-x-2 py-1"><i className="las la-plane  text-base"></i> With Flight</span>
                                )}
                                <span className="font nc-Badge bg-sky-100 text-sky-700 px-4 py-1 rounded-full">{(item as any).tour_type} </span>
                              </div>
                          </div>
                          {(item as any).night_description && (
                            <div className="card">
                              <p className="font text-xs font-normal">
                                {(item as any).night_description.split(", ")[0]} {(item as any).night_description.split(", ")[1] && (', ' + (item as any).night_description.split(", ")[1])}
                                <span>
                                  {showMoreStates[(item as any).id] && (
                                    <p className="font text-xs font-normal">
                                      {(item as any).night_description.split(", ").slice(2).map((night:any, i:any) => (
                                        <span key={i}>{night}, </span>
                                      ))}
                                    </p>
                                  )}
                                </span>
                                {(item as any).night_description.split(", ").length > 2 && (
                                  <span>
                                      <button onClick={() => toggleReadMoreLess((item as any).id)} className="font text-xs font-medium">
                                      {showMoreStates[(item as any).id] ? 'Show Less' : `+${((item as any).night_description?.split(", ").length || 0) - 2} more`}
                                      </button>
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                          <nav className="pt-1 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hotel"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-utensils"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-car"></i></a>
                              </div>
                              <div className="">
                                  <a className="flex items-center justify-center  w-7 h-7 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  rel="noopener noreferrer" ><i className="las la-hiking"></i></a>
                              </div>
                          </nav>
                      </div>
                      <div className="w-14  border-neutral-100 dark:border-neutral-800"></div>
                      <div className=" bg-[#fff0ff]  mt-2">
                          <div className="p-4 space-y-4" >
                              <div className="flex justify-between items-center">
                                <Link to={'/' + (item as any).slug}>
                                  <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                                      View Details
                                  </div>
                                </Link>
                                  <span className="text-xl font-extrabold">
                                      <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">
                                          Starts From
                                      </div>
                                      <div className="font">
                                        {(item as any).price ? `₹${(item as any).price}` : '₹'}
                                        <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal"></span>
                                      </div>
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </>}


      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div>


      {/* ======= Philippines ======= 

      <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-12 mt-6">
        Philippines Tour Packages
      </div>
      <div
        className={`grid gap-6 sm:gap-5 md:gap-5 lg:gap-2 xl:gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {DEMO_DATA1.map((stay) => renderCard1(stay))}
      </div>
      {/*<div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div>

      {/* ======= Philippines ======= 

      <div className="font text-black lg:font-semibold font-medium  lg:text-2xl text-lg lg:pb-6 pb-4 lg:mt-12 mt-6">
        Best Turkey Tour Packages
      </div>
      <div
        className={`grid gap-6 sm:gap-5 md:gap-5 lg:gap-2 xl:gap-3 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 ${gridClass}`}
      >
        {DEMO_DATA2.map((stay) => renderCard2(stay))}
      </div>
        {/*<div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
    </div>*/}

    </div>
  );
};

export default SectionGridFeaturePlaces;
