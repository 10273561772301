import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import logoss from "images/about/logoss.webp";
import logosss from "images/about/logosss.webp";
import ETAchieversAwardsKarnataka2023besttravelcompanytrawelmart from "images/about/ET-Achievers-Awards-Karnataka-2023-best-travel-company-trawelmart.png";
import indiabusinesssummit2022indiabesttravelcompany from "images/about/india-business-summit-2022-india-best-travel-company.png";
import indiatradeawards2022 from "images/about/india-trade-awards-2022-.png";
import MostPreferredTravelCompanybusinessmint from "images/about/Most -Preferred-Travel-Company-business-mint.png";

function TestimonialSlider() {
  const [isPaused, setIsPaused] = useState(false);

  const handleMouseEnter = () => {
    setIsPaused(true);
  };

  const settings = {
    autoplay: true,
    autoplaySpeed: 3000, // Adjust as needed
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
  };

  return (
    <div onMouseEnter={handleMouseEnter}>
      {/* <Slider {...settings}> */}
      <div className="flex gap-10 justify-center lg:mt-1 lg:mb-10 xl:pb-4 md:pb-6 md:pt-3 md:px-11 xl:mb-4 xl:pt-0 pb-5 px-4">
        <div className="justify-center center">
          <img
            src={ETAchieversAwardsKarnataka2023besttravelcompanytrawelmart}
            alt="Testimonial 1"
            className="xl:w-48 xl:h-12 md:w-44 md:h-10 justify-center xl:mt-4"
          />
        </div>
        <div className="justify-center center">
          <img
            src={indiabusinesssummit2022indiabesttravelcompany}
            alt="Testimonial 2"
            className="xl:w-48 xl:h-12 md:w-44 md:h-10  xl:mt-4"
          />
        </div>
        <div className="justify-center center">
          <img
            src={indiatradeawards2022}
            alt="Testimonial 1"
            className="xl:w-48 xl:h-12 md:w-44 md:h-10  xl:mt-4"
          />
        </div>
        <div className="justify-center center">
          <img
            src={MostPreferredTravelCompanybusinessmint}
            alt="Testimonial 2"
            className="xl:w-48 xl:h-12 md:w-44 md:h-10  xl:mt-4"
          />
        </div>
      </div>
      {/* <div className="justify-center center">
          <img src={ETAchieversAwardsKarnataka2023besttravelcompanytrawelmart} alt="Testimonial 1" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={indiabusinesssummit2022indiabesttravelcompany} alt="Testimonial 2" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={indiatradeawards2022} alt="Testimonial 1" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={MostPreferredTravelCompanybusinessmint} alt="Testimonial 2" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={ETAchieversAwardsKarnataka2023besttravelcompanytrawelmart} alt="Testimonial 1" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={indiabusinesssummit2022indiabesttravelcompany} alt="Testimonial 2" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={indiatradeawards2022} alt="Testimonial 1" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={MostPreferredTravelCompanybusinessmint} alt="Testimonial 2" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={ETAchieversAwardsKarnataka2023besttravelcompanytrawelmart} alt="Testimonial 1" className="w-32 xl:mt-4" />
        </div>
        <div className="justify-center center">
          <img src={indiabusinesssummit2022indiabesttravelcompany} alt="Testimonial 2" className="w-32 xl:mt-4" />
        </div> */}

      {/* Add more testimonial images here */}
      {/* </Slider> */}
    </div>
  );
}

export default TestimonialSlider;
