import React, { FC } from "react";
import PostCardMeta from "components/PostCardMeta/PostCardMeta";
import { PostDataType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";

export interface Card3SmallProps {
  className?: string;
  post: PostDataType;
}

const Card3Small: FC<Card3SmallProps> = ({ className = "h-full", post }) => {
  const { title, href, featuredImage, desc, date } = post;

  return (
    <Link
      className={`font nc-Card3Small relative flex flex-col-reverse sm:flex-row sm:justify-between sm:items-center ${className}`}
      data-nc-id="Card3Small" to={href}
    >
      <div  className=" absolute inset-0" title={title}></div>
      <div className="relative space-y-2">
        {/* <PostCardMeta meta={{ ...post }} /> */}
        <div className="flex">
        <div className="transition-colors hover:text-white duration-300 nc-Badge inline-flex px-2.5 py-1  rounded-full font-medium text-xs relative text-green-800 bg-green-100 hover:bg-green-800">
                {date}
              </div>
        <div className="pl-4">
              <i className="las la-clock font1 font-medium text-sm"></i><span className="text-xs pl-1"> 5 mins. read</span>
              </div>
        </div>
       
        <h2 className="nc-card-title block text-base font-semibold text-neutral-900 dark:text-neutral-100">
          <Link to={href} className="font line-clamp-2" title={title}>
            {title}
          </Link>
        </h2>
        <p className="font1 text-xs">
          {desc}
        </p>
      </div>

      <Link
        to={href}
        title={title}
        className={`block sm:w-20 flex-shrink-0 relative rounded-lg overflow-hidden mb-5 sm:ml-4 sm:mb-0 group`}
      >
        <div className={`w-full h-0 aspect-w-16 aspect-h-9 sm:aspect-h-16`}>
          <NcImage
            containerClassName="absolute inset-0"
            className="nc-will-change-transform object-cover w-full h-full group-hover:scale-110 transform transition-transform duration-300"
            src={featuredImage}
            title={title}
          />
        </div>
      </Link>
    </Link>
  );
};

export default Card3Small;
