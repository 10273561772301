import React, { FC, Fragment, useState } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import GuestsInput from "./GuestsInput";
import FlightCard2, { FlightCard2Props } from "components/FlightCard/FlightCard2";
import FlightCard3, { FlightCard3Props } from "components/FlightCard/FlightCard3";
import FlightCard4, { FlightCard4Props } from "components/FlightCard/FlightCard4";
import FlightCard5, { FlightCard5Props } from "components/FlightCard/FlightCard5";
import hotel from "images/ICONS/hotel.png";
import NextPrev from "shared/NextPrev/NextPrev";
import Button from "shared/Button/Button";
import { Tab } from "@headlessui/react";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import CarCard from "components/CarCard/CarCard";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StayCard from "components/StayCard/StayCard";

const StayDetailPageContainer: FC<{}> = () => {
  //
  let [categories] = useState(["Stay Information", "Need to know", "Preparation for tour"]);
  let [categories1] = useState(["Included", "Excluded", "Cancellation Policy"]);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };
  const DEMO_DATA: FlightCard2Props["data"][] = [
    {
      id: "1",
      price: "₹4,100",
      icons: "la las-car",
      day: "Day-1",
      days: "Day-1: Wed,Jun 07",
      text: "Dubai Welcomes You ",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",

      },
    },

  ];
  const DEMO_DATA1: FlightCard3Props["data"][] = [
    {
      id: "2",
      price: "₹12,100",
      icons: "la las-car",
      day: "Day-1",
      days: "Day-2: Wed,Jun 07",
      text: "Dubai Welcomes You ",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",

      },
    },

  ];

  const DEMO_DATA2: FlightCard4Props["data"][] = [
    {
      id: "2",
      price: "₹12,100",
      icons: "la las-car",
      day: "Day-1",
      days: "Day-2: Wed,Jun 07",
      text: "Dubai Welcomes You ",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",

      },
    },
  ];

  const DEMO_DATA5: FlightCard5Props["data"][] = [
    {
      id: "2",
      price: "₹12,100",
      icons: "la las-car",
      day: "Day-1",
      days: "Day-2: Wed,Jun 07",
      text: "Activities",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",

      },
    },
  ];

  const DEMO_DATA6: FlightCard5Props["data"][] = [
    {
      id: "2",
      price: "₹12,100",
      icons: "la las-car",
      day: "Day-1",
      days: "Day-2: Wed,Jun 07",
      text: "Dubai Welcomes You ( The Moonlit Dhow Cruise )",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",

      },
    },
  ];

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap !space-y-5">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name="Wooden house" />
          <LikeSaveBtns />
        </div>

        {/* 2 
        <h2 className="text-2xl sm:text-3xl lg:text-3xl font-semibold">
          Beach House in Collingwood
        </h2>

        {/* 3 
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Dubai</span>
          </span>
        </div>

        {/* 4 
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div>*/}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-clock text-2xl "></i>
            <span className="">
              <span className="hidden sm:inline-block font-semibold">Duration</span>
              <br></br>
              <h6 className="pt-1"> 5Days / 4Nights </h6>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-users text-2xl"></i>
            <span className=" font-semibold">
              Group <span className="hidden sm:inline-block">Size</span>
              <br></br>
              <h6 className="pt-1 font-normal"> 10 People</h6>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-language text-2xl"></i>
            <span className=" font-semibold">
              <span className="hidden sm:inline-block">Languages</span>
              <br></br>
              <h6 className="pt-1 font-normal">English</h6>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-shoe-prints text-2xl"></i>
            <span className="font-semibold ">
              Tour <span className="hidden sm:inline-block">Types</span>
              <br></br>
              <h6 className="pt-1 font-normal">Specific Tour</h6>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderSection9 = () => {
    return (
      <div className="listingSection__wrap !space-y-5">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name="Wooden house" />
          <LikeSaveBtns />
        </div>

        {/* 2 
        <h2 className="text-2xl sm:text-3xl lg:text-3xl font-semibold">
          Beach House in Collingwood
        </h2>

        {/* 3 
        <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Dubai</span>
          </span>
        </div>

        {/* 4 
        <div className="flex items-center">
          <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Hosted by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              Kevin Francis
            </span>
          </span>
        </div>*/}

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        <div className="grid lg:grid-cols-2 lg:gap-2">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-clock text-xl "></i>
            <span className="">
              <span className="hidden sm:inline-block font-semibold text-sm">Duration</span>
              <br></br>
              <h6 className="pt-1 text-xs"> 5Days / 4Nights </h6>
            </span>
          </div>
          <div className="flex items-center space-x-3 ">
            <i className=" las la-users text-xl "></i>
            <span className="">
              <span className="hidden sm:inline-block font-semibold text-sm">Group Size</span>
              <br></br>
              <h6 className="pt-1 text-xs"> 10 People </h6>
            </span>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 lg:gap-2">
          <div className="flex items-center space-x-3 ">
            <i className=" las la-clock text-xl "></i>
            <span className="">
              <span className="hidden sm:inline-block font-semibold text-sm">Duration</span>
              <br></br>
              <h6 className="pt-1 text-xs"> 5Days / 4Nights </h6>
            </span>
          </div>
          <div className="flex items-center space-x-3 ">
            <i className=" las la-shoe-prints text-xl "></i>
            <span className="">
              <span className="hidden sm:inline-block font-semibold text-sm">Tour Types</span>
              <br></br>
              <h6 className="pt-1 text-xs"> Specific Tour</h6>
            </span>
          </div>
        </div>
        {/* 6 */}

      </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Stay information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
            accommodation, an outdoor swimming pool, a bar, a shared lounge, a
            garden and barbecue facilities. Complimentary WiFi is provided.
          </span>
          <br />
          <br />
          <span>
            There is a private bathroom with bidet in all units, along with a
            hairdryer and free toiletries.
          </span>
          <br /> <br />
          <span>
            The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental
            service and a car rental service are available at the accommodation,
            while cycling can be enjoyed nearby.
          </span>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Amenities </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {` About the property's amenities and services`}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {Amenities_demos.filter((_, i) => i < 12).map((item) => (
            <div key={item.name} className="flex items-center space-x-3">
              <i className={`text-3xl las ${item.icon}`}></i>
              <span className=" ">{item.name}</span>
            </div>
          ))}
        </div>

        {/* ----- */}
        <div className="w-14 border-b border-neutral-200"></div>
        <div>
          <ButtonSecondary onClick={openModalAmenities}>
            View more 20 amenities
          </ButtonSecondary>
        </div>
        {renderMotalAmenities()}
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                    {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                      <div
                        key={item.name}
                        className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                      >
                        <i
                          className={`text-4xl text-neutral-6000 las ${item.icon}`}
                        ></i>
                        <span>{item.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSection4 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Room Rates </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>$199</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>$219</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Rent by month</span>
              <span>-8.34 %</span>
            </div>
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of nights</span>
              <span>90 nights</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderSection5 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Host Information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* host */}
        <div className="flex items-center space-x-4">
          <Avatar
            hasChecked
            hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
            sizeClass="h-14 w-14"
            radius="rounded-full"
          />
          <div>
            <a className="block text-xl font-medium" href="##">
              Kevin Francis
            </a>
            <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
              <StartRating />
              <span className="mx-2">·</span>
              <span> 12 places</span>
            </div>
          </div>
        </div>

        {/* desc */}
        <span className="block text-neutral-6000 dark:text-neutral-300">
          Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
          accommodation, an outdoor swimming pool, a bar, a shared lounge, a
          garden and barbecue facilities...
        </span>

        {/* info */}
        <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
              />
            </svg>
            <span>Joined in March 2016</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
              />
            </svg>
            <span>Response rate - 100%</span>
          </div>
          <div className="flex items-center space-x-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>

            <span>Fast response - within a few hours</span>
          </div>
        </div>

        {/* == */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div>
          <ButtonSecondary href="/author">See host profile</ButtonSecondary>
        </div>
      </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Reviews (23 reviews)</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

        {/* Content */}
        <div className="space-y-5">
          <FiveStartIconForRate iconClass="w-6 h-6" className="space-x-0.5" />
          <div className="relative">
            <Input
              fontClass=""
              sizeClass="h-16 px-4 py-3"
              rounded="rounded-3xl"
              placeholder="Share your thoughts ..."
            />
            <ButtonCircle
              className="absolute right-2 top-1/2 transform -translate-y-1/2"
              size=" w-12 h-12 "
            >
              <ArrowRightIcon className="w-5 h-5" />
            </ButtonCircle>
          </div>
        </div>

        {/* comment */}
        <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <CommentListing className="py-8" />
          <div className="pt-8">
            <ButtonSecondary>View more 20 reviews</ButtonSecondary>
          </div>
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING 
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            San Diego, CA, United States of America (SAN-San Diego Intl.)
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />*/}

        {/* MAP 
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
          <div className="rounded-xl overflow-hidden z-0">
            <iframe
              title="x"
              width="100%"
              height="100%"
              loading="lazy"
              allowFullScreen
              referrerPolicy="no-referrer-when-downgrade"
              src="https://www.google.com/maps/embed/v1/place?key=AIzaSyAGVJfZMAKYfZ71nzL_v5i3LjTTWnCYwTY&q=Eiffel+Tower,Paris+France"
            ></iframe>
          </div>
        </div>*/}
      </div>
    );
  };

  const renderSection8 = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the room
            within 48 hours after successful booking and 14 days before the
            check-in time. <br />
            Then, cancel the room 14 days before the check-in time, get a 50%
            refund of the total amount paid (minus the service fee).
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        {/* <div>
          <h4 className="text-lg font-semibold">Check-in time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Check-in</span>
              <span>08:00 am - 12:00 am</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Check-out</span>
              <span>02:00 pm - 04:00 pm</span>
            </div>
          </div>
        </div>*/}
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-12">
            <img src={hotel} className="w-10" alt="" />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  Check-in
                </span>
                <span className=" font-semibold">
                  08:00 am - 12:00 am
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  Check-out
                </span>
                <span className=" font-semibold">
                  02:00 pm - 04:00 pm
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
            <li>Trip time: 7 hours 45 minutes</li>
            <li>ANA · Business class · Boeing 787 · NH 847</li>
          </ul>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Ban and I will work together to keep the landscape and
                environment green and clean by not littering, not using
                stimulants and respecting people around.
              </li>
              <li>Do not sing karaoke past 11:30</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl">
        {/* PRICE */}
        <div className="flex justify-between">
          <span className="text-3xl font-semibold">
            ₹119
            <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /night
            </span>
          </span>
          <StartRating />
        </div>

        {/* FORM */}
        <form className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl ">
          <StayDatesRangeInput className="flex-1 z-[11]" />
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <GuestsInput className="flex-1" />
        </form>

        {/* SUM */}
        <div className="flex flex-col space-y-4">
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span> ₹119 x 3 night</span>
            <span> ₹357</span>
          </div>
          <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
            <span>Service charge</span>
            <span> ₹0</span>
          </div>
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Total</span>
            <span> ₹199</span>
          </div>
        </div>
        {/* SUBMIT */}
        <ButtonPrimary href={"/checkout"}>Book Now</ButtonPrimary>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage mt-2">
      {/*  HEADER */}<ul className="breadcrumb  lg:w-4/12">
        <li><a href="#">Home</a></li>
        <li><a href="/listing-real-estate">Filter</a></li>
        <li>Stay Details</li>
      </ul>
      <header className="rounded-md sm:rounded-xl lg:pt-6">

        <div className="flex flex-col lg:flex-row items-center lg:pb-4">
          <div className="lg:w-10/12">

            <h2 className="text-2xl sm:text-3xl lg:text-2xl lg:pb-2 font-semibold">
              Beach House in Collingwood
            </h2>
            <div className="flex items-center space-x-4 lg:pb-2">
              <StartRating />

              <span>
                <i className="las la-map-marker-alt"></i>
                <span className="ml-1"> Dubai</span>
              </span>
            </div>
          </div>


          <div className="lg:w-2/12 lg:pt-3 justify-center text-right">
            <div className="lg:text-3xl md:text-2xl text-sm font-semibold text-black dark:text-black-400 font-normal">
              ₹61,000
            </div>
            <ButtonPrimary href={"/checkout"} className="lg:text-xs rounded-lg lg:px-14 lg:mt-2">Book Now</ButtonPrimary>
            <div className="text-xs lg:pt-2 text-neutral-500 dark:text-neutral-400 font-normal">
              4,731 Guests already travelled
            </div>
          </div>
        </div>
        <div className="w-full flex">
          <div className="w-8/12">
            <div className="relative grid grid-cols-3 sm:grid-cols-2 gap-1 sm:gap-2 ">
              <div
                className="col-span-1 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                onClick={handleOpenModalImageGallery}
              >
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={PHOTOS[0]}
                  alt=""
                  sizes="(max-width: 900px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {PHOTOS.filter((_, i) => i >= 1 && i < 3).map((item, index) => (
                <div
                  key={index}
                  className={`relative xl:w-11/12 rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                    }`}
                >
                  <div className="aspect-w-4 aspect-h-3 sm:aspect-w-5 sm:aspect-h-2">
                    <img
                      className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-6/12 h-full"
                      src={item || ""}
                      alt=""
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

              <button
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="w-5 h-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </button>
            </div>
          </div>
          <div className="lg:w-4/12">
            {renderSection9()}
          </div>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}

        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10 lg:pb-12">
          {/*renderSection1()*/}
          <div className="lg:p-4 lg:bg-white border lg:dark:bg-black/20 grid grid-cols-1 gap-0  rounded-3xl">
            <div className="pl-4 pb-2 flex">
              <div className="lg:w-9/12">
                <h2 className="text-xl font-semibold pb-2">Itinerary</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 "></div></div>

              <nav className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2" data-nc-id="SocialsList">
                <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Facebook">
                  <i className="las la-plane-departure"></i></a><a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Twitter">
                  <i className="las la-hotel"></i></a><a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Youtube">
                  <i className="las la-utensils"></i></a><a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" title="Instagram">
                  <i className="las la-car"></i></a></nav>
            </div>
            {/*<div className="grid lg:grid-cols-2 gap-2  ">
              <div>
                <Badge name="Day 1: Wed, Jun 07" />
                <div className="text-justify-center">
                  <span className="block flex-grow border-l border-neutral-400 border-dashed  lg:ml-8">
                    <div className="lg:py-6 md:py-6"></div>
                  </span></div>
                <div className="grid lg:grid-cols-2 gap-2 ">
                  <span className="block w-8 h-8 rounded-full border border-green-200 lg:ml-4 bg-green-200">
                    <i className="text-xl las la-plane-departure p-1 text-green-800"></i>
                    <span className="block flex-grow border-l border-neutral-400 border-dashed lg:ml-4 mt-1 py-12">
                    </span>
                  </span>
                  <div className="">
                    Travel from Baghdad
                  </div>
                </div>
              </div>

              </div>*/}

            <div className="lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-0  rounded-3xl">
              {DEMO_DATA.map((item, index) => (
                <FlightCard2 defaultOpen={!index} key={index} data={item} className="pl-4" />
              ))}
            </div>
            <div className="lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-0 lg:-mt-7 rounded-3xl">
              {DEMO_DATA1.map((item, index) => (
                <FlightCard3 defaultOpen={!index} key={index} data={item} className="pl-4" />
              ))}
            </div>
            <div className="lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-0 lg:-mt-7 rounded-3xl">
              {DEMO_DATA2.map((item, index) => (
                <FlightCard4 defaultOpen={!index} key={index} data={item} className="pl-4" />
              ))}
            </div>
            

            {/* <div className="flex mt-12 justify-center items-center">
              <ButtonPrimary loading>Show more</ButtonPrimary>
            </div>*/}
          </div>
          {/*renderSection2()
          <div className="listingSection__wrap">
            <div>
              <h2 className="text-2xl font-semibold">Included</h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {` About the property's included/excluded in this tours`}
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* 6 
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 text-sm text-neutral-700 dark:text-neutral-300 ">
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-coffee  text-green-800"></i>
                  <span className=" ">Local Drinks</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-green-800"></i>
                  <span className=" ">Airfare with taxes</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-green-800"></i>
                  <span className=" ">RTPCR Test</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-coffee  text-green-800"></i>
                  <span className=" ">Local Drinks</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-green-800"></i>
                  <span className=" ">Airfare with taxes</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-green-800"></i>
                  <span className=" ">RTPCR Test</span>
                </div>
              </div>
            </div>
            <div>
              <h2 className="text-2xl font-semibold">Excluded</h2>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                {` About the property's included/excluded in this tours`}
              </span>
            </div>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            {/* 6 
            <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 text-sm text-neutral-700 dark:text-neutral-300 ">
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-coffee  text-red-800"></i>
                  <span className=" ">Local Drinks</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-red-800"></i>
                  <span className=" ">Airfare with taxes</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-red-800"></i>
                  <span className=" ">RTPCR Test</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-coffee  text-red-800"></i>
                  <span className=" ">Local Drinks</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-red-800"></i>
                  <span className=" ">Airfare with taxes</span>
                </div>
              </div>
              <div className="border rounded-full p-2">
                <div className="flex items-center space-x-3">
                  <i className="text-3xl las la-car  text-red-800"></i>
                  <span className=" ">RTPCR Test</span>
                </div>
              </div>
            </div>
          </div>*/}

          {/* Included starts */}

          <div className="listingSection__wrap">
            <Tab.Group>
              <Tab.List className="grid grid-cols-3 gap-2 space-x-1 overflow-x-auto bg-blue-500 ">
                {categories1.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                          ? "bg-white text-blue-800 border-b-2 border-blue-500"
                          : "text-white dark:text-neutral-400 border-r  border-white dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                          } `}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="">
                  <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 text-sm text-neutral-700 dark:text-neutral-300 ">
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-coffee  text-green-800"></i>
                          <span className=" ">Local Drinks</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-green-800"></i>
                          <span className=" ">Airfare with taxes</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-green-800"></i>
                          <span className=" ">RTPCR Test</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-coffee  text-green-800"></i>
                          <span className=" ">Local Drinks</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-green-800"></i>
                          <span className=" ">Airfare with taxes</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-green-800"></i>
                          <span className=" ">RTPCR Test</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </Tab.Panel>

                <Tab.Panel className="">
                  <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 text-sm text-neutral-700 dark:text-neutral-300 ">
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-coffee  text-red-800"></i>
                          <span className=" ">Local Drinks</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-red-800"></i>
                          <span className=" ">Airfare with taxes</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-red-800"></i>
                          <span className=" ">RTPCR Test</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-coffee  text-red-800"></i>
                          <span className=" ">Local Drinks</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-red-800"></i>
                          <span className=" ">Airfare with taxes</span>
                        </div>
                      </div>
                      <div className="border rounded-full p-2">
                        <div className="flex items-center space-x-3">
                          <i className="text-3xl las la-car  text-red-800"></i>
                          <span className=" ">RTPCR Test</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </Tab.Panel>
                <Tab.Panel className="">
                  <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                    <ul className="list-disc p-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        As everyone is becoming a frequent traveller now, we suggest that you make your own travel accessories kit to ensure a stress free holiday.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Always make sure to carry less and light luggage, use a four wheel small or medium suitcase and please make sure that you are carrying only one suitcase per person. This will ensure that you will travel smartly and without any inconveniences.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        It is also recommended that you have one cross shoulder bag or haversack for your handy needs which will ensure that you are comfortable at the time of sightseeing and also easy for photography during your tour.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        A smart cap/hat and sunglasses are definitely something that you should have in your kit.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Though Trawel Mart provides all three meals, that is breakfast, lunch, dinner and Veena World treats as per the itinerary, you can carry dry snacks of your choice in small sealed packets for munching during air travel or road journeys.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        If you are travelling during the monsoon or to destinations where it is rainy, please carry a small umbrella or poncho.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        For international tours, please carry a universal adapter for charging your cameras and mobile phones.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Ensure that your clothing and footwear is suitable for the destination you are travelling to.
                      </li>
                    </ul>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>

          {/* Included Excluded Ends */}

          {/* stay Information */}

          <div className="listingSection__wrap">
            <Tab.Group>
              <Tab.List className="grid grid-cols-3 gap-2 space-x-1 overflow-x-auto bg-blue-500 ">
                {categories.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                          ? "bg-white text-blue-800 border-b-2 border-blue-500"
                          : "text-white dark:text-neutral-400 border-r  border-white dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                          } `}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="">
                  <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                    <ul className="list-disc p-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides accommodation, an outdoor swimming pool, a bar, a shared lounge, a garden and barbecue facilities. Complimentary WiFi is provided.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        There is a private bathroom with bidet in all units, along with a hairdryer and free toiletries.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        The Symphony 9 Tam Coc offers a terrace. Both a bicycle rental service and a car rental service are available at the accommodation, while cycling can be enjoyed nearby.
                      </li>
                    </ul>
                  </div>

                </Tab.Panel>

                <Tab.Panel className="">
                  <div className="mt-4 grid grid-cols-1 gap-6 md:gap-2 sm:grid-cols-1">
                    <div className="text-lg font-semibold "><span>Weather</span></div>
                    <ul className="list-disc px-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        To and fro Air fare, Airport transfers, visa fees to join/leave the group for joining and leaving guests
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Anything specifically not mentioned in the ‘tour price includes’ column
                      </li>
                    </ul>
                    <div className="text-lg font-semibold "><span>Transport</span></div>
                    <div className="text-sm font-semibold "><span>Air Travel :</span></div>
                    <ul className="list-disc px-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Mumbai - London // Madrid - Mumbai.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        We use combination of Airlines like Kuwait Airways/Oman Air/Qatar Airways/Ethihad/Emirates etc.
                      </li>
                    </ul>
                    <div className="text-sm font-semibold "><span>Coach Tavel :</span></div>
                    <ul className="list-disc px-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        A/C coach - Seating capacity depends upon group size
                      </li>
                    </ul>
                    <div className="text-lg font-semibold "><span>Documents Required for Travel</span></div>
                    <ul className="list-disc px-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Original passport with minimum 6 months validity from the date of tour arrival along with sufficient blank pages for the stamping of visa
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        A valid Tourist Visa for the duration of the tour
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        For all Schengen countries passport validity should not exceed more than 10 years
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Handwritten passport is not acceptable
                      </li>
                    </ul>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="">
                  <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                    <ul className="list-disc p-6">
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        As everyone is becoming a frequent traveller now, we suggest that you make your own travel accessories kit to ensure a stress free holiday.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Always make sure to carry less and light luggage, use a four wheel small or medium suitcase and please make sure that you are carrying only one suitcase per person. This will ensure that you will travel smartly and without any inconveniences.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        It is also recommended that you have one cross shoulder bag or haversack for your handy needs which will ensure that you are comfortable at the time of sightseeing and also easy for photography during your tour.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        A smart cap/hat and sunglasses are definitely something that you should have in your kit.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Though Trawel Mart provides all three meals, that is breakfast, lunch, dinner and Veena World treats as per the itinerary, you can carry dry snacks of your choice in small sealed packets for munching during air travel or road journeys.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        If you are travelling during the monsoon or to destinations where it is rainy, please carry a small umbrella or poncho.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        For international tours, please carry a universal adapter for charging your cameras and mobile phones.
                      </li>
                      <li className="lg:text-sm md:text-base text-sm lg:pb-4">
                        Ensure that your clothing and footwear is suitable for the destination you are travelling to.
                      </li>
                    </ul>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>

          {/* Stay Information Ends */}


          {/*renderSection3()*/}
          {renderSection4()}
          {/*<SectionDateRange />*/}
          {/*renderSection5()*/}
          {/*renderSection7()*/}
          {renderSection8()}
          {renderSection6()}


        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
