import __stayListing from "./jsons/__stayListing.json";
import __stayListingPhilippines from "./jsons/__stayListingPhilippines.json";
import __stayListingBali from "./jsons/__stayListingBali.json";
import __stayListingVietnam from "./jsons/__stayListingVietnam.json";
import __stayListingSingapore from "./jsons/__stayListingSingapore.json";
import __stayListingAustralia from "./jsons/__stayListingAustralia.json";
import __stayListingTurkey from "./jsons/__stayListingTurkey.json";
import __stayListingNewzealanad from "./jsons/__stayListingNewzealand.json";
import __carsListing from "./jsons/__carsListing.json";
import __experiencesListing from "./jsons/__experiencesListing.json";
import __stayListingHotel from "./jsons/__stayListingHotel.json";
import __stayListingCambodia from "./jsons/__stayListingCambodia.json";
import __stayListingThailand from "./jsons/__stayListingThailand.json";
import __stayListingMalaysia from "./jsons/__stayListingMalaysia.json";
import __stayListingSrilanka from "./jsons/__stayListingSrilanka.json";
import __stayListingVillas from "./jsons/__stayListingVillas.json";

import {
  DEMO_STAY_CATEGORIES,
  DEMO_EXPERIENCES_CATEGORIES,
} from "./taxonomies";
import { CarDataType, ExperiencesDataType, StayDataType } from "./types";
import { DEMO_AUTHORS } from "./authors";
import car1 from "images/cars/1.png";
import car2 from "images/cars/2.png";
import car3 from "images/cars/3.png";
import car4 from "images/cars/4.png";
import car5 from "images/cars/5.png";
import car6 from "images/cars/6.png";
import car7 from "images/cars/7.png";
import car8 from "images/cars/8.png";
import car9 from "images/cars/9.png";
import car10 from "images/cars/10.png";
import car11 from "images/cars/11.png";
import car12 from "images/cars/12.png";
import car13 from "images/cars/13.png";
import car14 from "images/cars/14.png";
import car15 from "images/cars/15.png";
import car16 from "images/cars/16.png";
const carsImgs = [
  car1,
  car2,
  car3,
  car4,
  car5,
  car6,
  car7,
  car8,
  car9,
  car10,
  car11,
  car12,
  car13,
  car14,
  car15,
  car16,
];

const DEMO_STAY_LISTINGS = __stayListing.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListing_${index}_`,
    saleOff: !index ? "With Flights" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_VILLAS = __stayListingVillas.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingVillas_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_PHILIPPINES = __stayListingPhilippines.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingPhilippines_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_BALI = __stayListingBali.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingBali_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_THAILAND = __stayListingThailand.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingThailand_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_CAMBODIA = __stayListingCambodia.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingCambodia_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_VIETNAM = __stayListingVietnam.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingVietnam_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_MALAYSIA = __stayListingMalaysia.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingMalaysia_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_SINGAPORE = __stayListingSingapore.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingSingapore_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_SRILANKA = __stayListingSrilanka.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingSrilanka_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_Newzealand = __stayListingNewzealanad.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingNewzealand_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_AUSTRALIA = __stayListingAustralia.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingAustralia_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_TURKEY = __stayListingTurkey.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingTurkeys_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_STAY_LISTINGS_HOTEL = __stayListingHotel.map((post, index): StayDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_STAY_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `stayListingHotel_${index}_`,
    saleOff: !index ? "-5% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
  };
});

const DEMO_EXPERIENCES_LISTINGS = __experiencesListing.map(
  (post, index): ExperiencesDataType => {
    //  ##########  GET CATEGORY BY CAT ID ######## //
    const category = DEMO_EXPERIENCES_CATEGORIES.filter(
      (taxonomy) => taxonomy.id === post.listingCategoryId
    )[0];

    return {
      ...post,
      id: `experiencesListing_${index}_`,
      saleOff: !index ? "-20% today" : post.saleOff,
      isAds: !index ? true : post.isAds,
      author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
      listingCategory: category,
    };
  }
);

const DEMO_CAR_LISTINGS = __carsListing.map((post, index): CarDataType => {
  //  ##########  GET CATEGORY BY CAT ID ######## //
  const category = DEMO_EXPERIENCES_CATEGORIES.filter(
    (taxonomy) => taxonomy.id === post.listingCategoryId
  )[0];

  return {
    ...post,
    id: `carsListing_${index}_`,
    saleOff: !index ? "-20% today" : post.saleOff,
    isAds: !index ? true : post.isAds,
    author: DEMO_AUTHORS.filter((user) => user.id === post.authorId)[0],
    listingCategory: category,
    featuredImage: carsImgs[index],
  };
});

export { DEMO_STAY_LISTINGS, DEMO_STAY_LISTINGS_VILLAS, DEMO_STAY_LISTINGS_HOTEL, DEMO_STAY_LISTINGS_PHILIPPINES, DEMO_STAY_LISTINGS_MALAYSIA, DEMO_STAY_LISTINGS_BALI, DEMO_STAY_LISTINGS_THAILAND, DEMO_STAY_LISTINGS_CAMBODIA, DEMO_STAY_LISTINGS_VIETNAM, DEMO_STAY_LISTINGS_Newzealand, DEMO_STAY_LISTINGS_SINGAPORE, DEMO_STAY_LISTINGS_SRILANKA, DEMO_STAY_LISTINGS_AUSTRALIA, DEMO_STAY_LISTINGS_TURKEY, DEMO_EXPERIENCES_LISTINGS, DEMO_CAR_LISTINGS };
