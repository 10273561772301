import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogPhilippines = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Philippines" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Island Dreams Unveiled: Exploring the Wonders of the Philippines
                    </h1>
                    {/* <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Nepal, nestled in the heart of the Himalayas, is not just a haven for trekkers and adventure
                        seekers; it is also a treasure trove of cultural richness that spans centuries. The cultural
                        tapestry of Nepal is woven with threads of tradition, spirituality, and architectural marvels.
                        Join us on a virtual journey through the vibrant heritage of this enchanting land.
                    </span> */}

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    Paradise Found: A Journey through the Philippines
                </p>

                <p className="">
                    Embarking on a quest for tropical bliss led me to the captivating archipelago
                    of the Philippines, a land where each island boasts a unique tale and nature
                    unveils its finest masterpiece.
                </p>

                <p className="font-semibold text-xl">
                    Palawan's Hidden Havens
                </p>
                <p className="">
                    The journey began in Palawan, a paradise within a paradise. From the enchanting
                    Underground River, a UNESCO World Heritage Site, to the crystal-clear waters of El Nido,
                    every moment felt like a brushstroke on a canvas of tranquility.
                </p>

                <p className="">
                    Cultural Tapestry in Manila Navigating through the dynamic city of Manila,
                    I discovered a vibrant tapestry woven with colonial history and modern fervor.
                    The Intramuros district echoes with stories from the past, while the contemporary
                    scene in Makati pulses with energy.
                </p>

                <p className="font-semibold text-xl">
                    Chocolate Hills of Bohol
                </p>
                <p className="">
                    Venturing to Bohol, I marveled at the mysterious Chocolate Hills, a geological wonder
                    that left me in awe. The Tarsier Sanctuary provided an up-close encounter with these tiny
                    primates, adding a touch of whimsy to the island's natural wonders.
                </p>


                <p className="font-semibold text-xl">
                    Boracay's Beach Bliss
                </p>
                <p className="">
                    No exploration of the Philippines is complete without a visit to Boracay. The
                    pristine White Beach, with its powder-soft sand and vibrant nightlife, offered
                    a perfect blend of relaxation and excitement.
                </p>

                <p className="font-semibold text-xl">
                    Diving into Coron's Depths
                </p>
                <p className="">
                    Coron, an underwater treasure trove, beckoned with its sunken Japanese warships
                    and vibrant coral gardens. Each dive unveiled a new dimension of the ocean's beauty.
                </p>

                <p className="font-semibold text-xl">
                    Sunsets in Siargao
                </p>
                <p className="">
                    The final stop on this odyssey brought me to Siargao, the surfing capital of the
                    Philippines. The Cloud 9 boardwalk became my front-row seat to breathtaking sunsets,
                    painting the sky in hues of orange and pink.
                </p>

                <p className="font-semibold text-xl">
                    Dawn in Davao
                </p>
                <p className="">
                    As the journey continued to Davao, the city greeted me with the vibrant colors of its
                    bustling markets and the fragrance of exotic fruits. The Philippine Eagle Center, a haven
                    for the endangered Philippine Eagle, showcased the country's commitment to conservation.
                </p>

                <p className="font-semibold text-xl">
                    Magical Mayon Volcano
                </p>
                <p className="">
                    A flight to Legazpi brought me face to face with the majestic Mayon Volcano. Its
                    perfect cone, often shrouded in clouds, seemed like a guardian watching over the
                    Albay province. Exploring the Cagsawa Ruins, remnants of a church engulfed by lava
                    centuries ago, added an eerie yet fascinating touch to the landscape.
                </p>

                <p className="font-semibold text-xl">
                    Breath-taking Batanes
                </p>
                <p className="">
                    The journey to Batanes was a leap into a pastoral paradise. Rolling hills, stone houses,
                    and the rugged coastline formed a picturesque tableau. Witnessing the honesty system at the
                    Honesty Coffee Shop, where visitors pay for their purchases without any attendant, reflected
                    the trust and simplicity ingrained in the local culture.
                </p>


                <p className="font-semibold text-xl">
                    Traditional Flavors of Cebu
                </p>
                <p className="">
                    Culinary delights awaited in Cebu, where lechon (roast pig) tantalized my taste buds with its
                    crispy skin and succulent meat. A stroll through the historic Magellan's Cross and Fort San Pedro
                    transported me back in time, unveiling the layers of colonial history etched into the city's streets.
                </p>


                <p className="font-semibold text-xl">
                    Enigmatic Banaue Rice Terraces
                </p>
                <p className="">
                    The final leg took me to the awe-inspiring Banaue Rice Terraces, a UNESCO World Heritage Site.
                    Carved into the mountains by Ifugao tribes over 2,000 years ago, these terraces painted a verdant
                    masterpiece, a testament to the harmony between human ingenuity and nature.
                </p>


                <p className="font-semibold text-xl">
                    Reflections on a Journey
                </p>
                <p className="">
                    As I reflect on this odyssey through the Philippines, the archipelago emerges as a
                    kaleidoscope of experiences. It's not just about the stunning landscapes or the vibrant
                    cities; it's about the warmth of the Filipino people, the rich tapestry of traditions,
                    and the palpable sense of community that permeates every corner.
                </p>

                <p className="">
                    In the heart of the Philippines, I found not only a collection of islands but a nation with
                    a story to tell—a story of resilience, diversity, and the sheer joy of living. Join me in
                    this exploration of the extraordinary, where each chapter unfolds as a love letter to the Philippines.
                </p>


                <p className="font-semibold text-lg">
                    Conclusion
                </p>

                <p>
                    The Philippines, with its diverse landscapes and warm-hearted people, proved to be more than
                    a destination; it was a journey into the soul of Southeast Asia. Join me as I unravel the
                    enchanting tales and timeless beauty of this archipelago – where every step reveals a new facet of paradise.
                </p>

                <p>
                #PhilippinesAdventure #IslandEscape #WanderlustChronicles
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/tPx9W7p/philippines.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogPhilippines;
