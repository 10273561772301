import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";
import Sundhar from "images/about/Sundhar.jpg";
import Sundhar1 from "images/about/Sundhar1.png";
import quotation from "images/quotation.png";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Niamh O'Shea`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "4",
    name: `Danien Jame`,
    job: "Co-founder and Chief Executive",
    avatar:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "3",
    name: `Orla Dwyer`,
    job: "Co-founder, Chairman",
    avatar:
      "https://images.unsplash.com/photo-1560365163-3e8d64e762ef?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
  {
    id: "2",
    name: `Dara Frazier`,
    job: "Co-Founder, Chief Strategy Officer",
    avatar:
      "https://images.unsplash.com/photo-1580489944761-15a19d654956?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80",
  },
];

const SectionFounder = () => {
  return (


    <div className="nc-SectionFounder relative bg-sky-100 xl:p-12 p-4 rounded-lg"
    >
      <div className="items-center flex flex-wrap space-y-4 lg:space-y-0 lg:space-x-0  relative text-center lg:text-left lg:pb-4">
        <div className="center justify-center xl:w-6/12 lg:w-6/12">
          <img className=" text-center  xl:w-12/12 lg:w-10/12 md:w-6/12 rounded-lg" src={Sundhar} alt="" />
        </div>
        <div className="space-y-1 lg:space-y-0 xl:w-6/12 md:w-12/12 lg:w-6/12">
          <h3 className="text-left xl:pt-0 xl:pb-4 lg:pt-12 font font-medium xl:text-3xl lg:text-2xl text-2xl">
            Creating an Impact in the Travel World
          </h3>
          <h4 className="text-left xl:text-base text-base font-regular leading-7 font text-justify">
            An entrepreneur who wanted to create a success story and one who believed in his vision, K Mohan
            Sundar&#39;s favourite quote has also been
          </h4>
          <div className="xl:block ">
            <p className=" text-xl pt-4 flex text-left">
              <img src={quotation} alt="" className="w-7 h-7" />
              <p className="title step-1 ml-3">
                <span className="highlight font text-justify text-left ">
                  Risk more than others think is safe. <br></br>  Dream more than others think is practical.
                </span>
              </p>
            </p>
            <div className="text-left font text-sm text-sky-600 xl:ml-10 lg:ml-10 ml-10 xl:-mt-2 lg:-mt-2 -mt-2">
              -Howard Schultz, Starbucks CEO
            </div>
          </div>
          <p className="text-left xl:text-base text-base font-regular leading-7 xl:pt-2 lg:pt-2 text-justify">
            K Mohan Sundar is a dreamer who has worked his way up by taking risks, pushing the envelope and
            achieving his goal through sheer courage, hard work and innovation.
            He believes that a business has to be evolving, it has to exercise one&#39;s creative instincts’ perfectly.
            Trawel Mart has been working on his ideology and resonates the exact thoughts he started the
            organization with -not just to plan a vacation but to create an experience, a dream come true while
            providing the highest quality of standards in customer services.
          </p>
          <span className="text-left block text-base xl:text-2xl text-neutral-600 dark:text-neutral-400">
            <h6 className=" font-medium lg:pt-2 lg:text-3xl text-xl">
              K. Mohan Sundar
            </h6>
            <p className=" font-medium text-sky-600 text-base">
              Founder & Managing Director
            </p>
          </span>
        </div>
      </div>

  </div>




  );
};

export default SectionFounder;

{/*<div className="nc-SectionFounder relative bg-sky-100 xl:p-12 p-4 rounded-lg"
    >
      <div className="items-center flex flex-wrap space-y-4 lg:space-y-0 lg:space-x-0  relative text-center lg:text-left lg:pb-4">
        <div className="center justify-center xl:w-6/12 lg:w-6/12">
          <img className=" text-center  xl:w-12/12 lg:w-10/12 md:w-6/12 rounded-lg" src={Sundhar1} alt="" />
        </div>
        <div className="space-y-1 lg:space-y-0 xl:w-6/12 md:w-12/12 lg:w-6/12">
          <h3 className="text-left xl:pt-0 xl:pb-4 lg:pt-12 font font-medium xl:text-3xl lg:text-2xl text-2xl">
            Creating an Impact in the Travel World
          </h3>
          <h4 className="text-left xl:text-base text-base font-regular leading-7 font text-justify">
            An entrepreneur who wanted to create a success story and one who believed in his vision, K Mohan
            Sundar&#39;s favourite quote has also been
          </h4>
          <div className="xl:block ">
            <p className=" text-xl pt-4 flex text-left">
              <img src={quotation} alt="" className="w-7 h-7" />
              <p className="title step-1 ml-3">
                <span className="highlight font text-justify text-left ">
                  Risk more than others think is safe. <br></br>  Dream more than others think is practical.
                </span>
              </p>
            </p>
            <div className="text-left font text-sm text-sky-600 xl:ml-10 lg:ml-10 ml-10 xl:-mt-2 lg:-mt-2 -mt-2">
              -Howard Schultz, Starbucks CEO
            </div>
          </div>
          <p className="text-left xl:text-base text-base font-regular leading-7 xl:pt-2 lg:pt-2 text-justify">
            K Mohan Sundar is a dreamer who has worked his way up by taking risks, pushing the envelope and
            achieving his goal through sheer courage, hard work and innovation.
            He believes that a business has to be evolving, it has to exercise one&#39;s creative instincts’ perfectly.
            Trawel Mart has been working on his ideology and resonates the exact thoughts he started the
            organization with -not just to plan a vacation but to create an experience, a dream come true while
            providing the highest quality of standards in customer services.
          </p>
          <span className="text-left block text-base xl:text-2xl text-neutral-600 dark:text-neutral-400">
            <h6 className=" font-medium lg:pt-2 lg:text-3xl text-xl">
              K. Mohan Sundar
            </h6>
            <p className=" font-medium text-sky-600 text-base">
              Founder & Managing Director
            </p>
          </span>
        </div>
      </div>

  </div>*/}