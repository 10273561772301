import axios from "axios";
import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";

export interface SectionGridCategoryBoxProps {
  categories?: TaxonomyType[];
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const DEMO_CATS: TaxonomyType[] = [
  {
    id: "2",
    href: "dubai-tour-packages",
    name: "Dubai",
    taxonomy: "category",
    count: 9,
    text: "9 Tours | 1 Departure",
    thumbnail:
      "https://i.ibb.co/d7tdgmv/dubai.jpg",
  },
  {
    id: "1",
    href: "/bali-tour-packages",
    name: "Bali",
    taxonomy: "category",
    count: 9,
    text: "9 Tours | 2 Departures",
    thumbnail:
      "https://i.ibb.co/rmmBTcq/bali.jpg",
  },
  {
    id: "2",
    href: "/vietnam-tour-packages",
    name: "Vietnam",
    taxonomy: "category",
    count: 7,
    text: "5 Tours | 2 Departures",
    thumbnail:
      "https://i.ibb.co/Q8kdgTw/vietnam.webp",
  },

  {
    id: "2",
    href: "/cambodia-tour-packages",
    name: "Cambodia",
    taxonomy: "category",
    count: 3,
    text: "3 Tours | 1 Departure",
    thumbnail:
      "https://i.ibb.co/nBtVT9f/cambodia.jpg",
  },


  {
    id: "2",
    href: "/thailand-tour-packages",
    name: "Thailand",
    taxonomy: "category",
    count: 13,
    text: "13 Tours | 2 Departures",
    thumbnail:
      "https://i.ibb.co/LZfQ9kp/thailand.jpg",
  },

  {
    id: "2",
    href: "/singapore-tour-packages",
    name: "Singapore",
    taxonomy: "category",
    count: 13,
    text: "13 Tours | 2 Departures",
    thumbnail:
      "https://i.ibb.co/5Fp1tHm/singapore.webp",
  },

  {
    id: "2",
    href: "/malaysia-tour-packages",
    name: "Malaysia",
    taxonomy: "category",
    count: 10,
    text: "10 Tours | 2 Departures",
    thumbnail:
      "https://i.ibb.co/R2cpyzM/malaysia.webp",
  },

  {
    id: "2",
    href: "/malaysia-tour-packages",
    name: "Srilanka",
    taxonomy: "category",
    count: 16,
    text: "16 Tours | 3 Departures",
    thumbnail:
      "https://i.ibb.co/nr9N2JX/srilankaa.jpg",
  },

];

const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categories = DEMO_CATS,
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-2 lg:gap-2 gap-2 sm:grid-cols-3 lg:grid-cols-4 md:grid-cols-3  xl:grid-cols-4",
}) => {
  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  const [landingPages, setLandingPages] = useState([]);

  useEffect(() => {
    axios.get("https://admin.trawelmart.com/admin/public/index.php/api/getLandingPagesForWebsite")
      .then((response) => {
        if (response.data.status) {
          setLandingPages(response.data.pages);
        }
      })
  }, []);

  return (
    <div className={`font nc-SectionGridCategoryBox relative ${className}`}>
      <div className="lg:font-semibold font-medium font lg:text-2xl text-base">
        Explore the Charm of Well known Destinations
      </div>
      <div className="font font-regular lg:text-base text-xs text-neutral-60 pt-1">
      Travel and Tours Operations Top Destination In The World
      </div>
      <div className={`grid ${gridClassName}gap-5 xl:gap-3 lg:gap-2 sm:gap-2 md:gap-2 lg:mt-8 mt-4`}>
        {/* {categories.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />

        ))} */}
        {landingPages.filter((item) => ["bali-tour-packages", "dubai-tour-packages", "cambodia-tour-packages", "malaysia-tour-packages",
          "singapore-tour-packages", "thailand-tour-packages", "vietnam-tour-packages", "srilanka-tour-packages"].includes((item as any).slug)).map((item, i) => (
            <Link
              to={'/' + (item as any).slug}
              className={`nc-CardCategoryBox1 border-sky-100 relative shadow flex items-center lg:p-2 sm:p-2 p-3 rounded-lg [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] `}
              data-nc-id="CardCategoryBox1"
              key={i}
            >
              <Badge
                className="absolute right-2 top-2 lg:block hidden"
                color="blue"
                name={convertNumbThousand((item as any).count)}
              />
              <div className="relative flex-shrink-0 lg:w-20 lg:h-20 w-12 h-12 rounded-full overflow-hidden">
                <NcImage src={(item as any).logo ? 'https://admin.trawelmart.com/admin/public/' + (item as any).logo : 'https://admin.trawelmart.com/admin/public/' + (item as any).banner_image} containerClassName="absolute inset-0" />
              </div>
              <div className=" ml-4 flex-grow overflow-hidden">
                <h2 className="lg:text-lg text-xs lg:font-regular font-medium">
                  <span className="font line-clamp-1">{(item as any).city}</span>
                </h2>
                <span
                  className={`block mt-1 lg:text-xs text-xs text-neutral-600 dark:text-neutral-400`}
                >
                  {(item as any).count + ' Tours | 1 Departures'}
                </span>
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
};

export default SectionGridCategoryBox;
