import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogSrilanka = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Srilanka" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Love in Paradise: A Romantic Getaway Guide to Sri Lanka
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Nestled in the Indian Ocean, Sri Lanka is not only a land of breathtaking landscapes and rich
                        culture but also a haven for couples seeking a romantic escape. With its lush greenery,
                        pristine beaches, and a touch of exoticism, Sri Lanka is the perfect destination for lovebirds.
                        In this guide, we&#39;ll take you on a journey through the most enchanting spots for a romantic
                        getaway in Sri Lanka.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    Unwind on Exquisite Beaches
                </p>
                <p className="">
                    &quot;<strong>Love on the Shores:</strong> Romantic Beach Retreats in Sri Lanka&quot;
                </p>
                <p className="">
                    Sri Lanka boasts some of the most exquisite beaches in the world. Start your romantic
                    journey with a visit to Mirissa Beach, known for its golden sands and laid-back ambiance. It&#39;s
                    the ideal spot for a leisurely walk hand in hand along the shoreline, especially during sunset
                    when the sky is painted with hues of orange and pink.
                </p>
                <p className="">
                    For a more secluded experience, head to Tangalle Beach, where you can have a private picnic
                    under the swaying palms. Unawatuna Beach, near Galle, offers vibrant coral reefs perfect for
                    snorkelling with your loved one.
                </p>

                <p className="font-semibold text-xl">
                    Secluded Hideaways in the Hill Country
                </p>
                <p className="">
                    &quot;<strong>Embrace Love in the Mist:</strong> Romantic Retreats in Sri Lanka&#39;s Hill Country&quot;
                </p>
                <p className="">
                    Sri Lanka&#39;s hill country is a paradise for couples seeking cooler climes and breathtaking
                    landscapes. Nuwara Eliya, often called &quot;Little England,&quot; is the epitome of romance with its
                    misty mountains, lush tea plantations, and charming colonial architecture.
                </p>
                <p className="">
                    Stay in a cozy tea estate bungalow and embark on a scenic train journey through verdant
                    valleys from Ella to Kandy. While in Kandy, don&#39;t miss a visit to the Royal Botanic Gardens,
                    a botanical wonderland perfect for a romantic stroll.
                </p>



                <p className="font-semibold text-xl">
                    Luxury and Tranquillity in the South Coast
                </p>
                <p className="">
                    &quot;<strong>Luxury and Love :</strong> Romantic Retreats on Sri Lanka&#39;s South Coast&quot;
                </p>
                <p className="">
                    For those seeking luxury and seclusion, Sri Lanka&#39;s southern coast offers a plethora of
                    romantic resorts and boutique hotels. Spend your days lazing by your private pool or on the
                    pristine beaches of Bentota, where the turquoise waters provide an enchanting backdrop.
                </p>
                <p className="">
                    Indulge in a couples&#39; spa treatment, dine under the stars, and savor delectable seafood at one
                    of the fine dining restaurants along the coast. Mirissa, Tangalle, and Weligama also offer
                    luxury resorts where you can immerse yourselves in utter bliss.
                </p>


                <p className="font-semibold text-xl">
                    Sunsets and Serenity on the East Coast
                </p>
                <p className="">
                    &quot;<strong>Sun-Kissed Love :</strong> Romantic Escapes on Sri Lanka&#39;s East Coast&quot;
                </p>
                <p className="">
                    Sri Lanka&#39;s east coast is a hidden gem, known for its untouched beaches and tranquillity.
                    Passikudah and Kalkudah, with their shallow and clear waters, are perfect for romantic walks
                    along the shore. Unwind in one of the luxurious resorts nestled among the coconut palms.
                </p>
                <p className="">
                    If you&#39;re into water sports, the nearby city of Trincomalee offers opportunities for snorkelling,
                    diving, and dolphin watching. Don&#39;t forget to catch the mesmerizing sunrise on Nilaveli
                    Beach, a moment you&#39;ll cherish forever.
                </p>



                <p className="font-semibold text-xl">
                    Cultural Romance in Ancient Cities
                </p>
                <p className="">
                    &quot;<strong>Timeless Love :</strong> Romantic Journeys through Sri Lanka&#39;s Ancient Cities&quot;
                </p>
                <p className="">
                    For history enthusiasts, Sri Lanka&#39;s ancient cities provide a blend of culture and romance.
                    Explore the rock fortress of Sigiriya, where you can ascend the iconic Lion Rock together and
                    witness panoramic views of the surrounding landscapes.
                </p>
                <p className="">
                    Visit the enchanting city of Kandy, home to the Temple of the Tooth Relic, a sacred site for
                    Buddhists. In the evening, take a leisurely stroll around the Kandy Lake, where the serene
                    waters mirror the stars above.
                </p>


                <p className="font-semibold text-xl">
                    Gourmet Adventures for Two
                </p>
                <p className="">
                    &quot;<strong>Love and Flavors:</strong> Culinary Delights of Sri Lanka&quot;
                </p>
                <p className="">
                    Sri Lanka&#39;s cuisine is a symphony of flavours, and exploring it together can be a delight for
                    your taste buds. Share a plate of fiery yet flavourful curry, savor crispy hoppers, and indulge
                    in sweet treats like kavum and Wattala am.
                </p>
                <p className="">
                    Consider taking a cooking class together to learn the art of Sri Lankan cooking. It&#39;s a
                    wonderful way to bond over a shared love for food and culture.
                </p>


                <p className="font-semibold text-xl">
                    The Timeless Appeal of Galle
                </p>
                <p className="">
                    &quot;<strong>Walls of Love:</strong> Exploring Romantic Galle&quot;
                </p>
                <p className="">
                    The historic city of Galle, with its colonial architecture and ancient walls, exudes an old-
                    world charm that&#39;s perfect for romantic exploration. Walk hand in hand through the
                    cobblestone streets of Galle Fort, a UNESCO World Heritage Site.
                </p>
                <p className="">
                    Visit art galleries, boutique shops, and cafes within the fort, and enjoy a candlelit dinner
                    overlooking the ocean. Galle is a destination were history and romance merge seamlessly.
                </p>

                <p className="font-semibold text-lg">
                    Conclusion:Your Love Story in Sri Lanka
                </p>
                <p>
                    Sri Lanka&#39;s romantic getaways are like no other, offering a perfect blend of natural beauty,
                    cultural richness, and luxurious comforts. Whether you&#39;re watching the sunset on a secluded
                    beach, exploring ancient cities hand in hand, or savoring culinary delights together, Sri Lanka
                    sets the stage for a love story you&#39;ll cherish forever.
                </p>

                <p>
                    So, pack your bags, embark on this
                    journey of love, and create memories that will last a lifetime in this paradise of romance. Sri
                    Lanka awaits, ready to weave its magic on your hearts.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/bW1gxR2/Srilanka.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/* 
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogSrilanka;
