import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const DubaiBlogg = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Dubai" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Top 10 Tourist Attractions in Dubai
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Dubai, one of the UAE's seven emirates, has transformed from a humble fishing village into a
                        vibrant global city. It boasts opulent hotels, extravagant malls, and iconic architecture,
                        making it a coveted tourist destination. Whether indulging in retail therapy, embarking on a
                        culinary adventure, or immersing in Emirati culture, Dubai delivers.
                    </span>

                    {/*<div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>*/}
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p>
                    To ease your itinerary planning, here are Dubai's top 10 attractions. Explore the stunning Burj Khalifa,
                    the world's tallest skyscraper, and the awe-inspiring Dubai Fountain. Experience luxury at Palm Jumeirah's
                    lavish resorts or discover cultural gems at Al Fahidi Historic District. Satiate your shopping desires at Dubai
                    Mall, featuring diverse brands and entertainment.
                </p>
                {/*<p>
          It is a long established fact that a <strong>reader</strong> will be
          distracted by the readable content of a page when looking at its{" "}
          <strong>layout</strong>. The point of using Lorem Ipsum is that it has
          a more-or-less normal{" "}
          <a href="/#" target="_blank" rel="noopener noreferrer">
            distribution of letters.
          </a>{" "}
        </p>
        <ol>
          <li>We want everything to look good out of the box.</li>
          <li>
            Really just the first reason, that's the whole point of the plugin.
          </li>
          <li>
            Here's a third pretend reason though a list with three items looks
            more realistic than a list with two items.
          </li>
    </ol>*/}

                <p>
                    For adventure seekers, there's the Dubai Desert Safari, offering dune bashing and desert experiences.
                    Don't miss the vibrant Gold and Spice Souks, radiating local charm. Immerse in tradition at Jumeirah
                    Mosque or marvel at modernity in Dubai Marina.
                </p>
                {/*<p>Something a wise person once told me about typography is:</p>
        <blockquote>
          <p>
            Typography is pretty important if you don't want your stuff to look
            like trash. Make it good then it won't be bad.
          </p>
  </blockquote>*/}
                <h3 className="font ">Burj Khalifa</h3>
                <p>
                    Dominating Dubai's skyline at 829.8 meters, Burj Khalifa stands as the city's iconic centerpiece and a
                    prime tourist magnet. Adjacent to Dubai Mall, it epitomizes the city's essence, drawing a continuous stream
                    of visitors. Two observation decks, offering breathtaking city panoramas, contribute to its allure.
                    Burj Khalifa clinches several global records, including world's tallest edifice, freestanding structure,
                    and story count. Its dazzling stature and accolades make it an unparalleled attraction, showcasing Dubai's
                    grandeur.
                </p>
                {/*<figure>
                    <img
                        src="https://images.unsplash.com/photo-1501493870936-9c2e41625521?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;ixlib=rb-1.2.1&amp;auto=format&amp;fit=crop&amp;w=1045&amp;q=80"
                        alt="nc blog"
                        className="rounded-2xl"
                    />
                </figure>*/}

                {/*<ul>
          <li>So here is the first item in this list.</li>
          <li>In this example we're keeping the items short.</li>
          <li>Later, we'll use longer, more complex list items.</li>
        </ul>
        <p>And that's the end of this section.</p>
        <h2>Code should look okay by default.</h2>
        <p>
          I think most people are going to use{" "}
          <a href="https://highlightjs.org/">highlight.js</a> or{" "}
          <a href="https://prismjs.com/">Prism</a> or something if they want to
          style their code blocks but it wouldn't hurt to make them look{" "}
          <em>okay</em> out of the box, even with no syntax highlighting.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p>

        <p>Hopefully that looks good enough to you.</p>
        <h3>We still need to think about stacked headings though.</h3>
        <h4>
          Let's make sure we don't screw that up with <code>h4</code> elements,
          either.
        </h4>
        <p>
          Phew, with any luck we have styled the headings above this text and
          they look pretty good.
        </p>
        <p>
          Let's add a closing paragraph here so things end with a decently sized
          block of text. I can't explain why I want things to end that way but I
          have to assume it's because I think things will look weird or
          unbalanced if there is a heading too close to the end of the document.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p>*/}

                <h3 className="font ">Dubai Fountain</h3>
                <p>
                    An essential inclusion in Dubai's premier attractions is the renowned Dubai Fountain Show, a mesmerizing
                    blend of water, music, and light nestled in Downtown Dubai. Its water jets surge up to an astounding
                    150 meters, securing its title as the world's tallest performing fountain. The fountain's distinctive
                    design encompasses five varying-sized circles and two grand arcs. Boasting numerous high-powered water
                    nozzles that propel water skyward, the fountain maintains over 22,000 gallons of water suspended during
                    its operations.
                </p>

                <h3 className="font ">Dubai Museum</h3>
                <p>
                    The entrance hosts an array of vintage maps showcasing the Emirates' and Dubai's historical expansion
                    across centuries. Inside, the complex boasts traditional boats, an Emirati wind tower, and a palm-leaf
                    abode. Diverse display halls spotlight weaponry, Emirati musical instruments, and artifacts retrieved
                    from the ancient graves of the Al Qusais archaeological site, dating back 3,000 to 4,000 years. For
                    history enthusiasts, this destination stands as a paramount attraction, revealing Dubai's rich heritage
                    in a captivating narrative.
                </p>

                <h3 className="font ">Legoland Dubai</h3>
                <p>
                    For an unforgettable family getaway, Legoland Dubai is an unmissable gem amidst Dubai's attractions.
                    A cornerstone of Dubai Parks and Resorts, this theme park revolves around the beloved LEGO building blocks.
                    Split into two segments—a theme park and a water park—it boasts a LEGO-crafted marvel of the Burj Khalifa
                    and Dubai's captivating skyline. With 40+ exhilarating rides, imaginative children's zones, and captivating
                    features, Legoland Dubai guarantees a joyous, action-packed day for all.
                </p>

                <h3 className="font ">Dubai Zoo</h3>
                <p>
                    Established in 1967 as a private exotic animal collection, Dubai Zoo has evolved into the Arabian
                    Peninsula's inaugural zoo and a global sensation. Encompassing 2 hectares of lush, meticulously maintained
                    lawns, it's home to nine prominent feline species and seven primate varieties. Among its denizens
                    are African lions, Syrian bears, Bengal tigers, chimpanzees, and African giraffes. Renowned as a
                    must-visit, Dubai Zoo stands as a cherished attraction, offering a harmonious haven for diverse
                    wildlife and captivating visitors from around the world.
                </p>

                <h3 className="font ">Ain Dubai</h3>
                <p>
                    Ain Dubai, or Dubai Eye in Arabic, stands as the world's tallest Ferris wheel, soaring at an
                    astonishing 250 meters. Perched on Bluewaters Island, this colossal observation wheel boasts
                    48 climate-controlled cabins accommodating up to 1750 passengers simultaneously. During the
                    captivating 38-minute voyage, it unveils unparalleled 360-degree vistas of Dubai's captivating
                    skyline. The journey also offers breathtaking views of the coastline and iconic landmarks like
                    Burj Al Arab and Burj Khalifa. Ain Dubai, a pinnacle attraction, promises an unforgettable Dubai
                    experience, encapsulating the city's splendor in a single, awe-inspiring vista.
                </p>

                <h3 className="font ">Dubai Frame</h3>
                <p>
                    Dubai Frame, a monumental structure resembling an enormous picture frame, stands at an
                    impressive 150.24 meters in height and 95.53 meters in width, clinching the global title
                    for the largest frame. Symbolizing a metaphoric link between the emirate's storied history
                    and its splendid contemporary era, this iconic landmark resides in Zabeel Park. A fusion of
                    observatory, museum, and monument, Dubai Frame offers an all-encompassing experience.
                </p>

                <h3 className="font ">Global Village</h3>
                <p>
                    Global Village, Dubai, stands as the planet's largest shopping, leisure, and tourism hub, embracing
                    the world's captivating diversity. This vast, multicultural theme park offers a harmonious blend
                    of cultural displays, carnival thrills, street gastronomy, and more. It's an integrated entertainment
                    haven, celebrating global heritage through immersive experiences. From cultural performances to
                    exhilarating rides and delectable street fares, Global Village creates an unmissable destination,
                    encapsulating the essence of worldwide cultures. A must-visit gem, it invites visitors to traverse
                    the globe's cultural tapestry in a single enchanting locale.
                </p>

                <h3 className="font ">IMG Worlds of Adventure</h3>
                <p>
                    Spanning over 1.5 million square feet, IMG Worlds of Adventure ranks as the globe's largest
                    indoor theme and amusement park. This Dubai adventure haven hosts 17 exhilarating rides, including
                    exclusive bespoke attractions that are unavailable elsewhere. Anchored in beloved Marvel superheroes
                    and Cartoon Network characters, the park offers a thrilling escapade for kids and adults alike.
                </p>

                <h3 className="font ">Dubai Garden Glow</h3>
                <p>
                    Situated within Zabeel Park, Dubai Garden Glow reigns as the world's largest distinctive theme park,
                    boasting unparalleled and innovative concepts. An annual spectacle held during winter months,
                    this renowned attraction showcases an expansive array of mesmerizing light sculptures. Crafted by
                    a global collective of artists, engineers, and technicians, the park's enchanting creations captivate
                    with their uniqueness.
                </p>




            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {post.author.displayName}
                        </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/L6dgHLc/blog3.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default DubaiBlogg;
