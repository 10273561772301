import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { DEMO_POSTS } from "data/posts";

const BlogSingaporee = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Singapore" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Singapore's Green Oasis: Exploring the Serenity Within the Urban Bustle
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Singapore, often hailed as a concrete jungle, surprises visitors and locals alike with its
                        lush green spaces scattered across the city. Amidst the towering skyscrapers and bustling streets,
                        these verdant oases provide a retreat for nature lovers and a breath of fresh air for everyone.
                        Let's delve into the vibrant world of Singapore's green spaces, discovering the serenity they offer
                        within the heart of urban life.
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >
                <p className="font-semibold text-xl">
                    Gardens by the Bay: A Symphony of Nature and Innovation
                </p>
                <p className="">
                    At the forefront of Singapore's green revolution stands the iconic Gardens by the Bay. This futuristic
                    garden spans 101 hectares and is a harmonious blend of nature and technology.
                </p>

                <p className="">
                    The Supertrees, vertical gardens that mimic the functions of real trees, create a surreal
                    atmosphere during the day and come alive with a mesmerizing light display at night. The Flower
                    Dome and Cloud Forest further enrich the experience, showcasing a myriad of plant species from
                    around the world.
                </p>

                <p className="font-semibold text-xl">
                    Singapore Botanic Gardens: A Heritage of Green Elegance
                </p>
                <p className="">
                    Established in 1859, the Singapore Botanic Gardens boast a rich history and a timeless charm. Home
                    to the National Orchid Garden, it houses an extensive collection of orchid species, including the
                    elusive and exquisite Vanda Miss Joaquim, Singapore's national flower. The lush landscapes, serene
                    lakes, and heritage trees provide a tranquil setting for leisurely strolls and picnics, making it
                    a beloved spot for both locals and tourists.
                </p>

                <p className="font-semibold text-xl">
                    MacRitchie Reservoir Park: Nature's Playground in the City
                </p>
                <p className="">
                    For those seeking an escape into the wilderness, MacRitchie Reservoir Park offers a perfect
                    blend of nature and recreational activities. The TreeTop Walk, suspended 25 meters above the
                    forest floor, provides a bird's eye view of the lush rainforest canopy. Nature trails around
                    the reservoir cater to hikers and joggers, creating a haven for outdoor enthusiasts right in
                    the heart of Singapore.
                </p>


                <p className="font-semibold text-xl">
                    East Coast Park: Where the City Meets the Sea
                </p>
                <p className="">
                    East Coast Park stretches along the southeastern coast of Singapore, offering a unique green space
                    where urban landscapes seamlessly merge with sandy shores. This park is a haven for beach lovers,
                    cyclists, and families alike. With barbecue pits, water sports facilities, and an inviting coastline,
                    it's a popular destination for weekend getaways and outdoor activities.
                </p>

                <p className="font-semibold text-xl">
                    Bishan-Ang Mo Kio Park: Nature Reimagined in the Heartlands
                </p>
                <p className="">
                    Nestled in the heartlands of Singapore, Bishan-Ang Mo Kio Park showcases innovative eco-friendly
                    features. The Kallang River meanders through the park, surrounded by natural vegetation and thriving
                    biodiversity. The park's sustainable design and recreational spaces make it a perfect spot for community
                    gatherings, picnics, and even kayaking in the river.
                </p>

                <p className="font-semibold text-xl">
                    Pulau Ubin: A Step Back in Time
                </p>
                <p className="">
                    For a taste of rustic simplicity, Pulau Ubin offers an authentic experience away from the urban hustle.
                    This tranquil island, accessible by a short boat ride, is a haven for nature lovers and adventure seekers.
                    Explore lush forests, traditional kampongs (villages), and scenic trails, capturing a glimpse of Singapore'
                    s past.
                </p>


                <p className="font-semibold text-xl">
                    HortPark: A Horticultural Haven in the City
                </p>
                <p className="">
                    Nestled in the Southern Ridges, HortPark stands as Singapore's gardening hub and a
                    sanctuary for plant enthusiasts. Boasting themed gardens and educational resources,
                    it provides a unique blend of leisure and learning.
                </p>


                <p className="">
                    Nestled in the Southern Ridges, HortPark stands as Singapore's gardening hub and a
                    sanctuary for plant enthusiasts. Boasting themed gardens and educational resources,
                    it provides a unique blend of leisure and learning.

                </p>

                <p className="font-semibold text-xl">
                    Jurong Lake Gardens: Where Nature and Recreation Unite
                </p>
                <p className="">
                    The recent addition to Singapore's green spaces, Jurong Lake Gardens, has quickly become a
                    favorite among locals. This expansive garden seamlessly integrates nature, play, and sports,
                    catering to a wide spectrum of interests. The Lakeside Garden, Chinese and Japanese Gardens,
                    and the Clusia Cove exemplify the park's diverse landscapes, offering a tranquil escape and
                    recreational activities for all ages.
                </p>

                <p className="font-semibold text-xl">
                    Labrador Nature Reserve: Coastal Charm with Historical Echoes
                </p>
                <p className="">
                    For a unique blend of nature and history, the Labrador Nature Reserve stands out. This coastal
                    park boasts scenic cliff-top views and remnants of World War II structures, providing a captivating
                    journey through time. Nature trails along the coast, mangrove habitats, and historical relics make
                    it an educational and picturesque destination for nature enthusiasts and history buffs alike.
                </p>


                <p className="font-semibold text-xl">
                    Singapore's Skyline from Mount Faber Park: Nature Elevated
                </p>
                <p className="">
                    Mount Faber Park not only offers a respite from the urban grind but also provides a panoramic view of Singapore's
                    skyline. The cable car ride to Sentosa Island starts here, offering visitors a breathtaking perspective
                    of the city's landscape. With lush greenery, walking trails, and cozy dining options, Mount Faber Park
                    presents a perfect blend of natural beauty and urban vistas.
                </p>
              
                <p>
                    As Singapore continues to prioritize green initiatives, these additional green spaces contribute
                    to the city's commitment to sustainability, biodiversity, and the well-being of its residents. Each
                    park or garden represents a unique facet of the city-state, showcasing not only its dedication to
                    nature but also the harmonious coexistence of urban development and environmental preservation.
                </p>

                <p>
                    Incorporating these diverse green spaces into your Singapore itinerary ensures a holistic experience,
                    from futuristic gardens to historical reserves, demonstrating that amidst the urban sprawl, nature thrives,
                    inviting residents and visitors alike to connect with the green soul of the Lion City.
                </p>
                


                <p className="font-semibold text-lg">
                    Conclusion
                </p>


                <p>
                    In conclusion, Singapore's green spaces transcend mere aesthetics; they represent a commitment to
                    sustainability, environmental consciousness, and the well-being of its residents. These urban retreats
                    not only provide a sanctuary for flora and fauna but also serve as communal spaces that bring people
                    together. As the city-state continues to evolve, its green initiatives stand as a testament to the
                    harmonious coexistence of nature and modernity. So, the next time you find yourself in Singapore,
                    take a detour from the bustling streets and immerse yourself in the tranquility of these green gems.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="TravelBlog" />
                    <h2 className="block text-base font-medium text-white font">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/r29cbj4/singapore2.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-2">
                        {/*  
                        {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogSingaporee;
