import React, { FC, useState } from "react";

import email from "images/email.png"
import pic from "images/wepik-export-20230614115601ljhY.png"
import car from "images/sedan-car-front.png"
import plane from "images/aircraft.png"
import restaurant from "images/cutlery.png"
import building from "images/apartment.png"
import trek from "images/hiker.png"






export interface FlightCard2Props {
    className?: string;
    defaultOpen?: boolean;
    data: {
        id: string;
        airlines: {
            logo: string;
            name: string;
        };
        day: string;
        days: string;
        price: string;
        text: string;
        icons: string;
    };
}

const FlightCard2: FC<FlightCard2Props> = ({
  className = "",
  data,
  defaultOpen = false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  // const renderDetailTop = () => {
  //   return (
  //     <div>
  //         <div className="flex flex-col md:flex-row ">
  //         <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
  //           <img src={data.airlines.logo} className="w-10" alt="" />
  //         </div>
  //         <div className="flex my-5 md:my-0">
  //           <div className="flex-shrink-0 flex flex-col items-center py-2">
  //             <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  //             <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
  //             <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
  //           </div>
  //           <div className="ml-4 space-y-10 text-sm">
  //             <div className="flex flex-col space-y-1">
  //               <span className=" text-neutral-500 dark:text-neutral-400">
  //                 Monday, August 12 · 10:00
  //               </span>
  //               <span className=" font-semibold">
  //                 Tokyo International Airport (HND)
  //               </span>
  //             </div>
  //             <div className="flex flex-col space-y-1">
  //               <span className=" text-neutral-500 dark:text-neutral-400">
  //                 Monday, August 16 · 10:00
  //               </span>
  //               <span className=" font-semibold">
  //                 Singapore International Airport (SIN)
  //               </span>
  //             </div>
  //           </div>
  //         </div>
  //         <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
  //         <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
  //           <li>Trip time: 7 hours 45 minutes</li>
  //           <li>ANA · Business class · Boeing 787 · NH 847</li>
  //         </ul>
  //       </div>
  //     </div>
  //   );
  // };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="">
        {/* {renderDetailTop()} */}
        <div className="my-1 md:my-1 space-y-5 md:pl-2">
          <div className="flex mt-4">
            <div className="w-full border rounded-2xl ">
              <div className="p-4 bg-[#f4eeff] rounded-t-2xl">
                <h2 className="text-xl font-semibold inline ">Dubai</h2><span> - 12 Nights stay</span>
              </div>


              <hr />

              <div className="flex">
                <div className="lg:w-2/12 bg-[#fbf7fb] p-4  ">
                  <h2 className="font-bold font">Day 1</h2>
                  <p>Jul 6, Thu</p>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={building} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Check in to Hampton By Hilton Dubai Airport - Holidays Selections, 3 Star
                    </h2>
                  </div>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2

                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The stunning architecture of the area is showcased in full glory, including the National Bank of Dubai.
                    </h2>
                  </div>
                </div>

              </div>

              <hr className=" w-full" />

              <div className="flex">
                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 ">
                  <h2 className="font-bold font">Day 2</h2>
                  <p>Jul 7, Fri</p>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Visit Dubai Creek, Jumeirah Mosque, Jumeirah Open Beach, Dubai Museum, as you pass by Burj Al Arab, Palm Island, and Sheikh Zayed Road.
                    </h2>
                  </div>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>

              </div>

              <hr className=" w-full" />

              <div className="flex">
                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 ">
                  <h2 className="font-bold font">Day 3</h2>
                  <p>Jul 8, Sat</p>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The Expo 2020 is a World Expo currently hosted in Dubai from 1 October 2021 – 31 March 2022.
                    </h2>
                  </div>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>
              </div>

              <hr className=" w-full" />
              <div className="flex">
                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 ">
                  <h2 className="font-bold font">Day 4</h2>
                  <p>Jul 9, Sun</p>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Enjoy a water-themed day of adventure at one of Dubai’s most iconic structures, Atlantis the Palm.
                    </h2>
                  </div>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6 " />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>
              </div>

              <hr className=" w-full" />

              <div className="flex">
                <div className="lg:w-2/12 bg-[#fbf7fb] p-4 ">
                  <h2 className="font-bold font">Day 5</h2>
                  <p>Jul 10, Mon</p>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The Aquaventure Park and Lost Chambers Enjoy a water-themed day of adventure at one of Dubai’s most iconic structures, Atlantis The Palm.
                    </h2>
                  </div>
                </div>

              </div>

              <hr className=" w-full" />

              <div className="flex ">
                <div className="lg:w-2/12 bg-[#fbf7fb] rounded-bl-2xl p-4 ">
                  <h2 className="font-bold font">Day 6</h2>
                  <p>Jul 11, Tue</p>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center border-l ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2

                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      A large building in the shape of a frame, it is an architectural marvel that stands out against the Dubai city skyline! It offers a breath-taking view of Old and New Dubai!
                    </h2>
                  </div>
                </div>
              </div>


              <hr className=" w-full" />

              <div className="flex ">
                <div className="lg:w-2/12 bg-[#fbf7fb] rounded-bl-2xl p-4 ">
                  <h2 className="font-bold font">Day 7</h2>
                  <p>Jul 12, Wed</p>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={trek} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      The Ferrari Theme Park is another attraction to thrill your senses, not for the faint-hearted!
                    </h2>
                  </div>
                </div>
                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>

              </div>
              <hr className=" w-full" />
              <div className="flex ">
                <div className="lg:w-2/12 bg-[#fbf7fb] rounded-bl-2xl p-4 ">
                  <h2 className="font-bold font">Day 8</h2>
                  <p>Jul 13, Thu</p>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center ">
                  <div className="flex items-center">
                    <img src={restaurant} alt="no" className="mr-4 w-6 h-6" />
                    <h2
                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Day Meals: Breakfast : Included at Hampton By Hilton Dubai Airport - Holidays Selections , Dubai
                    </h2>
                  </div>
                </div>

                <div className="lg:w-5/12 p-4 flex items-center border-l my-2">
                  <div className="flex items-center">
                    <img src={building} alt="no" className="mr-4 w-6 h-6" />
                    <h2

                      className="font cursor-pointer text-sm font-medium  hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                    >
                      Checkout from Hotel in Dubai
                    </h2>
                  </div>
                </div>
              </div>

            </div>
          </div>



          {/* <div className="border-t border-neutral-200 dark:border-neutral-700" />
          <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
            Transit time: 15 hours 45 minutes - Bangkok (BKK)
          </div>
          <div className="border-t border-neutral-200 dark:border-neutral-700" /> */}
        </div>
        {/* {renderDetailTop()} */}
      </div>
    );
  };

  return (
    <div
      className={`font nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={`font sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${isOpen ? "transform -rotate-180" : ""
            }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img src={"https://i.ibb.co/8B89fPk/1-2.jpg"} className="w-20 rounded-lg" alt="" />
          </div>

          <div className="hidden lg:block ">
            <div className="font-bold text-lg ">{data.days}</div>
          </div>



          {/* FOR MOBILE RESPONSIVE */}
          {/* <div className="block lg:hidden space-y-1">
            <div className="flex font-semibold">
              <div>
                <span>11:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  HND
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span>20:00</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  SIN
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              <span className="VG3hNb">Nonstop</span>
              <span className="mx-2">·</span>
              <span>7h 45m</span>
              <span className="mx-2">·</span>
              <span>HAN</span>
            </div>
          </div> */}

          {/* TIME - NAME */}
          {/* <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg">11:00 - 20:00</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              {data.airlines.name}
            </div>
          </div> */}

          {/* TIMME */}
          {/* <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg"> HND - SIN</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              7 hours 15 minutes
            </div>
          </div> */}

          {/* TYPE */}
          {/* <div className="hidden lg:block flex-[4] whitespace-nowrap">
            <div className="font-medium text-lg">1 stop</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
              2 hours 15 minutes BKK
            </div>
          </div> */}

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-xl font-semibold text-secondary-6000">
                {data.price}
              </span>
            </div>
            <div className="text-xs sm:text-sm text-neutral-500 font-normal mt-0.5">
              Best Price Trip
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}
    </div>
  );
};

export default FlightCard2;
