import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { StayDataType } from "data/types";
import SocialsList from "shared/SocialsList/SocialsList";

export interface PropertyCardHProps {
  className?: string;
  data?: StayDataType;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCardH: FC<PropertyCardHProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const {
    galleryImgs,
    title,
    href,
    like,
    saleOff,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="flex">
        <div className="flex-shrink-0 p-3 w-full sm:w-64 ">
          <GallerySlider
            ratioClass="aspect-w-1 aspect-h-1"
            galleryImgs={galleryImgs}
            className="w-full h-full rounded-2xl overflow-hidden will-change-transform"
            uniqueID={`PropertyCardH_${id}`}
            href={href}
          />

          {saleOff && (
            <SaleOffBadge className="absolute left-5 top-5 !bg-orange-500" />
          )}
        </div>

        <div className="p-3 space-y-3 w-full">
          <div className="text-lg text-green-800 dark:text-neutral-400 font-medium pt-6">
            SPECIAL OFFER PRICE
            <div className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
              Starts From
            </div>
          </div>
          <span className="text-xl font-semibold">
            {`${price},000`}
          </span>
          <div className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
            per person on twin sharing
          </div>  
          <div className="flex items-center gap-2">
          <div className="bg-primary-500 text-white p-2 rounded-md text-center w-5/12">
            Get Offers
          </div>
          <div className="bg-gray-200 text-black p-2 rounded-md text-center w-5/12">
            Get A Quote 
          </div>
          </div>
          <SocialsList
            className="!space-x-2"
            itemClass="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
          />
          {/*<div className="inline-flex space-x-3">
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-share-alt"></i>
                  <span className="ml-1">4 Network</span>
                </div>
              }
            />
            <Badge
              name={
                <div className="flex items-center">
                  <i className="text-sm las la-user-friends"></i>
                  <span className="ml-1">Family</span>
                </div>
              }
              color="yellow"
            />
          </div>*/}
        </div>

      </div>
    );
  };

  const renderTienIch = () => {
    return (

      <div className="inline-grid grid-cols-6  gap-0 bg-blue-100 w-full py-1 px-4">
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
          <i className="las la-plane-departure text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
           Travel
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-hotel text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
           Rooms
          </span>
        </div>

        {/* ---- */}
        <div className="flex items-center space-x-2">
          <span className="hidden sm:inline-block">
            <i className="las la-car text-lg"></i>
          </span>
          <span className="text-xs text-neutral-500 dark:text-neutral-400">
           Cab
          </span>
        </div>
        <div className="flex items-center space-x-2">
       
        </div>
        
        <div className="flex items-center ">
          <div className="bg-yellow-100 text-yellow-800 p-2 text-xs rounded">
            Group Tour
          </div>
        </div>
        <div className="flex items-center">
          <div className="bg-green-100 text-green-800 p-2 text-xs rounded">
            Special Offer
          </div>
        </div>
      </div>

    );
  };

  const renderContent = () => {
    return (
      <div className="w-full flex-grow flex flex-col items-start">
      <div className="p-3 sm:pr-6 ">
        <div className="space-y-1 w-full">

          <div className="flex items-center space-x-2">
            
            <h2 className="text-lg font-medium capitalize">
              <span className="line-clamp-2">{title}</span>
            </h2>
          </div>
          </div>
          </div>
          <div className="w-full flex-grow flex flex-col items-start">
        <div className="w-full">
          {renderTienIch()}
          </div>
          </div>

          <div className="w-full flex-grow px-3 pt-2 sm:pr-6 pb-1 flex flex-col items-start bg-[#fbf7fb]">
        <div className="space-y-1 w-full">
          <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
          <div className="flex w-full justify-between items-end">
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2 pb-4 ">

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span className="">Travel</span>
              <span className="border-l border-gray pl-2">7</span>
              <span className="">Days</span>
              <span className="border-l border-gray pl-2">1</span>
              <span className="">Country</span>
              <span className="border-l border-gray pl-2">5</span>
              <span className="">Cities</span>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-PropertyCardH group relative dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="PropertyCardH"
    >
      <div className="bg-[#fbf7fb]">

        <Link to={href} className="absolute inset-0"></Link>
        <div className="h-full w-full sm:items-center">
          {renderSliderGallery()}
        </div>
        <BtnLikeIcon
          colorClass={` bg-neutral-100 dark:bg-neutral-700 hover:bg-neutral-200 hover:bg-opacity-70 text-neutral-6000 dark:text-neutral-400`}
          isLiked={like}
          className="absolute right-5 top-5 sm:right-3 sm:top-3 "
        />
      </div>


      {renderContent()}
    </div>
  );
};

export default PropertyCardH;
