import React, { FC, Fragment, useState, useEffect } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating from "components/StartRating/StartRating";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "../SectionDateRange";
import StayDatesRangeInput from "./StayDatesRangeInput";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { useLocation, useNavigate } from "react-router-dom";
import { Amenities_demos, PHOTOS9 } from "./constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import DetailPagetLayout from "../Layout";
import BaliCard1, { BaliCard1Props } from "components/FlightCard/Bali/BaliCard1";
import BaliCard2, { BaliCard2Props } from "components/FlightCard/Bali/BaliCard2";
import BaliCard3, { BaliCard3Props } from "components/FlightCard/Bali/BaliCard3";
import BaliCard4, { BaliCard4Props } from "components/FlightCard/Bali/BaliCard4";
import BaliCard5, { BaliCard5Props } from "components/FlightCard/Bali/BaliCard5";
import { Tab } from "@headlessui/react";
import $ from 'jquery';
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import coin from "images/ICONS/coin.png";
import india from "images/india.png"
import offersss from "images/offersss.gif";
import emirates from "images/ICONS/emirates.png";
import airasia from "images/ICONS/airasia.png";
import indigo from "images/ICONS/indigo.png";
import { HashLink } from 'react-router-hash-link';
import Accordion from "./Accordion";
import axios from "axios";
import PagePopup from "containers/PageHome/PagePopup";
import passport from "images/flags/passport.png";


const StayDetailsPageContainer: FC<{}> = () => {

    let [inOpen, setInOpen] = useState(false)
    function incloseModal() {
        setInOpen(false)
    }

    function inopenModal() {
        setInOpen(true)
    }
    //
    {/* wizard script starts */ }

    const [currentStep, setCurrentStep] = useState(1);
    const totalSteps = 3;
    const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);

    const handleNextButtonClick = () => {
        if (currentStep < totalSteps) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrevButtonClick = () => {
        if (currentStep > 1) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    const handleEnquiryButtonClick = () => {
        setShowEnquiryPopup(true);
    };

    const handleEditIconClick = () => {
        setCurrentStep(1);
        setShowEnquiryPopup(false); // Show the popup again after going back to the first step
    };

    {/* wizard script Ends */ }

    const accordionData = [
        {
            title: 'Must have Documents for Dubai Tourist Visa',
            content: [
                <ul className="pl-8 list-disc">
                    <li className="py-2">
                        Scanned colour copy of first and last page of your valid Passport
                    </li>
                    <li className="py-2">
                        Scanned colour copy of your passport size photograph with white background
                    </li>
                    <li className="py-2">
                        Confirmed return air ticket (required for Ok to Board processing)
                    </li>

                </ul>,
            ],
        },
    ]

    const accordionData2 = [
        {
            title: 'Flight Upgrade',
            content: [
                <ul key="list" >
                    <li className="text-xs font py-2">
                        Need to upgrade to business or first class? Please get in touch with our team on +91 8046427999 for more details.
                    </li>
                </ul>,
            ],
        },

        {
            title: 'Hotel Upgrade',
            content: [
                <ul key="list" >
                    <li className="text-xs font py-2">
                        You can select the Hotel Upgrade in your coach at an additional cost. Please get in touch with our team on +91 8046427999 for more details.
                    </li>
                </ul>,
            ],
        },
    ]



    const accordionData3 = [

        {
            title: 'Must have Documents for Dubai Tourist Visa:',
            content: [
                <ul className="pl-6 list-disc">
                    <li className="py-1">
                        Scanned colour copy of first and last page of your valid Passport
                    </li>
                    <li className="py-1">
                        Scanned colour copy of your passport size photograph with white background
                    </li>
                    <li className="py-1">
                        Confirmed return air ticket (required for Ok to Board processing)
                    </li>

                </ul>,
            ],
        },

    ]


    const [activeMenuItem, setActiveMenuItem] = useState('');

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('section');
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;

                if (
                    scrollPosition >= sectionTop - sectionHeight / 3 &&
                    scrollPosition < sectionTop + sectionHeight
                ) {
                    setActiveMenuItem(section.id);
                }
            });
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    let [isOpen, setIsOpen] = useState(false)
    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }
    let [categories] = useState(["Need to know", "Tour Preparation", "Cancellation Policy"]);
    let [categories2] = useState(["Transport Details", "Accommodation Details"]);
    let [categories3] = useState(["Flight Upgrade", "Hotel Upgrade"]);
    let [categories1] = useState(["Included", "Excluded"]);
    let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
    const [adultValue, setAdultValue] = useState(1);
    const [childrenValue, setChildrenValue] = useState(0);
    const [infantValue, setInfantValue] = useState(0);
    const [totalAmount, setTotalAmount] = useState(1999);
    const [maximumPeople, setMaximumPeople] = useState(1);
    const [selectedRoom, setSelectedRoom] = useState('');
    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [departureError, setDepartureError] = useState(false);
    const [travelError, setTravelError] = useState(false);
    const [destinationError, setDestinationError] = useState(false);
    const [passangerError, setPassangerError] = useState(false);
    const location = useLocation();
    let [name, setName] = useState('');
    let [email, setEmail] = useState('');
    let [mobileNumber, setMobileNumber] = useState('');
    let [departureCity, setDepartureCity] = useState('');
    let [travelDate, setTravelDate] = useState('');
    let [destination, setDestination] = useState('');
    let [noofPassanger, setNoofPassanger] = useState('');
    const [inputDateType, setInputDateType] = useState('text');
    let [description, setDescription] = useState('Enquiring for Dubai Visa Online');

    useEffect(() => {
        setMaximumPeople(adultValue + childrenValue + infantValue);

    }, [adultValue, childrenValue, infantValue]);
    const handleAdultChange = (value: any) => {
        setAdultValue(value);
        setTotalAmount((value * 50000) + (childrenValue * 30000) + (infantValue * 25000));
        console.log(value);
    }

    const handleChildrenChange = (value: any) => {
        setChildrenValue(value);
        setTotalAmount((adultValue * 50000) + (value * 30000) + (infantValue * 25000));
        console.log(value);
    }

    const handleInfantChange = (value: any) => {
        setInfantValue(value);
        setTotalAmount((adultValue * 50000) + (childrenValue * 30000) + (value * 25000));
        console.log(value);
    }

    const updateRoomPrice = (value: any, roomText: any) => {
        setTotalAmount((adultValue * 50000) + (childrenValue * 30000) + (infantValue * 25000) + value);
        setSelectedRoom(roomText);
    }

    useEffect(() => {
       const userDataJSON = localStorage.getItem('loggedInUser');
        if (userDataJSON) {
            const userData = JSON.parse(userDataJSON);
            setName(userData.name);
            setMobileNumber(userData.mobile);
            setEmail(userData.email);
            setDepartureCity(userData.departure);
            setTravelDate(userData.travel);
            setDestination(userData.destination);
            setNoofPassanger(userData.Passanger);
        }
    }, []);

   const handleSubmit = () => {
    setNameError(name.length === 0 || /\d/.test(name));
    setMobileError(mobileNumber.length !== 10);
    setEmailError(email.length === 0);
    setDepartureError(departureCity.length === 0);
    setTravelError(travelDate.length === 0);
    setDestinationError(destination.length === 0);
    setPassangerError(noofPassanger.length === 0);

    if (!nameError && !mobileError && !emailError) {
        const submitButton = document.getElementById("submitButton");
        if (submitButton) {
            (submitButton as HTMLButtonElement).disabled = true;
            submitButton.innerHTML = "Submitting...";
        }
        const travelers = `${adultValue} Adults, ${childrenValue} Child, ${infantValue} Infant`;
        const formData = { name, mobile: mobileNumber, email_address: email, description, guests: travelers, room: selectedRoom, url: location.pathname };
        axios.post("https://admin.trawelmart.com/admin/public/index.php/api/addContact", formData)
            .then((response: any) => {
                console.log(response);
                if (response.status === 200) {
                    if (response.data.status) {
                        (window as any).location = '/thanks';
                    }
                    else {
                        toastr.error(response.data.message);
                    }
                }
                else {
                    console.log(response.responseText);
                }
            });
    }
}

    const thisPathname = useLocation().pathname;
    const router = useNavigate();
    const handleOpenModalImageGallery = () => {
        router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
    };










    const renderSidebar = () => {
        return (
            <div className=" bg-white rounded-xl shadow-xl height">

                <div className=" p-4 bg-[#fbf7fb] rounded-lg">
                    <div className="flex justify-between">
                        <span className="text-xl font-semibold">
                            Visa Details
                            <div className="text-xs font-normal text-blue-800 italic">
                                9,232 Guests already travelled
                            </div>
                        </span>
                        <div className="text-right ">
                            <div className="border-orange-500 rounded text-orange-600 px-6 text-center border text-xs font-semibold py-1">
                                Visa
                            </div>
                        </div>
                    </div>
                </div>


                <div className="w-full border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>
                <div className="">

                    {/*<div className="btn ">
                        <div className={currentStep === 1 ? 'step11' : 'step11 hidden'}>
                            <h2 className="text-sm font-medium pb-2 ">Add guests (Max 6 guests at a time)</h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            {/* FORM 
                            <div className="space-y-4 mt-6">
                                <div className="flex flex-col lg:flex-row items-center  ">
                                    <div className="">
                                        <i className="las la-male text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                    </div>
                                    <div className="lg:w-full">
                                        <NcInputNumber label="Adults" defaultValue={1} onChange={handleAdultChange} />
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row items-center  ">
                                    <div className="">
                                        <i className="las la-child text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                    </div>
                                    <div className="lg:w-full">
                                        <NcInputNumber label="Child" defaultValue={0} onChange={handleChildrenChange} />
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row items-center lg:pb-4">
                                    <div className="">
                                        <i className="las la-baby text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                    </div>
                                    <div className="lg:w-full">
                                        <NcInputNumber label="Infant" defaultValue={0} onChange={handleInfantChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={currentStep === 2 ? 'step12' : 'step12 hidden'}>
                            <div className="flex"></div>
                            <h2 className="text-sm font-medium pb-2 ">
                                Chosse your room
                            </h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            <div className="space-y-4 mt-8">
                                {adultValue <= 2 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'1 Twin Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                1 Twin Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'2 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'1 Double Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                1 Double Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                                {adultValue == 3 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'2 Twin Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Twin Room - 2 Adults & 1 Adult <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'3 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                3 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'2 Double Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Double Room - 2 Adults & 1 Adult <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                                {adultValue == 4 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'2 Twin Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Twin Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'4 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                4 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'2 Double Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Double Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                                {adultValue == 5 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'3 Twin Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                3 Twin Room - 2 Adults & 1 Adult<br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'5 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                5 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'3 Double Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                3 Double Room - 2 Adults & 1 Adult<br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={currentStep === 3 ? 'step13' : 'step13 hidden'}>
                            <h2 className="text-sm font-medium pb-2 flex justify-between items-center">
                                Booking Details
                                <i
                                    className="las la-edit text-sky-600 text-xl font-bold cursor-pointer"
                                    onClick={handleEditIconClick}
                                ></i>
                            </h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            <div className="space-y-6 mt-4 mb-4">
                                <h4 className="font-normal text-neutral-400 text-xs pb-1 ">
                                    Departure City
                                </h4>
                                <span className="text-sm font-semibold">
                                    Bangalore
                                </span>

                                <h4 className="font-normal text-neutral-400 text-xs pb-1 ">
                                    Departure Date
                                </h4>
                                <span className="text-sm font-semibold">
                                    08 Aug 2023 <i className="las la-long-arrow-alt-right px-2"></i>  08 Aug 2023
                                </span>

                                <h4 className="font-normal text-neutral-400 text-xs pb-1 ">
                                    Travellers
                                </h4>
                                <span className="text-sm font-semibold ">
                                    {adultValue} Adult(s) <i className="las la-grip-lines-vertical"></i>   {infantValue} Infant <i className="las la-grip-lines-vertical"></i> {childrenValue} Child
                                </span>
                            </div>
                        </div>

                        <div className="pt-4">
                            <div className="w-full border-t-2 border-dashed border-neutral-200 dark:border-neutral-700 pb-3"></div>
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className="lg:w-5/12">
                                    <div className="text-2xl font-extrabold font1">
                                        ₹{totalAmount}
                                    </div>
                                    <div className="text-xs text-green-500  italic">
                                        per person on twin sharing
                                    </div>
                                    <div className="text-sm  italic text-red-500">
                                        Detailed Tour Price
                                    </div>
                                </div>


                                <div className="lg:w-7/12 text-right" id="travellers">
                                    {currentStep > 1 && currentStep !== totalSteps && (
                                        <button
                                            id="prevButton"
                                            className="bg-blue-500 rounded-full mr-1 text-white px-6 py-3 text-xs"
                                            onClick={handlePrevButtonClick}
                                        >
                                            Previous
                                        </button>
                                    )}

                                    {currentStep === totalSteps ? (
                                        <button
                                            id="enquiryButton"
                                            className="bg-blue-500 rounded-full mr-1 text-white px-6 py-3 text-xs"
                                            onClick={handleEnquiryButtonClick}
                                        >
                                            Enquiry
                                        </button>
                                    ) : (
                                        <button
                                            id="nextButton"
                                            className="bg-blue-500 rounded-full text-white px-6 py-3 text-xs"
                                            onClick={handleNextButtonClick}
                                        >
                                            Next
                                        </button>
                                    )}

                                    {currentStep === totalSteps && (
                                        <a href="/checkout">
                                            <button
                                                id="bookOnlineButton"
                                                className="bg-blue-500 rounded-full text-white px-6 py-3 text-xs"
                                                onClick={() => {
                                                    // Your logic to handle "Book Online" button click
                                                }}
                                            >
                                                Book Now
                                            </button>
                                        </a>
                                    )}

                                    {/* Enquiry Popup 
                                    {showEnquiryPopup && (
                                        <div className="fixed top-16 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center max-w-full">
                                            <div className="bg-white rounded-lg" style={{ width: '500px' }}>
                                                <div className="text-right mt-2 mr-2">
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => {
                                                            setShowEnquiryPopup(false);
                                                            // Logic to handle form submission
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                {/*  enquiry popup content here 
                                                <div className="font p-4 bg-[#fbf7fb] rounded-t-lg text-center lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                >
                                                    Quick Enquiry
                                                    <p className="pt-2 font-normal text-xs text-sky-500">
                                                        Plan an unforgettable trip with Trawel Mart
                                                    </p>
                                                </div>
                                                <form>
                                                    <div className="mx-auto">
                                                        <label className="block xl:px-16 px-4">
                                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                                            {nameError && (<p style={{ textAlign:'start',color:'red' }}>Please enter valid name</p>)}
                                                        </label>
                                                        <label className="block xl:px-16 px-4">
                                                            <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                            {mobileError && (<p style={{ textAlign:'start',color:'red' }}>Please enter valid mobile number</p>)}
                                                        </label>
                                                        <label className="block xl:px-16 px-4">
                                                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email ID" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                            {emailError && (<p style={{ textAlign:'start',color:'red' }}>Please enter valid email</p>)}
                                                        </label>
                                                        <label className="block xl:px-16 px-4">
                                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                                        </label>
                                                    </div>

                                                    <div className="xl:px-16 px-4 mt-4">
                                                        <button type="button"
                                                            id="submitButton"
                                                            className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                                            Submit Enquiry
                                                        </button>
                                                    </div>
                                                    <p className="mt-4 text-xs text-center">
                                                           Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                    </p>
                                                    <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                                                        <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                <div className="lg:w-2/12">
                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                </div>
                                                                <div className="lg:w-9/12 pl-2">
                                                                    <span className="text-sm font-medium ">
                                                                        Super Offers
                                                                    </span>
                                                                    <p className="text-sky-700 italic font-normal text-xs ">
                                                                        Explore Great Deals
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                <div className="lg:w-2/12">
                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                </div>
                                                                <div className="lg:w-10/12 pl-2">
                                                                    <span className="text-sm font-medium ">
                                                                        My Trips
                                                                    </span>
                                                                    <p className="text-sky-700 font-normal italic text-xs ">
                                                                        Manage Your Bookings
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* ... 
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>*/}


                    <form>
                        <div className="mx-auto">
                            <label className="block xl:px-8 px-4">
                                <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                            </label>
                            <label className="block xl:px-8 px-4">
                                <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                            </label>
                               <label className="block xl:px-8 px-4">
                                <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email ID" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                            </label>
                            <label className="block xl:px-8 px-4">
                                <Input type="text" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                            </label>
                           
 <label className="block xl:px-8 px-4">
                                <Input type={inputDateType} value={travelDate} onChange={(e) => setTravelDate(e.target.value)} placeholder="Travel Date" onFocus={() => setInputDateType('date')}className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {travelError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid travel date</p>)}
                            </label>   
                            {/* <label className="block xl:px-8 px-4">
                                <Input type="text" value={destination} onChange={(e) => setDestination(e.target.value)} placeholder="Destination" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid destination</p>)}
                            </label> */}
                             <label className="block xl:px-8 px-4">
                                <Input type="text" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passenger" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                {passangerError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid no of passenger</p>)}
                            </label>
                            <label className="block xl:px-8 px-4">
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                            </label>
                        </div>

                        <div className="xl:px-8 px-4 mt-4">
                            <button type="button"
                                id="submitButton"
                                className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                Submit Enquiry
                            </button>
                        </div>
                        <p className="mt-4 text-xs text-center">
                            Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                        </p>
                        <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                            <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                <div className="lg:w-6/12 text-left flex items-center">
                                    <div className="lg:w-2/12">
                                        <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                    </div>
                                    <div className="lg:w-9/12 pl-2">
                                        <span className="text-sm font-medium ">
                                            Super Offers
                                        </span>
                                        <p className="text-sky-700 italic font-normal text-xs ">
                                            Explore Great Deals
                                        </p>
                                    </div>
                                </div>
                                <div className="lg:w-6/12 text-left flex items-center">
                                    <div className="lg:w-2/12">
                                        <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                                    </div>
                                    <div className="lg:w-10/12 pl-2">
                                        <span className="text-sm font-medium ">
                                            My Trips
                                        </span>
                                        <p className="text-sky-700 font-normal italic text-xs ">
                                            Manage Your Bookings
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    };


    return (
        <div className="nc-ListingStayDetailPage font">
            {/*  HEADER*/} <ul className="breadcrumb  lg:w-4/12">
                <li className="lg:text-base text-xs"><a href="/">Home</a></li>
                <li className="lg:text-base text-xs"><a href="/visa">Visa</a></li>
                <li className="lg:text-base text-xs">Dubai Visa</li>
            </ul>
            <header className="rounded-md sm:rounded-xl lg:pt-5">
                {/* =================Title section Desktop view ============= */}
                <div className="flex flex-col lg:flex-row items-center ">
                    <div className="lg:w-8/12 xl:block lg:block md:hidden hidden">
                        <h2 className="font text-2xl sm:text-3xl lg:text-2xl lg:pb-1 lg:font-semibold ">
                            Dubai Visa Online
                        </h2>
                        <div className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
                            Processing Time : <span className="font-medium text-base">Up to 48 hours</span>
                        </div>
                    </div>
                    <div className="lg:w-2/12 lg:pt-3 justify-center text-left flex flex-row  items-center ">

                    </div>

                    <div className="xl:w-2/12 lg:w-3/12 lg:pt-3 justify-center text-right xl:block lg:block md:hidden hidden">
                        <div className=" xl:text-xs lg:text-sm font-regular text-green-600">
                            Trawelmart Offer Price
                        </div>
                        <div className="uppercase xl:text-xs lg:text-xs font-regular text-black">
                            Starts from
                        </div>
                        <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
                            ₹{totalAmount}
                        </div>
                    </div>
                </div>

                {/* =================Title section Mobile view ============= */}
                <div className="flex flex-col lg:flex-row items-center lg:pb-4 ">
                    <div className="lg:w-8/12 xl:hidden lg:hidden md:block block">
                        <h2 className="font text-2xl sm:text-3xl lg:text-2xl lg:pb-1 lg:font-semibold ">
                            Dubai Visa Online
                        </h2>
                        <div className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">
                            Processing Time : <span className="font-medium text-base">Up to 48 hours</span>
                        </div>
                    </div>
                    <div className="lg:w-2/12 lg:pt-3 justify-center text-left flex flex-row  items-center ">

                    </div>

                    <div className="xl:w-2/12 lg:w-3/12 lg:pt-3 justify-center text-right xl:hidden lg:hidden md:block block">
                        <div className=" xl:text-xs lg:text-sm font-regular text-green-600">
                            Trawelmart Offer Price
                        </div>
                        <div className="uppercase xl:text-xs lg:text-xs font-regular text-black">
                            Starts from
                        </div>
                        <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
                            ₹{totalAmount}
                        </div>
                    </div>
                </div>

                {/* ======== image Tour includes desktop view ======== */}
                <div className="w-full ">
                    <div className="xl:w-12/12 ">
                        <img src="https://i.ibb.co/W3bffJ4/Dubai-visa.jpg" alt="" className="w-12/12 "></img>
                    </div>

                </div>
            </header>

            {/* MAIN */}
            <main className=" relative z-10 mt-0 lg:mt-11 flex flex-col lg:flex-row ">
                <div className="w-full lg:w-3/5 xl:w-2/3 space-y-2 lg:space-y-2 lg:pr-10 lg:pb-12">
                    <div className="font-medium xl:text-xl text-xl xl:pt-8 md:pt-12 pt-12 font xl:pb-0 pb-2">
                        Types of Dubai Visas for Indians
                    </div>
                    <div className="grid xl:grid-cols-2 xl:gap-4 md:grid-cols-2 md:gap-4 lg:grid-cols-1 lg:gap-2 gap-4 xl:pt-4">
                        <div className=" shadow-xl rounded-lg">
                            <div className="bg-[#fff0ff] p-4 rounded-t-lg">
                                <h3 className="font font-medium xl:text-base">
                                    48 Hours Transit Visa + Insurance (Covid)
                                </h3>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 grid-cols-2 gap-2 px-4 pt-3 xl:pt-6 md:pt-4">
                                <div className="font-normal font text-base">
                                    Processing time:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Upto 5 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3  xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Stay period:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    2 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 grid-cols-2 gap-2  md:gap-2 pt-3 px-4 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Validity:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    30 days
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 grid-cols-2 gap-2 md:gap-2 pt-3 px-4 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Entry:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Single
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 grid-cols-2 gap-2 pt-3 px-4 xl:pt-3 md:pt-3 pb-6">
                                <div className="font-normal font text-base">
                                    Fees:
                                </div>
                                <div className="font-semibold text-sky-600 font text-lg text-end">
                                    INR 1,999/-
                                </div>
                            </div>
                        </div>




                        <div className=" shadow-xl rounded-lg">
                            <div className="bg-[#fff0ff] p-4 rounded-t-lg">
                                <h3 className="font font-medium xl:text-base">
                                    30 Days Tourist Visa + Insurance (Covid)
                                </h3>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-6 md:pt-4">
                                <div className="font-normal font text-base">
                                    Processing time:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Upto 5 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Stay period:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    30 days
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Validity:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    58 days
                                </div>
                            </div>

                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3">
                                <div className="font-normal font text-base">
                                    Entry:
                                </div>
                                <div className="font-normal font text-base text-end">
                                    Single
                                </div>
                            </div>
                            <div className="grid xl:grid-cols-2 xl:gap-2 md:grid-cols-2 md:gap-2 px-4 grid-cols-2 gap-2 pt-3 xl:pt-3 md:pt-3 pb-6">
                                <div className="font-normal font text-base">
                                    Fees:
                                </div>
                                <div className="font-semibold text-sky-600 font text-lg text-end">
                                    INR 7,799/-
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="font-medium xl:text-xl text-xl xl:pt-12 md:pt-12 pt-12">
                        Dubai Visa Price includes:
                    </div>
                    <ul className="list-disc pl-6">
                        <li className="py-1">
                            Consulate fees
                        </li>
                        <li className="py-1">
                            Medical insurance (Covers Coronavirus and everything else)
                        </li>
                        <li className="py-1">
                            Service charges
                        </li>
                        <li className="py-1">
                            All taxes
                        </li>
                    </ul>
                    <p className="xl:pt-6 pt-4">
                        Depending on the purpose of your planned visit and duration of trip, you can apply for
                        96 hours, 14 days, 30 days (short term) or 90 days (long term) Dubai Visa (Single & Multiple entry).
                        We are one of the leading Dubai Visa Experts offering Dubai visa for Indians at the lowest prices
                        and with minimum documentation.
                    </p>
                    {/* Included Excluded Ends */}
                    <div className="font-medium xl:text-xl text-xl xl:pt-12 pt-8">
                        Documents required for Dubai Visa for Indians
                    </div>
                    <section id="includ" className="hidden lg:block pt-4 ">
                        {accordionData3.map((item, index) => (
                            <Accordion key={index} title={item.title} content={item.content} />
                        ))}
                    </section>

                    <div className="rounded py-6 px-4 gradient2 xl:mt-4 lg:flex items-center justify-between hidden lg:block ">
                        <div className="text-white font1 font-bold text-2xl">
                            Detailed Dubai Visa process & Requirements
                        </div>
                        <img src={passport} alt="" className="w-14" />
                    </div>

                    <div className="app block lg:hidden pt-4">
                        {accordionData.map((item, index) => (
                            <Accordion key={index} title={item.title} content={item.content} />
                        ))}
                    </div>
                    <div className="rounded py-6 px-4 gradient2 xl:mt-4 flex items-center justify-between block lg:hidden ">
                        <div className="text-white font1 font-bold">
                            Detailed Dubai Visa process & Requirements
                        </div>
                        <img src={passport} alt="" className="w-14" />
                    </div>
                </div>

                {/* SIDEBAR */}
                <div className="block lg:block flex-grow mt-14 lg:mt-0 lg:mb-0 mb-12">
                    <div className="sticky top-28">{renderSidebar()}</div>
                </div>
            </main >
        </div >
    );
};

export default function DubaiVisa() {
    return (
        <DetailPagetLayout>
            <StayDetailsPageContainer />

        </DetailPagetLayout>
    );
}
