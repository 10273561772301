"use client";

import React, { FC, useState } from "react";
import StaySearchForm from "./(stay-search-form)/StaySearchForm";
import ExperiencesSearchForm from "./(experiences-search-form)/ExperiencesSearchForm";
import RentalCarSearchForm from "./(car-search-form)/RentalCarSearchForm";
import FlightSearchForm from "./(flight-search-form)/FlightSearchForm";
import besttour from "images/besttour.png";
import hotel from "images/hotel.png";
import visa from "images/visa.png";
import LocationInput from "components/HeroSearchForm/LocationInput";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StayDatesRangeInput from "components/HeroSearchForm/(stay-search-form)/StayDatesRangeInput";

export type SearchTab = "Tour Packages" | "Flights" | "Hotel" | "Visa";

export interface FilterSearchFormProps {
    className?: string;
    currentTab?: SearchTab;
    currentPage?: "Tour Packages" | "Flights" | "Hotel" | "Visa";
}

const FilterSearchForm: FC<FilterSearchFormProps> = ({
    className = "",
    currentTab = "Tour Packages",
    currentPage,
}) => {
    const tabs: SearchTab[] = ["Tour Packages", "Flights", "Hotel"];
    const [tabActive, setTabActive] = useState<SearchTab>(currentTab);

    const renderTab = () => {
        return (
            <ul className="ml-2 sm:ml-6 md:ml-12 flex space-x-5 sm:space-x-8 lg:space-x-4 overflow-x-auto hiddenScrollbar">
                {tabs.map((tab) => {
                    const active = tab === tabActive;
                    return (
                        <li
                            onClick={() => setTabActive(tab)}
                            className={` flex-shrink-0 flex items-center cursor-pointer text-sm lg:text-base font-medium ${active
                                ? ""
                                : "text-neutral-500 hover:text-neutral-700 dark:hover:text-neutral-400 "
                                } `}

                            key={tab}
                        >
                            {active && (
                                <span className="block w-2.5 h-2.5 rounded bg-neutral-800 dark:bg-neutral-100 mr-2" />
                            )}
                            <span>{tab}</span>
                        </li>
                    );
                })}
            </ul>
        );
    };

    const renderForm = () => {
        switch (tabActive) {
            case "Tour Packages":
                return <StaySearchForm />;
            case "Hotel":
                return <ExperiencesSearchForm />;
            case "Flights":
                return <RentalCarSearchForm />;
            case "Visa":
                return <FlightSearchForm />;

            default:
                return null;
        }
    };

    return (
        <div
            className={`bg-font nc-HeroSearchForm w-full max-w-7xl py-5 lg:py-0 ${className}`}
        >
            {/*renderTab()*/}
            <form className="w-full relative lg:flex md:flex mt-0 ">
                <div className="xl:w-4/12 md:w-3/12 h-20 border border-neutral-300 rounded-xl mr-2">
                    <LocationInput className="lg:-mt-2 mx-2" />
                </div>
                <div className="self-center  h-8"></div>
                <div className="xl:w-4/12 md:w-4/12 h-20 border border-neutral-300 rounded-xl mr-2 xl:-mt-0 lg:-mt-0 md:-mt-0 -mt-6">
                    <StayDatesRangeInput className="lg:-mt-2 mx-1" />
                </div>
                <div className="self-center  h-8"></div>
                <div className="xl:w-4/12 md:w-5/12 h-20 border border-neutral-300 rounded-xl xl:-mt-0 lg:-mt-0 md:-mt-0 -mt-6">
                    <GuestsInput className="flex-1 lg:-mt-2 mx-2" />
                </div>
            </form>
        </div>
    );
};

export default FilterSearchForm;
