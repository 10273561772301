import { DEMO_POSTS_THAILAND } from "data/postss";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const BlogThailand = () => {
    const renderHeader = () => {
        return (
            <header className="font container rounded-xl">
                <div className="max-w-screen-md mx-auto space-y-5">
                    <Badge href="##" color="purple" name="Thailand" />
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Experiences tourist often miss in Thailand
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        “Travel is not about the miles we cover, but the moments we discover. Don’t
                        miss a single unique experience at your destination, for it is these moments that
                        the world truly comes alive!”
                    </span>

                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div
                id="single-entry-content"
                className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
            >

                <li>
                    Thailand is a land of diverse landscapes, rich culture, and incredible experiences waiting to
                    be explored. While many tourists flock to popular destinations like Bangkok, Phuket, and
                    Chiang Mai, there are numerous hidden gems and unique experiences that often go
                    unnoticed. In this essay, we will delve into some of these lesser-known but equally
                    captivating aspects of Thailand that tourists often miss out on.
                </li>

                <li>
                    One of the first experiences that tourists might overlook is the opportunity to immerse
                    themselves in the local way of life in Thailand&#39;s rural villages. While the bustling cities are
                    undoubtedly enticing, the true essence of Thai culture can be found in its countryside.
                </li>

                <li>
                    Visiting villages like Ban Chiang in northeastern Thailand allows travellers to witness
                    traditional Thai customs, such as rice farming, silk weaving, and pottery making. Staying in a
                    homestay in one of these villages can provide a deeper understanding of the Thai way of life,
                    as well as a chance to Savor authentic, homemade Thai cuisine.
                </li>

                <li>
                    Another hidden gem is the serene beauty of Thailand&#39;s lesser-known islands. While Phuket
                    and Koh Samui are famous for their stunning beaches, there are smaller, less crowded islands
                    that offer equally breathtaking landscapes. Koh Tao, for example, is a haven for divers and
                    snorkelers, boasting crystal-clear waters and vibrant coral reefs. On Koh Chang, visitors can
                    explore lush jungles, hike to waterfalls, and relax on secluded beaches. These lesser-known
                    islands provide a more tranquil and authentic island experience compared to the crowded
                    tourist hubs.
                </li>

                <li>
                    Thailand is not just about its stunning natural beauty; it also boasts a rich spiritual heritage.
                    Tourists often flock to Wat Pho in Bangkok to see the famous reclining Buddha, but there are
                    countless other temples across the country that offer unique and awe-inspiring experiences.
                    Wat Rong Khun, also known as the White Temple, in Chiang Rai is a masterpiece of
                    contemporary Thai artistry. Its intricate white facade is adorned with fragments of mirrors
                    that glisten in the sun, creating a surreal and otherworldly atmosphere. Exploring less-visited
                    temples like Wat Rong Khun can provide a deeper appreciation for Thailand&#39;s spiritual
                    traditions.
                </li>

                <li>
                    For those interested in history and archaeology, Thailand has much to offer beyond the well-
                    known historical sites. The ancient city of Sukhothai, a UNESCO World Heritage Site,
                    predates Bangkok as the capital of Thailand and is home to remarkable ruins, serene lotus
                    ponds, and stunning Buddha statues. Similarly, the historical park of Ayutthaya showcases the
                    grandeur of a bygone era with its ancient temples and towering pagodas. Exploring these
                    lesser-visited historical sites allows travellers to step back in time and appreciate Thailand&#39;s
                    rich heritage.
                </li>

                <li>
                    Thailand is renowned for its delicious street food, but many tourists stick to the familiar
                    dishes like pad thai and green curry. Venturing into local markets and street stalls can lead to
                    culinary discoveries that are often missed. Exploring the markets of Isaan in northeastern
                    Thailand, for example, introduces visitors to unique dishes like Som tam (spicy papaya salad)

                    and laab (spicy minced meat salad). These regional delicacies offer a taste of the diverse
                    Flavors that Thai cuisine has to offer.
                </li>

                <li>
                    Nature enthusiasts will find plenty to discover in Thailand&#39;s national parks beyond the famous
                    Khao Sok and Erawan. Khao Yai National Park, located just a few hours from Bangkok, is a
                    haven for wildlife enthusiasts, with the chance to spot elephants, gibbons, and a variety of
                    bird species. Doi Inthanon National Park in northern Thailand boasts the country&#39;s highest
                    peak and offers opportunities for trekking, birdwatching, and exploring picturesque
                    waterfalls. These lesser-visited national parks provide a chance to connect with nature and
                    experience the beauty of Thailand&#39;s diverse ecosystems.
                </li>



                <li>
                    While Thailand is known for its vibrant festivals like Songkran and Loy Krathong, there are
                    smaller, regional festivals that offer unique cultural experiences. The Bun Bang Fai Rocket
                    Festival in northeastern Thailand, for instance, is a colorful and explosive event where locals
                    launch homemade rockets into the sky to encourage rain during the dry season. Attending
                    such festivals allows tourists to witness the deep-rooted traditions and vibrant celebrations
                    that are an integral part of Thai culture.
                </li>

                <li>
                    Thailand&#39;s art and crafts scene often goes unnoticed by tourists, who may focus on shopping
                    for souvenirs in crowded markets. Exploring local art galleries and handicraft villages can
                    lead to the discovery of exquisite Thai craftsmanship. The town of Bo Sang, near Chiang
                    Mai, is famous for its umbrella-making artisans who create intricate, hand-painted parasols.
                    Additionally, the town of Pak Kret, just north of Bangkok, is known for its pottery and
                    ceramics. Supporting local artists and artisans by purchasing their creations not only provides
                    a unique memento of your trip but also contributes to the preservation of these traditional
                    crafts.
                </li>

                <p>
                    In conclusion, Thailand is a country that offers much more than meets the eye. While the
                    popular tourist destinations have their allure, there are countless hidden experiences and
                    treasures waiting to be discovered. From immersing oneself in rural village life to exploring
                    lesser-known temples, islands, and national parks, Thailand&#39;s diversity and cultural richness
                    are boundless. By venturing off the beaten path and embracing these lesser-known aspects of
                    Thailand, travellers can create unforgettable memories and gain a deeper appreciation for this
                    remarkable country.
                </p>

            </div>
        );
    };

    const renderTags = () => {
        return (
            <div className="max-w-screen-md mx-auto flex flex-wrap">
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Garden
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Jewelry
                </a>
                <a
                    className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
                    href="##"
                >
                    Tools
                </a>
            </div>
        );
    };

    const renderAuthor = () => {
        return (
            <div className="max-w-screen-md mx-auto ">
                <div className="nc-SingleAuthor flex">
                    <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
                    <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
                        <span className="text-xs text-neutral-400 uppercase tracking-wider">
                            WRITEN BY
                        </span>
                        <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
                            <a href="/">Fones Mimi</a>
                        </h2>
                        <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
                            There’s no stopping the tech giant. Apple now opens its 100th
                            store in China.There’s no stopping the tech giant.
                            <a className="text-primary-6000 font-medium ml-1" href="/">
                                Readmore
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        );
    };

    const renderCommentForm = () => {
        return (
            <div className="max-w-screen-md mx-auto pt-5">
                <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
                    Responses (14)
                </h3>
                <form className="nc-SingleCommentForm mt-5">
                    <Textarea />
                    <div className="mt-2 space-x-3">
                        <ButtonPrimary>Submit</ButtonPrimary>
                        <ButtonSecondary>Cancel</ButtonSecondary>
                    </div>
                </form>
            </div>
        );
    };

    const renderCommentLists = () => {
        return (
            <div className="max-w-screen-md mx-auto">
                <ul className="nc-SingleCommentLists space-y-5">
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                    <li>
                        <Comment />
                        <ul className="pl-4 mt-5 space-y-5 md:pl-11">
                            <li>
                                <Comment isSmall />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        );
    };

    const renderPostRelated = (post: PostDataType) => {
        return (
            <div
                key={post.id}
                className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
            >
                <Link to={post.href} />
                <NcImage
                    className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
                    src={post.featuredImage}
                />
                <div>
                    <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
                </div>
                <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
                    <Badge name="Categories" />
                    <h2 className="block text-lg font-semibold text-white ">
                        <span className="line-clamp-2">{post.title}</span>
                    </h2>

                    <div className="flex">
                        <span className="block text-neutral-200 hover:text-white font-medium truncate">
                            {post.author.displayName}
                        </span>
                        <span className="mx-1.5 font-medium">·</span>
                        <span className="font-normal truncate">{post.date}</span>
                    </div>
                </div>
                <Link to={post.href} />
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Trawel Mart</title>
            </Helmet>
            {renderHeader()}
            <NcImage
                className="w-full rounded-xl"
                containerClassName="container my-10 sm:my-12 "
                src="https://i.ibb.co/wdKLkn8/thailandblog.png"
            />

            <div className="nc-SingleContent container space-y-10 mb-24">
                {renderContent()}
                {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
            </div>
            {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
                <div className="container ">
                    <h2 className="text-3xl font-semibold">Related posts</h2>
                    <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
                        {/*  
                        {DEMO_POSTS_THAILAND.filter((_, i) => i < 1).map(renderPostRelated)}
                        {/*  
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default BlogThailand;
