import { Popover, Transition } from "@headlessui/react";
import {
  UserCircleIcon,
  ChatBubbleBottomCenterTextIcon,
  HeartIcon,
  HomeIcon,
  ArrowRightOnRectangleIcon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";

const solutions = [
  {
    name: "Account",
    href: "/author",
    icon: UserCircleIcon,
  }
];
// const solutions = [
//   {
//     name: "Account",
//     href: "/author",
//     icon: UserCircleIcon,
//   },
//   {
//     name: "Messages",
//     href: "##",
//     icon: ChatBubbleBottomCenterTextIcon,
//   },
//   {
//     name: "Wishlists",
//     href: "/",
//     icon: HeartIcon,
//   },
//   {
//     name: "Booking",
//     href: "##",
//     icon: HomeIcon,
//   },
// ];

const solutionsFoot = [
  {
    name: "Logout",
    href: "#!",
    icon: ArrowRightOnRectangleIcon,
  },
];
// const solutionsFoot = [
//   {
//     name: "Help",
//     href: "##",
//     icon: LifebuoyIcon,
//   },

//   {
//     name: "Logout",
//     href: "##",
//     icon: ArrowRightOnRectangleIcon,
//   },
// ];

export default function AvatarDropdown() {

  const [userName, setUserName] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const userDataJSON = localStorage.getItem('loggedInUser');
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setUserName(userData.name);
    }

    const isLoggedInString = localStorage.getItem("isLoggedIn");
    setIsLoggedIn(isLoggedInString ? JSON.parse(isLoggedInString) : false);
  }, []);

  return (
    <div className="AvatarDropdown">
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button
              className={`inline-flex items-center focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <Avatar sizeClass="w-8 h-8 sm:w-9 sm:h-9" userName={isLoggedIn ? userName : ''} />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute z-10 w-screen max-w-[230px] px-4 mt-4 xl:-right-10 lg:-right-10 md:-right-10 -right-4 sm:right-0 sm:px-0">
                <div className="overflow-hidden rounded-xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 pt-3 pb-2 text-center">
                    {isLoggedIn ?
                      solutions.map((item, index) => (
                        <Link
                          key={index}
                          to={item.href}
                          className="flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                        >
                          <div className="pl-4 py-1 flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                            <item.icon aria-hidden="true" className="w-6 h-6" />
                          </div>
                          <div className="ml-4 ">
                            <p className="text-sm font-medium ">{item.name}</p>
                          </div>
                        </Link>
                      ))
                      :
                      <h6>Welcome, Guest!</h6>}
                  </div>
                  <hr className="h-[1px] border-t border-neutral-300 dark:border-neutral-700" />
                  <div className="relative grid gap-6 bg-white dark:bg-neutral-800 pt-2 pb-4 text-center">
                    {isLoggedIn ? (
                      solutionsFoot.map((item, index) => {
                        const handleClick = () => {
                          if (index === 0) {
                            // Remove the "isLoggedIn" key from localStorage
                            localStorage.setItem("isLoggedIn", JSON.stringify(false));
                            localStorage.removeItem("loggedInUser");
                            (window as any).location = "/";
                          }
                        };

                        if (index === 0 && !isLoggedIn) {
                          // Skip rendering the anchor tag for index 1 if isLoggedIn is false
                          return null;
                        }

                        return (
                          <a
                            key={index}
                            href={index === 0 ? undefined : item.href}
                            onClick={handleClick}
                            className="cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                          >
                            <div className="pl-4 py-1 flex items-center justify-center flex-shrink-0 text-neutral-500 dark:text-neutral-300">
                              <item.icon aria-hidden="true" className="w-6 h-6" />
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium ">{item.name}</p>
                            </div>
                          </a>
                        );
                      })
                    ) : (
                      <p>Please Signup / Login</p>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
