import React, { FC, Fragment, useState, useEffect } from "react";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import StartRating1 from "components/StartRating/StartRating1";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import LikeSaveBtns from "components/LikeSaveBtns";
import SectionDateRange from "containers/ListingDetailPage/SectionDateRange";
import StayDatesRangeInput from "components/HeroSearchForm2Mobile/DatesRangeInput";
import LocationInput from "components/HeroSearchForm/LocationInput";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Amenities_demos,
  PHOTOS71,
} from "containers/ListingDetailPage/listing-stay-detail/constant";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonCircle from "shared/Button/ButtonCircle";
import Input from "shared/Input/Input";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Button from "shared/Button/Button";
import DetailPagetLayout from "containers/ListingDetailPage/Layout";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
// import ResortCard3, { ResortCard3Props } from "components/FlightCard/Maldives/ResortCard3";
// import ResortCard4, { ResortCard4Props } from "components/FlightCard/Maldives/ResortCard4";
import hotel from "images/ICONS/hotel.png";
import { Tab } from "@headlessui/react";
import $ from "jquery";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import presents from "images/ICONS/presents.png";
import luggage from "images/ICONS/luggage.png";
import coin from "images/ICONS/coin.png";
import india from "images/india.png";
import offersss from "images/offersss.gif";
import emirates from "images/ICONS/emirates.png";
import airasia from "images/ICONS/airasia.png";
import indigo from "images/ICONS/indigo.png";
import { HashLink } from "react-router-hash-link";
import Accordion from "containers/ListingDetailPage/listing-stay-detail/Accordion";
import axios from "axios";
import PagePopup from "containers/PageHome/PagePopup";
import ResortCard1, {
  ResortCard1Props,
} from "components/FlightCard/Resort/ResortCard1";
import ResortCard2, {
  ResortCard2Props,
} from "components/FlightCard/Resort/ResortCard2";
import ResortCard3, {
  ResortCard3Props,
} from "components/FlightCard/Resort/ResortCard3";
import ResortCard4, {
  ResortCard4Props,
} from "components/FlightCard/Resort/ResortCard4";

import PropertyCardMaldives from "components/PropertyCardH/PropertyCardMaldives";
import { DEMO_STAY_LISTINGS_VILLAS } from "data/listings";
import { StayDataType } from "data/types";

export interface StayDetailsPageContainerProps {
  className?: string;
  data?: StayDataType[];
  defaultOpen?: boolean;
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS_VILLAS.filter((_, i) => i < 4);

const StayDetailsPageContainer: FC<StayDetailsPageContainerProps> = ({
  className = "",
  data = DEMO_DATA,
  defaultOpen = true,
}) => {


  let [inOpen, setInOpen] = useState(false);
  function incloseModal() {
    setInOpen(false);
  }

  function inopenModal() {
    setInOpen(true);
  }
  //
  {
    /* wizard script starts */
  }

  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 3;
  const [showEnquiryPopup, setShowEnquiryPopup] = useState(false);

  const handleNextButtonClick = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  };

  const handlePrevButtonClick = () => {
    if (currentStep > 1) {
      setCurrentStep((prevStep) => prevStep - 1);
    }
  };

  const handleEnquiryButtonClick = () => {
    setShowEnquiryPopup(true);
  };

  const handleEditIconClick = () => {
    setCurrentStep(1);
    setShowEnquiryPopup(false); // Show the popup again after going back to the first step
  };

  {
    /* wizard script Ends */
  }

  const accordionData2 = [
    {
      title: " Dining Experiences",
      content: [
        <ul key="list">
          <h1 className="font-semibold text-base font">
            Element X (All Day Dining)
          </h1>
          <p className="pt-3 pb-3 text-base">
            Opening Hours: Breakfast: 07:00 – 10:00 | Lunch: 12:00 – 15:00 | Dinner: 19:00 – 22:00
          </p>
          <li className="text-sm font py-2">
            Fresh, contemporary décor with comfortable indoor and outdoor seating.
          </li>
          <li className="text-sm font py-2">
            Delightfully flavor some feasts featuring modern West, Central Asia, and the Far East cuisine.
          </li>
          <li className="text-sm font py-2">
            A show cooking kitchen laid out in five sections: Western, Eastern, Central, Cold Starters and Pastry, and Bakery.
          </li>
          <li className="text-sm font py-2">
            Fresh, contemporary décor with comfortable indoor and outdoor seating.
          </li>
        </ul>,
      ],
    },

    {
      title: " Bevarages",
      content: [
        <ul key="list">

          <li className="text-sm font py-2">
            Unlimited orders of regular spirits, beers, and house pour wines from the grab and go counter at X360 Bar.
          </li>
          <li className="text-sm font py-2">
            One signature cocktail at X360 Bar.
          </li>
          <li className="text-sm font py-2">
            Unlimited house pour wines and beers are available in the self-assisted sections at Element X.
          </li>
          <li className="text-sm font py-2">
            Selected wine pairings at Copper Pot Food Truck.
          </li>
        </ul>,
      ],
    },

    {
      title: " Kids Club",
      content: [
        <ul key="list">

          <li className="text-sm font py-2">
            One of the largest kids’ clubs in the Maldives with top-level for older kids and teenagers with a game arcade and a lower deck for younger kids.
          </li>
          <li className="text-sm font py-2">
            A separate food corner for kids inside the kids’ club. Young guests can enjoy a buffet spread of comfort finger food, pasta, pizzas, and cakes to munch on, while at the club.
          </li>
          <li className="text-sm font py-2">
            Kids can enjoy arcade games at an additional cost.
          </li>

        </ul>,
      ],
    },
  ];


  const accordionData = [
    {
      title: "About This Tour",
      content: [
        <ul key="list">
          <li className="text-xs font py-2">
            Explore the world of Maldives Parks and Resorts which offers every
            experience people would want in a holiday, as it transports you into
            a world all for you!
          </li>
          <li className="text-xs font py-2">
            The moonlight… Maldives Creek… The traditionally decorated wooden
            Dhow… A romantic evening… with a buffet dinner and traditional
            Arabic background music… This magical mystery ride awaits, setting
            the tone for your holiday!
          </li>
          <li className="text-xs font py-2">
            A city tour of Maldives awaits you the next day, to kickstart your
            holiday – the beautiful Clubhouse building, Jumeirah Mosque,
            Jumeirah Open Beach, Burj Al Arab, Burj Khalifa Maldives, Palm
            Islands.
          </li>
          <li className="text-xs font py-2">
            Miracle Garden and the Maldives Frame are awaiting to mesmerise you
            the next day!
          </li>
          <li className="text-xs font py-2">
            Continue your vacay mode with a visit to the Palm Atlantis with a
            lazy river, water coaster, nine-storey nearly vertical slide into a
            shark-filled lagoon.
          </li>
        </ul>,
      ],
    },
    {
      title: "Need To Know",
      content: [
        <ul key="list" className="pl-2">
          <li className="text-xs font py-1">
            <h2 className="text-sm font-semibold pb-2">Find Out</h2>
            <p className="pb-2">
              To and fro Air fare, Airport transfers, visa fees to join/leave
              the group for joining and leaving guests
            </p>
            <p className="">
              Anything specifically not mentioned in the ‘tour price includes’
              column
            </p>
          </li>
          <li className="text-xs font py-1">
            <h2 className="text-sm font-semibold pb-1">Transport</h2>
            <h4 className="text-xs font-medium pb-2">Air Travel :</h4>
            <p className="pb-2">
              Bangalore - Maldives // Maldives - Bangalore.
            </p>
            <p className="">
              We use combination of Airlines like Kuwait Airways/Oman Air/Qatar
              Airways/Ethihad/Emirates etc.
            </p>
            <h4 className="text-xs font-medium py-2">Coach Travel :</h4>
            <p className="pb-2">
              A/C coach - Seating capacity depends upon group size
            </p>
          </li>
          <li className="text-xs font py-1">
            <h2 className="text-sm font-semibold pb-2">
              Documents Required for Travel
            </h2>
            <p className="pb-2">
              Original passport with minimum 6 months validity from the date of
              tour arrival along with sufficient blank pages for the stamping of
              visa
            </p>
            <p className="">
              A valid Tourist Visa for the duration of the tour
            </p>
            <p className="">Handwritten passport is not acceptable</p>
          </li>
        </ul>,
        /*<table key="table" className="table">
                    <thead>
                        <tr>
                            <th>Header 1</th>
                            <th>Header 2</th>
                            <th>Header 3</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Data 1</td>
                            <td>Data 2</td>
                            <td>Data 3</td>
                        </tr>
                        <tr>
                            <td>Data 4</td>
                            <td>Data 5</td>
                            <td>Data 6</td>
                        </tr>
                    </tbody>
            </table>,*/
      ],
    },
    {
      title: "Tour Preparation",
      content: [
        <ul key="list">
          <p className="text-xs text-justify font-regular pt-4">
            Preparing for an international tour involves several important
            steps. As everyone wishes to invest in themselves and explore the
            world, we have a few helpful tips to ensure a stress-free holiday.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Passport and Visa:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Ensure your passport is valid for at least six months beyond your
            return date.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Obtain the necessary visas for your destination(s).
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Travel Itinerary:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            carry a copy of your itinerary, with all the bookings and
            reservations made in advance.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Share your itinerary with a trusted person and check in regularly.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Health Preparations:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Consult your doctor for required vaccinations or medications.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Carry a copy of your medical records and any necessary
            prescriptions.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Travel Insurance:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Purchase comprehensive travel insurance to cover unexpected events.
          </p>

          <p className="text-sm font-medium text-base pt-2">
            Money and Finances:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Notify your bank of your travel plans to avoid issues with your
            cards.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Carry some local currency and a credit/debit card for emergencies.
          </p>
          <p className="text-sm font-medium text-base pt-2">Packing:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Always make sure to carry less and light luggage, use a four-wheel
            small or medium suitcase and please make sure that you are carrying
            only one suitcase per person. This will ensure that you will travel
            smartly and without any inconveniences.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Create a packing list to ensure you don&#39;t forget essentials.
            Pack weather-appropriate clothing and accessories. If you are
            travelling during the monsoon or to destinations where it is rainy,
            please carry a small umbrella or poncho
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Don&#39;t forget chargers, adapters, and a travel-sized first-aid
            kit. A smart cap/hat and sunglasses are definitely something that
            you should have in your kit.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Important Documents:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Make photocopies of your passport, visa, and travel insurance.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Store digital copies of these documents securely.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Safety and Security:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Research the safety situation at your destination.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Language and Culture:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Learn basic phrases in the local language.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Familiarize yourself with local customs and etiquette.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Ensure that your clothing and footwear is suitable for the
            destination you are travelling to.
          </p>
          <p className="text-sm font-medium text-base pt-2">Electronics:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Bring necessary gadgets (phone, camera, etc.) and chargers.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Check voltage requirements and bring appropriate adapters.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            For international tours, please carry a universal adapter for
            charging your cameras and mobile phones.
          </p>
          <p className="text-sm font-medium text-base pt-2">Entertainment:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Pack books, music, or other forms of entertainment for long flights
            or downtime.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Health and Wellness:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Carry any essential medications and a small first-aid kit.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Stay hydrated and maintain a balanced diet while traveling.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Travel Accessories:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Consider items like a neck pillow, eye mask, and earplugs for
            comfort during long flights.
          </p>
          <p className="text-sm font-medium text-base pt-2">Transportation:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Arrange transportation from the airport to your accommodation in
            advance.
          </p>
          <p className="text-sm font-medium text-base pt-2">
            Local Information:
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Research local transportation options and maps.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Know emergency numbers and embassy contact information.
          </p>
          <p className="text-sm font-medium text-base pt-2">Packing Light:</p>
          <p className="text-xs text-justify font-regular pt-1">
            It is also recommended that you have one cross shoulder bag or
            haversack for your handy needs which will ensure that you are
            comfortable at the time of sightseeing and also easy for photography
            during your tour.
          </p>
          <p className="text-xs text-justify font-regular pt-1">
            Try to minimize the amount of luggage you bring to make travel
            easier.
          </p>
          <p className="text-sm font-medium text-base pt-2">Travel Apps:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Download useful travel apps, such as maps, translation, and currency
            conversion.
          </p>
          <p className="text-sm font-medium text-base pt-2">Safety Gear:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Depending on your destination, consider items like a money belt or
            RFID- blocking wallet.
          </p>
          <p className="text-sm font-medium text-base pt-2">Communication:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Buy a local SIM card or consider international roaming options for
            your phone.
          </p>
          <p className="text-sm font-medium text-base pt-2">Enjoy:</p>
          <p className="text-xs text-justify font-regular pt-1">
            Finally, relax and enjoy your trip, but stay vigilant and adapt to
            the local culture.
          </p>
          <p className="text-xs text-justify font-regular pt-2">
            Remember that this checklist can vary depending on your destination
            and personal preferences, so adjust it accordingly. Safe travels!
          </p>
        </ul>,
      ],
    },

    {
      title: "Cancellation Policy",
      content: [
        <table key="table" className="table ">
          <table className="mt-2" style={{ width: "710px" }}>
            <thead>
              <tr>
                <th
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Number of days before departure, TRAWEL MART WORLD TOURS
                  receives your cancellations
                </th>
                <th
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  Amount of cancellations charge shown as a % of a total holiday
                  price you must pay
                </th>
                <th
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "500",
                    textAlign: "left",
                  }}
                >
                  If we cancel your holiday, amount you will receive from us
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  More than 45 days
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Deposit Only
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Advance Received
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  44 - 30 Days
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  40% of total holiday cost
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Amount Received
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  29 - 15 days
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  60% of total holiday cost
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Amount Received
                </td>
              </tr>
              <tr>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  15 days or less
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  90% of total holiday cost
                </td>
                <td
                  style={{
                    border: "1px solid #949494",
                    padding: "8px",
                    fontWeight: "400",
                    fontSize: "12px",
                  }}
                >
                  Full Amount Received plus 10% of the amount
                </td>
              </tr>
              {/* Add more rows as needed */}
            </tbody>
          </table>
        </table>,
      ],
    },
  ];

  const accordionData1 = [
    {
      title: "Transport Details",
      content: [
        <ul key="list">
          <li className="text-xs font py-2">Making the finest for you!.</li>
          <li className="text-xs font py-2">
            We are trying to reserve the airplane tickets for this trip. We will
            update it here, as we are done.
          </li>
          <li className="text-xs font py-2">
            Post Booking, you will be notified through your contact details
            about all tour related updates.
          </li>
        </ul>,
      ],
    },

    {
      title: "Accomodation Details",
      content: [
        <ul key="list">
          <li className="text-xs font py-2">
            <div className="grid grid-cols-3 gap-6 md:gap-2 lg:grid-cols-3 bg-[#fbf7fb] p-2 border-b border-dashed">
              <div className="font-medium text-xs">City - Country</div>
              <div className="font-medium text-xs">Hotel</div>
              <div className="font-medium text-xs">Check In - Check Out</div>
            </div>
            <div className="grid grid-cols-3 gap-6 md:gap-2 lg:grid-cols-3 bg-white p-2 border-b border-dashed">
              <div className="font-regular text-xs">
                Maldives - United Arab Emirates
              </div>
              <div className="font-regular text-xs">-</div>
              <div className="font-regular text-xs">-</div>
            </div>
            <div className="grid grid-cols-3 gap-6 md:gap-2 lg:grid-cols-3 bg-neutral-50 p-2 border-b border-dashed">
              <div className="font-regular text-xs">
                Abu Dhabi - United Arab Emirates
              </div>
              <div className="font-regular text-xs">-</div>
              <div className="font-regular text-xs">-</div>
            </div>
          </li>
        </ul>,
      ],
    },
  ];

  

  const accordionData3 = [
    {
      title: "About This tour",
      content: [
        <ul className="pl-2">
          <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
            <ul className="list-disc p-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Explore the world of Maldives Parks and Resorts which offers
                every experience people would want in a holiday, as it
                transports you into a world all for you!
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                The moonlight… Maldives Creek… The traditionally decorated
                wooden Dhow… A romantic evening… with a buffet dinner and
                traditional Arabic background music… This magical mystery ride
                awaits, setting the tone for your holiday!
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                A city tour of Maldives awaits you the next day, to kickstart
                your holiday – the beautiful Clubhouse building, Jumeirah
                Mosque, Jumeirah Open Beach, Burj Al Arab, Burj Khalifa
                Maldives, Palm Islands.
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Our packages stand apart, and this next feature will prove it!
                Hop onto the Desert Safari for a thrilling experience of
                Maldives and end it with a beautiful sunset is sure that will
                steal your hearts. A delicious barbecue dinner along with a
                belly dance to end the day the Maldives way.
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Continue your vacay mode with a visit to the Palm Atlantis with
                a lazy river, water coaster, nine-storey nearly vertical slide
                into a shark-filled lagoon.
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Then head over to the Lost Chambers and see 65,000 marine
                animals so closely and rediscover Atlantis
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Miracle Garden and the Maldives Frame are awaiting to mesmerise
                you the next day!
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Embrace yourself for a thrilling and racy climax by visiting Abu
                Dhabi Yas Island, which is a popular leisure island in Abu
                Dhabi. The Ferrari ride in Ferrari World Abu Dhabi at Yas Marina
                Circuit will get your adrenaline pumping in this Maldives
                Package with Yas Island!
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },
    {
      title: "Need To Know",
      content: [
        <ul key="list" className="pl-2">
          <div className="mt-4 grid grid-cols-1 gap-0 md:gap-2 sm:grid-cols-1">
            <div className="text-xl font-bold ">
              <span>Find Out</span>
            </div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                To and fro Air fare, Airport transfers, visa fees to join/leave
                the group for joining and leaving guests
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Anything specifically not mentioned in the ‘tour price includes’
                column
              </li>
            </ul>
            <div className="text-lg font-bold ">
              <span>Transport</span>
            </div>
            <div className="text-base font-semibold ">
              <span>Air Travel :</span>
            </div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Bangalore - Maldives / Maldives - Bangalore.
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We use combination of Airlines like Kuwait Airways/Oman
                Air/Qatar Airways/Ethihad/Emirates etc.
              </li>
            </ul>
            <div className="text-base font-semibold ">
              <span>Coach Tavel :</span>
            </div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                A/C coach - Seating capacity depends upon group size
              </li>
            </ul>
            <div className="text-lg font-bold ">
              <span>Documents Required for Travel</span>
            </div>
            <ul className="list-disc px-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Original passport with minimum 6 months validity from the date
                of tour arrival along with sufficient blank pages for the
                stamping of visa
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                A valid Tourist Visa for the duration of the tour
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Handwritten passport is not acceptable
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },
    {
      title: "Tour Preparation",
      content: [
        <ul key="list">
          <div className="-mt-6 p-4">
            <p className="text-base text-justify font-regular pt-4">
              Preparing for an international tour involves several important
              steps. As everyone wishes to invest in themselves and explore the
              world, we have a few helpful tips to ensure a stress-free holiday.
            </p>
            <p className="font-medium text-base pt-2">Passport and Visa:</p>
            <p className="text-base text-justify font-regular pt-1">
              Ensure your passport is valid for at least six months beyond your
              return date.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Obtain the necessary visas for your destination(s).
            </p>
            <p className="font-medium text-base pt-2">Travel Itinerary:</p>
            <p className="text-base text-justify font-regular pt-1">
              carry a copy of your itinerary, with all the bookings and
              reservations made in advance.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Share your itinerary with a trusted person and check in regularly.
            </p>
            <p className="font-medium text-base pt-2">Health Preparations:</p>
            <p className="text-base text-justify font-regular pt-1">
              Consult your doctor for required vaccinations or medications.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Carry a copy of your medical records and any necessary
              prescriptions.
            </p>
            <p className="font-medium text-base pt-2">Travel Insurance:</p>
            <p className="text-base text-justify font-regular pt-1">
              Purchase comprehensive travel insurance to cover unexpected
              events.
            </p>

            <p className="font-medium text-base pt-2">Money and Finances:</p>
            <p className="text-base text-justify font-regular pt-1">
              Notify your bank of your travel plans to avoid issues with your
              cards.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Carry some local currency and a credit/debit card for emergencies.
            </p>
            <p className="font-medium text-base pt-2">Packing:</p>
            <p className="text-base text-justify font-regular pt-1">
              Always make sure to carry less and light luggage, use a four-wheel
              small or medium suitcase and please make sure that you are
              carrying only one suitcase per person. This will ensure that you
              will travel smartly and without any inconveniences.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Create a packing list to ensure you don&#39;t forget essentials.
              Pack weather-appropriate clothing and accessories. If you are
              travelling during the monsoon or to destinations where it is
              rainy, please carry a small umbrella or poncho
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Don&#39;t forget chargers, adapters, and a travel-sized first-aid
              kit. A smart cap/hat and sunglasses are definitely something that
              you should have in your kit.
            </p>
            <p className="font-medium text-base pt-2">Important Documents:</p>
            <p className="text-base text-justify font-regular pt-1">
              Make photocopies of your passport, visa, and travel insurance.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Store digital copies of these documents securely.
            </p>
            <p className="font-medium text-base pt-2">Safety and Security:</p>
            <p className="text-base text-justify font-regular pt-1">
              Research the safety situation at your destination.
            </p>
            <p className="font-medium text-base pt-2">Language and Culture:</p>
            <p className="text-base text-justify font-regular pt-1">
              Learn basic phrases in the local language.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Familiarize yourself with local customs and etiquette.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Ensure that your clothing and footwear is suitable for the
              destination you are travelling to.
            </p>
            <p className="font-medium text-base pt-2">Electronics:</p>
            <p className="text-base text-justify font-regular pt-1">
              Bring necessary gadgets (phone, camera, etc.) and chargers.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Check voltage requirements and bring appropriate adapters.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              For international tours, please carry a universal adapter for
              charging your cameras and mobile phones.
            </p>
            <p className="font-medium text-base pt-2">Entertainment:</p>
            <p className="text-base text-justify font-regular pt-1">
              Pack books, music, or other forms of entertainment for long
              flights or downtime.
            </p>
            <p className="font-medium text-base pt-2">Health and Wellness:</p>
            <p className="text-base text-justify font-regular pt-1">
              Carry any essential medications and a small first-aid kit.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Stay hydrated and maintain a balanced diet while traveling.
            </p>
            <p className="font-medium text-base pt-2">Travel Accessories:</p>
            <p className="text-base text-justify font-regular pt-1">
              Consider items like a neck pillow, eye mask, and earplugs for
              comfort during long flights.
            </p>
            <p className="font-medium text-base pt-2">Transportation:</p>
            <p className="text-base text-justify font-regular pt-1">
              Arrange transportation from the airport to your accommodation in
              advance.
            </p>
            <p className="font-medium text-base pt-2">Local Information:</p>
            <p className="text-base text-justify font-regular pt-1">
              Research local transportation options and maps.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Know emergency numbers and embassy contact information.
            </p>
            <p className="font-medium text-base pt-2">Packing Light:</p>
            <p className="text-base text-justify font-regular pt-1">
              It is also recommended that you have one cross shoulder bag or
              haversack for your handy needs which will ensure that you are
              comfortable at the time of sightseeing and also easy for
              photography during your tour.
            </p>
            <p className="text-base text-justify font-regular pt-1">
              Try to minimize the amount of luggage you bring to make travel
              easier.
            </p>
            <p className="font-medium text-base pt-2">Travel Apps:</p>
            <p className="text-base text-justify font-regular pt-1">
              Download useful travel apps, such as maps, translation, and
              currency conversion.
            </p>
            <p className="font-medium text-base pt-2">Safety Gear:</p>
            <p className="text-base text-justify font-regular pt-1">
              Depending on your destination, consider items like a money belt or
              RFID- blocking wallet.
            </p>
            <p className="font-medium text-base pt-2">Communication:</p>
            <p className="text-base text-justify font-regular pt-1">
              Buy a local SIM card or consider international roaming options for
              your phone.
            </p>
            <p className="font-medium text-base pt-2">Enjoy:</p>
            <p className="text-base text-justify font-regular pt-1">
              Finally, relax and enjoy your trip, but stay vigilant and adapt to
              the local culture.
            </p>
            <p className="text-base text-justify font-regular pt-2">
              Remember that this checklist can vary depending on your
              destination and personal preferences, so adjust it accordingly.
              Safe travels!
            </p>
          </div>
        </ul>,
      ],
    },

    {
      title: "Cancellation Policy",
      content: [
        <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
          <ul className="text-base font-medium pt-6">
            <table className="w-full">
              <thead>
                <tr>
                  <th
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Number of days before departure, TRAWEL MART WORLD TOURS
                    receives your cancellations
                  </th>
                  <th
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    Amount of cancellations charge shown as a % of a total
                    holiday price you must pay
                  </th>
                  <th
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "13px",
                      fontWeight: "600",
                      textAlign: "left",
                    }}
                  >
                    If we cancel your holiday, amount you will receive from us
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontSize: "14px",
                      fontWeight: "400",
                    }}
                  >
                    More than 45 days
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Deposit Only
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Advance Received
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    44 - 30 Days
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    40% of total holiday cost
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Amount Received
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    29 - 15 days
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    60% of total holiday cost
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Amount Received
                  </td>
                </tr>
                <tr>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    15 days or less
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    90% of total holiday cost
                  </td>
                  <td
                    style={{
                      border: "1px solid #949494",
                      padding: "8px",
                      fontWeight: "400",
                      fontSize: "14px",
                    }}
                  >
                    Full Amount Received plus 10% of the amount
                  </td>
                </tr>
                {/* Add more rows as needed */}
              </tbody>
            </table>
          </ul>
        </div>,
      ],
    },

    {
      title: "Transport Details",
      content: [
        <ul key="list" className="pl-2">
          <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
            <ul className=" p-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Making the finest for you!.
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We are trying to reserve the airplane tickets for this trip. We
                will update it here, as we are done.
              </li>
              <li className="lg:text-base md:text-base text-sm  font-normal">
                Post Booking, you will be notified through your contact details
                about all tour related updates.
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },

    {
      title: "Accomodation Details",
      content: [
        <ul key="list" className="pl-2">
          <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
            <ul className=" p-6">
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                Processing the best for you!
              </li>
              <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                We are in the process of booking the accommodation for this
                tour. We will update it here, as we are done.
              </li>
              <li className="lg:text-base md:text-base text-sm  font-normal">
                Post Booking, you will be notified through your contact details
                about all tour related updates.
              </li>
            </ul>
          </div>
        </ul>,
      ],
    },
  ];

  const [activeMenuItem, setActiveMenuItem] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("section");
      const scrollPosition = window.scrollY;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;

        if (
          scrollPosition >= sectionTop - sectionHeight / 3 &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          setActiveMenuItem(section.id);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }
  let [categories] = useState([
    "About this tour",
    "Need to know",
    "Tour Preparation",
    "Cancellation Policy",
  ]);
  let [categories2] = useState(["Transport Details", "Accommodation Details"]);
  let [categories3] = useState(["Flight Upgrade", "Hotel Upgrade"]);
  // let [categories1] = useState(["Highlights", "Included", "Excluded"]);
  let [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [adultValue, setAdultValue] = useState(1);
  const [childrenValue, setChildrenValue] = useState(0);
  const [infantValue, setInfantValue] = useState(0);
  const [totalAmount, setTotalAmount] = useState(114576);
  const [maximumPeople, setMaximumPeople] = useState(1);
  const [selectedRoom, setSelectedRoom] = useState("");
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [departureError, setDepartureError] = useState(false);
  const [travelError, setTravelError] = useState(false);
  const [destinationError, setDestinationError] = useState(false);
  const [passangerError, setPassangerError] = useState(false);
  const location = useLocation();
  let [name, setName] = useState("");
  let [email, setEmail] = useState("");
  let [mobileNumber, setMobileNumber] = useState("");
  let [departureCity, setDepartureCity] = useState('');
  let [travelDate, setTravelDate] = useState('');
  let [destination, setDestination] = useState('');
  let [noofPassanger, setNoofPassanger] = useState('');
  const [inputDateType, setInputDateType] = useState('text');
  let [description, setDescription] = useState(
    ""
  );

  useEffect(() => {
    setMaximumPeople(adultValue + childrenValue + infantValue);
  }, [adultValue, childrenValue, infantValue]);
  const handleAdultChange = (value: any) => {
    setAdultValue(value);
    setTotalAmount(value * 50000 + childrenValue * 30000 + infantValue * 25000);
    console.log(value);
  };

  const handleChildrenChange = (value: any) => {
    setChildrenValue(value);
    setTotalAmount(adultValue * 50000 + value * 30000 + infantValue * 25000);
    console.log(value);
  };

  const handleInfantChange = (value: any) => {
    setInfantValue(value);
    setTotalAmount(adultValue * 50000 + childrenValue * 30000 + value * 25000);
    console.log(value);
  };

  const updateRoomPrice = (value: any, roomText: any) => {
    setTotalAmount(
      adultValue * 50000 + childrenValue * 30000 + infantValue * 25000 + value
    );
    setSelectedRoom(roomText);
  };

  useEffect(() => {
    const userDataJSON = localStorage.getItem("loggedInUser");
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setName(userData.name);
      setMobileNumber(userData.mobile);
      setEmail(userData.email);
    }
  }, []);

  const handleSubmit = () => {
    if (name.length == 0) {
      setNameError(true);
    } else if (/\d/.test(name)) {
      setNameError(true);
    } else if (mobileNumber.length != 10) {
      setNameError(false);
      setMobileError(true);
    } else if (email.length == 0) {
      setNameError(false);
      setMobileError(false);
      setEmailError(true);
    } else {
      setNameError(false);
      setMobileError(false);
      setEmailError(false);
      const submitButton = document.getElementById("submitButton");
      if (submitButton) {
        (submitButton as HTMLButtonElement).disabled = true;
        submitButton.innerHTML = "Submitting...";
      }
      const travelers =
        (adultValue as any) +
        " Adults, " +
        (childrenValue as any) +
        " Child, " +
        (infantValue as any) +
        " Infant";
      const formData = {
        name: name,
        mobile: mobileNumber,
        email_address: email,
        description: description,
        guests: travelers,
        room: selectedRoom,
        url: location.pathname,
      };
      axios
        .post(
          "https://admin.trawelmart.com/admin/public/index.php/api/addContact",
          formData
        )
        .then((response: any) => {
          console.log(response);
          if (response.status == 200) {
            if (response.data.status) {
              (window as any).location = "/thanks";
            } else {
              toastr.error(response.data.message);
            }
          } else {
            console.log(response.responseText);
          }
        });
    }
  };

  const thisPathname = useLocation().pathname;
  const router = useNavigate();

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const DEMO_DATAA: ResortCard1Props["data"][] = [
    {
      id: "2",
      price: "Maldives Tour",
      icons: "la las-car",
      day: "Day-1",
      days: "Day-1",
      text: "Arrive at Maldives – Island Hopping - Sunset Cruise",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",
      },
    },
  ];
  const DEMO_DATAA1: ResortCard2Props["data"][] = [
    {
      id: "2",
      price: "Maldives Tour",
      icons: "la las-car",
      day: "Day-2",
      days: "Day-2",
      text: "Snorkelling - Vaadhoo Island",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",
      },
    },
  ];

  const DEMO_DATAA2: ResortCard3Props["data"][] = [
    {
      id: "2",
      price: "Maldives Tour",
      icons: "la las-car",
      day: "Day-3",
      days: "Day-3",
      text: "Male - Dolphin Watching",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",
      },
    },
  ];

  const DEMO_DATAA3: ResortCard4Props["data"][] = [
    {
      id: "2",
      price: "Maldives Tour",
      icons: "la las-car",
      day: "Day-4",
      days: "Day-4",
      text: "Depart from Maldives",
      airlines: {
        logo: "https://www.gstatic.com/flights/airline_logos/70px/KE.png",
        name: "Korean Air",
      },
    },
  ];

  const renderSection9 = () => {
    return (
      <div className="!space-y-3 ">
        {/* ============== Tour include benifits desktop view =============*/}

        {/* <div className="rounded-lg border lg:block hidden">
          <div className="gradient px-4 xl:h-36 rounded-lg">
            <div className="flex flex-col lg:flex-row items-center pt-1">
              <div className="text-white text-base font-medium lg:w-8/12">
                Tour Cost Person
              </div>
              <div className="text-white text-sm lg:w-4/12 textright">
                <button>
                  <div className="p-1 border border-white rounded-full text-xs px-4 text-center">
                    Standard{" "}
                    <span>
                      <i className="las la-angle-double-right"></i>
                    </span>
                  </div>
                </button>
              </div>
            </div>
            <div className="p-4 border-t mt-2">
              <div className="flex">
                <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                  <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                    SEP - OCT 23
                  </li>
                </ul>
                <ul className="text-white xl:ml-12 lg:w-6/12">
                  <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                    NOV - DEC 23
                  </li>
                </ul>
              </div>
              <div className="flex pt-3 xl:-mt-12">
                <ul className="xl:ml-8 text-white lg:w-6/12">
                  <li className="text-xs lg:text-sm xl:text-base font-semibold">
                    Rs. 98,650/-
                  </li>
                </ul>
                <ul className="xl:ml-16 text-white lg:w-6/12">
                  <li className="text-xs lg:text-sm xl:text-base font-semibold">
                    Rs. 11,8650/-
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}
        <div className="rounded-lg border lg:block hidden">
          <div className="gradient px-4 xl:h-36 rounded-lg">
            <div className="flex">
              <ul className="xl:ml-12 border-r h-10 text-white lg:w-6/12">
                <li className="xl:-ml-4 text-sm font-normal font xl:pt-3 lg:text-sm">
                  Meal Plan - All Inclusive
                </li>
              </ul>
              <ul className="text-white xl:ml-12 lg:w-6/12">
                <li className="xl:-ml-3 text-sm font-normal font xl:pt-3 lg:text-sm">
                  Transfers - Speed Boat
                </li>
              </ul>
            </div>
            {/* <div className="flex pt-3 xl:-mt-8">
              <ul className="xl:ml-8 text-white lg:w-6/12">
                <li className="text-xs lg:text-sm xl:text-base  font">
                  All Inclusive
                </li>
              </ul>
              <ul className="xl:ml-16 text-white lg:w-6/12">
                <li className="text-xs lg:text-sm xl:text-base font">
                  Speed Boat
                </li>
              </ul>
            </div> */}
            <div className="p-4 border-t mt-2">
              <div className="flex">
                <ul className="xl:ml-12 border-r h-16 text-white lg:w-6/12">
                  <li className="xl:-ml-7 text-sm font-semibold lg:text-lg">
                    SEP - OCT 23
                  </li>
                </ul>
                <ul className="text-white xl:ml-12 lg:w-6/12">
                  <li className="xl:-ml-3 text-sm font-semibold lg:text-lg">
                    NOV - DEC 23
                  </li>
                </ul>
              </div>
              <div className="flex pt-3 xl:-mt-12">
                <ul className="xl:ml-8 text-white lg:w-6/12">
                  <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                    ₹ 98,650/-
                  </li>
                </ul>
                <ul className="xl:ml-16 text-white lg:w-6/12">
                  <li className="font text-xs lg:text-sm xl:text-base font-semibold">
                    ₹ 11,8650/-
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="border px-4 py-4 rounded-lg shadow lg:block hidden">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="text-lg font-semibold lg:w-7/12">Activities</div>
            <div className="lg:w-3/12 text-end"></div>
            <div className="border-orange-500 rounded-md text-orange-600 text-center border text-xs font-semibold lg:w-2/12 py-1">
              Maldives
            </div>
          </div>

          <nav
            className="pt-4 nc-SocialsList flex text-2xl text-neutral-6000 dark:text-neutral-300 "
            data-nc-id="SocialsList"
          >
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/MgWdPrT/27.png" alt="" />
              </a>
            </div>

            <div className=" xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className="las la-swimmer"></i> 
                <img src="https://i.ibb.co/f0YNkHK/25.png" alt="" />
              </a>
              {/* <span className="text-xs font-normal text-black text-center">
                                Diving  
                            </span> 
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className="las la-ring"></i> 
                <img src="https://i.ibb.co/Sd8CkyK/22.png" alt="" />
              </a>
              {/* <span className="text-xs font-normal text-black text-center">
                                Weddings
                            </span> 
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className="las la-child"></i> 
                <img src="https://i.ibb.co/P6GKhHK/12.png" alt="" />
              </a>
              {/* <span className="text-xs font-normal text-black text-center">
                                Kids Club
                            </span> 
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className="las la-magic"></i> 
                <img src="https://i.ibb.co/S7qQvst/23.png" alt="" />
              </a>
              {/* <span className="text-xs font-normal text-black text-center">
                                Surfing
                            </span> 
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                {/* <i className="las la-biking"></i> 
                <img src="https://i.ibb.co/zPYLWg6/26.png" alt="" />
              </a>
              {/* <span className="text-xs font-normal text-black text-center">
                                Bicycle
                            </span> 
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/pWXNthS/7.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/jr624TB/20.png" alt="" />
              </a>
            </div>
          </nav>
          <nav
            className="pt-4 nc-SocialsList flex text-2xl text-neutral-6000 dark:text-neutral-300 "
            data-nc-id="SocialsList"
          >
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/31sQzyW/13.png" alt="" />
              </a>
            </div>

            <div className=" xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/71RSc6X/19.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/qxHxch0/1.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/WfbgDz3/6.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/VCdBZMp/9.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/r70M8WQ/5.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/nRh9Gvt/10.png" alt="" />
              </a>
            </div>
            <div className="xl:mr-3">
              <a
                className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="https://i.ibb.co/vQyv3mZ/8.png" alt="" />
              </a>
            </div>
          </nav>
        </div> */}

        {/* ============== Tour include benifits Mobile view =============*/}
        <div className="md:flex justify-between">
          <div className="border px-4 py-4 rounded-lg shadow xl:hidden lg:hidden md:hidden hidden mt-6 md:mt-0 md:w-6/12 md:mr-2">
            <div className="flex items-center justify-between">
              <div className="text-base font-medium lg:w-7/12">Activities</div>
              <div className="flex justify-between items-center">
                <div className="lg:w-3/12 text-end">
                  {/* <span className="text-right py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="h-5 w-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth={1.5}
                                                d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                            />
                                        </svg>
                                        <span className="hidden sm:block ml-2.5">Save</span>
                                    </span>*/}
                </div>
                <div className="border-orange-500 rounded-md text-orange-600 text-center border text-xs font-medium px-3">
                  Maldives
                </div>
              </div>
            </div>

            <nav
              className="pt-4 nc-SocialsList flex space-x-12 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
              data-nc-id="SocialsList"
            >
              {/*<div className="">
                                <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="" target="_blank" rel="noopener noreferrer">
                                    <i className="las la-plane-departure"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                    Flight
                                </span>
                            </div>*/}
              <div className="">
                <a
                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="las la-hotel"></i>
                </a>
                <span className="text-xs font-normal text-black text-center">
                  Parasailing
                </span>
              </div>
              <div className="">
                <a
                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="las la-utensils"></i>
                </a>
                <span className="text-xs font-normal text-black text-center">
                  Diving
                </span>
              </div>
              {/*<div className="">
                                <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="" target="_blank" rel="noopener noreferrer">
                                    <i className="las la-passport"></i>
                                </a>
                                <span className="text-xs font-normal text-black text-center">
                                    Visa
                                </span>
                            </div>*/}
              <div className="">
                <a
                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="las la-exchange-alt"></i>
                </a>
                <span className="text-xs font-normal text-black text-center">
                  Kids Club
                </span>
              </div>
              <div className="">
                <a
                  className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"
                  href=""
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="las la-magic"></i>
                </a>
                <span className="text-xs font-normal text-black text-center">
                  Surfing
                </span>
              </div>
            </nav>
          </div>
          <div className="rounded-lg border xl:hidden lg:hidden md:hidden hidden md:w-6/12 md:mt-0 mt-4 md:mb-0 mb-4">
            <div className="gradient px-4 rounded-t-lg">
              <div className="flex justify-between items-center pt-3">
                <div className="text-white text-sm lg:w-8/12">
                  Tour Cost Person
                </div>
                <div className="text-white text-sm lg:w-4/12 textright">
                  <button type="button">
                    <div className="p-1 border border-white rounded-full text-sm px-4 text-center">
                      Standard{" "}
                      <span>
                        <i className="las la-angle-double-right"></i>
                      </span>
                    </div>
                  </button>
                </div>
              </div>
              <div className="p-4 border-t mt-3 grid grid-cols-2 gap-2">
                <ul className="list-disc text-white ">
                  <li className="text-sm">Best Offers</li>
                </ul>
                <ul className="list-disc text-white ">
                  <li className="text-sm">Manage Bookings</li>
                </ul>
                <ul className="list-disc text-white ">
                  <li className="text-sm">Special Deals</li>
                </ul>
                <ul className="list-disc text-white ">
                  <li className="text-sm">Great Offers</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMotalAmenities = () => {
    return (
      <Transition appear show={isOpenModalAmenities} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50 overflow-y-auto"
          onClose={closeModalAmenities}
        >
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="inline-block h-screen align-middle"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block py-8 h-screen w-full max-w-4xl">
                <div className="inline-flex pb-2 flex-col w-full text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="headlessui-dialog-title-70"
                    >
                      Amenities
                    </h3>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalAmenities} />
                    </span>
                  </div>
                  {/* <div className="px-8 overflow-auto text-neutral-700 dark:text-neutral-300 divide-y divide-neutral-200">
                                        {Amenities_demos.filter((_, i) => i < 1212).map((item) => (
                                            <div
                                                key={item.name}
                                                className="flex items-center py-2.5 sm:py-4 lg:py-5 space-x-5 lg:space-x-8"
                                            >
                                                <i
                                                    className={`text-4xl text-neutral-6000 las ${item.icon}`}
                                                ></i>
                                                <span>{item.name}</span>
                                            </div>
                                        ))}
                                    </div> */}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  };

  const renderSidebar = () => {
    return (
      <div className=" bg-white rounded-xl shadow-xl height">
        <div className=" p-4 bg-[#fbf7fb] rounded-lg">
          <div className="flex justify-between">
            <span className="text-xl font-semibold">
              Booking Details
              <div className="text-xs font-normal text-blue-800 italic">
                5,232 Guests already travelled
              </div>
            </span>
            <div className="text-right ">
              <div className="border-orange-500 rounded text-orange-600 px-6 text-center border text-xs font-semibold py-1">
                Maldives
              </div>
              <StartRating1 className="w-full pt-2 ml-6" />
            </div>
          </div>
        </div>

        <div className="w-full border-b border-dashed border-neutral-200 dark:border-neutral-700"></div>
        <div className="">
          {/*<div className="btn ">
                        <div className={currentStep === 1 ? 'step11' : 'step11 hidden'}>
                            <h2 className="text-sm font-medium pb-2 ">Add guests (Max 6 guests at a time)</h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            {/* FORM 
                            <div className="space-y-4 mt-6">
                                <div className="flex flex-col lg:flex-row items-center  ">
                                    <div className="">
                                        <i className="las la-male text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                    </div>
                                    <div className="lg:w-full">
                                        <NcInputNumber label="Adults" defaultValue={1} onChange={handleAdultChange} />
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row items-center  ">
                                    <div className="">
                                        <i className="las la-child text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                    </div>
                                    <div className="lg:w-full">
                                        <NcInputNumber label="Child" defaultValue={0} onChange={handleChildrenChange} />
                                    </div>
                                </div>

                                <div className="flex flex-col lg:flex-row items-center lg:pb-4">
                                    <div className="">
                                        <i className="las la-baby text-2xl text-neutral-500 dark:text-neutral-400"></i>
                                    </div>
                                    <div className="lg:w-full">
                                        <NcInputNumber label="Infant" defaultValue={0} onChange={handleInfantChange} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={currentStep === 2 ? 'step12' : 'step12 hidden'}>
                            <div className="flex"></div>
                            <h2 className="text-sm font-medium pb-2 ">
                                Chosse your room
                            </h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            <div className="space-y-4 mt-8">
                                {adultValue <= 2 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'1 Twin Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                1 Twin Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'2 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'1 Double Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                1 Double Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                                {adultValue == 3 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'2 Twin Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Twin Room - 2 Adults & 1 Adult <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'3 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                3 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'2 Double Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Double Room - 2 Adults & 1 Adult <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                                {adultValue == 4 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'2 Twin Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Twin Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'4 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                4 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'2 Double Room - 2 Adults')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                2 Double Room - 2 Adults <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                                {adultValue == 5 && (
                                    <>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="127000" onChange={() => updateRoomPrice(127000,'3 Twin Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                3 Twin Room - 2 Adults & 1 Adult<br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Twin Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex border-b lg:pb-4">
                                            <input type="radio" id="age1" name="age" value="370000" onChange={() => updateRoomPrice(370000,'5 Single Room - 1 Adult in each room')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                5 Single Room - 1 Adult in each room <br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Single Bed in each room</span>
                                            </label>
                                        </div>
                                        <div className="flex ">
                                            <input type="radio" id="age1" name="age" value="126000" onChange={() => updateRoomPrice(126000,'3 Double Room - 2 Adults & 1 Adult')} />
                                            <label className="text-sm font-semibold ml-3  -mt-2">
                                                3 Double Room - 2 Adults & 1 Adult<br></br>
                                                <span className="text-neutral-400 text-xs font-normal">
                                                    <i className="las la-bed mr-2"></i>1 Double Bed in each room</span>
                                            </label>
                                        </div><br />
                                    </>
                                )}
                            </div>
                        </div>
                        <div className={currentStep === 3 ? 'step13' : 'step13 hidden'}>
                            <h2 className="text-sm font-medium pb-2 flex justify-between items-center">
                                Booking Details
                                <i
                                    className="las la-edit text-sky-600 text-xl font-bold cursor-pointer"
                                    onClick={handleEditIconClick}
                                ></i>
                            </h2>
                            <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
                            <div className="space-y-6 mt-4 mb-4">
                                <h4 className="font-normal text-neutral-400 text-xs pb-1 ">
                                    Departure City
                                </h4>
                                <span className="text-sm font-semibold">
                                    Bangalore
                                </span>

                                <h4 className="font-normal text-neutral-400 text-xs pb-1 ">
                                    Departure Date
                                </h4>
                                <span className="text-sm font-semibold">
                                    08 Aug 2023 <i className="las la-long-arrow-alt-right px-2"></i>  08 Aug 2023
                                </span>

                                <h4 className="font-normal text-neutral-400 text-xs pb-1 ">
                                    Travellers
                                </h4>
                                <span className="text-sm font-semibold ">
                                    {adultValue} Adult(s) <i className="las la-grip-lines-vertical"></i>   {infantValue} Infant <i className="las la-grip-lines-vertical"></i> {childrenValue} Child
                                </span>
                            </div>
                        </div>

                        <div className="pt-4">
                            <div className="w-full border-t-2 border-dashed border-neutral-200 dark:border-neutral-700 pb-3"></div>
                            <div className="flex flex-col lg:flex-row items-center">
                                <div className="lg:w-5/12">
                                    <div className="text-2xl font-extrabold font1">
                                        ₹{totalAmount}
                                    </div>
                                    <div className="text-xs text-green-500  italic">
                                        per person on twin sharing
                                    </div>
                                    <div className="text-sm  italic text-red-500">
                                        Detailed Tour Price
                                    </div>
                                </div>


                                <div className="lg:w-7/12 text-right" id="travellers">
                                    {currentStep > 1 && currentStep !== totalSteps && (
                                        <button
                                            id="prevButton"
                                            className="bg-blue-500 rounded-full mr-1 text-white px-6 py-3 text-xs"
                                            onClick={handlePrevButtonClick}
                                        >
                                            Previous
                                        </button>
                                    )}

                                    {currentStep === totalSteps ? (
                                        <button
                                            id="enquiryButton"
                                            className="bg-blue-500 rounded-full mr-1 text-white px-6 py-3 text-xs"
                                            onClick={handleEnquiryButtonClick}
                                        >
                                            Enquiry
                                        </button>
                                    ) : (
                                        <button
                                            id="nextButton"
                                            className="bg-blue-500 rounded-full text-white px-6 py-3 text-xs"
                                            onClick={handleNextButtonClick}
                                        >
                                            Next
                                        </button>
                                    )}

                                    {currentStep === totalSteps && (
                                        <a href="/checkout">
                                            <button
                                                id="bookOnlineButton"
                                                className="bg-blue-500 rounded-full text-white px-6 py-3 text-xs"
                                                onClick={() => {
                                                    // Your logic to handle "Book Online" button click
                                                }}
                                            >
                                                Book Now
                                            </button>
                                        </a>
                                    )}

                                    {/* Enquiry Popup 
                                    {showEnquiryPopup && (
                                        <div className="fixed top-16 left-0 right-0 bottom-0 bg-black bg-opacity-50 flex items-center justify-center max-w-full">
                                            <div className="bg-white rounded-lg" style={{ width: '500px' }}>
                                                <div className="text-right mt-2 mr-2">
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                        onClick={() => {
                                                            setShowEnquiryPopup(false);
                                                            // Logic to handle form submission
                                                        }}
                                                    >
                                                        X
                                                    </button>
                                                </div>
                                                {/*  enquiry popup content here 
                                                <div className="font p-4 bg-[#fbf7fb] rounded-t-lg text-center lg:-mt-8 -mt-10 pt-4 lg:pt-4 text-2xl font-semibold leading-6 text-black"
                                                >
                                                    Quick Enquiry
                                                    <p className="pt-2 font-normal text-xs text-sky-500">
                                                        Plan an unforgettable trip with Trawel Mart
                                                    </p>
                                                </div>
                                                <form>
                                                    <div className="mx-auto">
                                                        <label className="block xl:px-16 px-4">
                                                            <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                                                            {nameError && (<p style={{ textAlign:'start',color:'red' }}>Please enter valid name</p>)}
                                                        </label>
                                                        <label className="block xl:px-16 px-4">
                                                            <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                            {mobileError && (<p style={{ textAlign:'start',color:'red' }}>Please enter valid mobile number</p>)}
                                                        </label>
                                                        <label className="block xl:px-16 px-4">
                                                            <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                                                            {emailError && (<p style={{ textAlign:'start',color:'red' }}>Please enter valid email</p>)}
                                                        </label>
                                                        <label className="block xl:px-16 px-4">
                                                            <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                                        </label>
                                                    </div>

                                                    <div className="xl:px-16 px-4 mt-4">
                                                        <button type="button"
                                                            id="submitButton"
                                                            className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75" onClick={handleSubmit}>
                                                            Submit Enquiry
                                                        </button>
                                                    </div>
                                                    <p className="mt-4 text-xs text-center">
                                                           Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
                                                    </p>
                                                    <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                                                        <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                <div className="lg:w-2/12">
                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                </div>
                                                                <div className="lg:w-9/12 pl-2">
                                                                    <span className="text-sm font-medium ">
                                                                        Super Offers
                                                                    </span>
                                                                    <p className="text-sky-700 italic font-normal text-xs ">
                                                                        Explore Great Deals
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="lg:w-6/12 text-left flex items-center">
                                                                <div className="lg:w-2/12">
                                                                    <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                                                                </div>
                                                                <div className="lg:w-10/12 pl-2">
                                                                    <span className="text-sm font-medium ">
                                                                        My Trips
                                                                    </span>
                                                                    <p className="text-sky-700 font-normal italic text-xs ">
                                                                        Manage Your Bookings
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* ... 
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>*/}

<form>
              <div className="mx-auto xl:mt-4">
                <label className="block xl:px-8 px-4">
                  <Input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-6" />
                  {nameError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid name</p>)}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input type="number" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} placeholder="Phone Number" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                  {mobileError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid mobile number</p>)}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email ID" className="bg-white text-black border border-slate-300 px-3 py-1 h-12 rounded-lg mt-4" />
                  {emailError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid email</p>)}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input type="" value={departureCity} onChange={(e) => setDepartureCity(e.target.value)} placeholder="Departure City" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                  {departureError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid departure city</p>)}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input value={travelDate} onChange={(e) => setTravelDate(e.target.value)} type={inputDateType} placeholder="Travel Date" onFocus={() => setInputDateType('date')} className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                  {travelError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid travel date</p>)}
                </label>
                <label className="block xl:px-8 px-4">
                  <Input type="" value={noofPassanger} onChange={(e) => setNoofPassanger(e.target.value)} placeholder="No of Passenger" className="bg-white text-black border border-slate-300 px-3 py-1 h-10 rounded-md mt-4" />
                  {passangerError && (<p style={{ textAlign: 'start', color: 'red' }}>Please enter valid number of passenger</p>)}
                </label>
                {/*<label className="block xl:px-8 px-4">
                                <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-20 rounded-lg mt-4" />
                                </label>*/}
                <label className="block xl:px-8 px-4">
                  <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Drop us a small description" className="bg-white text-black border border-slate-300 px-3 py-1 h-14 rounded-md mt-4" />
                </label>
              </div>

              <div className="xl:px-8 px-4 mt-4">
                <button type="button"
                  id="submitButton"
                  className="w-full rounded-md bg-sky-600 mt-2 px-4 py-2 text-sm 
                                                                        font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white 
                                                                        focus-visible:ring-opacity-75" onClick={handleSubmit}>
                  Submit Enquiry
                </button>
              </div>
              <p className="mt-4 text-xs text-center">
                Get the most recent travel offers, new tour <br></br>announcements, trip ideas and much more.
              </p>
              <div className="bg-[#fbf7fb] mt-6 rounded-b-lg">
                <div className="w-full flex items-center justify-between p-2 xl:pl-12 pl-6">
                  <div className="lg:w-6/12 text-left flex items-center">
                    <div className="lg:w-2/12">
                      <img className="block dark:hidden w-8" src={presents} alt="logo1" />
                    </div>
                    <div className="lg:w-9/12 pl-2">
                      <span className="text-sm font-medium ">
                        Super Offers
                      </span>
                      <p className="text-sky-700 italic font-normal text-xs ">
                        Explore Great Deals
                      </p>
                    </div>
                  </div>
                  <div className="lg:w-6/12 text-left flex items-center">
                    <div className="lg:w-2/12">
                      <img className="block dark:hidden w-8" src={luggage} alt="logo1" />
                    </div>
                    <div className="lg:w-10/12 pl-2">
                      <span className="text-sm font-medium ">
                        My Trips
                      </span>
                      <p className="text-sky-700 font-normal italic text-xs ">
                        Manage Your Bookings
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-ListingStayDetailPage font">
      {/*  HEADER*/}{" "}
      <ul className="breadcrumb  lg:w-4/12">
        <li className="lg:text-base text-xs">
          <a href="/">Home</a>
        </li>
        <li className="lg:text-base text-xs">
          <a href="/Maldives-tour-packages">Maldives</a>
        </li>
        <li className="lg:text-base text-xs">Itinerary Details</li>
      </ul>
      <header className="rounded-md sm:rounded-xl lg:pt-5">
        {/* =================Title section Desktop view ============= */}
        <div className="flex flex-col lg:flex-row  text-left lg:pb-4 ">
          <div className="lg:w-10/12 xl:block lg:block md:block block">
            <h2 className="font text-2xl sm:text-3xl lg:text-3xl md:text-2xl lg:pb-2 md:pb-2 lg:font-semibold ">
            OBLU Xperience Ailafushi
            </h2>
            <div className="flex items-center space-x-4 ">
              <StartRating1 />
              <span className="xl:block lg:block md:block hidden">
                <i className="las la-map-marker-alt"></i>
                <span className="text-sm font font-medium"> Maldives</span>
              </span>
              <span>
                <i className="las la-calendar"></i>
                <span className="text-sm font-medium"> 5 Days</span>
              </span>
              <span>
                <div className="font text-xs font-regular text-sky-600 border-sky-600 border rounded py-1 px-2">
                  Standard
                </div>
              </span>
            </div>
            <div className="text-xs lg:pt-2 pt-2 text-neutral-500 dark:text-neutral-400 font-normal">
              1,336 Guests already travelled
            </div>
            {/* <div className="grid lg:grid-cols-4 xl:grid-cols-4 sm:grid-cols-2 xl:gap-4 lg:gap-4 sm:gap-2 xl:mt-5 xl:mb-4">
                  <div className="flex items-center py-2  xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/zhdC1bV/icons8-communication-50.png" alt="" className="w-12 h-12 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Language
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <p className="font text-sky-600 text-sm">English</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center py-2  xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/89ZLTvs/icons8-people-32.png" alt="" className="w-12 h-12 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Group Size
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <p className="font text-sky-600 text-sm">Unlimited</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center py-2  xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/MGybc1C/icons8-feet-32.png" alt="" className="w-12 h-12 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Tour Type
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <p className="font text-sky-600 text-sm">Daily Tour</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex items-center py-2  xl:my-0 my-1">
                        <div className="">
                            <img src="https://i.ibb.co/qgt76v6/icons8-time-50.png" alt="" className="w-12 h-12 rounded-full" />
                        </div>
                        <div className="pl-4">
                            <h4 className="text-base font-medium font">
                                Duration
                            </h4>
                            <div className="flex items-center ">
                                <div>
                                    <p className="font text-sky-600 text-sm">3 Nights 4 Days</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
          </div>

          <div className="lg:w-2/12 lg:pt-3 pt-4 justify-center text-left flex flex-row  items-center ">
            <div>
              <img
                className="block dark:hidden w-12  xl:lg:md:block hidden"
                src={offersss}
                alt="logo1"
              />
            </div>
            <div>
              <div className="lg:text-sm md:text-2xl text-sm lg:-mb-1 text-black uppercase dark:text-black-400 font-medium  xl:lg:md:block hidden">
                Super Offers
              </div>
              <span className="lg:text-base md:text-2xl text-sm lg:pb-2 pt-1 text-black bannerfont dark:text-black-400 font-normal  xl:lg:md:block hidden">
                Explore Great Deals & Offers
              </span>
            </div>
          </div>

          <div className="xl:w-0/12 lg:w-0/12 lg:pt-3 justify-center text-right xl:block lg:block md:hidden hidden">
            {/* <div className=" xl:text-xs lg:text-sm font-regular text-green-600">
              Trawelmart Offer Price
            </div>
            <div className="uppercase xl:text-xs lg:text-xs font-regular text-black">
              Starts from
            </div>
            <div className="font1 lg:text-3xl md:text-2xl text-sm font-extrabold text-black dark:text-black-400">
              ₹{totalAmount}
            </div>
            <div className=" xl:text-xs lg:text-xs font-regular text-black">
              per person on twin sharing
            </div> */}
            {/* <button
              type="button"
              onClick={inopenModal}
              className=" text-xs bg-white border-sky-600 border text-sky-600 px-4 py-1 rounded-md mt-1 font-medium"
            >
              View Pricing
            </button> */}
            <Transition appear show={inOpen} as={Fragment}>
              <Dialog as="div" className="relative z-10" onClose={incloseModal}>
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>
                <div className="font fixed inset-0 overflow-y-auto">
                  <div className="flex min-h-full items-center justify-center p-4 text-center">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-out duration-300"
                      enterFrom="opacity-0 scale-95"
                      enterTo="opacity-100 scale-100"
                      leave="ease-in duration-200"
                      leaveFrom="opacity-100 scale-100"
                      leaveTo="opacity-0 scale-95"
                    >
                      <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                        <div className="text-right mt-2 mr-2">
                          <button
                            type="button"
                            className="inline-flex justify-center rounded-full border border-transparent bg-blue-100 px-2 py-1 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                            onClick={incloseModal}
                          >
                            X
                          </button>
                        </div>
                        <Dialog.Title
                          as="h3"
                          className="font p-4 bg-[#fbf7fb] lg:-mt-12 md:-mt-9 -mt-12 lg:pt-6 text-base font-regular text-left leading-6 text-sky-600"
                        >
                          Trawel Mart's Detailed Tour Price
                          <p className="xl:text-xs font font-regular text-gray-500">
                            Prices & discounts are Per Person for Indian
                            Nationals only.
                          </p>
                        </Dialog.Title>
                        <div className="p-4">
                          <table key="table" className="table">
                            <table
                              className="rounded-2xl"
                              style={{ width: "460px" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      textAlign: "left",
                                      width: "230px",
                                    }}
                                  >
                                    Standard Maldives Packages
                                  </th>
                                  <th
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontSize: "13px",
                                      fontWeight: "500",
                                      textAlign: "center",
                                    }}
                                  >
                                    Price
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontSize: "13px",
                                      fontWeight: "400",
                                      textAlign: "left",
                                      backgroundColor: "#f9f9f9",
                                      width: "230px",
                                    }}
                                  >
                                    Adult
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      textAlign: "center",
                                      backgroundColor: "#f9f9f9",
                                    }}
                                  >
                                    ₹ 1,14,576
                                  </td>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontSize: "13px",
                                      fontWeight: "400",
                                      textAlign: "left",
                                      width: "210px",
                                    }}
                                  >
                                    Child With Bed
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      textAlign: "center",
                                    }}
                                  >
                                    ₹ 48,000
                                  </td>
                                </tr>

                                <tr>
                                  <td
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontSize: "13px",
                                      fontWeight: "400",
                                      textAlign: "left",
                                      backgroundColor: "#f9f9f9",
                                      width: "230px",
                                    }}
                                  >
                                    Child Without Bed
                                  </td>
                                  <td
                                    style={{
                                      border: "1px solid #d9d7d7",
                                      padding: "10px",
                                      fontWeight: "500",
                                      fontSize: "14px",
                                      textAlign: "center",
                                      backgroundColor: "#f9f9f9",
                                    }}
                                  >
                                    ₹ 39,000
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </table>
                        </div>
                        <div className="text-left font font-medium text-sm xl:ml-4">
                          Exclusion
                        </div>
                        <ul className="list-disc text-left xl:text-xs list-inside xl:ml-4 pb-6">
                          <li className="list-disc list-inside py-1">
                            Air Ticket
                          </li>
                          <li className="list-disc list-inside py-1">Visa</li>
                          <li className="list-disc list-inside py-1">
                            GST 5% , TCS 5%
                          </li>
                        </ul>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </div>
              </Dialog>
            </Transition>
          </div>
        </div>

        {/* =================Title section Mobile view ============= */}
        

        {/* ======== image Activities desktop view ======== */}
        <div className="w-full flex flex-wrap ">
          <div className="xl:w-8/12 lg:w-7/12 lg:block hidden">
            <div className="relative grid grid-cols-3 sm:grid-cols-2 gap-1 sm:gap-2 ">
              <div
                className="col-span-1 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                onClick={handleOpenModalImageGallery}
              >
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={PHOTOS71[0]}
                  alt=""
                  sizes="(max-width: 900px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {PHOTOS71.filter((_, i) => i >= 1 && i < 3).map((item, index) => (
                <div
                  key={index}
                  className={`relative xl:w-11/12 rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                    }`}
                >
                  <div className="aspect-w-4 aspect-h-3 sm:aspect-w-5 sm:aspect-h-2 xl:aspect-w-5 xl:aspect-h-2 lg:aspect-w-6 lg:aspect-h-3">
                    <img
                      className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-6/12 h-full"
                      src={item || ""}
                      alt=""
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

              <button
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="w-5 h-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </button>
            </div>
          </div>
          <div className="xl:w-4/12 lg:w-5/12 lg:block hidden xl:pl-0 lg:pl-6">
            {renderSection9()}
          </div>
        </div>

        {/* ======== image Activities mobile view ======== */}
        <div className="w-full lg:hidden block">
          <div className="w-12/12">
            <div className="relative grid grid-cols-1 sm:grid-cols-2 gap-1 sm:gap-2 ">
              <div
                className="col-span-1 row-span-1 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
                onClick={handleOpenModalImageGallery}
              >
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={PHOTOS71[0]}
                  alt=""
                  sizes="(max-width: 900px) 100vw, (max-width: 1200px) 50vw, 50vw"
                />
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
              </div>
              {PHOTOS71.filter((_, i) => i >= 1 && i < 2).map((item, index) => (
                <div
                  key={index}
                  className={`relative xl:w-11/12 rounded-md sm:rounded-xl overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
                    }`}
                >
                  <div className="aspect-w-8 aspect-h-4 sm:aspect-w-5 sm:aspect-h-4">
                    <img
                      className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-12/12 h-full"
                      src={item || ""}
                      alt=""
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

              <button
                className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
                onClick={handleOpenModalImageGallery}
              >
                <Squares2X2Icon className="w-5 h-5" />
                <span className="ml-2 text-neutral-800 text-sm font-medium">
                  Show all photos
                </span>
              </button>
            </div>
          </div>
          <div className="lg:w-4/12">{renderSection9()}</div>
        </div>
      </header>
      {/* MAIN */}
      <main className=" relative z-10 mt-0 lg:mt-11 flex flex-col lg:flex-row ">
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-6 lg:pr-10 lg:pb-12">
          {/* itinerary section 
                    <div className="hidden lg:block lg:p-6 p-5 bg-[#fbf7fb] rounded-lg lg:dark:bg-black/20 shadow sticky lg:top-20 top-24 z-50 lg:overflow-x-auto overflow-x-scroll">
                        {/* itinerary section desktop view*/}
          {/*<div className="lg:block hidden">
                            <div className="grid lg:grid-cols-6 lg:gap-2 items-center justify-center text-center ">
                                <HashLink smooth to="#itinerary ">
                                    <span
                                        className={`text-black text-base font-medium ${activeMenuItem === 'itinerary' ? 'active' : ''
                                            }`}
                                    >
                                        Itinerary
                                    </span>
                                </HashLink>
                                <HashLink smooth to="#includ" className="lg:-ml-4">
                                    <span
                                        className={`text-black text-base font-medium ${activeMenuItem === 'includ' ? 'active' : ''
                                            }`}
                                    >
                                        Include/Exclude
                                    </span>
                                </HashLink>
                                <HashLink smooth to="#about-tour">
                                    <span
                                        className={`text-black text-base font-medium ${activeMenuItem === 'about-tour' ? 'active' : ''
                                            }`}
                                    >
                                        Information
                                    </span>
                                </HashLink>
                                <HashLink smooth to="#policy-terms">
                                    <span
                                        className={`text-black text-base font-medium ${activeMenuItem === 'policy-terms' ? 'active' : ''
                                            }`}
                                    >
                                        TourDetails
                                    </span>
                                </HashLink>

                                <HashLink smooth to="#reviews">
                                    <span
                                        className={`text-black text-base font-medium ${activeMenuItem === 'reviews' ? 'active' : ''
                                            }`}
                                    >
                                        Upgrades
                                    </span>
                                </HashLink>
                            </div>
                                        </div>*/}
          {/* itinerary section mobile view
                        <div className="flex w-12/12 items-center  text-center lg:hidden hidden">
                            <div className="w-8/12 pr-6">
                                <HashLink smooth to="#itinerary">
                                    <span
                                        className={`text-black text-sm font-medium ${activeMenuItem === 'itinerary' ? 'active' : ''
                                            }`}
                                    >
                                        Itinerary
                                    </span>
                                </HashLink>
                            </div>
                            <div className="w-8/12 pr-6">
                                <HashLink smooth to="#includ" className="lg:-ml-4">
                                    <span
                                        className={`text-black text-sm font-medium ${activeMenuItem === 'includ' ? 'active' : ''
                                            }`}
                                    >
                                        Include/Exclude
                                    </span>
                                </HashLink>
                            </div>
                            <div className="w-8/12 pr-6">
                                <HashLink smooth to="#about-tour">
                                    <span
                                        className={`text-black text-sm font-medium ${activeMenuItem === 'about-tour' ? 'active' : ''
                                            }`}
                                    >
                                        Information
                                    </span>
                                </HashLink>
                            </div>
                            <div className="w-8/12 pr-6">
                                <HashLink smooth to="#policy-terms">
                                    <span
                                        className={`text-black text-sm font-medium ${activeMenuItem === 'policy-terms' ? 'active' : ''
                                            }`}
                                    >
                                        TourDetails
                                    </span>
                                </HashLink>
                            </div>
                            <div className="w-8/12 pr-6">
                                <HashLink smooth to="#reviews">
                                    <span
                                        className={`text-black text-sm font-medium ${activeMenuItem === 'reviews' ? 'active' : ''
                                            }`}
                                    >
                                        Upgrades
                                    </span>
                                </HashLink>
                            </div>
                        </div>
                    </div>*/}
                    <section
            id="itinerary"
            className="lg:p-4 p-2 lg:bg-white border lg:dark:bg-black/20 grid grid-cols-1 gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-6 lg:pt-3 md:pt-4"
          >
            <h2 className="text-xl font-semibold font1">
            OBLU Xperience Ailafushi 
            </h2>
            <p className="font1 text-sm pt-2">
              Embrace the magical Indian Ocean experience from a vibrant beach or overwater villa, offering stunning ocean views. Refreshingly friendly, caring service and playfully designed spaces create a fun, free-spirited stay.
            </p>
          </section>
          <section
            id="itinerary"
            className=" lg:bg-white lg:dark:bg-black/20  xl:mt-0 lg:mt-0 md:mt-0 xl:pt-2 lg:pt-2 md:pt-2"
          >

            {data.map((stay) => (
              <PropertyCardMaldives key={stay.id} data={stay} />
            ))}

          </section>

          {/* <section
            id="itinerary"
            className="lg:p-6 p-2 lg:bg-white border lg:dark:bg-black/20 grid grid-cols-1 gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-6 lg:pt-3 md:pt-4"
          >
            <div className="grid xl:grid-cols-2 xl:gap-6">
              <div
                className="lg:bg-white border lg:dark:bg-black/20  gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-0 lg:pt-0 md:pt-0">
                <a className="block aspect-w-7 aspect-h-3 " href="/singapore-malaysia-bangkok-10nights-11days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="/Hotel-11/kandima1.jpg" className="object-cover w-full h-full lg:rounded-t-xl rounded-t-md" alt="nc-imgs" />
                  </div>
                </a>
                <div className="p-4">
                  <h2 className="font font-semibold xl:text-lg xl:pt-2">
                    Ocean View Room
                  </h2>
                  <p className="xl:text-sm xl:pt-1">
                    Our standard duplex style rooms are snug, homey havens.
                  </p>
                </div>

                <div className=" bg-[#fff0ff]  mt-2">
                  <div className="p-4 space-y-4">
                    <div className="flex justify-between items-center">
                      <a href="/bali-10nights-11days-tour-packages">
                        <div className="font text-xs md:text-xs xl:text-sm text-white gradient2 rounded px-6 py-2 font-medium ">
                          View Details
                        </div></a><span className="text-xl font-extrabold">'
                        <div className="font text-xs text-center text-black dark:text-neutral-400 font-normal">Starts From</div>
                        <div className="font">₹130000<span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                        </span></div></span></div></div></div>
              </div>


              <div
                className="lg:bg-white border lg:dark:bg-black/20  gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-0 lg:pt-0 md:pt-0">
                <a className="block aspect-w-7 aspect-h-3 " href="/singapore-malaysia-bangkok-10nights-11days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="/Hotel-11/kandima1.jpg" className="object-cover w-full h-full lg:rounded-t-xl rounded-t-md" alt="nc-imgs" />
                  </div>
                </a>
                <div className="p-4">
                  <h2 className="font font-semibold xl:text-lg xl:pt-2">
                    Beach Villas
                  </h2>
                  <p className="xl:text-sm xl:pt-1">
                    The scenic Beach Villas are just a few steps from the white, sandy beach.
                  </p>
                </div>
              </div>
            </div>


            <div className="grid xl:grid-cols-2 xl:gap-6 xl:pt-4">
              <div
                className="lg:bg-white border lg:dark:bg-black/20  gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-0 lg:pt-0 md:pt-0">
                <a className="block aspect-w-7 aspect-h-3 " href="/singapore-malaysia-bangkok-10nights-11days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="/Hotel-11/kandima1.jpg" className="object-cover w-full h-full lg:rounded-t-xl rounded-t-md" alt="nc-imgs" />
                  </div>
                </a>
                <div className="p-4">
                  <h2 className="font font-semibold xl:text-lg xl:pt-2">
                    Water Villas
                  </h2>
                  <p className="xl:text-sm xl:pt-1">
                    Stretching into the dazzling lagoon are the water villas accessed by a wooden jetty.
                  </p>
                </div>
              </div>


              <div
                className="lg:bg-white border lg:dark:bg-black/20  gap-0 lg:rounded-xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-0 lg:pt-0 md:pt-0">
                <a className="block aspect-w-7 aspect-h-3 " href="/singapore-malaysia-bangkok-10nights-11days-tour-package">
                  <div className="nc-NcImage " data-nc-id="NcImage">
                    <img src="/Hotel-11/kandima1.jpg" className="object-cover w-full h-full lg:rounded-t-xl rounded-t-md" alt="nc-imgs" />
                  </div>
                </a>
                <div className="p-4">
                  <h2 className="font font-semibold xl:text-lg xl:pt-2">
                    Ocean View Family
                  </h2>
                  <p className="xl:text-sm xl:pt-1">
                    Lively, comfortable, and full of appeal – these two-bedroom and two-bathroom rooms are in the heart of the island.
                  </p>
                </div>
              </div>
            </div>


          </section>
          <section
            id="itinerary"
            className="lg:p-4 p-2 lg:bg-white border lg:dark:bg-black/20 grid grid-cols-1 gap-0 lg:rounded-3xl rounded-md xl:mt-0 lg:mt-0 md:mt-6 xl:pt-6 lg:pt-3 md:pt-4"
          >
            <div className="lg:pl-4 pl-2 pb-2 lg:flex md:flex md:justify-between">
              <div className="lg:w-9/12">
                <h2 className="lg:text-xl text-lg font lg:font-semibold font-semibold lg:pb-2 pb-3 lg:pt-0 pt-2">
                  Itinerary
                </h2>
                <p className="xl:text-sm font ">
                  Boasting one of the longest swimming pools in Maldives,
                  Kandima is a sprawling resort with beachfront dining,
                  expansive sports arena and activities for all. Enjoy
                  snorkelling, diving, surfing, windsurfing, glass-bottomed boat
                  tour, and island picnics
                </p>
              </div>

              <nav
                className="nc-SocialsList flex space-x-2.5 text-2xl text-neutral-6000 dark:text-neutral-300 !space-x-2"
                data-nc-id="SocialsList"
              >
                <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl">
                  {/*<i className="las la-plane-departure"></i></a><a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl"  >
                  <i className="las la-hotel"></i>
                </a>
                <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl">
                  <i className="las la-utensils"></i>
                </a>
                <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl">
                  <i className="las la-car"></i>
                </a>
              </nav>
            </div>

            <div className=" rounded-3xl mt-6">
              {DEMO_DATAA.map((item, index) => (
                <ResortCard1
                  defaultOpen={!index}
                  key={index}
                  data={item}
                  className="pl-4"
                />
              ))}
            </div>
            <div className=" rounded-3xl">
              {DEMO_DATAA1.map((item, index) => (
                <ResortCard2
                  defaultOpen={false}
                  key={index}
                  data={item}
                  className="pl-4"
                />
              ))}
            </div>
            <div className=" rounded-3xl">
              {DEMO_DATAA2.map((item, index) => (
                <ResortCard3
                  defaultOpen={false}
                  key={index}
                  data={item}
                  className="pl-4"
                />
              ))}
            </div>
            <div className=" rounded-3xl">
              {DEMO_DATAA3.map((item, index) => (
                <ResortCard4
                  defaultOpen={false}
                  key={index}
                  data={item}
                  className="pl-4"
                />
              ))}
            </div>

            {/* <div className="flex mt-12 justify-center items-center">
              <ButtonPrimary loading>Show more</ButtonPrimary>
            </div>
          </section> */}

          {/* Included starts */}
          {/* <section id="includ" className="listingSection__wrap">
                        <Tab.Group>
                            <Tab.List className="grid grid-cols-3 gap-0 space-x-0 overflow-x-auto lg:-mt-2 bg-blue-500 rounded-lg">
                                {categories1.map((item) => (
                                    <Tab key={item} as={Fragment}>
                                        {({ selected }) => (
                                            <button
                                                className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm lg:text-lg sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                                                    ? "bg-blue-500 text-white "
                                                    : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                                    } `}
                                            >
                                                {item}
                                            </button>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel className="">
                                    <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 text-sm text-neutral-700 dark:text-neutral-300 lg:-mt-4 -mt-4">
                                            <div className="space-y-4">
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-city"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	City tour of Maldives</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-car-side"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">  Desert Safari with Belly dance with BBQ Dinner</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-fish"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Underwater Zoo </span>
                                                </div>

                                            </div>
                                            <div className="space-y-4">
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-10 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-building"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Burj Khalifa 124 Floor Visit with Fountain Show</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-ship"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Dhow Cruise with Dinner	</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-9 h-9 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-city"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Abu Dhabi city tour </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>

                                <Tab.Panel className="">
                                    <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 text-sm text-neutral-700 dark:text-neutral-300 lg:-mt-4 -mt-4">
                                            <div className="lg:space-y-4 space-y-2">
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-hotel "></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	5 Nights stay in Maldives at Hotel Admiral Plaza or Similar </span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-utensils"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Daily Breakfast</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-city"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">Maldives City Tour </span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-car-side"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	Desert Safari with Belly Dance with BBQ Dinner</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-monument"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	Museum Of the Future</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-bus"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	All Transportation by Luxury Executive Coach at Destinations</span>
                                                </div>
                                            </div>
                                            <div className="lg:space-y-4 space-y-2">
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-ship"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Dhow Cruise with Dinner</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-fish"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	Underwater Zoo</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-building"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	Burj Khalifa 124 floor visit with Fountain Show</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-city"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">Abu Dhabi City Tour</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-stop"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	Maldives Frame </span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-file-invoice"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	All Sightseeing as mentioned in the itinerary and all the entry fees </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel className="">
                                    <div className="mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                                        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 text-sm text-neutral-700 dark:text-neutral-300 lg:-mt-4 -mt-4">
                                            <div className="lg:space-y-4 space-y-2">
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-percentage "></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	Airfare with taxes</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-passport"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Maldives Visa fee with Travel Insurance</span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-money-bill-wave"></i>
                                                    </a>
                                                    <span className="text-sm font-normal">	GST 5%, TCS 5% </span>
                                                </div>
                                            </div>
                                            <div className="lg:space-y-4 space-y-2">
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-file-invoice"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> Expenses of Personal natures </span>
                                                </div>
                                                <div className="space-x-3 flex items-center">
                                                    <a className="flex items-center justify-center w-8 h-8 rounded-full bg-neutral-100 dark:bg-neutral-800 text-xl" href="#" target="_blank" rel="noopener noreferrer" >
                                                        <i className="las la-utensils"></i>
                                                    </a>
                                                    <span className="text-sm font-normal"> All Lunches and Dinner </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </section> */}

          {/* Included Excluded Ends */}

          {/* About Your */}

          {/* <section className="mt-4">
                        <span className="lg:text-lg text-xl lg:font-semibold font-semibold">
                            Tour Information
                        </span>
                        <p className="lg:text-base text-sm">
                            Read this to prepare for your tour in the best way!
                        </p>
                    </section> */}

          {/* <section id="includ" className="hidden lg:block">
                        {accordionData3.map((item, index) => (
                            <Accordion key={index} title={item.title} content={item.content} />
                        ))}
                    </section> */}

          {/* <div className="app block lg:hidden">
                        {accordionData.map((item, index) => (
                            <Accordion key={index} title={item.title} content={item.content} />
                        ))}
                    </div> */}

          {/*<section id="about-tour" className="listingSection__wrap hidden lg:block">
                        <Tab.Group>
                            <Tab.List className="grid grid-cols-4 gap-0 space-x-0 overflow-x-auto bg-blue-500 rounded-lg">
                                {categories.map((item) => (
                                    <Tab key={item} as={Fragment}>
                                        {({ selected }) => (
                                            <button
                                                className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                                                    ? "bg-blue-500 text-white "
                                                    : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                                    } `}
                                            >
                                                {item}
                                            </button>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel className="">
                                    <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                                        <ul className="list-disc p-6">
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Explore the world of Maldives Parks and Resorts which offers every experience people would want in a holiday, as it transports you into a world all for you!
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                The moonlight… Maldives Creek… The traditionally decorated wooden Dhow… A romantic evening… with a buffet dinner and traditional Arabic background music… This magical mystery ride awaits, setting the tone for your holiday!
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                A city tour of Maldives awaits you the next day, to kickstart your holiday – the beautiful Clubhouse building, Jumeirah Mosque, Jumeirah Open Beach, Burj Al Arab, Burj Khalifa Maldives, Palm Islands.
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Our packages stand apart, and this next feature will prove it! Hop onto the Desert Safari for a thrilling experience of Maldives and end it with a beautiful sunset is sure that will steal your hearts. A delicious barbecue dinner along with a belly dance to end the day the Maldives way.
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Continue your vacay mode with a visit to the Palm Atlantis with a lazy river, water coaster, nine-storey nearly vertical slide into a shark-filled lagoon.
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Then head over to the Lost Chambers and see 65,000 marine animals so closely and rediscover Atlantis
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Miracle Garden and the Maldives Frame are awaiting to mesmerise you the next day!
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Embrace yourself for a thrilling and racy climax by visiting Abu Dhabi Yas Island, which is a popular leisure island in Abu Dhabi. The Ferrari ride in Ferrari World Abu Dhabi at Yas Marina Circuit will get your adrenaline pumping in this Maldives Package with Yas Island!
                                            </li>
                                        </ul>
                                    </div>

                                </Tab.Panel>

                                <Tab.Panel className="">
                                    <div className="mt-4 grid grid-cols-1 gap-0 md:gap-2 sm:grid-cols-1">
                                        <div className="text-xl font-bold "><span>Find Out</span></div>
                                        <ul className="list-disc px-6">
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                To and fro Air fare, Airport transfers, visa fees to join/leave the group for joining and leaving guests
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Anything specifically not mentioned in the ‘tour price includes’ column
                                            </li>
                                        </ul>
                                        <div className="text-lg font-bold "><span>Transport</span></div>
                                        <div className="text-base font-semibold "><span>Air Travel :</span></div>
                                        <ul className="list-disc px-6">
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Bangalore - Maldives // Maldives - Bangalore.
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                We use combination of Airlines like Kuwait Airways/Oman Air/Qatar Airways/Ethihad/Emirates etc.
                                            </li>
                                        </ul>
                                        <div className="text-base font-semibold "><span>Coach Tavel :</span></div>
                                        <ul className="list-disc px-6">
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                A/C coach - Seating capacity depends upon group size
                                            </li>
                                        </ul>
                                        <div className="text-lg font-bold "><span>Documents Required for Travel</span></div>
                                        <ul className="list-disc px-6">
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Original passport with minimum 6 months validity from the date of tour arrival along with sufficient blank pages for the stamping of visa
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                A valid Tourist Visa for the duration of the tour
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Handwritten passport is not acceptable
                                            </li>
                                        </ul>
                                    </div>
                                </Tab.Panel>
                                <Tab.Panel className="">
                                    <div className="-mt-6 ">
                                        <p className="text-base text-justify font-regular pt-4">
                                            Preparing for an international tour involves several important steps. As
                                            everyone wishes to invest in themselves and explore the world, we have a few
                                            helpful tips to ensure a stress-free holiday.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            1. Passport and Visa:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Ensure your passport is valid for at least six months beyond your return
                                            date.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Obtain the necessary visas for your destination(s).
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            2. Travel Itinerary:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - carry a copy of your itinerary, with all the bookings and reservations made
                                            in advance.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            -Share your itinerary with a trusted person and check in regularly.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            3. Health Preparations:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Consult your doctor for required vaccinations or medications.

                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Carry a copy of your medical records and any necessary prescriptions.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            4. Travel Insurance:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Purchase comprehensive travel insurance to cover unexpected events.
                                        </p>

                                        <p className="font-medium text-base pt-2">
                                            5. Money and Finances:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Notify your bank of your travel plans to avoid issues with your cards.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Carry some local currency and a credit/debit card for emergencies.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            6. Packing:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Always make sure to carry less and light luggage, use a four-wheel small or
                                            medium suitcase and please make sure that you are carrying only one suitcase per
                                            person. This will ensure that you will travel smartly and without any
                                            inconveniences.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Create a packing list to ensure you don&#39;t forget essentials.
                                            - Pack weather-appropriate clothing and accessories. If you are travelling
                                            during the monsoon or to destinations where it is rainy, please carry a small
                                            umbrella or poncho
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Don&#39;t forget chargers, adapters, and a travel-sized first-aid kit.
                                            -A smart cap/hat and sunglasses are definitely something that you should
                                            have in your kit.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            7. Important Documents:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Make photocopies of your passport, visa, and travel insurance.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Store digital copies of these documents securely.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            8. Safety and Security:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Research the safety situation at your destination.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            9. Language and Culture:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Learn basic phrases in the local language.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Familiarize yourself with local customs and etiquette.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Ensure that your clothing and footwear is suitable for the destination you
                                            are travelling to.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            10. Electronics:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Bring necessary gadgets (phone, camera, etc.) and chargers.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Check voltage requirements and bring appropriate adapters.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            -For international tours, please carry a universal adapter for charging your
                                            cameras and mobile phones.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            11. Entertainment:

                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Pack books, music, or other forms of entertainment for long flights or
                                            downtime.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            12. Health and Wellness:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Carry any essential medications and a small first-aid kit.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Stay hydrated and maintain a balanced diet while traveling.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            13. Travel Accessories:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Consider items like a neck pillow, eye mask, and earplugs for comfort
                                            during long flights.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            14. Transportation:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Arrange transportation from the airport to your accommodation in advance.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            15. Local Information:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Research local transportation options and maps.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Know emergency numbers and embassy contact information.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            16. Packing Light:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            -It is also recommended that you have one cross shoulder bag or haversack for
                                            your handy needs which will ensure that you are comfortable at the time of
                                            sightseeing and also easy for photography during your tour.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Try to minimize the amount of luggage you bring to make travel easier.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            17. Travel Apps:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Download useful travel apps, such as maps, translation, and currency
                                            conversion.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            18. Safety Gear:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Depending on your destination, consider items like a money belt or RFID-
                                            blocking wallet.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            19. Communication:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Buy a local SIM card or consider international roaming options for your
                                            phone.
                                        </p>
                                        <p className="font-medium text-base pt-2">
                                            20. Enjoy:
                                        </p>
                                        <p className="text-base text-justify font-regular pt-1">
                                            - Finally, relax and enjoy your trip, but stay vigilant and adapt to the local
                                            culture.
                                        </p>
                                        <p className="text-base text-justify font-regular pt-2">
                                            Remember that this checklist can vary depending on your destination and
                                            personal preferences, so adjust it accordingly. Safe travels!
                                        </p>
                                    </div>
                                </Tab.Panel>

                                <Tab.Panel className="">
                                    <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-7 sm:grid-cols-1">
                                        <ul className="text-base font-medium pt-6">
                                            <table className="w-full">
                                                <thead>
                                                    <tr>
                                                        <th style={{ border: '1px solid #949494', padding: '8px', fontSize: '13px', fontWeight: '600', textAlign: 'left' }}>
                                                            Number of days before departure, TRAWEL MART WORLD TOURS
                                                            receives your cancellations
                                                        </th>
                                                        <th style={{ border: '1px solid #949494', padding: '8px', fontSize: '13px', fontWeight: '600', textAlign: 'left' }}>
                                                            Amount of cancellations charge shown as a % of a total
                                                            holiday price you must pay
                                                        </th>
                                                        <th style={{ border: '1px solid #949494', padding: '8px', fontSize: '13px', fontWeight: '600', textAlign: 'left' }}>
                                                            If we cancel your holiday, amount you will receive from us
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontSize: '14px', fontWeight: '400' }}>
                                                            More than 45 days
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            Deposit Only
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            Full Advance Received
                                                        </td>

                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            44 - 30 Days
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            40% of total holiday cost
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            Full Amount Received
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            29 - 15 days
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            60% of total holiday cost
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            Full Amount Received
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            15 days or less
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            90% of total holiday cost
                                                        </td>
                                                        <td style={{ border: '1px solid #949494', padding: '8px', fontWeight: '400', fontSize: '14px' }}>
                                                            Full Amount Received plus 10% of the amount
                                                        </td>
                                                    </tr>
                                                    {/* Add more rows as needed 
                                                </tbody>
                                            </table>
                                        </ul>
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </section>*/}

          {/* Stay Information Ends */}

          {/* Tour Details */}
{/* 
          <section id="policy-terms" className="mt-4 lg:hidden block">
            <span className="lg:text-lg text-xl lg:font-semibold font-semibold">
              Tour Details
            </span>
            <p className="lg:text-base text-sm font-normal">
              Best facilities with no added cost.
            </p>
          </section> */}

          <div className="app block lg:block hidden">
            {accordionData2.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>
          

          {/*<section id="policy-terms" className="listingSection__wrap hidden lg:block">
                        <Tab.Group>
                            <Tab.List className="grid grid-cols-2 gap-0 space-x-0 overflow-x-auto bg-blue-500 rounded-lg">
                                {categories2.map((item) => (
                                    <Tab key={item} as={Fragment}>
                                        {({ selected }) => (
                                            <button
                                                className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                                                    ? "bg-blue-500 text-white "
                                                    : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                                                    } `}
                                            >
                                                {item}
                                            </button>
                                        )}
                                    </Tab>
                                ))}
                            </Tab.List>
                            <Tab.Panels>
                                <Tab.Panel className="">
                                    <div className="-mt-6 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1">
                                        <ul className=" p-6">
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                Making the finest for you!.
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm lg:pb-4 font-normal">
                                                We are trying to reserve the airplane tickets for this trip. We will update it here, as we are done.
                                            </li>
                                            <li className="lg:text-base md:text-base text-sm  font-normal">
                                                Post Booking, you will be notified through your contact details about all tour related updates.
                                            </li>
                                        </ul>
                                    </div>

                                </Tab.Panel>

                                <Tab.Panel className="">
                                    <div className="grid grid-cols-3 gap-6 md:gap-2 lg:grid-cols-3 bg-[#fbf7fb] p-2 border-b border-dashed">
                                        <div className="font-semibold text-lg">
                                            City - Country
                                        </div>
                                        <div className="font-semibold text-lg">
                                            Hotel
                                        </div>
                                        <div className="font-semibold text-lg">
                                            Check In - Check Out
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-6 md:gap-2 lg:grid-cols-3 bg-white p-2 border-b border-dashed">
                                        <div className="font-normal text-base">
                                            Maldives - United Arab Emirates
                                        </div>
                                        <div className="font-normal text-base">
                                            -
                                        </div>
                                        <div className="font-normal text-base">
                                            -
                                        </div>
                                    </div>
                                    <div className="grid grid-cols-3 gap-6 md:gap-2 lg:grid-cols-3 bg-neutral-50 p-2 border-b border-dashed">
                                        <div className="font-normal text-base">
                                            Abu Dhabi - United Arab Emirates
                                        </div>
                                        <div className="font-normal text-base">
                                            -
                                        </div>
                                        <div className="font-normal text-base">
                                            -
                                        </div>
                                    </div>
                                </Tab.Panel>

                            </Tab.Panels>
                        </Tab.Group>
                    </section>*/}

          {/* Tour Details */}

          <section id="reviews" className="mt-4">
            <span className="lg:text-xl text-xl font-semibold">
              Upgrades Available
            </span>
            <p className="">Want luxury? Add luxury at minimum cost!</p>
          </section>

          <div className="app block lg:hidden">
            {accordionData2.map((item, index) => (
              <Accordion
                key={index}
                title={item.title}
                content={item.content}
              />
            ))}
          </div>

          <section className="listingSection__wrap hidden lg:block">
            <Tab.Group>
              <Tab.List className="grid grid-cols-2 gap-0 space-x-0 overflow-x-auto bg-blue-500 rounded-lg">
                {categories3.map((item) => (
                  <Tab key={item} as={Fragment}>
                    {({ selected }) => (
                      <button
                        className={`flex-shrink-0 block !leading-none font-medium px-5  py-2.5 text-sm sm:text-base sm:px-4 sm:py-3 capitalize focus:outline-none ${selected
                          ? "bg-blue-500 text-white "
                          : "text-blue-800 border-blue-500 border-b-2 bg-white dark:text-neutral-400  dark:hover:text-neutral-100 dark:hover:bg-neutral-800"
                          } `}
                      >
                        {item}
                      </button>
                    )}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel className="">
                  <div className="-mt-2 grid grid-cols-1 gap-6 md:gap-0 sm:grid-cols-1 text-base font-regular">
                    Need to upgrade to business or first class? Please get in
                    touch with our team on +91 8046427999 for more details.
                  </div>
                </Tab.Panel>

                <Tab.Panel className="">
                  <div className="grid grid-cols-1 gap-0 md:gap-2 lg:grid-cols-1">
                    <div className="font-regular text-normal">
                      You can select the Hotel Upgrade in your coach at an
                      additional cost. Please get in touch with our team on +91
                      8046427999 for more details.
                    </div>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </section>

          {/*renderSection3()*/}
          {/*renderSection4()}
                    {/*<SectionDateRange />*/}
          {/*renderSection5()*/}
          {/*renderSection7()*/}
          {/*renderSection8()*/}
          <section id="reviews">{/*renderSection6() */}</section>
        </div>

        {/* SIDEBAR */}
        <div className="block lg:block flex-grow mt-14 lg:mt-0 lg:mb-0 mb-12">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>
    </div>
  );
};

export default function Hotel2() {
  return (
    <DetailPagetLayout>
      <StayDetailsPageContainer />
    </DetailPagetLayout>
  );
}
