import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import worldpackages from "images/sample/worldpackages.png";
import mumbai from "images/contact-us/mumbai.png";
import globess from "images/sample/globess.png";
import homeimage from "images/homeimage.png";


export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {
  return (
    <div
      className={` nc-SectionOurFeatures relative flex flex-col items-center ${type === "type1" ? "lg:flex-row" : "lg:flex-row-reverse"
        } ${className}`}
      data-nc-id="SectionOurFeatures"
    >
      <div className="flex-grow">
      <img className="w-full block dark:hidden textcenter" src={homeimage} alt="logo1" />
      </div>
      <div
        className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-4/5 ${type === "type1" ? "lg:pl-16" : "lg:pr-16"
          }`}
      >
        <span className="font font-semibold uppercase text-sm text-blue-500 tracking-widest">
          BEnefits
        </span>
        <h2 className="font-bold text-4xl mt-2">We Create the trips you love</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400 font-semibold">
          We take the best of what we have learned over our 25 years of experience.
        </span>
        <ul className="space-y-2">
        {/*  <li className="space-y-4">
            <Badge name="Advertising" />
            <span className="block text-xl font-semibold">
              Cost-effective advertising
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              With a free listing, you can advertise your rental with no upfront
              costs
            </span>
          </li>*/}
          
            <li className="space-y-2">
            <div className="w-10/12 relative mt-8 flex rounded-lg shadow dark:shadow-2xl bg-blue-50 dark:bg-neutral-800 ">
              <div className="flex lg:flex-row items-center p-2">
              <div className="lg:w-4/12 textcenter">
              <img className="w-12 block dark:hidden textcenter" src={mumbai} alt="logo1" />
                </div>
                <div className="lg:w-12/12 py-2">
                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-blue-800 bg-blue-100  relative">
                 India Packages
                  </span>
                  <span className="font block mt-2 text-neutral-500 font-medium text-sm dark:text-neutral-400">
                  Explore India, earn rewards and live the best adventures with Trawel Mart.
                  </span>
                </div>
              </div>
            </div>
           
            {/*<Badge color="green" name="Exposure " />
            <span className="block text-xl font-semibold">
              Reach millions with Chisfis
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              Millions of people are searching for unique places to stay around
              the world
        </span>*/}
          </li>
          <li className="space-y-2">
            <div className="w-10/12 relative mt-8 flex rounded-lg shadow dark:shadow-2xl bg-green-50 dark:bg-neutral-800 ">
              <div className="flex lg:flex-row items-center p-2">
              <div className="lg:w-4/12 textcenter">
              <img className="w-12 block dark:hidden textcenter" src={worldpackages} alt="logo1" />
                </div>
                <div className="lg:w-12/12 py-2">
                  <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-green-800 bg-green-100  relative">
                  World Packages
                  </span>
                  <span className="font block mt-2 text-neutral-500 font-medium text-sm dark:text-neutral-400">
                  hotel, VISA, sightseeings, all designed keeping in mind your interests!
                  </span>
                </div>
              </div>
            </div>
            </li>

            <li className="space-y-2">
            <div className="w-10/12 relative mt-8 flex rounded-lg shadow dark:shadow-2xl bg-red-50 dark:bg-neutral-800 ">
              <div className="flex lg:flex-row items-center p-2">
              <div className="lg:w-4/12 textcenter">
              <img className="w-12 block dark:hidden textcenter" src={globess} alt="logo1" />
                </div>
                <div className="lg:w-12/12 py-2">
                <span className="nc-Badge inline-flex px-2.5 py-1 rounded-full font-medium text-xs relative text-red-800 bg-red-100  relative">
                  All Packages
                  </span>
                  <span className="font block mt-2 text-neutral-500 font-medium text-sm dark:text-neutral-400">
                    Millions of people are searching for unique places to stay around
                    the world
                  </span>
                </div>
              </div>
            </div>
            </li>

        {/*  <li className="space-y-4">
            <Badge color="red" name="Secure" />
            <span className="block text-xl font-semibold">
              Secure and simple
            </span>
            <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
              A Holiday Lettings listing gives you a secure and easy way to take
              bookings and payments online
            </span>
      </li>*/}
        </ul>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
