import rightImg from "images/about-hero-right.png";
import React, { FC, useState } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import $ from 'jquery';
import quotation from "images/quotation.png";
import icon1 from "images/sample/icon1.png";
import icon2 from "images/sample/icon2.png";
import icon5 from "images/sample/icon5.png";
import icon4 from "images/sample/icon4.png";
import PagePopup from "containers/PageHome/PagePopup";
import bgg from "images/about/bgg.png";
import PageEnquiryy from "containers/PageHome/PageEnquiryy";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {

  const totalSteps = 3;
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const stepsContent = [
    {
      title: 'Our Vision',
      content: (
        <div>
          <p>
            To establish and maintain TRAWEL MART as the most highlighted travel agency.
            A reliable and enthusiastic tour operator with superior customer service to provide extra value
            added service to the customers.
          </p>
        </div>
      ),
    },
    {
      title: 'Our Mission',
      content: (
        <div>
          <p>
            To continuously expand our customer base by being a proactive travel service company
            and provide a comprehensive range of travel services products. To be one of the foremost and innovative
            travel companies in the world.
          </p>
        </div>
      ),
    },
    {
      title: 'Founders Motto',
      content: (
        <div>
          <p>
            Going above and beyond to provide excellent, world-class affordable travel with customer service and satisfaction as the key.
            To bring in creative and innovative tour packages to cater to customers from all walks of life.
          </p>
        </div>
      ),
    },
  ];


  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber: React.SetStateAction<number>) => {
    setActiveTab(tabNumber);
  };

  return (
    <div
      className={`font nc-PageAbout overflow-hidden relative font ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>
          About Us - Trawel Mart 
        </title>
        <meta
          name="description"
          content="We offer services including world tour packages, custom itineraries, expert guides, and hassle-free bookings. Explore the world with confidence and ease."
        />
        <meta
          name="keywords"
          content="Tour packages, Best tour packages, Best tour packages company, Travel Company in Bangalore, Visa Packages"
        />
      </Helmet>
      {/*<BgGlassmorphism />*/}

      {/* ======== BG GLASS ======== */}

      <div className="font pt-16 pb-2 lg:pt-12 space-y-16 lg:space-y-2">
        <SectionHero className="" />
      </div>



      <div className="container py-16 lg:py-0 space-y-6 lg:space-y-2 pb-16 xl:mb-24">
        <div className="tab-buttons text-center">
          <div className="grid xl:grid-cols-3 xl:gap-0 gap-2 xl:px-32">

            <button
              className={activeTab === 1 ? 'activeeeee xl:text-xl' : 'border-sky-600 border mx-4 rounded-lg xl:text-xl xl:py-0 py-2'}
              onClick={() => handleTabClick(1)}
            >
              Our Vision
            </button>

            <button
              className={activeTab === 2 ? 'activeeeee xl:text-xl' : 'border-sky-600 border mx-4 rounded-lg xl:text-xl xl:py-0 py-2'}
              onClick={() => handleTabClick(2)}
            >
              Our Mission
            </button>
            <button
              className={activeTab === 3 ? 'activeeeee xl:text-xl' : 'border-sky-600 border mx-4 rounded-lg xl:text-xl xl:py-0 py-2'}
              onClick={() => handleTabClick(3)}
            >
              Founder Motto
            </button>
          </div>
        </div>

        <div className="tab-content text-center">
          {activeTab === 1 &&
            <div className="content-center xl:px-52 xl:pt-6 xl:text-xl">
              To establish and maintain TRAWEL MART as the most highlighted travel agency.
              A reliable and enthusiastic tour operator with superior customer service to provide extra value
              added service to the customers.
            </div>}
          {activeTab === 2 &&
            <div className="content-center xl:px-52 xl:pt-6 xl:text-xl">
              To continuously expand our customer base by being a proactive travel service company
              and provide a comprehensive range of travel services products. To be one of the foremost and innovative
              travel companies in the world.
            </div>}
          {activeTab === 3 &&
            <div className="content-center xl:px-52 xl:pt-6 xl:text-xl">
              Going above and beyond to provide excellent, world-class affordable travel with customer service and satisfaction as the key.
              To bring in creative and innovative tour packages to cater to customers from all walks of life.
            </div>}
        </div>
      </div>

      <PageEnquiryy />

      {/* ======== Vision Value ======== */}

      <div className="font container py-2 lg:py-0 space-y-16 lg:space-y-2">
       

          <div className="xl:pb-12  xl:px-32 text-center">
            <span className="font-bold xl:text-4xl text-2xl text-sky-600 text-center">
              Our Story
            </span>
            <p className="font-regular xl:text-lg text-gray-600 text-sm sm:text-xs lg:pt-3 pt-2 xl:pr-0 pr-4 ">
              Trawel Mart is a brain child of Mr.K Mohan Sundar
              a visionary of speciality travel concepts and a renowned name in the travel industry with an
              enriching travel experience of over 20 years.
            
              Our endeavour has been to be outstanding in designing tailor made national and international tour
              packages. Regarded as experts we enhance the travel experience with world class standards in
              service and best possible prices for any destination worldwide. Insight, expertise, personalizing
              travel experience and dedicated personal service has been the USP of this company.
            </p>
          </div>
         


          {/* <div className="lg:w-5/12 sm:w-6/12  border rounded-lg shadow-md xl:pb-2 xl:mt-0 mt-6 sm:mt-1"
            style={{
              backgroundImage: `url("https://i.ibb.co/sW485bY/gridbanner.png")`,
              height: "280px",
              backgroundSize: "cover",
            }} >

            <div className="wizard-form">
              <div className="btn">
                {stepsContent.map((step, index) => (
                  <div
                    key={`step${index + 1}`}
                    className={`step step${index + 1} ${currentStep === index + 1 ? '' : 'hidden'}`}
                  >
                    <div className="px-6 pt-3">
                      <img src={quotation} alt="" className="xl:w-10 xl:h-8 w-8 h-6 lg:-mb-3 -mb-3" />
                      <span className="font xl:text-xl text-base text-neutral-800 font-semibold uppercase">
                        {step.title}
                      </span>
                      <div className="text-sm xl:text-lg lg:text-base md:text-sm pt-2">
                        {step.content}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <div className="pt-4">
                <div className="flex flex-col lg:flex-row items-center">
                  <div className="lg:w-5/12">
                    {/* Any additional content 
                  </div>
                  <div className="lg:w-7/12 w-full text-right items-center xl:pb-2">
                    {currentStep > 1 && (
                      <button
                        id="prevButton1"
                        className="text-2xl text-sky-800 font-bold rounded-lg px-4 bg-sky-100 xl:mr-4 mr-2"
                        onClick={handlePrev}
                      >
                        &larr;
                      </button>
                    )}

                    {currentStep < totalSteps ? (
                      <button
                        id="nextButton1"
                        className="text-2xl text-sky-800 font-bold rounded-lg px-4 bg-sky-100 xl:mr-4 mr-2"
                        onClick={handleNext}
                      >
                        &rarr;
                      </button>
                    ) : (
                      <button
                        id="submitButton1"

                        onClick={() => {
                          // Handle form submission
                        }}
                      >

                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>*/}
       
      </div>

      {/*<div className="bg-neutral-100 xl:py-6 xl:mt-0 mt-5"
      >
        <div className="container py-5 lg:py-6 space-y-4 lg:space-y-2">
          <div className="grid lg:grid-cols-4 lg:gap-2 grid-cols-2 gap-4 lg:px-24">
            <div className="text-center justify-center">
              <div className="justify-center text-center center">
                <img src={icon1} alt="" className="xl:w-20 xl:h-20 w-14 h-14" />
              </div>
              <span className="xl:text-base text-sm font-regular font xl:pt-0 pt-4">
                2 Decades of Experience and Expertise
              </span>
            </div>

            <div className="text-center justify-center">
              <div className="justify-center text-center center">
                <img src={icon2} alt="" className="xl:w-20 xl:h-20 w-14 h-14" />
              </div>
              <span className="xl:text-base text-sm font-regular font xl:pt-0 pt-4">
                2 Lakhs+ satisfied & thrilled customers
              </span>
            </div>

            <div className="text-center justify-center">
              <div className="justify-center text-center center">
                <img src={icon2} alt="" className="xl:w-20 xl:h-20 w-14 h-14" />
              </div>
              <span className="xl:text-base text-sm font-regular font xl:pt-0 pt-4">
                Lid by ingenious & skillfull <br></br>Industry Experts
              </span>
            </div>


            <div className="text-center justify-center">
              <div className="justify-center text-center center">
                <img src={icon4} alt="" className="xl:w-20 xl:h-20 w-14 h-14" />
              </div>
              <span className="xl:text-base text-sm font-regular font xl:pt-0 pt-4">
                Personalized care at every <br></br>phase of booking
              </span>
            </div>

          </div>
        </div>
      </div>*/}

      {/* ======== Vision Value ENDS======== */}















      <div className="container py-16 lg:py-6 space-y-4 lg:space-y-2 pb-16 xl:mb-24">
        <div className="lg:pb-0 md:pb-0 pb-6 mb-0">
          <SectionFounder />
        </div>
        <div className="relative lg:pt-0 md:pt-0 lg:pb-0 md:pb-0  pt-0 pb-6 ">
          {/*<BackgroundSection />*/}
          <SectionClientSay uniqueClassName="PageAbout_" />
        </div>

        {/*<SectionStatistic />*/}

        {/* <SectionSubscribe2 />*/}
      </div>
    </div>
  );
};

export default PageAbout;
