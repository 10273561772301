import { DEMO_POSTS } from "data/posts";
import { PostDataType } from "data/types";
import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";

const DubaiBlog = () => {
  const renderHeader = () => {
    return (
      <header className="font container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="purple" name="Dubai" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            A Fascinating Fusion of Tradition and Modernity
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
            Nestled on the southeastern coast of the Arabian Peninsula, Dubai stands as a testament to
            human ambition and innovation.
          </span>

          {/*<div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-baseline sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a className="block font-semibold" href="/">
                    Fones Mimi
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    May 20, 2021
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    6 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-0 sm:ml-3">
              <SocialsList />
            </div>
          </div>*/}
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="font prose dark:prose-invert prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-dark"
      >
        <p>
          This dynamic city, characterized by its iconic skyline, luxurious lifestyle, and cultural diversity,
          has captured the imagination of travelers from around the world. Let's embark on a journey through the
          enchanting tapestry of Dubai, where ancient traditions harmoniously coexist with modern marvels.
        </p>
        {/*<p>
          It is a long established fact that a <strong>reader</strong> will be
          distracted by the readable content of a page when looking at its{" "}
          <strong>layout</strong>. The point of using Lorem Ipsum is that it has
          a more-or-less normal{" "}
          <a href="/#" target="_blank" rel="noopener noreferrer">
            distribution of letters.
          </a>{" "}
        </p>
        <ol>
          <li>We want everything to look good out of the box.</li>
          <li>
            Really just the first reason, that's the whole point of the plugin.
          </li>
          <li>
            Here's a third pretend reason though a list with three items looks
            more realistic than a list with two items.
          </li>
    </ol>*/}
        <h3 className="font ">A Glimpse into the Past</h3>
        <p>
          Dubai's history dates back to ancient times, when it was a modest fishing village situated along the
          bustling trade routes of the Arabian Gulf. The city's transformation from a pearl-diving center to a
          global metropolis is a tale of resilience and vision. The Dubai Museum, housed within the historic Al
          Fahidi Fort, offers a captivating narrative of the city's evolution, showcasing artifacts and exhibits
          that take visitors on a captivating journey through time.
        </p>
        {/*<p>Something a wise person once told me about typography is:</p>
        <blockquote>
          <p>
            Typography is pretty important if you don't want your stuff to look
            like trash. Make it good then it won't be bad.
          </p>
  </blockquote>*/}
        <h3 className="font ">The Spectacle of Architecture</h3>
        <p>
          Dubai's skyline is an architectural marvel that has redefined the possibilities of urban design. The iconic
          Burj Khalifa, the tallest building in the world, pierces the heavens with its futuristic design and offers
          panoramic views that stretch across the city's vast expanse. The Palm Jumeirah, a man-made island shaped
          like a palm tree, and the intricate lattice of the Dubai Frame showcase the city's commitment to pushing
          the boundaries of imagination.
        </p>
        {/*<figure>
          <img
            src="https://i.ibb.co/nD6qcS0/desertadventure.jpg"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
            Dubai is synonymous with luxury, and its opulent offerings cater to the most discerning of travelers.
            The Burj Al Arab, often hailed as the world's only 7-star hotel, stands as an emblem of extravagance.
          </figcaption>
</figure>*/}
        <p>
          With its sail-like silhouette and unparalleled service, it has become an iconic symbol of Dubai's hospitality.
          The city's array of luxurious resorts, world-class shopping malls, and Michelin-starred restaurants redefine the
          meaning of indulgence.
        </p>
        {/*<ul>
          <li>So here is the first item in this list.</li>
          <li>In this example we're keeping the items short.</li>
          <li>Later, we'll use longer, more complex list items.</li>
        </ul>
        <p>And that's the end of this section.</p>
        <h2>Code should look okay by default.</h2>
        <p>
          I think most people are going to use{" "}
          <a href="https://highlightjs.org/">highlight.js</a> or{" "}
          <a href="https://prismjs.com/">Prism</a> or something if they want to
          style their code blocks but it wouldn't hurt to make them look{" "}
          <em>okay</em> out of the box, even with no syntax highlighting.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p>

        <p>Hopefully that looks good enough to you.</p>
        <h3>We still need to think about stacked headings though.</h3>
        <h4>
          Let's make sure we don't screw that up with <code>h4</code> elements,
          either.
        </h4>
        <p>
          Phew, with any luck we have styled the headings above this text and
          they look pretty good.
        </p>
        <p>
          Let's add a closing paragraph here so things end with a decently sized
          block of text. I can't explain why I want things to end that way but I
          have to assume it's because I think things will look weird or
          unbalanced if there is a heading too close to the end of the document.
        </p>
        <p>
          What I've written here is probably long enough, but adding this final
          sentence can't hurt.
        </p>*/}

        <h3 className="font ">Cultural Melting Pot</h3>
        <p>
          While Dubai is celebrated for its modernity, its cultural fabric is woven with diverse threads from around
          the world. The Al Fahidi neighborhood, also known as Al Bastakiya, is a charming enclave where traditional
          wind-tower architecture and narrow alleys transport visitors to another era. The district is home to art
          galleries, cultural centers, and the Dubai Museum, offering a glimpse into the city's humble beginnings.
        </p>

        <h3 className="font ">Sensory Delights</h3>
        <p>
          Dubai's bustling markets, known as souks, provide an immersive experience for visitors seeking
          authentic flavors and fragrances. The Gold Souk, with its dazzling display of intricate jewelry,
          and the Spice Souk, where aromatic spices tickle the senses, offer a sensory journey that captures
          the essence of the city's heritage.
        </p>

        <h3 className="font ">Desert Adventures</h3>
        <p>
          Beyond the urban landscape lies the mesmerizing Arabian Desert, a playground for adventure enthusiasts.
          Experience the thrill of dune bashing as skilled drivers navigate the undulating sands in 4x4 vehicles. For a
          tranquil escape, embark on a serene camel trek and witness the sun setting over the golden dunes, painting
          the sky with hues of orange and pink.
        </p>

        <h3 className="font ">Extravaganza and Innovation</h3>
        <p>
          Dubai's commitment to innovation is epitomized by its awe-inspiring projects. The Dubai Mall, not just a
          shopping haven but a world of entertainment with an indoor ice rink, an underwater zoo, and the Dubai Aquarium,
          showcases the city's fusion of creativity and extravagance. The Dubai Expo 2020, a global event that
          showcased innovation and sustainable solutions, underscored Dubai's role as a hub of creativity and collaboration.
        </p>

        <p>
          In conclusion, Dubai is a city that defies categorization. It's a captivating blend of tradition and
          modernity, where ancient stories intertwine with futuristic visions. Whether you're gazing at the city's
          skyline from the observation deck of the Burj Khalifa, exploring the vibrant souks, or immersing yourself
          in the desert's tranquility, Dubai offers an enchanting experience that leaves an indelible mark on your soul.
          As you journey through this desert gem, you'll discover that Dubai is not just a destination—it's a multifaceted
          masterpiece that continues to evolve, enchant, and inspire.
        </p>





      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 dark:text-neutral-300 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24" />
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/">Fones Mimi</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              There’s no stopping the tech giant. Apple now opens its 100th
              store in China.There’s no stopping the tech giant.
              <a className="text-primary-6000 font-medium ml-1" href="/">
                Readmore
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (post: PostDataType) => {
    return (
      <div
        key={post.id}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={post.href} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={post.featuredImage}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{post.title}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {post.author.displayName}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">{post.date}</span>
          </div>
        </div>
        <Link to={post.href} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>Trawel Mart</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://i.ibb.co/z8TvfZZ/blog2.png"
      />

      <div className="nc-SingleContent container space-y-10 mb-24">
        {renderContent()}
        {/*renderTags()
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
        {renderCommentForm()}
        {renderCommentLists()}*/}
      </div>
      {/* <div className="relative bg-neutral-100 dark:bg-neutral-800 py-16 lg:py-28 mt-16 lg:mt-24">
        <div className="container ">
          <h2 className="text-3xl font-semibold">Related posts</h2>
          <div className="mt-10 grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8">
            {/*  
            {DEMO_POSTS.filter((_, i) => i < 4).map(renderPostRelated)}
            {/*  
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default DubaiBlog;
